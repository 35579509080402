import {GET_LOGGING_DATA} from 'shared/constants/ActionTypes';

const initialState = {
  loggingData: [],
};

const reducer = (state = initialState, action) => {
  if (action.type == GET_LOGGING_DATA) console.log('Success request data');
  switch (action.type) {
    case GET_LOGGING_DATA:
      return {
        ...state,
        loggingData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
