import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import styles from './TagForm.module.css';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const TagForm = ({
  addLink,
  fieldName,
  fieldValue,
  href,
  onCheckedChange,
  onTextValueChange,
  index,
  removeTag,
}) => {
  return (
    <TableRow>
      <TableCell style={{minWidth: '100px'}}>
        <Checkbox
          name='addLink'
          onChange={(event) => onCheckedChange(event, index)}
          checked={addLink}
        />
      </TableCell>
      <TableCell>
        <TextField
          name='fieldName'
          id='tag'
          variant='outlined'
          value={fieldName}
          onChange={(event) => onTextValueChange(event, index)}
          required
          autoFocus
        />
      </TableCell>
      <TableCell>
        <TextField
          name='fieldValue'
          id='value'
          variant='outlined'
          value={fieldValue}
          onChange={(event) => onTextValueChange(event, index)}
          required
        />
      </TableCell>
      <TableCell style={{minWidth: '100px'}}>
        {addLink == true ? (
          <TextField
            name='href'
            id='href'
            variant='outlined'
            value={href}
            onChange={(event) => onTextValueChange(event, index)}
            required
          />
        ) : (
          'No Url'
        )}
      </TableCell>
      <TableCell>
        <Button
          className={styles.removeTag}
          onClick={() => removeTag(index)}
          size='small'
          variant='contained'
          color='secondary'
        >
          Remove
        </Button>
      </TableCell>
    </TableRow>
  );
};

TagForm.propTypes = {
  addLink: PropTypes.bool,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
  href: PropTypes.string,
  onCheckedChange: PropTypes.func,
  onTextValueChange: PropTypes.func,
  index: PropTypes.number,
  removeTag: PropTypes.func,
};

export default TagForm;
