import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import TextField from '@mui/material/TextField';
import styles from './TwoStepVerification.module.css';
import {Button} from '@mui/material';
import MailSharpIcon from '@mui/icons-material/MailSharp';
import {Box} from '@mui/material';

const iconSize = {fontSize: 60};

const TwoStepVerification = ({
  open,
  handleModalClose,
  emailVerification,
  type,
}) => {
  const [count, setCount] = useState(120);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount <= 1) {
          handleModalClose();
          clearInterval(intervalId);
          return prevCount;
        } else {
          return prevCount - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <ModalContainer open={open} handleModalClose={handleModalClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className={styles.otpVerifyContainer}>
          <MailSharpIcon color='primary' sx={iconSize} />
          <h2>Please enter the code we just sent to your {type}.</h2>
          <p>This screen will close in {count} seconds.</p>
          <form onSubmit={emailVerification}>
            <TextField
              id='authentication-code'
              placeholder='Authentication Code'
              variant='outlined'
              required
              name='name'
            />
            <div className={styles.btnContainer}>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleModalClose}
              >
                Cancel
              </Button>
              <Button variant='contained' type='submit'>
                Verify
              </Button>
            </div>
          </form>
        </div>
      </Box>
    </ModalContainer>
  );
};

TwoStepVerification.propTypes = {
  handleModalClose: PropTypes.func,
  open: PropTypes.bool,
  emailVerification: PropTypes.func,
  type: PropTypes.string,
};

export default TwoStepVerification;
