import {useState} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {useTheme} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import History from '../History/History';
import CommentSection from '../CommentSection/CommentSection';
import HistorySharpIcon from '@mui/icons-material/HistorySharp';
import CommentSharpIcon from '@mui/icons-material/CommentSharp';
import styles from './DetailsTab.module.css';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function DetailsTab({
  history,
  assigned,
  comments,
  _id,
  writeable,
  tagsIds,
}) {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const detailsTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{width: '100%'}}>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={detailsTabChange}
          textColor='inherit'
          indicatorColor='secondary'
          aria-label='full width tabs example'
        >
          <Tab
            iconPosition='start'
            icon={<HistorySharpIcon />}
            label='History'
            {...a11yProps(0)}
          />
          <Tab
            iconPosition='start'
            sx={{borderLeft: '1px solid white'}}
            icon={<CommentSharpIcon />}
            label='Comment'
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          <div id='history' className={styles.historyContainer}>
          <History history={history} assigned={assigned} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CommentSection
            _id={_id}
            comments={comments}
            detailsTabChange={detailsTabChange}
            writeable={writeable}
            tagsIds={tagsIds}
          />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}

DetailsTab.propTypes = {
  history: PropTypes.array,
  tagsIds: PropTypes.array,
  assigned: PropTypes.object,
  comments: PropTypes.array,
  _id: PropTypes.string,
  readOnly: PropTypes.bool,
  writeable: PropTypes.bool,
};
