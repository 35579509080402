import jwtAxios from '@crema/services/auth/jwt-auth';
import {
  GET_LOGGING_DATA,
  IS_API_IN_PROCESS,
  FETCH_ERROR,
} from 'shared/constants/ActionTypes';

export const getData = (query) => {
  return (dispatch) => {
    //make the request to the api
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    jwtAxios
      .get(`/logging/entries?${query}`)
      .then((response) => {
        dispatch({
          type: GET_LOGGING_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};
