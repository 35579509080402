import React from 'react';
import PropTypes from 'prop-types';
import styles from './VulnerabilityTrace.module.css';
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp';

const SingleVulnerabilityTrace = ({traces, traceItem, index}) => {
  return (
    <div className={styles.trace}>
      <div className={styles.traceHeader}>
        <h5>{traceItem['ASTNodeType']} </h5>
        <p className={styles.NodeName}>{traceItem['ASTNodeName']}</p>
        <p>
          (
          {traceItem['filename'].substring(
            traceItem['filename'].lastIndexOf(
              '/',
              traceItem['filename'].lastIndexOf('/') - 1,
            ) + 1,
          )}
          &nbsp; Line: {traceItem['line']} Col: {traceItem['col']})
          {traceItem['params'].map((item) => {
            return (
              <p key={item._id}>
                Parameter: {item.paramName} (Type: {item.paramType})
              </p>
            );
          })}
        </p>
      </div>
      <p className={styles.codeSnippet}>{traceItem['codeSnippet']}</p>
      {traces.length - 1 != index && (
        <div className={styles.arrowContainer}>
          <ArrowDownwardSharpIcon color='primary' sx={{fontSize: 40}} />
        </div>
      )}
    </div>
  );
};

SingleVulnerabilityTrace.propTypes = {
  traceItem: PropTypes.object,
  traces: PropTypes.array,
  index: PropTypes.number,
};

const VulnerabilityTrace = ({traces, exploits}) => {
  // const getLastTracedItem = (traces) => {
  //   return traces[0].length == 0 ? null : traces[0][traces[0].length - 1];
  // };

  return (
    <div className={styles.TraceContainer}>
      {traces?.length > 0 || exploits.length > 0 ? (
        <h3>Vulnerability Trace</h3>
      ) : (
        <h3>No Vulnerability Trace Present</h3>
      )}

      {traces && traces?.length && traces?.map((traceItem, index) => {
        return (
          <SingleVulnerabilityTrace
            key={traceItem._id}
            traceItem={traceItem}
            index={index}
            traces={traces}
          />
        );
      })}

      {/* {full == false && getLastTracedItem(traces) != null && (
        <SingleVulnerabilityTrace
          traceItem={getLastTracedItem(traces)}
          traces={traces}
          index={0}
        />
      )} */}
    </div>
  );
};

export default VulnerabilityTrace;

VulnerabilityTrace.propTypes = {
  traces: PropTypes.array,
  full: PropTypes.bool,
  exploits: PropTypes.string,
};
