import React from 'react';
import styles from './FrontPage.module.css';
import s4img from '../../../assets/icon/white-horizontal-transparent.svg';
import hipaa from '../../../assets/images/Report/HIPAA.svg';
import appi from '../../../assets/images/Report/APPI.svg';
import gdpr from '../../../assets/images/Report/GDPR.svg';
import iso from '../../../assets/images/Report/ISO 27001.svg';
import pci from '../../../assets/images/Report/PCI.svg';
import {useParams} from 'react-router-dom';

export const FrontPage = () => {
  let options1 = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  let options = {timeStyle: 'long', hour12: true};

  
  let {violations} = useParams();

  const getDate = () => {
    // let date = new Date();
    // return date.toGMTString();

    return (
      new Date().toLocaleString('en-us', options1) +
      ' ' +
      new Date().toLocaleString('en-us', options)
    );
  };

  return (
    <div>
      <div className={styles.frontPage}>
        <div className={styles.frontInfo}>
          <div className={styles.frontImageContainer}>
            <img className={styles.mainfrontImage} src={s4img}/>
            {violations?.includes('APPI') && (
              <img className={styles.frontImage} src={appi} />
            )}
            {violations?.includes('GDPR') && (
              <img className={styles.frontImage} src={gdpr} />
            )}
            {violations?.includes('HIPAA') && (
              <img className={styles.frontImage} src={hipaa} />
            )}
            {violations?.includes('ISO 2027001') && (
              <img className={styles.frontImage} src={iso} />
            )}
            {violations?.includes('PCI') && (
              <img className={styles.frontImage} src={pci} />
            )}
          </div>

          <h1 className={styles.titleFrontPage}>
            {violations == undefined
              ? 'DigitSec Security Report'
              : 'DigitSec Security Report with APPI, GDPR, HIPAA, ISO 27001, PCI violations'}
          </h1>
          <p>{getDate()}</p>
        </div>
      </div>
    </div>
  );
};
