import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import {Box} from '@mui/material';
import MessageSharpIcon from '@mui/icons-material/MessageSharp';
import TextField from '@mui/material/TextField';
import styles from './VerifyPhoneNumber.module.css';
import {Button} from '@mui/material';
import {useDispatch} from 'react-redux';
import {onPostUpdatePhone} from 'redux/actions/Profile';

const iconSize = {fontSize: 60};

const VerifyPhoneNumber = ({profileModalClose, internationalCode, phone}) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(120);
  const [twofatoken, setTwofatoken] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount <= 1) {
          profileModalClose();
          clearInterval(intervalId);
          return prevCount;
        } else {
          return prevCount - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const verifyPhone = (event) => {
    event.preventDefault();
    dispatch(onPostUpdatePhone({internationalCode, phone, twofatoken}));
  };

  const onFormChange = (event) => {
    setTwofatoken(event.target.value);
  };

  return (
    <ModalContainer open={true} handleModalClose={profileModalClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className={styles.vpContainer}>
          <MessageSharpIcon color='primary' sx={iconSize} />
          <h2>Please enter the code we just sent to your phone</h2>
          <p>This screen will close in {count} seconds.</p>
          <form onSubmit={verifyPhone}>
            <TextField
              id='authentication-code'
              placeholder='Authentication Code'
              variant='outlined'
              required
              onChange={onFormChange}
              value={twofatoken}
            />
            <div className={styles.btnContainer}>
              <Button
                variant='contained'
                color='secondary'
                onClick={profileModalClose}
              >
                Cancel
              </Button>
              <Button variant='contained' type='submit'>
                Verify
              </Button>
            </div>
          </form>
        </div>
      </Box>
    </ModalContainer>
  );
};

VerifyPhoneNumber.propTypes = {
  profileModalClose: PropTypes.func,
  internationalCode: PropTypes.string,
  phone: PropTypes.string,
};

export default VerifyPhoneNumber;
