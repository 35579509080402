import React from 'react';
import {InfinitySpin} from 'react-loader-spinner';

function ProcessLoader() {
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <InfinitySpin color='grey' />
    </div>
  );
}
export default ProcessLoader;
