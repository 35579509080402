import React from 'react';
import PropTypes from 'prop-types';

function IssueFindings({traces, codesnippets}) {
  return (
    <div>
      <h3>Issue (Code Snippet, Exploit)</h3>
      {traces != null && traces != undefined && traces.length > 0 && (
        <p>{traces[traces.length - 1]['codeSnippet']}</p>
      )}
      {codesnippets != null && <p>{codesnippets}</p>}
    </div>
  );
}

IssueFindings.propTypes = {
  traces: PropTypes.array,
  codesnippets: PropTypes.string,
};

export default IssueFindings;
