import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import React from 'react';
import styles from './HeaderFindings.module.css';
import MemorySharpIcon from '@mui/icons-material/MemorySharp';
import VerifiedUserSharpIcon from '@mui/icons-material/VerifiedUserSharp';
import DateRangeSharpIcon from '@mui/icons-material/DateRangeSharp';
function HeaderIssues({
  index,
  severity,
  issue,
  filename,
  line,
  status,
  founddate,
  issueId,
  _id,
}) {
  let options1 = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  let options = {timeStyle: 'long', hour12: true};

  const getColor = () => {
    let color = '#FFFFFF';
    switch (severity) {
      case 'high':
        color = '#AB0906';
        break;
      case 'medium':
        color = '#f4a227';
        break;
      case 'low':
        color = '#0F9AEE';
        break;
      case 'critical':
        color = '#f64225';
        break;
      default:
        color = '#AB0906';
    }

    return color;
  };

  const issueTitle =
    filename && filename !== 'NA' && filename !== ''
      ? line && line !== 0
        ? `${index + 1} - ${issue} in file ${filename} on line: ${line}`
        : `${index + 1} - ${issue} in file ${filename}`
      : `${index + 1} - ${issue}`;

  const getReadableDate = () => {
    // const readableDate = new Date(founddate);
    // return readableDate.toString();

    return (
      new Date(founddate).toLocaleString('en-us', options1) +
      ' ' +
      new Date(founddate).toLocaleString('en-us', options)
    );
  };

  return (
    <div className={styles.issueHeader}>
      <div>
        <Chip
          label={severity.toUpperCase()}
          sx={{bgcolor: getColor(), color: 'white'}}
        />
      </div>
      <div className={styles.issueTitle}>{issueTitle}</div>
      <div className={styles.subHeading}>
        <MemorySharpIcon />
        <h5>{`${status} |`}</h5>
        <DateRangeSharpIcon />
        <h5>{getReadableDate()} |</h5>
        <VerifiedUserSharpIcon />
        <h5>{issue} | &nbsp;</h5>
        <h5>{` Issue Id: ${issueId ? issueId : _id}`}</h5>
      </div>
    </div>
  );
}

HeaderIssues.propTypes = {
  index: PropTypes.number,
  severity: PropTypes.string,
  issue: PropTypes.string,
  filename: PropTypes.string,
  line: PropTypes.number,
  status: PropTypes.string,
  founddate: PropTypes.string,
  description: PropTypes.string,
  traces: PropTypes.array,
  remediationguidance: PropTypes.array,
  issueId: PropTypes.bool,
  _id: PropTypes.string,
};

export default HeaderIssues;
