import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Cloud from '@mui/icons-material/Cloud';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import SalesforceOrgTab from './SalesforceOrg/SalesforceOrgTab';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import CommerceCloudOrgTab from './CommerceCloudOrg/CommerceCloudOrgTab';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import PropTypes from 'prop-types';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '350px',
    sm: '600px',
    md: '800px',
    lg: '800px',
  },
  height: 'auto',
  minHeight: '450px',
  bgcolor: 'background.paper',
  boxShadow: 49,
  p: 4,
  borderRadius: 5,
};

export default function AddSalesforceDropdown({readOnlySfdcInfo, readOnlySfccInfo}) {
  const [salesforceModal, setSalesforceModal] = useState(false);

  const [commerceCloudModal, setCommerceCloudModal] = useState(false);
  const commerceCloudHandler = () => {
    setAnchorEl(null);
    setCommerceCloudModal(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const addSalesforceOrgHandler = () => {
    setAnchorEl(null);
    setSalesforceModal(true);
  };

  const closeAddSalesHandler = () => {
    setSalesforceModal(false);
  };

  const closeCommerceCloudHandler = () => {
    setCommerceCloudModal(false);
  };

  return (
    <>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
        endIcon={<ArrowDropDownSharpIcon />}
      >
        Add Workspace
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{width: '200'}}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem disabled = {readOnlySfdcInfo} onClick={addSalesforceOrgHandler}>
          <ListItemIcon>
            <Cloud fontSize='small' />
          </ListItemIcon>
          <ListItemText>Salesforce</ListItemText>
        </MenuItem>
        <Divider sx={{my: 0.5}} />
        <MenuItem disabled = {readOnlySfccInfo} onClick={commerceCloudHandler}>
          <ListItemIcon>
            <ShoppingCartSharpIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Commerce Cloud (B2C)</ListItemText>
        </MenuItem>
      </Menu>

      {salesforceModal == true && (
        <Modal
          open={true}
          onClose={closeAddSalesHandler}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                margin: '10px',
              }}
            >
              <LanguageSharpIcon color='primary' />
              <h2>Salesforce Settings</h2>
            </div>
            <SalesforceOrgTab closeModal={closeAddSalesHandler} />
          </Box>
        </Modal>
      )}

      {commerceCloudModal == true && (
        <Modal
          open={true}
          onClose={closeCommerceCloudHandler}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                margin: '10px',
              }}
            >
              <LanguageSharpIcon color='primary' />
              <h2>Commerce Cloud Settings</h2>
            </div>
            <CommerceCloudOrgTab closeModal={closeCommerceCloudHandler} />
          </Box>
        </Modal>
      )}
    </>
  );
}

AddSalesforceDropdown.propTypes={
  readOnlySfdcInfo:PropTypes.bool,
  readOnlySfccInfo:PropTypes.bool,
};