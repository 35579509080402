import React from 'react';
import PropTypes from 'prop-types';
import styles from './ComplianceViolations.module.css';

const ComplianceViolations = ({finding}) => {
  return (
    <div className={styles.complianceViolation}>
      {finding?.complianceViolations?.length > 0 && (
        <h3>Compliance Violation</h3>
      )}
      <ul className={styles.violationLists}>
        {finding?.complianceViolations?.map((violation, index) => {
          return <li key={index}>{violation}</li>;
        })}
      </ul>
      {finding?.complianceViolations?.length == 0 && (
        <h3>No Compliance Violation Found</h3>
      )}
    </div>
  );
};

export default ComplianceViolations;

ComplianceViolations.propTypes = {
  finding: PropTypes.object,
};
