import React from 'react';

import Details from './Details';

export const detailPagesConfigs = [
  {
    path: '/details/:org_id/:report_id/:issue_id',
    element: <Details />,
  },
  {
    path: '/details/:org_id/:report_id/:issue_id/:page',
    element: <Details />,
  },
];
