import {
  GET_SCAN_BY_ID,
  SET_SELECTED_ROW,
  TRENDING_SCAN_PER_ORG,
} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  scanById: null,
  trendingScanPerOrg: null,
  selectedRow: [],
};

const ScanStatistics = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCAN_BY_ID:
      return {
        ...state,
        scanById: action.payload,
      };
    case TRENDING_SCAN_PER_ORG:
      return {
        ...state,
        trendingScanPerOrg: action.payload,
      };
    case SET_SELECTED_ROW:
      return {
        ...state,
        selectedRow: action.payload,
      };
    default:
      return state;
  }
};
export default ScanStatistics;
