import React, {useState} from 'react';
import styles from './SingleUserSetup.module.css';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PropTypes from 'prop-types';
import {onPostDeleteUser, onPostResetUser} from 'redux/actions';
import {useDispatch} from 'react-redux';
import DeleteUserConfirmation from '../DeleteUserConfirmation/DeleteUserConfirmation';
import PasswordResetConfirmation from '../PasswordResetConfirmation/PasswordResetConfirmation';
import {MenuItem} from '@mui/material';
import AddUser from '../AddUser';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import {useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import {FETCH_ERROR} from 'shared/constants/ActionTypes';

import PersonIcon from '@mui/icons-material/Person';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const userIcon = {fontSize: 40};
//import Chip from '@mui/material/Chip';

const SingleUserSetup = ({user, writeable}) => {
  let options1 = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  let options = {timeStyle: 'long', hour12: true};
  const [deleteModal, setDeleteModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [workspaceModal, setWorkspaceModal] = useState(false);
  const allRoles = useSelector(({roles}) => roles.getAllRoles);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const loggedUser = useSelector(({common}) => common.userInfo);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const editModalClose = () => {
    setEditUserModal(false);
  };

  const workspaceModalClose = () => {
    setWorkspaceModal(false);
  };

  const workspaceModalOpen = () => {
    handleClose();
    if (loggedUser.usr._id === user._id) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Logged in user cannot edit their own user',
      });
    } else {
      setWorkspaceModal(true);
    }
  };

  const editModalOpen = () => {
    handleClose();

    // if (loggedUser.usr._id === user._id){
    //   dispatch({
    //     type: FETCH_ERROR,
    //     payload: 'Logged in user cannot edit their own user',
    //   });
    // }else{
    setEditUserModal(true);
    // }
  };

  const deleteModalOpen = () => {
    handleClose();
    if (loggedUser.usr._id === user._id) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Logged in user cannot delete their own user',
      });
    } else {
      setDeleteModal(true);
    }
  };
  const deleteModalClose = () => {
    setDeleteModal(false);
  };

  const deleteUser = () => {
    dispatch(
      onPostDeleteUser({
        userid: user._id,
        del: true,
        deletedBy: user.email,
        deletedAt: Date.now(),
      }),
    );
    deleteModalClose();
  };

  const restoreUser = () => {
    dispatch(onPostDeleteUser({userid: user._id, del: false}));
    handleCloseRestore();
  };

  const [passwordModal, setPasswordModal] = useState(false);
  const passwordModalOpen = () => {
    handleClose();
    setPasswordModal(true);
  };
  const passwordModalClose = () => {
    setPasswordModal(false);
  };
  const passwordReset = () => {
    dispatch(onPostResetUser({userid: user._id, reset_token: true}));
    passwordModalClose();
  };

  const dispatch = useDispatch();

  const activeOrgsAssigned = (user) => {
    let orgAssigned = user?.salesforceOrgs?.filter(
      (org) => org?.access === true,
    )?.length;
    if (orgAssigned > 1) {
      return `${orgAssigned} Workspaces Assigned`;
    } else if (orgAssigned === 1) {
      return '1 Workspace Assigned';
    } else {
      return 'No Workspace Assigned';
    }
  };
  let role = {};
  if (user.roleId) {
    role = allRoles?.find(
      (item) => item.type === 'GLOBAL' && user.roleId === item._id,
    );
  } else if (user.role !== 'NO_ROLE' && user.permissions !== 'NO_PERMISSIONS') {
    if (user.role === 'admin' && user.permissions === 'write') {
      role = allRoles?.find((item) => item.name === 'Admin');
    } else if (user.role === 'user' && user.permissions === 'write') {
      role = allRoles?.find((item) => item.name === 'Environment User');
    } else {
      role = allRoles?.find(
        (item) => item.name === 'Environment User Read Only',
      );
    }
  }

  //Modal for restore user
  const restoreIcon = {fontSize: 90};
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    p: 4,
  };

  const [openRestore, setOpenRestore] = useState(false);
  const handleOpenRestore = () => setOpenRestore(true);
  const handleCloseRestore = () => setOpenRestore(false);


  return (
    <>
      <div className={styles.singleUserSetup}>
        <div
          className={`${styles.singleUserInfo} ${
            user.isDeleted === 'true' ? styles.activeUser : ''
          }`}
        >
          <PersonPinIcon sx={userIcon} color='primary'></PersonPinIcon>

          <div className={styles.nameEmail}>
            <h3>{user.name ? user.name : ''}</h3>
            <p>{user.email ? user.email : ''}</p>
            <p className={styles.lastLoggedIn}>
              Last Logged in @ &nbsp;
              {new Date(user.last_login).toLocaleString('en-us', options1) +
                ' ' +
                new Date(user.last_login).toLocaleString('en-us', options)}
            </p>
          </div>

        </div>

        <div
          className={`${user.isDeleted === false || !('isDeleted' in user) ? '' : styles.showDiv} ${
            user.isDeleted === true ? styles.hideDiv : ''
          }`}
        >
          <div className={styles.roleDropdown}>
              <p
                id='role'
                inputProps={{'aria-label': 'Without label'}}
                name='role'
                label='role'
                value={role}
                disabled
                className={styles.selectRole}
              >
                <p value={role}>
                  {role?.name?.replaceAll('_', ' ') || ''}
                </p>
              </p>
          </div>
        </div>

        <div
          className={`${user.isDeleted === true ? '' : styles.showDiv} ${
            user.isDeleted === false || !('isDeleted' in user) ? styles.hideDiv : ''
          }`}
        >
          <div>
            <div className={styles.nameEmail}>
              <p>{role?.name?.replaceAll('_', ' ') || ''}</p>
              <p className={styles.lastLoggedIn}>
                Deleted by: {user.deletedBy ? user.deletedBy : 'no email'}
              </p>

              <p className={styles.lastLoggedIn}>
                Deleted at @ &nbsp;
                {new Date(user.deletedAt).toLocaleString('en-us', options1) +
                  ' ' +
                  new Date(user.deletedAt).toLocaleString('en-us', options)}
              </p>
            </div>
          </div>
        </div>

        {/* <div
          className={`${user.isDeleted === true ? '' : styles.showDiv} ${
            user.isDeleted === false ? styles.hideDiv : ''
          }`}
        >
          <p
            className={`${styles.rolesAssigned} ${
              user.isDeleted === 'true' ? styles.activeUser : ''
            }`}
          >
            Deleted Date:
          </p>
        </div> */}

        <div
          className={`${styles.rolesAssigned} ${
            user.isDeleted === 'false'  ? styles.activeUser : ''
          }`}
        >
          <p>{activeOrgsAssigned(user)}</p>
        </div>

        <div>
          <Button
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreHorizIcon
              sx={{fontSize: 30, margin: 0, padding: '0 !important'}}
              color='primary'
            />
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
          >
            <MenuItem
              onClick={editModalOpen}
              disabled={!writeable}
              className={user.isDeleted ? styles.hideDiv : styles.showDiv}
            >
              &nbsp;Edit User
            </MenuItem>
            <Divider
              sx={{my: 0.5}}
              className={user.isDeleted ? styles.hideDiv : styles.showDiv}
            />
            <MenuItem
              onClick={passwordModalOpen}
              disabled={!writeable}
              className={user.isDeleted ? styles.hideDiv : styles.showDiv}
            >
              &nbsp;Reset Password
            </MenuItem>
            <Divider
              sx={{my: 0.5}}
              className={user.isDeleted ? styles.hideDiv : styles.showDiv}
            />
            <MenuItem
              onClick={deleteModalOpen}
              disabled={!writeable}
              className={user.isDeleted ? styles.hideDiv : styles.showDiv}
            >
              &nbsp;Delete User
            </MenuItem>

            <Divider sx={{my: 0.5}} />
            <MenuItem
              onClick={() => workspaceModalOpen()}
              className={`${user.isDeleted === true ? '' : styles.showDiv} ${
                user.isDeleted === false || !('isDeleted' in user) ? styles.hideDiv : ''
              }`}
            >
              &nbsp;See Workspaces/Roles
            </MenuItem>

            <Divider
              sx={{my: 0.5}}
              className={`${user.isDeleted === true ? '' : styles.showDiv} ${
                user.isDeleted === false || !('isDeleted' in user) ? styles.hideDiv : ''
              }`}
            />
            <MenuItem
              onClick={handleOpenRestore}
              disabled={!writeable}
              className={user.isDeleted ? styles.showDiv : styles.hideDiv}
            >
              &nbsp;Restore User
            </MenuItem>
          </Menu>
        </div>
      </div>
      <DeleteUserConfirmation
        deleteModal={deleteModal}
        deleteModalClose={deleteModalClose}
        deleteUser={deleteUser}
      />
      <PasswordResetConfirmation
        passwordModal={passwordModal}
        passwordModalClose={passwordModalClose}
        passwordReset={passwordReset}
      />
      <ModalContainer open={editUserModal} handleModalClose={editModalClose}>
        <AddUser
          addModalClose={editModalClose}
          title='Edit User'
          user={user}
          editMode={true}
          userGlobalRole={role}
          teamMenu={false}
        />
      </ModalContainer>

      <ModalContainer
        open={workspaceModal}
        handleModalClose={workspaceModalClose}
      >
        <AddUser
          addModalClose={workspaceModalClose}
          deletedUserModal={workspaceModal}
          hidden={workspaceModal}
          title='Edit User'
          user={user}
          userGlobalRole={role}
        />
      </ModalContainer>

      {/*Modal for restore*/}
      <Modal
        open={openRestore}
        onClose={handleCloseRestore}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className={styles.deleteUserContainer}>
            <div>
              <PersonIcon color='primary' sx={restoreIcon} />
            </div>
            <h3>Do yo want to restore this user?</h3>

            <div className={styles.deleteBtnContainer}>
              <Button
                variant='contained'
                onClick={handleCloseRestore}
                color='secondary'
              >
                No
              </Button>
              <Button variant='contained' onClick={restoreUser} color='primary'>
                Yes
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      {/*Modal for restore*/}
    </>
  );
};

export default SingleUserSetup;

SingleUserSetup.propTypes = {
  user: PropTypes.object,
  readOnly: PropTypes.bool,
  rolesModalOpen: PropTypes.func,
  filterGlobalRoles: PropTypes.array,
  writeable: PropTypes.bool,
  deletedUserModal: PropTypes.bool,
  hidden: PropTypes.bool,
};
