import React, {useEffect, useRef, useState} from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ErrorsPieDashboard from './DashboardCharts/ErrorsPieDashboard';
import Grid from '@mui/material/Grid';
import Integration from './Integration/Integration';
import Issues from './Issues/Issues';
import Scheduler from 'components/configure/Scheduler/Scheduler';
import SettingsBar from './Settings/SettingsBar';
import Team from './Team/Team';
import Reports from './DashboardCharts/Reports/Reports.js';
import {useSelector, useDispatch} from 'react-redux';
import {
  APP_BAR_DASHBOARD,
  GET_ISSUES_CUR_PAGE,
} from 'shared/constants/ActionTypes';
import ReportsDownload from './ReportsDownload/ReportsDownload';
import FindingsAreaChart from './DashboardCharts/FindingsAreaChart/FindingsAreaChart';
import SecurityGate from 'components/SecurityGate/SecurityGate';
import {useParams, useSearchParams, useNavigate} from 'react-router-dom';
import {onGetSingleOrg, onGetUserInfo, onPutSecurityGate} from 'redux/actions';
import Advanced from './Advanced/Advanced';
import {checkOrgRole} from 'roles/Org';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {GET_CUR_REPORT_ID} from 'shared/constants/ActionTypes';
import {getTags} from 'redux/actions/Tags';

const settingsAccess = ({orgscanrules, orgscansettings, orgignorelist}) => {
  return orgscansettings?.read || orgscanrules?.read || orgignorelist?.read;
};

function AppBarDashboard() {
  const [searchParams] = useSearchParams();
  const _id = useSelector(({dashboard}) => dashboard.curReportId);
  let reportId = useRef(null);
  const securityData = useSelector(({dashboard}) => dashboard.securityData);
  const orgRoles = useSelector(({roles}) => roles.orgRoles);
  const globalRole = useSelector(({roles}) => roles.globalRole);
  const dispatch = useDispatch();
  let tab = useRef('dashboard');
  const navigate = useNavigate();
  let {org_id} = useParams();
  let selectedOrgRole = orgRoles?.find((role) => role.sfdcId === org_id);
  let selectedOrgRolePermissions = selectedOrgRole?.permissions;
  const issuesCurPage = useSelector(({dashboard}) => dashboard.issuesCurPage);

  useEffect(() => {
    dispatch(getTags());
    if (!selectedOrgRolePermissions?.length) {
      dispatch(onGetUserInfo());
    }
    return () => {};
  }, []);

  let selectedGlobalRolePermissions = globalRole?.permissions;

  let user = checkOrgRole({
    object: 'user',
    permissions: selectedGlobalRolePermissions,
  });

  const reportsData = useSelector(({reports}) => reports.reportsData);

  useEffect(() => {
    if (!securityData) {
      dispatch(onGetSingleOrg({org_id}));
    }
    if (searchParams.get('tab')) {
      tab = searchParams.get('tab');
    }
    if (searchParams.get('reportId')) {
      reportId = searchParams.get('reportId');
      dispatch({
        type: GET_CUR_REPORT_ID,
        payload: searchParams.get('reportId'),
      });
      dispatch({
        type: APP_BAR_DASHBOARD,
        payload: reportId ? 'issues' : tab,
      });
      return;
    }
    if (reportsData?.length && !_id) {
      dispatch({type: GET_CUR_REPORT_ID, payload: reportsData[0]._id});
    }
    if (finding?.read) {
      let _tab = tab?.current || tab;
      if ((_id != null || reportId.current != null) && tab === 'issues') {
        _tab = 'issues';
      }
      dispatch({
        type: APP_BAR_DASHBOARD,
        payload: _tab,
      });
    }
    return () => {};
  }, [reportsData, selectedOrgRolePermissions]);

  const submitHandler = ({config}) => {
    dispatch(onPutSecurityGate({config, org_id}));
  };

  const appBarDashboard = useSelector(
    ({dashboard}) => dashboard.appBarDashboard,
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pageFromUrl = urlParams.get('page');

    if (pageFromUrl && !isNaN(pageFromUrl)) {
      const page = parseInt(pageFromUrl, 10);
      dispatch({type: GET_ISSUES_CUR_PAGE, payload: page});
    }
  }, [dispatch]);


  const handleChange = (event, newValue,) => {
    const currentTab = new URLSearchParams(window.location.search).get('tab');
    tab = newValue;

    dispatch({type: APP_BAR_DASHBOARD, payload: newValue});
    dispatch({type: GET_ISSUES_CUR_PAGE, payload: issuesCurPage});


    if (currentTab === 'scheduler' && tab === 'issues') {
      window.location.href = `/app/dashboard/${org_id}?tab=${tab}`;
      return;
    }
    if (currentTab === 'issues' && tab === 'scheduler') {
      navigate({
        pathname: `/dashboard/${org_id}`,
        search: `?tab=${tab}`,
      });
    }

    navigate({
      pathname: `/dashboard/${org_id}`,
      search: `?tab=${tab}`,
    });
  };

  if (selectedOrgRolePermissions) {
    var scan = checkOrgRole({
      object: 'scan',
      permissions: selectedOrgRolePermissions,
    });

    var securitygate = checkOrgRole({
      object: 'orgsecuritygate',
      permissions: selectedOrgRolePermissions,
    });

    var reports = checkOrgRole({
      object: 'reports',
      permissions: selectedOrgRolePermissions,
    });

    var orgintegrations = checkOrgRole({
      object: 'orgintegrations',
      permissions: selectedOrgRolePermissions,
    });

    var team = checkOrgRole({
      object: 'team',
      permissions: selectedOrgRolePermissions,
    });

    var finding = checkOrgRole({
      object: 'finding',
      permissions: selectedOrgRolePermissions,
    });

    var orgscanrules = checkOrgRole({
      object: 'orgscanrules',
      permissions: selectedOrgRolePermissions,
    });

    var orgscansettings = checkOrgRole({
      object: 'orgscansettings',
      permissions: selectedOrgRolePermissions,
    });

    var orgignorelist = checkOrgRole({
      object: 'orgignorelist',
      permissions: selectedOrgRolePermissions,
    });

    var orgscheduler = checkOrgRole({
      object: 'orgscheduler',
      permissions: selectedOrgRolePermissions,
    });
  }

  const currentURL = window.location.href;
  const showDivDetails = currentURL.includes('tab=issues');
//  console.log('Reports from Dashboard: ', reportsData);
  const [scanReportId, setScanReportId] = useState(null);
  const [filteredReport, setFilteredReport] = useState(null);

  useEffect(() => {
    const handleReportIdChange = () => {
      const currentURL = window.location.href;
      const reportIdMatch = currentURL.match(/reportId=([^&]*)/);
      const reportIdFromURL = reportIdMatch ? reportIdMatch[1] : null;

      setScanReportId(reportIdFromURL);
    };

    handleReportIdChange();

    window.addEventListener('popstate', handleReportIdChange);

    return () => {
      window.removeEventListener('popstate', handleReportIdChange);
    };
  }, [window.location.href]);

  useEffect(() => {
    if (reportsData) {
      const foundReport = reportsData.find(
        (report) => report._id === scanReportId,
      );
      setFilteredReport(foundReport);
    }
  }, [scanReportId, reportsData]);

  const showSettings = settingsAccess({
    orgscanrules,
    orgscansettings,
    orgignorelist,
  });

  const formatDate = (dateString) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    };

    const formattedDate = new Date(dateString).toLocaleString('en-US', options);
    return formattedDate;
  };

  return (
    <Box sx={{width: '100%'}}>
      <TabContext value={appBarDashboard}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <TabList
            onChange={handleChange}
            aria-label='lab API tabs example'
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label='Dashboard' value='dashboard' />
            {securitygate?.read && (
              <Tab label='Security Gate' value='securitygate' />
            )}
            {team?.read && <Tab label='Team' value='team' />}
            {orgintegrations?.read && (
              <Tab label='Integration' value='integration' />
            )}
            {reports?.read && <Tab label='Reports' value='reports' />}
            {showSettings && <Tab label='Settings' value='settings' />}
            {orgscansettings?.read && <Tab label='Advanced' value='advanced' />}
            {finding?.read && <Tab label='Issues' value='issues' />}
            {orgscheduler?.read && (
              <Tab label='Scheduler' value='scheduler' />
            )}
          </TabList>
        </Box>

        <TabPanel value='dashboard'>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ErrorsPieDashboard scanreadable={scan?.read} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FindingsAreaChart scanreadable={scan?.read} />
            </Grid>
            <Grid item xs={12}>
              <Reports
                viewIssues={finding?.read}
                writeable={scan?.write}
                readable={scan?.read}
              />
            </Grid>
          </Grid>
        </TabPanel>
        {securitygate?.read && securityData && (
          <TabPanel value='securitygate'>
            <SecurityGate
              defaultValue={{
                critical: securityData?.Critical,
                high: securityData?.High,
                medium: securityData?.Medium,
                low: securityData?.Low,
                isEnabled: securityData?.isEnabled,
                isEnforced: securityData?.isEnforced,
              }}
              submitHandler={submitHandler}
              writeable={securitygate?.write}
              isGlobal={false}
            />
          </TabPanel>
        )}
        {team?.read && (
          <TabPanel value='team'>
            <Team writeable={team?.write} userwriteable={user?.read} />
          </TabPanel>
        )}
        {orgintegrations?.read && (
          <TabPanel value='integration'>
            <Integration writeable={orgintegrations?.write}></Integration>
          </TabPanel>
        )}
        {reports?.read && (
          <TabPanel value='reports'>
            <ReportsDownload
              readable={reports?.read}
              writeable={reports?.write}
            />
          </TabPanel>
        )}
        {showSettings && (
          <TabPanel value='settings'>
            <SettingsBar />
          </TabPanel>
        )}
        {orgscansettings?.read && (
          <TabPanel value='advanced'>
            <Advanced writeable={orgscansettings?.write} isGlobal={false} />
          </TabPanel>
        )}
        {finding?.read && (reportId != null || _id != null) && (
          <TabPanel value='issues'>
            <div>
              {showDivDetails && filteredReport && (
                <div
                  style={{display: 'flex', flexDirection: 'row', color:'gray', fontSize:'10px', marginLeft:'15px'}}
                  key={filteredReport._id}
                >
                  <h6>{filteredReport.name || 'Scanned'}</h6>
                  <h6 style={{marginLeft: '5px'}}>@</h6>
                  <h6 style={{marginLeft: '5px'}}>
                    {formatDate(filteredReport.Initiated_Scan_date)}
                  </h6>
                </div>
              )}
            </div>
            <br></br>
            <Issues />
          </TabPanel>
        )}
        {orgscheduler?.read && (
          <TabPanel value='scheduler'>
            <Scheduler writeable={orgscheduler?.write}/>
          </TabPanel>
        )}
      </TabContext>
    </Box>
  );
}

export default AppBarDashboard;
