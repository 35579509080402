import React, {Fragment, useState} from 'react';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import styles from './ScratchOfflineOrg.module.css';
import {Button} from '@mui/material';
import DownloadForOfflineSharpIcon from '@mui/icons-material/DownloadForOfflineSharp';
import {useDispatch} from 'react-redux';
import {addOfflineOrg} from 'redux/actions';
import PropTypes from 'prop-types';
// import AddAgain from 'components/salesforce/AddSalesforceCloud/AddAgain';

function ScratchOfflineOrg({closeModal}) {
  const [name, setName] = useState('');
  const dispatch = useDispatch();

  const nameChangeHandler = (event) => {
    setName(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(addOfflineOrg({name}));
    setName('');
    closeModal();
  };

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <DownloadForOfflineSharpIcon />
        <h4> Add Scratch (Offline) Workspace</h4>
      </div>
      <div>
        <form onSubmit={submitHandler}>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Workspace Name
            </InputLabel>
            <TextField
              id='filled-basic'
              label='Name'
              variant='filled'
              placeholder='Name to Remember your Salesforce'
              name='name'
              value={name}
              onChange={nameChangeHandler}
              sx={{
                width: {xs: '100%', sm: '480px', md: '480px'},
              }}
              required
            ></TextField>
          </div>

          <div
            style={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}
          >
            <Button variant='contained' color='secondary' onClick={closeModal}>
              Cancel
            </Button>

            <Button variant='contained' type='submit'>
              Save
            </Button>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default ScratchOfflineOrg;

ScratchOfflineOrg.propTypes = {
  closeModal: PropTypes.func,
};
