import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {assignTagsSFDC, assignTagsScan} from 'redux/actions/Tags';
import PropTypes from 'prop-types';
import styles from './ScanCustomTags.module.css';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import DeleteIcon from '@mui/icons-material/Delete';

const deleteIcon = {fontSize: 60};

const ScanCustomTags = ({fieldName, tagId, fieldValue, href, org_id, scanId, orgId, isDisabledCreate, isDeleted, onCleanFields}) => {
  const dispatch = useDispatch();

  const [deleteTagsModal, setDeleteTagsModal] = useState(false);

  const deleteTagsModalOpen = () => {
    setDeleteTagsModal(true);
  };
  const deleteTagsModalClose = () => {
    setDeleteTagsModal(false);
  };

  const tagIds = [tagId];
  let action = 'remove';

  const deleteTagsHandler = async () => {
    // let fieldToDelete = {
    //   fieldName: fieldName,
    //   fieldValue: fieldValue,
    // };
    const currentURL = window.location.href;

    if (currentURL.includes('app/salesforce')) {
      await dispatch(assignTagsSFDC(tagIds, org_id, action));
      onCleanFields();
    } else if (currentURL.includes('app/dashboard')) {
      await dispatch(assignTagsScan(tagIds, scanId, orgId, action));
    }
  };

  function onMouseOver(event) {
    if (event.target.href) {
      event.target.style.color = isDeleted ? 'red' : 'blue';
    }
  }

  function onMouseOut(event) {
    event.target.style.color = isDeleted ? 'red' : '#0a8fdc';
  }

  function onMouseOverX(event) {
    event.target.style.cursor = 'pointer';
    event.target.style.color = 'red';
  }

  function onMouseOutX(event) {
    event.target.style.cursor = 'default';
    event.target.style.color = isDeleted ? 'red' : '#0a8fdc';
  }
  function onDeleteTag() {
    deleteTagsModalOpen();
  }

  return (
    <>
      <div className={isDeleted ? styles.badgeTagInactive : styles.badgeTag}>
        <a
          className={isDeleted ? styles.aTagInactive : styles.aTag}
          onMouseEnter={onMouseOver}
          onMouseOut={onMouseOut}
          href={href || '#'}
          target={href && href !== '#' ? '_blank' : '_self'}
          rel='noreferrer'
          onClick={(e) => {
            if (!href || href === '#') {
              e.preventDefault();
            }
          }}
          style={{
            pointerEvents: href && href !== '#' ? 'auto' : 'none',
          }}
        >
          {fieldName + ' : ' + fieldValue}
        </a>
        <a
          className={`${isDeleted ? styles.aTag2Inactive : styles.aTag2} ${
            isDisabledCreate ? styles.disabled : ''
          }`}
          onMouseEnter={onMouseOverX}
          onMouseLeave={onMouseOutX}
          onClick={onDeleteTag}
        >
          x
        </a>
      </div>
      <ModalContainer
        open={deleteTagsModal}
        handleModalClose={deleteTagsModalClose}
        handleModalProceed={deleteTagsHandler}
        proceedButtonText='Confirm'
        closeButtonText='Cancel'
      >
        <div
          className={styles.deleteModal}
          style={{
            textAlign: 'center',
          }}
        >
          <DeleteIcon sx={deleteIcon} color='secondary' />
          <h2>Please confirm you want to remove this tag assignment</h2>
          <p>Removed tags can be re-assigned later</p>
        </div>
      </ModalContainer>
    </>
  );
};

export default ScanCustomTags;

ScanCustomTags.propTypes = {
  _id: PropTypes.string,
  tagId: PropTypes.string,
  scanId: PropTypes.string,
  orgId: PropTypes.string,
  org_id: PropTypes.string,
  reportsCurPage: PropTypes.number,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
  href: PropTypes.string,
  deleteTagsModalOpen: PropTypes.func,
  isDisabledCreate: PropTypes.bool,
  isDeleted: PropTypes.bool,
  onCleanFields: PropTypes.func,
};
