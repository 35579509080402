import {SiSalesforce} from 'react-icons/si';
import {AiFillSetting} from 'react-icons/ai';
import {MdOutlineAdminPanelSettings} from 'react-icons/md';

const routesConfig = [
  {
    id: 'Salesforce',
    title: 'Salesforce',
    messageId: 'Workspaces',
    type: 'item',
    icon: <SiSalesforce />,
    url: '/salesforce',
  },

  {
    id: 'Configure',
    title: 'Configure',
    messageId: 'Configure',
    type: 'item',
    icon: <MdOutlineAdminPanelSettings />,
    url: '/configure',
  },

  {
    id: 'Setup',
    title: 'Setup',
    messageId: 'Setup',
    type: 'item',
    icon: <AiFillSetting />,
    url: '/setup',
  },

];
export default routesConfig;
