import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import ContactSupportSharpIcon from '@mui/icons-material/ContactSupportSharp';
import {useDispatch} from 'react-redux';
import {onPostStartScan} from 'redux/actions/Reports.js';
import {onGetSubscription} from 'redux/actions/Setup.js';
import {onSearchSfdcInfo} from 'redux/actions/Salesforce.js';
import {onGetSingleOrg} from 'redux/actions/Dashboard.js';
import {useParams} from 'react-router-dom';
import {DASHBOARD_RESPONSE} from 'shared/constants/ActionTypes.js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
  borderRadius: 5,
  textAlign: 'center',
};

export default function NewScan() {
  let {org_id} = useParams();

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const toggleOpenParent = () => {
    setOpen(!open);
  };

  const toggleCancel = () => {
    dispatch({
      type: DASHBOARD_RESPONSE,
      payload: {status: 'fail', message: 'Scan Cancelled!'},
    });
  };

  const initiateScan = async () => {
    toggleOpenParent();
    dispatch(onPostStartScan({org_id: org_id}));
    dispatch(onGetSubscription());
    dispatch(onSearchSfdcInfo(1, ''));
    dispatch(onGetSingleOrg({org_id}));
  };

  return (
    <div>
      <Button
        onClick={toggleOpenParent}
        sx={{marginRight: 1}}
        variant='contained'
      >
        + New Scan
      </Button>
      <Modal open={open} onClose={toggleOpenParent}>
        <Box sx={{...style, width: 400}}>
          <Box sx={style}>
            <ContactSupportSharpIcon sx={{fontSize: 100}} />
            <h2> Do you want to initate a scan?</h2>
            <h6>You will receive an email once the scan completes</h6>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                margin: '10px',
              }}
            >
            
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  toggleCancel();
                  toggleOpenParent();
                }}
              >
                No
              </Button>
              <Button variant='contained' onClick={initiateScan}>
                Yes
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
