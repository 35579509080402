import React from 'react';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import styles from './DeleteUserConfirmation.module.css';
import PropTypes from 'prop-types';

import {Button} from '@mui/material';

const deleteIcon = {fontSize: 60};

const DeleteUserConfirmation = ({
  deleteModal,
  deleteModalClose,
  deleteUser,
}) => {
  return (
    <ModalContainer open={deleteModal} handleModalClose={deleteModalClose}>
      <div className={styles.deleteUserContainer}>
        <div>
          <DeleteForeverSharpIcon color='secondary' sx={deleteIcon} />
        </div>
        <h3>Are you sure you want to delete this user?</h3>

        <div className={styles.deleteBtnContainer}>
       
          <Button
            variant='contained'
            onClick={deleteModalClose}
            color='secondary'
          >
            No
          </Button>
          <Button variant='contained' onClick={deleteUser} color='primary'>
            Yes
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

DeleteUserConfirmation.propTypes = {
  deleteModal: PropTypes.bool,
  deleteModalClose: PropTypes.func,
  deleteUser: PropTypes.func,
};

export default DeleteUserConfirmation;
