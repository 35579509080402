import React, {useState, useEffect} from 'react';
import {Fragment} from 'react';
import AppCard from '@crema/core/AppCard';
import Profile from './User/Profile';
import TextField from '@mui/material/TextField';
import {useSelector, useDispatch} from 'react-redux';
import {Button} from '@mui/material';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import {useParams} from 'react-router-dom';
import styles from './Team.module.css';
// import GroupAddSharpIcon from '@mui/icons-material/GroupAddSharp';
import {onGetSpecificOrgUsers, onGetUsers, onGetAllOrgsInAccount} from 'redux/actions';
import {TEAM_RESPONSE, SPECIFIC_ORG_USERS} from 'shared/constants/ActionTypes';
import ModalChildrenMessage from 'components/modals/ModalChildrenMessage/ModalChildrenMessage';
import AddSpecificOrgUser from './AddSpecificOrgUser/AddSpecificOrgUser';
import PropTypes from 'prop-types';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PlaceHolderDiv from 'components/PlaceHolderDiv/PlaceHolderDiv';
// import {useNavigate} from 'react-router-dom';

const Team = ({/*userReadOnly,*/ writeable /*userwriteable*/}) => {
  const specificOrgUsers = useSelector(({team}) => team.specificOrgUsers);
  const [searchUser, setSearchUser] = useState('');
  const teamResponse = useSelector(({team}) => team.teamResponse);
  const currOrgName = useSelector(({dashboard}) => dashboard.curOrgName);
  // let navigate = useNavigate();

  const dispatch = useDispatch();
  let {org_id} = useParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const addModalOpen = () => {
    setShowAddModal(true);
  };
  const addModalClose = () => {
    setShowAddModal(false);
  };

  useEffect(() => {
    dispatch(onGetSpecificOrgUsers({org_id}));
    dispatch(onGetUsers());
    dispatch(onGetAllOrgsInAccount());
    return () => {
      dispatch({
        type: SPECIFIC_ORG_USERS,
        payload: null,
      });
    };
  }, [org_id]);

  const changeSearchForm = (event) => {
    setSearchUser(event.target.value);
  };

  const teamModalClose = () => {
    dispatch({type: TEAM_RESPONSE, payload: null});
  };

  return (
    <Fragment>
      <AppCard title='Team'>
        <div className={styles.teamHeaderContainer}>
          <TextField
            id='users-search'
            type='search'
            variant='outlined'
            value={searchUser}
            disabled={!writeable}
            onChange={changeSearchForm}
            placeholder='Search by Email'
          />
          <div className={styles.teamBtnContainer}>
            <Button
              variant='contained'
              startIcon={<SupervisedUserCircleIcon />}
              onClick={addModalOpen}
              disabled={!writeable}
            >
              Add Team Member
            </Button>
            {/* <Button
              variant='contained'
              startIcon={<GroupAddSharpIcon />}
              onClick={() => navigate('/setup/user')}
              disabled={!userwriteable}
            >
              Add Users
            </Button> */}
          </div>
        </div>
        <div className={styles.profileCardContainer}>
          {specificOrgUsers &&
            specificOrgUsers?.length > 0 &&
            specificOrgUsers.map((user) => {
              if (user.email.toLowerCase().includes(searchUser.toLowerCase())) {
                return (
                  <Profile
                    key={user._id}
                    user={user}
                    /**userwriteable={userwriteable}*/ writeable={writeable}
                  />
                );
              }
            })}
          {specificOrgUsers && specificOrgUsers?.length == 0 && (
            <PlaceHolderDiv status='notfound' message='No Users Found!' />
          )}
        </div>
      </AppCard>

      <ModalContainer open={showAddModal} handleModalClose={addModalClose}>
        <AddSpecificOrgUser
          addModalClose={addModalClose}
          orgName={`${currOrgName}/*/*/${org_id}`}
          org_id={org_id}
        />
      </ModalContainer>

      {teamResponse && (
        <ModalContainer open={true}>
          <ModalChildrenMessage
            status={teamResponse?.status}
            message={teamResponse?.message}
            close='Close'
            handleModalClose={teamModalClose}
          />
        </ModalContainer>
      )}
    </Fragment>
  );
};

export default Team;
Team.propTypes = {
  userReadOnly: PropTypes.bool,
  writeable: PropTypes.bool,
  // userwriteable: PropTypes.bool,
};
