import React, {useState} from 'react';
import Button from '@mui/material/Button';
import {AppCard} from '@crema';
import ApartmentSharpIcon from '@mui/icons-material/ApartmentSharp';
import style from './CompanySetup.module.css';
import TextField from '@mui/material/TextField';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {onGetCompanyInfo, onPostCompanyInfo} from 'redux/actions';
// import SecurityIcon from '@mui/icons-material/Security';
import AuthenticationConfirmation from '../User/AuthenticationConfirmation/AuthenticationConfirmation';
import {onPutAllTwoFA} from 'redux/actions';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';

function CompanySetup({writeable}) {
  let options1 = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  const dispatch = useDispatch();
  const companyInfo = useSelector(({setup}) => setup.companyInfo);
  const subscription = useSelector(({setup}) => setup.getSubscriptionInfo);
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    setCompanyName(companyInfo?.company_name);
  }, [companyInfo]);

  useEffect(() => {
    setActivated(companyInfo?.tfa_enabled);
  }, [companyInfo?.tfa_enabled]);


  const onSaveHandler = () => {
    dispatch(
      onPostCompanyInfo({
        companyid: companyInfo._id,
        company: companyName,
        tfa: companyInfo.tfa_enabled,
      }),
    );
  };

  const nameChangeHandler = (event) => {
    setCompanyName(event.target.value);
  };

  const [activated, setActivated] = useState(companyInfo?.tfa_enabled);
  const [authenticationModal, setAuthenticationModal] = useState(false);

const authenticationEnable = () => {
  dispatch(onPutAllTwoFA(true));
  dispatch(onGetCompanyInfo());
  setAuthenticationModal(false);
  setActivated((prevActivated) => !prevActivated);
};

const authenticationDisable = () => {
  dispatch(onPutAllTwoFA(false));
  dispatch(onGetCompanyInfo());
  setAuthenticationModal(false);
};

const authenticationModalOpen = () => {
  setAuthenticationModal(true);
};

const authenticationModalClose = () => {
  setAuthenticationModal(false);
};

const handleSwitchChange = () => {
  authenticationModalOpen();
};
  
  
  return (
    <>
      <AppCard sxStyle={{maxWidth: 400}} title=''>
        <div className={style.companyHeader}>
          <div className={style.companyHeaderTitle}>
            <ApartmentSharpIcon></ApartmentSharpIcon>
            <h4>Manage Company</h4>
          </div>
        </div>
        <div className={style.companyForm}>
          {(companyInfo !== null && companyInfo !== undefined) && (subscription !== null && subscription !== undefined) && (
          <form>
            <label>Name:</label>
            <div /*key={companyInfo?.company_name}*/>
              <TextField
                fullWidth
                required
                id='name'
                variant='filled'
                name='companyName'
                defaultValue={companyInfo?.company_name?companyInfo?.company_name:''}
                onChange={nameChangeHandler}
              />
            </div>

            <label>Signup Date:</label>
            <TextField
              fullWidth
              required
              disabled
              id='date'
              variant='filled'
              name='date'
              value={
                companyInfo?.sign_up_date
                  ? new Date(companyInfo.sign_up_date).toLocaleString('en-us', options1)
                  : ''
              }
            />
            <label>Customer Id :</label>
            <TextField
              fullWidth
              required
              disabled
              id='date'
              variant='filled'
              name='date'
              value={companyInfo?.customer_id?companyInfo?.customer_id:''}
            />
            <label>Expiration date :</label>
            <TextField
              fullWidth
              required
              disabled
              id='date'
              variant='filled'
              name='date'
              value={
                companyInfo?.expiration_date
                  ? new Date(companyInfo.expiration_date).toLocaleString('en-us', options1)
                  : ''
              }
            />
            <label>Subscription :</label>
            <TextField
              fullWidth
              required
              disabled
              id='subscription'
              variant='filled'
              name='subscription'
              value = {subscription&&subscription[0].active?'Active':'Expired'
            }
            />
            <div className={style.twoFContainer}>
              <p>Require 2Fa</p>
                  <Switch
                    checked={activated}
                    onChange={handleSwitchChange}
                    color="primary"
                    disabled={!writeable}

                  />
              </div>
            <div className={style.manageCompanyButton}>
              {/* <Button
                variant='contained'
                onClick={authenticationModalOpen}
                endIcon={<SecurityIcon />}
                disabled={!writeable}
              >
                Require 2Fa
              </Button> */}
              <Button
                variant='contained'
                onClick={onSaveHandler}
                disabled={!writeable}
              >
                Save
              </Button>
            </div>
          </form>
          )}
        </div>
      </AppCard>
      <AuthenticationConfirmation
        authenticationModal={authenticationModal}
        authenticationModalClose={authenticationModalClose}
        authenticationEnable={authenticationEnable}
        authenticationDisable={authenticationDisable}
        activated={!activated}
      />
    </>
  );
}

CompanySetup.propTypes = {
  writeable: PropTypes.bool,
};

export default CompanySetup;
