import React, {useEffect, useState,forwardRef} from 'react';
import {AppCard} from '@crema';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import styles from './ManageProfile.module.css';
import Button from '@mui/material/Button';
import {useDispatch, useSelector} from 'react-redux';
import {InfinitySpin} from 'react-loader-spinner';
import {Disable2fa, onUpdateProfile,generate2faOtpRequest, reset2FaValues, enable2fa} from 'redux/actions/Profile';
import {PROFILE_RESPONSE} from 'shared/constants/ActionTypes';
import VerifyPhoneNumber from './VerifyPhoneNumber/VerifyPhoneNumber';
import {
  onGet2FaSettings,
  onGetCountryCodes,
} from '../../../redux/actions/Profile';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import ModalChildrenMessage from 'components/modals/ModalChildrenMessage/ModalChildrenMessage';
import TwofaModal from 'components/modals/TwofaModal/TwofaModal';
import {Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import PhoneModal from 'components/modals/PhoneModal/PhoneModa;';
import PasswordResetConfirmation from '../User/PasswordResetConfirmation/PasswordResetConfirmation';
import {onPostResetUser} from 'redux/actions';

const ManageProfile = () => {

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  // Redux
  const dispatch = useDispatch();
  const userInfo = useSelector(({common}) => common.userInfo);

  const countryCodes = useSelector(({profile}) => profile.countryCodes);
  const profileResponse = useSelector(({profile}) => profile.profileResponse);
  const generatedOtp = useSelector(({profile}) => profile.generatedOtp);
  const twofaEnabled = useSelector(({profile}) => profile.twofaEnabled);
  const error = useSelector(({profile}) => profile.error);

  const [openDialog,setOpenDialog]=useState(false);
  const [openPhoneDialog,setOpenPhoneDialog]=useState(false);
  const [barType,setBarType]=useState("error");
  const [authType,setAuthType]=useState("");


  // Local State
  const [profileData, setProfileData] = useState({
    name: '',
    internationalCode: '',
    phone_num: '',
    username: '',
    alert: false,
    status_changes: false,
    daily_digest: false,
    weekly_digest: false,
    twoStepAuth: false,
  });

  // UseEffect
  useEffect(() => {
    if (userInfo != null) {
      setProfileData({
        name: userInfo.usr.name,
        internationalCode: userInfo.usr.countryCode,
        phone_num: userInfo.usr.phone,
        username: userInfo.usr.email,
        alert: userInfo.usr.alert,
        status_changes: userInfo.usr.status_changes,
        daily_digest: userInfo.usr.daily_digest,
        weekly_digest: userInfo.usr.weekly_digest,
        twoStepAuth: userInfo.usr.enabled2fa,
      });
    }
    //console.log(userInfo);
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(onGet2FaSettings());
    dispatch(onGetCountryCodes());

    return () => {
      dispatch(reset2FaValues());

    };
  }, []);

  const [passwordModal, setPasswordModal] = useState(false);

  const passwordModalOpen = () => {
    handleClose();
    setPasswordModal(true);
  };
  const passwordModalClose = () => {
    setPasswordModal(false);
  };
  const passwordReset = () => {
    dispatch(onPostResetUser({userid: userInfo.usr._id, reset_token: true}));
    passwordModalClose();
  };


  //Change Handlers

  const handlePhoneClose=()=>{
    setOpenPhoneDialog(false);
    setAuthType("");
  };

  const selectAllAlert = (event) => {
    setProfileData((old_state) => {
      return {
        name: old_state.name,
        internationalCode: old_state.internationalCode,
        phone_num: old_state.phone_num,
        username: old_state.email,
        alert: event.target.checked,
        // status_changes: event.target.checked,
        daily_digest: event.target.checked,
        weekly_digest: event.target.checked,
        twoStepAuth: old_state.twoStepAuth,
      };
    });
  };

  // const switchChangeHandler = (event) => {
  //   setProfileData((old_state) => {
  //     return {
  //       ...old_state,
  //       [event.target.name]: event.target.checked,
  //     };
  //   });
  // };

  const formChangeHandler = (event) => {
    setProfileData((old_state) => {
      return {
        ...old_state,
        [event.target.name]: event.target.value,
      };
    });
  };

  const phoneChangeHandlder = (event) => {
    if (isNaN(event.target.value)) return;
    setProfileData((old_state) => {
      return {
        ...old_state,
        phone_num: event.target.value,
      };
    });
  };

  const onSaveButtonClick = (event) => {
    event.preventDefault();
    dispatch(
      onUpdateProfile({
        name: profileData.name,
        internationalCode: profileData.internationalCode,
        phone: profileData.phone_num,
        alert: profileData.alert,
        // status_changes: profileData.status_changes,
        weekly: profileData.weekly_digest,
        daily: profileData.daily_digest,
      }),
    );
  };

  const profileModalClose = () => {
    dispatch({type: PROFILE_RESPONSE, payload: null});
    setAuthType("");
  };

  const twoStepAuthHandler = (event) => {
    if (event.target.checked == true) {
      dispatch(generate2faOtpRequest(userInfo.usr.email));
    } else {
      dispatch(Disable2fa());
    }
  };

  const [open, setOpen] = React.useState(false);
  const [otpMessage,setOtpMessage]=useState("Invalid OTP Entered");



  const handleClose = () => {
    //console.log('handleClose called');
    setOpen(false);
    dispatch(reset2FaValues());
    setAuthType("");

  };

  useEffect(() => {
    //console.log("called");
    if(!open && generatedOtp!=""){
      //console.log('useeffect called');
      setOpen(true);
    };


  },[generatedOtp]);

  useEffect(() => {
    if(twofaEnabled){
      //console.log("Success");
    }
    else{
      setBarType("error");
      setOtpMessage("Invalid OTP Entered");

    }
  },[twofaEnabled]);

  useEffect(()=>{
    if(error.length>0){
      setBarType("error");
      setOtpMessage("Invalid OTP Entered");
      setOpenDialog(true);
      }
      else{
        setOpenDialog(false);
      }
    },[error]);




    useEffect(()=>{
      if(openDialog){
        setTimeout(()=>{
          setOpenDialog(false);

        },3000);
        }

      },[openDialog]);

      const handleAuthType=(type)=>{
        if(type=="phone" && (userInfo?.usr.phone=="")){
          //console.log("set");
          setOpenPhoneDialog(true);
        }
        else if(type=="email"){
          dispatch(enable2fa("email"));
        }
        else if(type=="phone"){
          dispatch(enable2fa("phone"));
        }
        else{
      dispatch(generate2faOtpRequest(userInfo.usr.email));
      
        }
        setAuthType(type);
      };
  
  return (
    <>
        <Snackbar
  open={openDialog}
  autoHideDuration={6000}
  message={otpMessage}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
>
<Alert  severity={barType} sx={{ width: '100%' }}>
{otpMessage}
  </Alert>
</Snackbar>
    
        <TwofaModal handleClosefun={handleClose}  open={open} keepMounted />
        <PhoneModal handleClosefun={handlePhoneClose} open={openPhoneDialog} setProfile={setProfileData} profileData={profileData} keepMounted />

      <AppCard sxStyle={{maxWidth: 410}} title='Manage Profile'>
        {userInfo && Object.keys(userInfo).length > 0 && (
          <form
            className={styles.ManageProfileForm}
            onSubmit={onSaveButtonClick}
          >
            <label className={styles.textLabel}>Name:</label>
            <TextField
              fullWidth
              id='name'
              variant='filled'
              name='name'
              value={profileData.name}
              onChange={formChangeHandler}
            />
            <label className={styles.textLabel}>Phone Number:</label>
            <div className={styles.groupPhoneNumber}>
              <Select
                className={styles.internationalCode}
                id='internationalCode'
                variant='filled'
                name='internationalCode'
                onChange={formChangeHandler}
                value={profileData.internationalCode}
                displayEmpty
                renderValue={(selected) => {
                  if (selected?.length === 0) {
                    return <p>Country Code</p>;
                  }

                  return selected;
                }}
              >
                {countryCodes.length > 0 &&
                  countryCodes.map((item, index) => (
                    <MenuItem value={item.code} key={index}>
                      {`${item.name} ${item.code}`}
                    </MenuItem>
                  ))}
              </Select>
              <TextField
                id='phone_num'
                variant='filled'
                name='phone_num'
                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                value={profileData.phone_num}
                onChange={phoneChangeHandlder}
                className={styles.phone_num}
                placeholder='Phone Number'
              />
            </div>
            <label className={styles.textLabel}>Username:</label>
            <TextField
              fullWidth
              id='username'
              variant='filled'
              name='username'
              value={profileData.username}
              onChange={formChangeHandler}
              disabled
            />
            <div className={styles.groupLabelInput}>
              <label className={styles.textLabel}>User Notifications</label>
              <Switch
                inputProps={{'aria-label': 'controlled'}}
                size='large'
                checked={profileData.alert}
                onChange={selectAllAlert}
                id='selectAll'
              />
            <label className={styles.alertSub}>Receive Scheduler, Scan, Issue Data Updates via email</label>
            </div>
            {/* <div className={styles.groupLabelInput}>
              <label className={styles.textLabel}>Status Changes:</label>
              <Switch
                inputProps={{'aria-label': 'controlled'}}
                checked={profileData.status_changes}
                id='status_changes'
                name='status_changes'
                onChange={switchChangeHandler}
              />
            </div> */}
            {/* <div className={styles.groupLabelInput}>
              <label className={styles.textLabel}>Daily Digest:</label>
              <Switch
                inputProps={{'aria-label': 'controlled'}}
                checked={!!profileData.daily_digest}
                id='dailyDigest'
                name='daily_digest'
                onChange={switchChangeHandler}
              />
            </div> */}
            {/* <div className={styles.groupLabelInput}>
              <label className={styles.textLabel}>Weekly Digest:</label>
              <Switch
                inputProps={{'aria-label': 'controlled'}}
                checked={!!profileData.weekly_digest}
                id='weeklyDigest'
                name='weekly_digest'
                onChange={switchChangeHandler}
              />
            </div> */}
            <div className={styles.groupLabelInput}>
              <label className={styles.textLabel}>Two Step Verification:</label>
              {userInfo?.usr.enabled2fa?
              (
                <Switch
                inputProps={{'aria-label': 'controlled'}}
                checked={userInfo?.usr.enabled2fa==true}
                id='twoStepVerification'
                name='twoStepAuth'
                onChange={twoStepAuthHandler}
                data-cy="2faSwitch"
              />
              )
              :
                (
                  <FormControl >
                  <Select
                    style={{marginBottom:"2%"}}
                    value={authType}
                    onChange={(e)=>handleAuthType(e.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                        <MenuItem disabled value="">
                        <p>AuthType</p>
                      </MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="phone">Phone</MenuItem>
                    <MenuItem value="multi-factor">Multi Factor</MenuItem>
                  </Select>
                </FormControl>
                )
              
              }
            
                
            </div>
            <Button variant='contained' onClick={passwordModalOpen}>
              Reset Password
            </Button>
            <Button type='submit' variant='contained' className={styles.btnContainer}>
              Save
            </Button>
          </form>
        )}

        {userInfo != null && Object.keys(userInfo).length == 0 && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <InfinitySpin color='grey' />
          </div>
        )}
        {profileResponse?.status == 'verify2fa' && (
          <VerifyPhoneNumber
            internationalCode={profileData.internationalCode}
            phone={profileData.phone_num}
            profileModalClose={profileModalClose}
          />
        )}
      </AppCard>

      {profileResponse != null &&
        (profileResponse.status == 'success' ||
          profileResponse.status == 'fail') && (
          <ModalContainer open={true} handleModalClose={profileModalClose}>
            <ModalChildrenMessage
              status={profileResponse.status}
              close='Close'
              handleModalClose={profileModalClose}
              message={profileResponse.message}
            />
          </ModalContainer>
        )}
        <PasswordResetConfirmation
        passwordModal={passwordModal}
        passwordModalClose={passwordModalClose}
        passwordReset={passwordReset}
      />
    </>
  );
};

export default ManageProfile;
