import React from 'react';
import {PieChart, Pie, Cell, ResponsiveContainer, Tooltip} from 'recharts';
import PropTypes from 'prop-types';
import {ScanColors} from 'shared/constants/AppEnums';

const COLORS = [
  ScanColors.critical,
  ScanColors.high,
  ScanColors.medium,
  ScanColors.low,
];

const ReportPieChart = ({Critical, High, Medium, Low}) => {
  const data = [
    {name: 'Critical', value: Critical},
    {name: 'High', value: High},
    {name: 'Medium', value: Medium},
    {name: 'Low', value: Low},
  ];
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <PieChart>
        <Pie
          data={data}
          labelLine={false}
          outerRadius={80}
          fill='#8884d8'
          dataKey='value'
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ReportPieChart;

ReportPieChart.propTypes = {
  Critical: PropTypes.number,
  High: PropTypes.number,
  Medium: PropTypes.number,
  Low: PropTypes.number,
};
