import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import EditSfdc from './EditSfdc/EditSfdc';
import EditSfcc from './EditSfcc/EditSfcc';
import {Fragment} from 'react';
import EditSfccOffline from './EditSfccOffline/EditSfccOffline';
import EditSfdcOffline from './EditSfdcOffline/EditSfdcOffline';
import {useDispatch} from 'react-redux';
import {EDIT_SALESFORCE_ORG_RESULT} from 'shared/constants/ActionTypes';

function EditOrg({
  open,
  org_id,
  handleModalClose,
  isSfcc,
  name,
  webdavurl,
  instanceurl,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({type: EDIT_SALESFORCE_ORG_RESULT, payload: null});
    };
  }, []);

  return (
    <Fragment>
      {isSfcc == false && (instanceurl != 'Scratch Org (Offline)' && instanceurl != 'scratch_offline') && (
        <EditSfdc
          name={name}
          org_id={org_id}
          open={open}
          handleModalClose={handleModalClose}
        />
      )}
      {isSfcc == false && (instanceurl == 'Scratch Org (Offline)' || instanceurl == 'scratch_offline') && (
        <EditSfdcOffline
          name={name}
          open={open}
          handleModalClose={handleModalClose}
          org_id={org_id}
        />
      )}

      {isSfcc == true && webdavurl != '' && (
        <EditSfcc
          name={name}
          instanceurl={instanceurl}
          open={open}
          handleModalClose={handleModalClose}
          org_id={org_id}
        />
      )}
      {isSfcc == true && webdavurl == '' && (
        <EditSfccOffline
          name={name}
          open={open}
          handleModalClose={handleModalClose}
          org_id={org_id}
        />
      )}
    </Fragment>
  );
}

export default EditOrg;

EditOrg.propTypes = {
  org_id: PropTypes.string,
  open: PropTypes.bool,
  handleModalClose: PropTypes.func,
  name: PropTypes.string,
  isSfcc: PropTypes.bool,
  webdavurl: PropTypes.string,
  instanceurl: PropTypes.string,
};
