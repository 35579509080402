import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
  IS_API_IN_PROCESS,
  GET_USER_INFO,
  GET_USERS,
  GET_GLOBAL_PERMISSION,
} from 'shared/constants/ActionTypes';

import jwtAxios from '@crema/services/auth/jwt-auth';
import IntlMessages from '@crema/utility/IntlMessages';
import {processGlobalPermissions, prcessOrgPermissions} from './Roles';

export const fetchStart = () => {
  return (dispatch) => dispatch({type: FETCH_START});
};

export const fetchSuccess = () => {
  return (dispatch) => dispatch({type: FETCH_SUCCESS});
};
export const updatingContent = () => {
  return (dispatch) => dispatch({type: UPDATING_CONTENT});
};

export const fetchError = (error) => {
  return (dispatch) => dispatch({type: FETCH_ERROR, payload: error});
};

export const showMessage = (message) => {
  return (dispatch) => dispatch({type: SHOW_MESSAGE, payload: message});
};
export const onToggleAppDrawer = () => {
  return (dispatch) => dispatch({type: TOGGLE_APP_DRAWER});
};

export const hideMessage = () => {
  return (dispatch) => dispatch({type: HIDE_MESSAGE});
};

// Get user Info

export const onGetUserInfo = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get('/user/get')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_USER_INFO, payload: data.data});
          dispatch({
            type: GET_GLOBAL_PERMISSION,
            payload: data.data?.rolesAndPermissionsObject?.global,
          });
          dispatch(
            processGlobalPermissions({
              rolesAndPermissionsObject:
                data.data?.rolesAndPermissionsObject || null,
            }),
          );
          dispatch(
            prcessOrgPermissions({
              orgLevel: data.data?.rolesAndPermissionsObject?.orgLevel || null,
            }),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

// GET USERS

export const onGetUsers = () => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get('/users/get')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          data.data['users']
            ? dispatch({type: GET_USERS, payload: data.data.users})
            : dispatch({type: GET_USERS, payload: undefined});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

// Refresh Users

export const onRefreshUsers = () => {
  return (dispatch) => {
    dispatch(onGetUsers());
    dispatch(onGetUserInfo());
  };
};
