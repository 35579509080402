import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Dashboard from './Dashboard';
import Salesforce from './Salesforce';
import Setup from './Setup';
import Profile from './Profile';
import Reports from './Reports';
import Integration from './Integration';
import Details from './Details';
import Configure from './Configure';
import Team from './Team';
import Authentication from './Authentication';
import Roles from './Roles';
import ScanStatistics from './ScanStatistics';
import Table from './Table';
import Logging from './Logging';
import Scheduler from './Scheduler';
import Tags from './Tags';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    dashboard: Dashboard,
    salesforce: Salesforce,
    setup: Setup,
    profile: Profile,
    reports: Reports,
    integration: Integration,
    details: Details,
    configure: Configure,
    team: Team,
    authentication: Authentication,
    roles: Roles,
    scanStatistics: ScanStatistics,
    table: Table,
    logging: Logging,
    scheduler: Scheduler,
    tags: Tags,
  });
export default reducers;
