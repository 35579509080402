import React, {useEffect, useState} from 'react';
import AppCard from '@crema/core/AppCard';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import styles from './ScanSettings.module.css';
import ScanTypes from './ScanTypes';
import {onGetScanTypes} from 'redux/actions';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {GET_SCAN_TYPES} from 'shared/constants/ActionTypes';
import PropTypes from 'prop-types';
import {onGetGlobalConfigs} from 'redux/actions';
import Switch from '@mui/material/Switch';
import {onPostScanTypes} from 'redux/actions';

function ScanSettings({writeable}) {
  let {org_id} = useParams();

  const scanTypes = useSelector(({dashboard}) => dashboard.scanTypes);
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetScanTypes({org_id}));
    return () => {
      dispatch({type: GET_SCAN_TYPES, payload: null});
    };
  }, [org_id]);

  useEffect(() => {
    dispatch(onGetGlobalConfigs(true));
  }, []);

  const updateHandler = (name_key, value) => {
    dispatch({
      type: GET_SCAN_TYPES,
      payload: {
        ...scanTypes,
        name_key: value,
      },
    });
  };

  const [isOn, setIsOn] = useState(scanTypes?.isenabled);
  
  const toggleSwitch = (event) => {
    const newIsOn = event.target.checked;
    setIsOn(newIsOn);
    dispatch(
      onPostScanTypes(
        org_id,
        Object.assign({}, scanTypes, {isenabled: newIsOn}),
      ),
    );
    dispatch(onGetGlobalConfigs(true));
  };

  useEffect(() => {
    setIsOn(scanTypes?.isenabled);
  }, [scanTypes?.isenabled]);
  

  return (
    <AppCard>
      <div className={styles.switchContainer}>
        <p>Enforce Settings</p>
        <Switch 
        checked={isOn} 
        onChange={toggleSwitch} 
        updateHandler={updateHandler}
        color='primary' 
        disabled = {!writeable}
        />
      </div>
      <p className={styles.rulesetSub}>
          {!isOn ? 'Scan Settings as defined at the Environment level are now ACTIVE' : 'Scan Settings as defined on this Workspace page are now ACTIVE'}
        </p>
      <div className={styles.scanSettingsHeader}>
        <SettingsOutlinedIcon />
        <h3>Edit Scan Types</h3>
      </div>

      <div className={styles.scanSettingsInfo}>
        <p className={styles.rulesetSub}>
          Scan Type settings managed on this tab will apply only to this
          workspace and will override the global version of these settings.
        </p>
        <p className={styles.rulesetSub}>
          Changes will not impact scans that have already completed, only future
          scans.
        </p>
      </div>

      {scanTypes != null && Object.keys(scanTypes).length > 0 &&   (
        <>
          {/* <ScanTypes
            updateHandler={updateHandler}
            name='Auto Scan'
            name_key='auto_scanning'
            checkbool={scanTypes.auto_scanning}
            writeable={writeable}
          /> */}
          <ScanTypes
            updateHandler={updateHandler}
            name='Static Code Analysis (SAST)'
            name_key='static_code'
            checkbool={isOn && scanTypes.static_code !== undefined ? scanTypes.static_code : globalConfigs?.static_code}
            writeable={writeable && isOn}
          />
          <ScanTypes
            updateHandler={updateHandler}
            name='Dynamic Testing (IAST)'
            name_key='runtime_testing'
            checkbool={isOn && scanTypes.runtime_testing !== undefined ? scanTypes.runtime_testing : globalConfigs?.runtime_testing}
            writeable={writeable && isOn}
          />
          <ScanTypes
            updateHandler={updateHandler}
            name='Software Composition Analysis (SCA)'
            name_key='software_composition_analysis'
            checkbool={isOn && scanTypes.software_composition_analysis !== undefined ? scanTypes.software_composition_analysis : globalConfigs?.software_composition_analysis}
            writeable={writeable && isOn}
          />
          <ScanTypes
            updateHandler={updateHandler}
            name='Configuration Testing'
            name_key='configuration_testing'
            checkbool={isOn && scanTypes.configuration_testing !== undefined ? scanTypes.configuration_testing : globalConfigs?.configuration_testing}
            writeable={writeable && isOn}
          />
          <ScanTypes
            updateHandler={updateHandler}
            name='Third Party Packages'
            name_key='third_party_packages'
            checkbool={isOn && scanTypes.third_party_packages !== undefined ? scanTypes.third_party_packages : globalConfigs?.third_party_packages}
            writeable={writeable && isOn}
          />
          {/* <ScanTypes
            updateHandler={updateHandler}
            name='Notify All Users of Vulnerabilities'
            name_key='send_results_to_all_users'
            checkbool={scanTypes.send_results_to_all_users}
            writeable={writeable}
          /> */}
        </>
      )}
    </AppCard>
  );
}

export default ScanSettings;

ScanSettings.propTypes = {
  writeable: PropTypes.bool,
};
