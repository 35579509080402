import React from 'react';
import styles from './FindingsOverview.module.css';
import VulnerabilityTypes from './VulnerabilityTypes/VulnerabilityTypes';

function FindingsOverview() {
  return (
    <div className={styles.findingsOverviewContainer}>
      <h1>Findings Overview</h1>
      <p>Following is a visualization of vulnerabilities identified by DigitSec.</p>
      <VulnerabilityTypes />
    </div>
  );
}

export default FindingsOverview;
