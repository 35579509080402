import React, {Fragment, useState} from 'react';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styles from './ComplianceReport.module.css';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {FETCH_ERROR} from 'shared/constants/ActionTypes';

const options = [
  {label: 'Select All', value: 'select-all'},
  {label: 'APPI', value: 'APPI'},
  {label: 'GDPR', value: 'GDPR'},
  {label: 'HIPAA', value: 'HIPAA'},
  {label: 'ISO 27001', value: 'ISO 27001'},
  {label: 'PCI', value: 'PCI'},
];

const ComplianceReport = ({
  complianceModal,
  handleModalClose,
  _id,
  isSummaryReport,
  orgId
}) => {
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState([]);

  const navigate = useNavigate();
  const complianceHandleModalProceed = () => {
    if (selectedValues.length == 0) {
      return dispatch({
        type: FETCH_ERROR,
        payload: 'Please Select atleast 1 Compliance field',
      });
    }
    let arrayVal = selectedValues
      .map((item) => item.value)
      .filter((item) => item != 'select-all');
      handleModalClose();

    navigate(`/report/${_id}/${orgId}/${arrayVal}/${isSummaryReport}`);
  };

  const complianceChangeHandler = (event, value) => {
    if (value.find((val) => val.value === 'select-all')) {
      setSelectedValues(options.filter((item) => item.value != 'select-all'));
    } else setSelectedValues(value);
  };

  return (
    <Fragment>
      <ModalContainer
        title=' Download Compliance Report'
        open={complianceModal}
        handleModalClose={handleModalClose}
        handleModalProceed={complianceHandleModalProceed}
        proceedButtonText='Download'
        closeButtonText='Close'
      >
        <p>Please select the fields you would like in the report.</p>
        <div className={styles.autoCompleteContainer}>
          <Autocomplete
            multiple
            limitTags={1}
            disablePortal
            id='combo-box-demo'
            options={options}
            value={selectedValues}
            onChange={complianceChangeHandler}
            className={styles.autoComplete}
            disableCloseOnSelect
            filterSelectedOptions
            required
            renderInput={(params) => (
              <TextField
                className={styles.textField}
                {...params}
                variant='outlined'
                label='Compliance Fields'
                size='small'
                name='assign'
              />
            )}
          ></Autocomplete>
        </div>
      </ModalContainer>
    </Fragment>
  );
};

export default ComplianceReport;
ComplianceReport.propTypes = {
  complianceModal: PropTypes.bool,
  handleModalClose: PropTypes.func,
  _id: PropTypes.string,
  isSummaryReport: PropTypes.bool,
  orgId: PropTypes.string
};
