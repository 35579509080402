import React, {useEffect} from 'react';
import AppContentView from '@crema/core/AppContentView';
import {useAuthUser} from '../../utility/AuthHooks';
import {
  useLayoutActionsContext,
  useLayoutContext,
} from '../../utility/AppContextProvider/LayoutContextProvider';
import Layouts from './Layouts';
import AuthWrapper from './AuthWrapper';
import {useUrlSearchParams} from 'use-url-search-params';
import {useSidebarActionsContext} from '../../utility/AppContextProvider/SidebarContextProvider';
import {useDispatch} from 'react-redux';
import {onGetUserInfo} from 'redux/actions';
import {getAllRoles} from 'redux/actions/Roles';
import {useNavigate} from 'react-router-dom';

const AppLayout = () => {
  const {navStyle} = useLayoutContext();
  const {isAuthenticated} = useAuthUser();
  const {updateNavStyle} = useLayoutActionsContext();
  const {updateMenuStyle, setSidebarBgImage} = useSidebarActionsContext();
  const AppLayout = Layouts[navStyle];
  const [params] = useUrlSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout);
    if (params.menuStyle) updateMenuStyle(params.menuStyle);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(onGetUserInfo());
      dispatch(getAllRoles());
      if (params.token) {
        navigate('salesforce');
      }
    }
    return () => {};
  }, [isAuthenticated]);

  return (
    <>
      {isAuthenticated ? (
        <AppLayout />
      ) : (
        <AuthWrapper>
          <AppContentView />
        </AuthWrapper>
      )}
    </>
  );
};

export default React.memo(AppLayout);
