import React, {Fragment, useEffect} from 'react';
import AppBarSetup from '../../components/setup/AppBarSetup';
import {useDispatch, useSelector} from 'react-redux';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import ModalChildrenMessage from 'components/modals/ModalChildrenMessage/ModalChildrenMessage';
import {SETUP_RESPONSE} from 'shared/constants/ActionTypes';
import {
  onGetCompanyInfo,
  onGetSubscription,
  onGetAllOrgs,
  onGetAllOrgsInAccount,
  onCheckIfAdmin,
  onGetUsers,
} from 'redux/actions';
import {AppInfoView} from '@crema';

const Setup = () => {
  // const isAdmin = useSelector(({authentication}) => authentication.isAdmin);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onCheckIfAdmin());
    dispatch(onGetUsers());
    dispatch(onGetSubscription());
    dispatch(onGetCompanyInfo());
    dispatch(onGetAllOrgs());
    dispatch(onGetAllOrgsInAccount());
  }, []);

  const setupResponse = useSelector(({setup}) => setup.setupResponse);

  const setupModalClose = () => {
    dispatch({type: SETUP_RESPONSE, payload: undefined});
  };

  return (
    <Fragment>
      {/* {isAdmin == false ? (
        <h1>No Access Provided</h1>
      ) : ( */}
      <AppBarSetup></AppBarSetup>

      {setupResponse != null && (
        <ModalContainer open={true}>
          <ModalChildrenMessage
            status={setupResponse?.status}
            message={setupResponse?.message}
            close='Close'
            handleModalClose={setupModalClose}
          />
        </ModalContainer>
      )}
      <AppInfoView />
    </Fragment>
  );
};

export default Setup;
