import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import styles from './ModalContainer.module.css';
import {Button} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '370px',
    sm: '600px',
    md: '800px',
    lg: '800px',
  },
  maxHeight: '900px',
  // overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

function ModalContainer({
  open,
  handleModalClose,
  handleModalProceed,
  title,
  children,
  proceedButtonText,
  closeButtonText,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <h1 className={styles.modalTitle}>{title}</h1>
          {children}
          <div className={styles.btnGroup}>
            

            {closeButtonText != undefined && (
              <Button
                onClick={handleModalClose}
                color='secondary'
                variant='contained'
              >
                {closeButtonText ? closeButtonText : 'Cancel'}
              </Button>
            )}

            {proceedButtonText != undefined && (
              <Button
                onClick={handleModalProceed}
                color='primary'
                variant='contained'
              >
                {proceedButtonText ? proceedButtonText : 'Proceed'}
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalContainer;

ModalContainer.propTypes = {
  open: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleModalProceed: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  proceedButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
};
