import * as React from 'react';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import styles from './IgnoredScans.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import IgnoredScansTable from './IgnoredScansTable';
import {onPostCustomRulesBulk} from 'redux/actions';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import DeleteIcon from '@mui/icons-material/Delete';
// import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import Checkbox from '@mui/material/Checkbox';

export default function IgnoredScans(props) {
  let {org_id} = useParams();
  const dispatch = useDispatch();

  const [ignorelistIds, setIgnorelistIds] = useState([]);

  // useEffect(() => {
  //   console.log('ignorelistIds', ignorelistIds);
  // }, [ignorelistIds]);

  const handleCheckboxChange = (id) => {
    setIgnorelistIds((prev) => {
      return prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id];
    });
  };

  const [deleteBulkModal, setDeleteBulkModal] = useState(false);
  const openDeleteModal = () => setDeleteBulkModal(true);

  const closeDeleteBulkModal = () => setDeleteBulkModal(false);

  const deleteBulkIgnore = () => {
    dispatch(onPostCustomRulesBulk({org_id, ignorelistIds}));
    closeDeleteBulkModal();
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = props.customRules.map((rule) => rule._id);
      setIgnorelistIds(newSelectedIds);
    } else {
      setIgnorelistIds([]);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.btnContainer}>
      <div className={styles.containerAll}>
      <p className={styles.pContainerAll}>Select all</p>
      <Checkbox
        indeterminate={
          ignorelistIds.length > 0 &&
          ignorelistIds.length < (props.customRules?.length || 0)
        }
        checked={
          props.customRules?.length > 0 &&
          ignorelistIds.length === props.customRules?.length
        }
        onChange={handleSelectAllClick}
      />
      </div>
      {ignorelistIds.length > 0 && (
        <div onClick={openDeleteModal}>
          <p className={styles.pContainer}>Delete all selections</p>
        </div>
      )}
      </div>
      <TableContainer className={styles.tableContainer}>
        <Table sx={{minWidth: 650}} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'></TableCell>
              <TableCell>Filename</TableCell>
              <TableCell align='center'>Reason</TableCell>
              <TableCell align='center'>Added By</TableCell>
              <TableCell align='center'>Date</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.customRules != null &&
              props.customRules.map((row) => (
                <IgnoredScansTable
                  key={row._id}
                  row={row}
                  writeable={props.writeable}
                  onCheckboxChange={handleCheckboxChange}
                  isSelected={ignorelistIds.includes(row._id)}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.customRules != null && props.customRules.length == 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
            width: '100%',
          }}
        >
          <h2>There are currently no rules present.</h2>
        </div>
      )}
      <ModalContainer
        open={deleteBulkModal}
        handleModalClose={closeDeleteBulkModal}
        handleModalProceed={deleteBulkIgnore}
        closeButtonText='No'
        proceedButtonText='Yes'
      >
        <div className={styles.deleteContainer}>
          <DeleteIcon color='secondary' sx={{fontSize: 100}} />
          <h2>Do you want to delete all elements selected?</h2>
          <p>This will remove this file from the ignore list</p>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
}

IgnoredScans.propTypes = {
  customRules: PropTypes.array,
  writeable: PropTypes.bool,
};
