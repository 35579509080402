import React from 'react';
import styles from './Contact.module.css';

function Contact() {
  return (
    <div className={styles.container}>
      <div className={styles.contactContainer}>
        <h2>Contact</h2>
        <ul className={styles.listContainer}>
          <li>DigitSec, Inc.</li>
          <li>92 Lenora Street #137</li>
          <li>Seattle, WA 98121</li>
          <li>206-659-9521</li>
          <li>info@digitsec.com</li>
        </ul>
      </div>

      <div className={styles.confidentialInformation}>
        <h2>Confidential Information</h2>
        <p>
          The information contained in this document is confidential in nature
          and should be handled with due care and restricted to authorized
          personnel only.
        </p>
      </div>
    </div>
  );
}

export default Contact;
