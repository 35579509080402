import React, {Fragment} from 'react';
import AppCard from '@crema/core/AppCard';
import styles from './HistoryItem.module.css';
import PropTypes from 'prop-types';
// import {trimEmail} from 'components/utils/UtilFunctions';
// import {useSelector} from 'react-redux';

function HistoryItem({historyItem}) {
  let options1 = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  let options = {timeStyle: 'long', hour12: true};
  // const userInfo = useSelector(({common}) => common.userInfo);

  return (
    <Fragment>
      <AppCard sxStyle={{}}>
        <div className={styles.historyItemContainer}>
          <div className={styles.historyItemTitle}>
            <h5>{historyItem?.author != null ? historyItem.author : ''}</h5>
            <p>{historyItem.message}</p>
          </div>
          <div>
            <p>{new Date(historyItem.date).toLocaleString('en-us', options1)}{' '}
              {new Date(historyItem.date).toLocaleString('en-us', options)}{' '}</p>
          </div>
        </div>
      </AppCard>
    </Fragment>
  );
}

export default HistoryItem;

HistoryItem.propTypes = {
  historyItem: PropTypes.object,
  assigned: PropTypes.object,
};
