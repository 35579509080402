import React from 'react';
// import styles from './CodeSnippet.module.css';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism as prismStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';

function CodeSnippet({vulnerablefunction, vulnerablefunctionline}) {

  // const preStyle = {
  //   backgroundColor: '#eee',
  // };

  //line number for vuln function
  const lines = vulnerablefunction.split("\n");

  const codeWithLineNumbers = lines
    .map((line, index) => `${vulnerablefunctionline + index} ${line}`)
    .join("\n");

  return (
        <div>
        <SyntaxHighlighter language="java" customStyle={prismStyle}>
          {codeWithLineNumbers}
        </SyntaxHighlighter>
      </div>
  );
}

export default CodeSnippet;

CodeSnippet.propTypes = {
    vulnerablefunction: PropTypes.string,
    vulnerablefunctionline: PropTypes.number,
};
