import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  IS_ADMIN,
  IS_API_IN_PROCESS,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';

// Verify Authentication
import jwtAxios from '@crema/services/auth/jwt-auth';

export const onVerifyAuthentication = () => {
  jwtAxios
    .post('api/verify_authentication', {})
    .then(({data}) => {
      console.log(data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const onCheckIfAdmin = () => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get('user/checkIfAdmin')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          data.data == 'hasAccess'
            ? dispatch({type: IS_ADMIN, payload: true})
            : data.data == 'noAccess'
            ? dispatch({type: IS_ADMIN, payload: false})
            : dispatch({type: IS_ADMIN, payload: null});
        } else {
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onResetPassword = ({email, recaptchtoken}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post('passwordreset', {email, recaptchtoken})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (data.error) {
            dispatch({
              type: FETCH_ERROR,
              payload: data?.error || 'Something went wrong',
            });
          } else if (data.success) {
            dispatch({type: SHOW_MESSAGE, payload: data.success});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong! Please try again',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const onVerifyPassword = ({emailtoken, recaptchtoken, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post('verifypassword', {emailtoken, recaptchtoken, password})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (data.error) {
            dispatch({
              type: FETCH_ERROR,
              payload: data?.error || 'Something went wrong',
            });
          } else if (data.success) {
            dispatch({type: SHOW_MESSAGE, payload: data.success});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong! Please try again',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};
