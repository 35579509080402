import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import styles from './SeverityOverview.module.css';
import ChartStyles from 'style/Chart.module.css';

import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';

const renderLegend = (props) => {
  const {payload} = props;
  return (
    <ul className={ChartStyles.legendStyle}>
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{color: entry['color'], fontSize: 18, fontWeight: 'bold'}}
        >
          {entry.payload.name}:{entry.payload.value}
        </li>
      ))}
    </ul>
  );
};

const CustomTooltip = ({active, payload}) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p className='label'>{`${payload[0].payload.name} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  payload: PropTypes.any,
  active: PropTypes.bool,
  label: PropTypes.string,
};

function SeverityOverview() {
  const getScanStats = useSelector(({reports}) => reports.getScanStats);

  const COLORS = [
    'rgb(255, 0, 0)',
    'rgb(171, 9, 6)',
    'rgb(245, 162, 0)',
    'rgb(15, 154, 238)',
    'rgb(52, 193, 20)',
    '#cc3300',
    '#00cc00',
    '#33cccc',
    '#00ff99',
  ];

  let Severity = [];

  if (getScanStats != null && getScanStats.findings != null) {
    Severity = [
      {name: 'Critical', value: getScanStats.findings[0]},
      {name: 'High', value: getScanStats.findings[1]},
      {name: 'Medium', value: getScanStats.findings[2]},
      {name: 'Low', value: getScanStats.findings[3]},
      {name: 'Info', value: getScanStats.findings[4]},
    ];
  }

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='black'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <>
      {getScanStats != null && Severity != null && (
        <div className={styles.ChartContainer}>
          <h2>Vulnerability Severity Overview</h2>
          <div>
            <div className='col-md-8'>
              <ResponsiveContainer
                width={1000}
                height={800}
                className='text-center'
              >
                <PieChart
                  width={1000}
                  height={800}
                  margin={{top: 10, left: 50}}
                  label
                >
                  <Tooltip content={<CustomTooltip />} />
                  <Legend verticalAlign='top' content={renderLegend} />

                  <Pie
                    data={Severity}
                    cx='50%'
                    cy='50%'
                    label={renderCustomizedLabel}
                    fill='#8884d8'
                    dataKey='value'
                    outerRadius={160}
                    innerRadius={110}
                    isAnimationActive={false}
                    paddingAngle={2}
                    minAngle={1}
                  >
                    {getScanStats.findings.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SeverityOverview;
