import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  IS_API_IN_PROCESS,
  SPECIFIC_ORG_USERS,
  TEAM_RESPONSE,
} from 'shared/constants/ActionTypes';

import jwtAxios from '@crema/services/auth/jwt-auth';
import IntlMessages from '@crema/utility/IntlMessages';
import {onPostEditUserSfdc} from './Setup';
import {onGetUsers} from './Common';
import {assignRole} from './Roles';

// Get users specific to ORG

export const onGetSpecificOrgUsers = ({org_id, search}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post('/user/specificorg', {orgId: org_id, search})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: SPECIFIC_ORG_USERS,
            payload: data.data.users ? data.data.users : [],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: SPECIFIC_ORG_USERS, payload: null});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

// Post Specific Org

export const onPostAddSpecificUser = ({
  email,
  role,
  permission,
  is_sfdc_oauth,
  orgName,
  org_id,
  roleId,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/users/add`, {email, role, permission, is_sfdc_oauth})
      .then((data) => {
        if (data.status == 200) {
          dispatch({type: FETCH_SUCCESS});
          if (
            data.data.success == 'User registered' ||
            data.data.error == 'User already exists'
          ) {
            dispatch({
              type: TEAM_RESPONSE,
              payload: {
                status: 'success',
                message: 'User Added Successfully!',
              },
            });
            dispatch(
              onPostEditUserSfdc({
                userId: data.data?.user_added?._id,
                orgName: [...data.data?.user_added.salesforceOrgs, orgName],
                org_id: org_id,
                orgId: org_id,
              }),
            );
            if (roleId) {
              dispatch(
                assignRole({
                  method: 'assign',
                  orgs: [{sfdcId: org_id, roleId: roleId}],
                  flag: 'ORG_LEVEL',
                  userId: data.data?.user_added?._id._id,
                  orgId: org_id,
                }),
              );
            }
          } else {
            dispatch({
              type: TEAM_RESPONSE,
              payload: {
                status: 'fail',
                message: data.data.error,
              },
            });
          }
          dispatch(onGetUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({
            type: TEAM_RESPONSE,
            payload: {
              status: 'fail',
              message: 'Failed to Add User!',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({
          type: TEAM_RESPONSE,
          payload: {
            status: 'fail',
            message: 'Failed to Add User!',
          },
        });
      });
  };
};
