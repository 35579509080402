import React from 'react';
import {Box} from '@mui/material';
import Logo from 'assets/icon/black-horizontal-whitebackground.svg';

const logoSize = {
  height: '50px',
};

const appLogoContainer = {
  height: {xs: 56, sm: 70},
  padding: 2.5,
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    height: {xs: 40, sm: 45},
  },
};

const AppLogo = () => {
  return (
    <Box sx={appLogoContainer} className='app-logo'>
      <img src={Logo} alt='App Logo' style={logoSize} />
    </Box>
  );
};

export default AppLogo;
