import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_PAGE_COUNT,
  GET_SFDC_INFO,
  IS_API_IN_PROCESS,
  ADD_SALESFORCE_ORG_RESULT,
  ADDING_ORG_IN_PROCESS,
  EDIT_SALESFORCE_ORG_RESULT,
  GET_CUR_PAGE,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {defaultError} from 'shared/constants/AppConst';
import {SALESFORCE_RESPONSE} from 'shared/constants/ActionTypes';
// import config from 'config/test_config.json';
import { onGetUserInfo } from './Common';

// Getting SFDC Info

const s4url = window.env.REACT_APP_S4_URL;
const sfdcoauth = window.env.REACT_APP_SFDC_OAUTH;
const clientidt = window.env.REACT_APP_CLIENT_ID;

export const onSearchSfdcInfo = ({pageNum = 1, search = '', isSfcc = '', tagId = ''}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dispatch({
      type: GET_PAGE_COUNT,
      payload: 1,
    });
    jwtAxios
      .get(`/sfdcinfo?page=${pageNum}&search=${search}&isSfcc=${isSfcc}&tagIds=${tagId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_SFDC_INFO, payload: data.data});
          dispatch({
            type: GET_PAGE_COUNT,
            payload: Math.ceil(data.data.total / 10),
          });
        } else {
          dispatch({
            type: GET_SFDC_INFO,
            payload: {
              error: true,
            },
          });
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_SFDC_INFO,
          payload: {
            error: true,
          },
        });
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const addSalesforceOrg = ({name, instanceurl, clientid = ''}) => {
  return (dispatch) => {
    dispatch({type: ADDING_ORG_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/sfdcinfo`, {name, instanceurl, clientid})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: ADD_SALESFORCE_ORG_RESULT,
            payload: {
              status: 'success',
              message: 'Workspace added successfully!',
            },
          });
          dispatch(onGetUserInfo());
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          dispatch(
            checkClientId({
              _id: data.data._id,
              instanceurl: instanceurl,
              clientid: clientid
            }),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          dispatch({
            type: ADD_SALESFORCE_ORG_RESULT,
            payload: {
              status: 'fail',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        dispatch({
          type: ADD_SALESFORCE_ORG_RESULT,
          payload: {
            status: 'fail',
            message: error.message,
          },
        });
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

const redirectURL = ({instanceurl, _id, clientid}) => {
  const redirect_uri = `${s4url}${sfdcoauth}`;
  var url = '';
  url = `${instanceurl}/services/oauth2/authorize?response_type=code&client_id=${clientidt}&redirect_uri=${redirect_uri}&state=${_id}!${btoa(
    jwtAxios.defaults.headers.common['x-access-token'],
  )}&prompt=login&scope=refresh_token%20api%20web`;
  if(clientid !='')
  {

    url = `${instanceurl}/services/oauth2/authorize?response_type=code&client_id=${
      clientid
    }&redirect_uri=${redirect_uri}&state=${_id}!${btoa(
    jwtAxios.defaults.headers.common['x-access-token'],
  )}&prompt=login&scope=refresh_token%20api%20web`;
  }
  return url;
};

export const checkClientId = ({_id, instanceurl, clientid}) => {
  return (dispatch) => {
    dispatch({type: ADDING_ORG_IN_PROCESS, payload: true});
    // dispatch({type: FETCH_START});
    jwtAxios
      .get(
        `api/check_client_id?sfdcclienturl=${btoa(
          redirectURL({instanceurl, _id, clientid}),
        )}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          if (data.data.message == 'success') {
            window.location.href = redirectURL({instanceurl, _id, clientid});
          } else {
            dispatch({
              type: SALESFORCE_RESPONSE,
              payload: {
                status: 'fail',
                message: data.data?.message || defaultError,
              },
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          dispatch({
            type: SALESFORCE_RESPONSE,
            payload: {
              status: 'fail',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({
          type: SALESFORCE_RESPONSE,
          payload: {
            status: 'fail',
            message: error.message || defaultError,
          },
        });
      });
  };
};

export const get_oauth_token = ({code, state}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`api/get_oauth_token?code=${code}&state=${state}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: ADD_SALESFORCE_ORG_RESULT,
            payload: {
              status: 'success',
              message: 'Workspace added successfully!',
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({
            type: ADD_SALESFORCE_ORG_RESULT,
            payload: {
              status: 'fail',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({
          type: ADD_SALESFORCE_ORG_RESULT,
          payload: {
            status: 'fail',
            message: error.message,
          },
        });
      });
  };
};

export const addOfflineOrg = ({name}) => {
  return (dispatch) => {
    dispatch({type: ADDING_ORG_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`s4onprem/offline`, {name})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          dispatch(onSearchSfdcInfo({pageNum: 1, search: ''}));
          dispatch(onGetUserInfo());
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Workspace added successfully!',
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const addOfflineCC = ({name}) => {
  return (dispatch) => {
    dispatch({type: ADDING_ORG_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`s4onprem/offlinecc`, {name})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          dispatch(onSearchSfdcInfo({pageNum: 1, search: ''}));
          dispatch(onGetUserInfo());
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Workspace added successfully!',
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const addCommerceCloudOrg = ({
  name,
  clientId,
  clientSecret,
  username,
  accesskey,
  instanceUrl,
}) => {
  return (dispatch) => {
    dispatch({type: ADDING_ORG_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/sfccinfo`, {
        name,
        clientId,
        clientSecret,
        username,
        accesskey,
        instanceUrl,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (data.data.message == 'success') {
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Workspace added successfully!',
            });
            dispatch(onGetUserInfo());
            dispatch(onSearchSfdcInfo({pageNum: 1, search: ''}));
            // window.location.href = 'https://s409.digitsec.com/app/success';
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload:
                data.data.message.length > 0 ? data.data.message : defaultError,
            });
          }
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

// Delete Organization

export const onDeleteOrg = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    // const curPage = getState().salesforce.curPage;
    jwtAxios
      .delete(`/sfdcinfo/${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(onSearchSfdcInfo({pageNum: 1, search: ''}));
          // dispatch({type: GET_SFDC_INFO, payload: data.data});
          dispatch({
            type: GET_PAGE_COUNT,
            payload: Math.ceil(data.data.total / 10),
          });
          dispatch({
            type: GET_CUR_PAGE,
            payload: 1,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

// Update Organization

export const updateSalesforceOrg = ({org_id, name, instanceurl, clientid = ''}) => {
  return (dispatch) => {
    dispatch({type: ADDING_ORG_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`/sfdcinfo/${org_id}`, {name, instanceurl, clientid})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: ADD_SALESFORCE_ORG_RESULT,
            payload: {
              status: 'success',
              message: 'Workspace added successfully!',
            },
          });
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          dispatch(
            checkClientId({
              _id: data.data._id,
              instanceurl: instanceurl,
              clientid: clientid
            }),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          dispatch({
            type: ADD_SALESFORCE_ORG_RESULT,
            payload: {
              status: 'fail',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        dispatch({
          type: ADD_SALESFORCE_ORG_RESULT,
          payload: {
            status: 'fail',
            message: defaultError,
          },
        });
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const updateOfflineOrg = ({name, org_id}) => {
  return (dispatch, getState) => {
    dispatch({type: ADDING_ORG_IN_PROCESS, payload: true});
    const curPage = getState().salesforce.curPage;
    jwtAxios
      .put(`s4onprem/offline/${org_id}`, {name})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          dispatch(onSearchSfdcInfo({pageNum: curPage, search: ''}));
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Offline SFDC Updated Successfully!',
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.data?.message || defaultError,
          });
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error?.message || defaultError,
        });

      });
  };
};

// UPDATE CC

export const updateCommerceCloudOrg = ({
  name,
  clientId,
  clientSecret,
  username,
  accesskey,
  instanceUrl,
  org_id,
}) => {
  return (dispatch, getState) => {
    dispatch({type: ADDING_ORG_IN_PROCESS, payload: true});
    const curPage = getState().salesforce.curPage;
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`/sfccinfo/${org_id}`, {
        name,
        clientId,
        clientSecret,
        username,
        accesskey,
        instanceUrl,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          if (data.data.message == 'success') {
            dispatch({type: EDIT_SALESFORCE_ORG_RESULT, payload: 'success'});
            dispatch(onSearchSfdcInfo({pageNum: curPage, search: ''}));
          } else {
            dispatch({type: EDIT_SALESFORCE_ORG_RESULT, payload: 'fail'});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          dispatch({type: EDIT_SALESFORCE_ORG_RESULT, payload: 'fail'});
        }
      })
      .catch((error) => {
        dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        dispatch({type: EDIT_SALESFORCE_ORG_RESULT, payload: 'fail'});
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const updateOfflineCC = ({offlineName, org_id}) => {
  return (dispatch, getState) => {
    dispatch({type: ADDING_ORG_IN_PROCESS, payload: true});
    const curPage = getState().salesforce.curPage;
    jwtAxios
      .put(`s4onprem/offlinecc/${org_id}`, {name: offlineName})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          dispatch(onSearchSfdcInfo({pageNum: curPage, search: ''}));
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Offline SFCC Updated Successfully!',
          });
        } else {
          dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
          dispatch({
            type: FETCH_ERROR,
            payload: data.data?.message || defaultError,
          });
        }
      })
      .catch((error) => {
        dispatch({type: ADDING_ORG_IN_PROCESS, payload: false});
        dispatch({
          type: FETCH_ERROR,
          payload:  error.response?.data?.message || error?.message || defaultError,
        });
      });
  };
};
