import React, {useState} from 'react';
import Button from '@mui/material/Button';
import {Form, Formik} from 'formik';
import * as yup from 'yup';
import AppInfoView from '@crema/core/AppInfoView';
import {Link, useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import IntlMessages from '@crema/utility/IntlMessages';
import {useIntl} from 'react-intl';
import AppTextField from '@crema/core/AppFormComponents/AppTextField';
import {useAuthMethod} from '@crema/utility/AuthHooks';
import {Fonts} from '../../../shared/constants/AppEnums';
import {FcGoogle} from 'react-icons/fc';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {useDispatch} from 'react-redux';
import {FETCH_ERROR} from 'shared/constants/ActionTypes';
import {checkIfUrl} from 'components/utils/UtilFunctions';

const validationSchema = yup.object({
  email: yup
    .string()
    .email(<IntlMessages id='validation.emailFormat' />)
    .required(<IntlMessages id='validation.emailRequired' />),
  password: yup
    .string()
    .required(<IntlMessages id='validation.passwordRequired' />),
});

const validationSchemaEmail = yup.object({
  email: yup
    .string()
    .email(<IntlMessages id='validation.emailFormat' />)
    .required(<IntlMessages id='validation.emailRequired' />),
});



const SigninJwtAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {signInUser, googleSSO} = useAuthMethod();
  const onGoToForgetPassword = () => {
    navigate('/forget-password', {tab: 'jwtAuth'});
  };


  const {messages} = useIntl();

  const [showPass, setShowPass] = useState({email: '', sso: false});

  const checkSSOEnabled = async ({email}) => {
    try {
      const {data} = await jwtAxios.post('api/checkIfSSOEnabled', {email});
      if (data == 'userpass') {
        setShowPass(() => {
          return {
            email: email,
            sso: true,
          };
        });
      } else if (checkIfUrl(data)) {
        window.location.href = data;
      } else if (data == 'error') {
        throw 'There seems to have been an error. Please try again';
      }
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: error?.message || 'Network Error! Please try again.',
      });
    }
  };

  return (
    <>
    <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
      <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', mb: 5}}>
        {showPass.sso && (
          <Formik
            validateOnChange={true}
            initialValues={{
              email: showPass.email || '',
              password: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(data, {setSubmitting}) => {
              setSubmitting(true);
              signInUser({
                email: data.email,
                password: data.password,
              });
              setSubmitting(false);
            }}
          >
            {({isSubmitting}) => (
              <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                <Box sx={{mb: {xs: 5, xl: 8}}}>
                  <AppTextField
                    placeholder={messages['common.email']}
                    name='email'
                    label={<IntlMessages id='common.email' />}
                    variant='outlined'
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                  />
                </Box>

                <Box sx={{mb: {xs: 3, xl: 4}}}>
                  <AppTextField
                    autoFocus
                    type='password'
                    placeholder={messages['common.password']}
                    label={<IntlMessages id='common.password' />}
                    name='password'
                    variant='outlined'
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: {xs: 3, xl: 4},
                  }}
                >
                  <Box
                    component='span'
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      fontWeight: Fonts.MEDIUM,
                      cursor: 'pointer',
                      display: 'block',
                      textAlign: 'left',
                    }}
                    onClick={onGoToForgetPassword}
                  >
                    <IntlMessages id='common.forgetPassword' />
                  </Box>
                </Box>

                <div>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={isSubmitting}
                    sx={{
                      minWidth: 160,
                      fontWeight: Fonts.REGULAR,
                      fontSize: 16,
                      textTransform: 'capitalize',
                      padding: '4px 16px 8px',
                    }}
                  >
                    <IntlMessages id='common.login' />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
        {!showPass.sso && (
          <Formik
            validateOnChange={true}
            initialValues={{
              email: '',
            }}
            validationSchema={validationSchemaEmail}
            onSubmit={(data, {setSubmitting}) => {
              setSubmitting(true);
              checkSSOEnabled({
                email: data.email,
              });
              setSubmitting(false);
            }}
          >
            {({isSubmitting}) => (
              <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                <Box sx={{mb: {xs: 5, xl: 8}}}>
                  <AppTextField
                    autoFocus
                    placeholder={messages['common.email']}
                    name='email'
                    label={<IntlMessages id='common.email' />}
                    variant='outlined'
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        fontSize: 14,
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: {xs: 3, xl: 4},
                  }}
                >
                  <Box
                    component='span'
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      fontWeight: Fonts.MEDIUM,
                      cursor: 'pointer',
                      display: 'block',
                      textAlign: 'left',
                    }}
                    onClick={onGoToForgetPassword}
                  >
                    <IntlMessages id='common.forgetPassword' />
                  </Box>
                </Box>

                <div>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={isSubmitting}
                    sx={{
                      minWidth: 160,
                      fontWeight: Fonts.REGULAR,
                      fontSize: 16,
                      textTransform: 'capitalize',
                      padding: '4px 16px 8px',
                    }}
                  >
                    Next
                  </Button>

                </div>
              </Form>
            )}
          </Formik>
        )}
      </Box>

      <Box>
        <Button
          sx={{
            fontSize: 12,
          }}
          variant='outlined'
          endIcon={<FcGoogle />}
          onClick={googleSSO}
        >
          Sign In with Google
        </Button>
      </Box>

      <Box
        sx={{
          color: 'grey.500',
          margin: '10px 0px',
        }}
      >
        <span style={{marginRight: 4}}>
          <IntlMessages id='common.dontHaveAccount' />
        </span>
        <Box
          component='span'
          sx={{
            fontWeight: Fonts.MEDIUM,
            '& a': {
              color: (theme) => theme.palette.primary.main,
              textDecoration: 'none',
            },
          }}
        >
          <Link to='/signup'>
            <IntlMessages id='common.signup' />
          </Link>
        </Box>
      </Box>

      <AppInfoView />
    </Box>
    </>
  );
};

export default SigninJwtAuth;
