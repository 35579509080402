import {
  GET_SCHEDULER_DATA,
  POST_SCHEDULER_DATA,
  FETCH_ERROR,
  UPDATE_SCHEDULER_DATA,
  GET_ORG_SCHEDULER_DATA,
  POST_CHECK_SCHEDULER_DATA,
  GET_SCHEDULER_ORG_DATA,
} from 'shared/constants/ActionTypes';

const initialState = {
  schedulerData: [],
  checkedSchedulerData: [],
  schedulerOrgs: [],
  error: null,
};

const schedulerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULER_DATA:
      return {
        ...state,
        schedulerData: action.payload,
        error: null,
      };
    case GET_SCHEDULER_ORG_DATA:
      return {
        ...state,
        schedulerOrgs: action.payload,
        error: null,
      };
    case GET_ORG_SCHEDULER_DATA:
      return {
        ...state,
        orgSchedulerData: action.payload,
        error: null,
      };
    case POST_SCHEDULER_DATA:
      return {
        ...state,
        schedulerData: [...state.schedulerData, action.payload],
        error: null,
      };
    case POST_CHECK_SCHEDULER_DATA:
      return {
        ...state,
        checkedSchedulerData: [...state.checkedSchedulerData, action.payload],
        error: null,
      };

    case UPDATE_SCHEDULER_DATA: {
      const updatedIndex = state.schedulerData.findIndex(
        (item) => item._id === action.payload._id,
      );

      const updatedData = [...state.schedulerData];
      updatedData[updatedIndex] = action.payload;

      return {
        ...state,
        schedulerData: updatedData,
        error: null,
      };
    }
    case FETCH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default schedulerReducer;
