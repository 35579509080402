import {
  GET_COMPANY_INFO,
  GET_ALL_ORGS,
  GET_ALL_ORGS_IN_ACCOUNT,
  SETUP_RESPONSE,
  GET_SUBSCRIPTION_INFO,
  SETUP_PROCESS_API,
  UPDATE_USER_STATUS,
} from 'shared/constants/ActionTypes';

const initialState = {
  companyInfo: {},
  getAllOrgs: null,
  setupResponse: null,
  setupProcessApi: false,
  getSubscriptionInfo: null,
};

const setupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_INFO:
      return {
        ...state,
        companyInfo: action.payload,
      };
    case GET_ALL_ORGS:
      return {
        ...state,
        getAllOrgs: action.payload,
      };
    case GET_ALL_ORGS_IN_ACCOUNT:
      return {
        ...state,
        getAllOrgsInAccount: action.payload,
      };
    case SETUP_RESPONSE:
      return {
        ...state,
        setupResponse: action.payload,
      };
    case GET_SUBSCRIPTION_INFO:
      return {
        ...state,
        getSubscriptionInfo: action.payload,
      };
    case SETUP_PROCESS_API:
      return {
        ...state,
        setupProcessApi: action.payload,
      };
    case UPDATE_USER_STATUS:
      return {
        ...state,
        updateUserStatusData: {
          userid: action.payload.userid,
          isDeleted: action.payload.isDeleted,
          Name: action.payload.Name,
          Date: action.payload.Date,
        },
      };
    default:
      return state;
  }
};

export default setupReducer;
