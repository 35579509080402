import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import {useState} from 'react';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import {useDispatch, useSelector} from 'react-redux';
import {onDeleteOrg} from 'redux/actions';
import EditOrg from '../EditOrg/EditOrg';
import {EDIT_SALESFORCE_ORG_RESULT} from 'shared/constants/ActionTypes';
import AddTagsModal from 'components/configure/Tags/AddTagsModal';
// import {getTags} from 'redux/actions/Tags';
import {useEffect} from 'react';
import {assignTagsSFDC} from 'redux/actions/Tags';
import {getAllPermissions} from 'redux/actions/Roles';

function DotsDropdownButton({
  org_id,
  name,
  isSfcc,
  webdavurl,
  instanceurl,
  writeable,
  filteredTags,
  onCleanFields,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteHandler = () => {
    setDeleteModal(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPermissions());
  }, []);

  const [deleteModal, setDeleteModal] = useState(false);

  const deleteModalClose = () => {
    setDeleteModal(false);
    setAnchorEl(null);
  };

  const deleteModalProceed = () => {
    setDeleteModal(false);
    dispatch(onDeleteOrg({org_id}));
  };

  const [editModal, setEditModal] = useState(false);
  const editModalClose = () => {
    dispatch({type: EDIT_SALESFORCE_ORG_RESULT, payload: null});
    setEditModal(false);
    setAnchorEl(null);
  };
  const editModalOpen = () => {
    setEditModal(true);
  };

  //modal for new tags
  // useEffect(() => {
  //   dispatch(getTags());
  // }, [dispatch]);

  const allTags = useSelector(({tags}) => tags.allTagsData);
  const filteredTagIds = filteredTags.map((tag) => tag._id);

  const options = allTags
    .filter((tag) => tag.category === 'workspace' && tag.isDeleted === false)
    .filter((tag) => !filteredTagIds.includes(tag._id))
    .map((tag) => ({
      value: tag.key,
      label: tag.value,
      url: tag.url,
      tagLibrary: true,
      tagId: tag._id,
    }));

  const handleSubmit = async () => {
    const filteredSelectedValues = selectedValues.filter(
      (item) => item.url !== '',
    );

    const tagIds = filteredSelectedValues.map((item) => item.tagId);
    let action = 'assign';
    await dispatch(assignTagsSFDC(tagIds, org_id, action));
    setSelectedValues([]);
    setIsModalOpen(false);
    onCleanFields();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setAnchorEl(null);
  };

  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (newValue) => {
    setSelectedValues(newValue);
  };

  // const [assignPermissions, setAssignPermissions] = useState(false);
  //tags permissions
  const loggedUser = useSelector(({common}) => common.userInfo);
  const allRoles = useSelector(({roles}) => roles.getAllRoles);
  const allPermissions = useSelector(({roles}) => roles.getAllPermissions);

  const currentOrg = loggedUser.usr.salesforceOrgs.find(
    (org) => org.sfdcId === org_id,
  );

  if (!currentOrg) {
    console.error('Org not found');
    return null;
  }

  const roleForCurrentOrg = allRoles?.find(
    (role) => role._id === currentOrg.roleId,
  );

  if (!roleForCurrentOrg) {
    console.error('role not found');
    return null;
  }

  const permissionsForCurrentOrg = (allPermissions || []).filter(
    (permission) => permission._id === roleForCurrentOrg.permissionsId,
  );

  // const findingAssignPermission = permissionsForCurrentOrg
  //   .flatMap((permission) => permission.permissions)
  //   .find((permission) => permission.object === 'assignTags');

  // const isDisabledAssign =
  //   findingAssignPermission &&
  //   (!findingAssignPermission.permissions ||
  //     findingAssignPermission.permissions.length !== 1 ||
  //     !findingAssignPermission.permissions.includes('WRITE'));

  const isDisabledAssign = !permissionsForCurrentOrg
  .flatMap((permission) => permission.permissions)
  .some((permission) => permission.object === 'assignTags');


  const isDisabledCreate = !permissionsForCurrentOrg
    .flatMap((permission) => permission.permissions)
    .some((permission) => permission.object === 'createTags');

  return (
    <>
      <div>
        <Button
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreHorizIcon
            sx={{fontSize: 30, margin: 0, padding: '0 !important'}}
            color='primary'
          />
        </Button>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{horizontal: 'right', vertical: 'top'}}
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
          <MenuItem disabled={!writeable} onClick={editModalOpen}>
            Edit
          </MenuItem>
          <Divider sx={{my: 0.5}} />
          <MenuItem disabled={!writeable} onClick={deleteHandler}>
            Delete
          </MenuItem>
          <Divider sx={{my: 0.5}} />
          <MenuItem
            disabled={!writeable || isDisabledAssign}
            onClick={handleOpenModal}
          >
            Assign Tags
          </MenuItem>
        </Menu>

        <ModalContainer
          handleModalClose={deleteModalClose}
          handleModalProceed={deleteModalProceed}
          proceedButtonText='Yes'
          closeButtonText='No'
          open={deleteModal}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <DeleteForeverSharpIcon color='primary' sx={{fontSize: '80px'}} />
            <h1> Do you want to delete the Org?</h1>
            <p>
              This will remove all scans and findings associated with this org
            </p>
          </div>
        </ModalContainer>

        <EditOrg
          open={editModal}
          handleModalClose={editModalClose}
          org_id={org_id}
          name={name}
          isSfcc={isSfcc}
          webdavurl={webdavurl}
          instanceurl={instanceurl}
        ></EditOrg>
      </div>
      <AddTagsModal
        title='Assign Workspace Tags'
        selectLabel='Tags'
        selectValue={selectedValues}
        onChange={handleChange}
        options={options}
        open={isModalOpen}
        onClose={handleCloseModal}
        handleSubmit={handleSubmit}
        isDisabledAssign={isDisabledAssign}
        isDisabledCreate={isDisabledCreate}
        orgSFDCId={org_id}
        onCleanFields={onCleanFields}
      />
    </>
  );
}

export default DotsDropdownButton;

DotsDropdownButton.propTypes = {
  org_id: PropTypes.string,
  name: PropTypes.string,
  isSfcc: PropTypes.bool,
  writeable: PropTypes.bool,
  webdavurl: PropTypes.string,
  instanceurl: PropTypes.string,
  filteredTags: PropTypes.array,
  onCleanFields: PropTypes.func,
};