import React from 'react';
import './loaderlogging.css';

const AppLoaderLogging = () => {
  return (
    <div className='loader-container'>
      <div className='loader-spin'>
        <span className='crema-dot crema-dot-spin'>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </div>
  );
};
export default AppLoaderLogging;
