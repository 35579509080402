import {SET_TABLE_COLUMNS} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  tableColumns: [],
};

const Table = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TABLE_COLUMNS:
      return {
        ...state,
        tableColumns: action.payload,
      };
    default:
      return state;
  }
};
export default Table;
