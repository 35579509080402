import React, {Fragment, useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import styles from './SecurityGate.module.css';
import AppCard from '@crema/core/AppCard';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {AppInfoView} from '@crema';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import {onGetGlobalConfigs} from 'redux/actions';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {onPutSecurityGate} from 'redux/actions';
import {onPutSecurityGateDisabled} from 'redux/actions';
import {onPutGlobalConfigs} from 'redux/actions';
import {useParams} from 'react-router-dom';

const SecurityGate = ({submitHandler, defaultValue, isGlobal}) => {
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);
  const securityData = useSelector(({dashboard}) => dashboard.securityData);

  const dispatch = useDispatch();
  let {org_id} = useParams();

  useEffect(() => {
    dispatch(onGetGlobalConfigs(true));
  }, []);

  useEffect(() => {

    setSecurityDataLocal({
      critical:
        defaultValue.isEnforced === 'environment'
          ? globalConfigs?.securityGateConfigs?.Critical
          : defaultValue.critical,
      high:
        defaultValue.isEnforced === 'environment'
          ? globalConfigs?.securityGateConfigs?.High
          : defaultValue.high,
      medium: 
        defaultValue.isEnforced === 'environment'
          ? globalConfigs?.securityGateConfigs?.Medium
          : defaultValue.medium,
      low: 
        defaultValue.isEnforced === 'environment'
          ? globalConfigs?.securityGateConfigs?.Low
          : defaultValue.low,
      isEnabled: defaultValue.isEnabled || false,
      isEnforced: defaultValue.isEnforced || 'environment',
    });

  }, [globalConfigs, defaultValue]);

  const [securityDataLocal, setSecurityDataLocal] = useState({
    critical:
      defaultValue.isEnforced === 'environment'
        ? globalConfigs?.securityGateConfigs?.Critical
        : defaultValue.critical,
    high:
      defaultValue.isEnforced === 'environment'
        ? globalConfigs?.securityGateConfigs?.High
        : defaultValue.high,
    medium:
      defaultValue.isEnforced === 'environment'
        ? globalConfigs?.securityGateConfigs?.Medium
        : defaultValue.medium,
    low:
      defaultValue.isEnforced === 'environment'
        ? globalConfigs?.securityGateConfigs?.Low
        : defaultValue.low,
    isEnabled: defaultValue.isEnabled || false,
    isEnforced: defaultValue.isEnforced || 'environment',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const config = {
      critical: securityDataLocal.critical || 0,
      high: securityDataLocal.high || 0,
      medium: securityDataLocal.medium || 0,
      low: securityDataLocal.low || 0,
      isEnabled: true,
      isEnforced: securityDataLocal.isEnforced || 'environment',
    };
      submitHandler({config});
  };

  const handleChange = (event) => {
      setSecurityDataLocal((prevState) => {
        return {...prevState, [event.target.name]: event.target.value};
      });
  };

  const [selectedOption, setSelectedOption] = useState('environment');
  const [selectedOptionDisabled, setSelectedOptionDisabled] = useState(false);

  useEffect(() => {
    if (!isGlobal && globalConfigs?.securityGateConfigs?.isEnforced === 'disabled' && securityDataLocal.isEnforced === 'environment') {
      setSelectedOptionDisabled(true);
    } else {
      setSelectedOptionDisabled(false);
    };
  }, []);

  useEffect(() => {
    if (!isGlobal) {
      setSelectedOption(securityDataLocal?.isEnforced || 'environment');
    } else {
      setSelectedOption(globalConfigs?.securityGateConfigs?.isEnforced || 'environment');
    };
  }, [globalConfigs?.securityGateConfigs?.isEnforced]);


  useEffect(() => {
    if (selectedOptionDisabled) {
        const config = {
            isEnforced: 'environment',
            isEnabled: true,
        };
        dispatch(onPutSecurityGateDisabled({org_id, config}));
    }
}, [globalConfigs?.securityGateConfigs?.isEnforced, selectedOptionDisabled]);


  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);

    if (newValue === 'environment') {
      const config = {
        critical: globalConfigs?.securityGateConfigs?.Critical,
        high: globalConfigs?.securityGateConfigs?.High,
        medium: globalConfigs?.securityGateConfigs?.Medium,
        low: globalConfigs?.securityGateConfigs?.Low,
        isEnforced: newValue,
        isEnabled: true,
      };
      setSecurityDataLocal({
        critical: globalConfigs?.securityGateConfigs?.Critical,
        high: globalConfigs?.securityGateConfigs?.High,
        medium: globalConfigs?.securityGateConfigs?.Medium,
        low: globalConfigs?.securityGateConfigs?.Low,
        isEnforced: newValue,
        isEnabled: true,
      });
      if(!isGlobal){
        dispatch(onPutSecurityGate({org_id, config}));
      }
    }else if(newValue === 'workspace'){
      setSecurityDataLocal({
        critical: securityData?.Critical,
        high: securityData?.High,
        medium: securityData?.Medium,
        low: securityData?.Low,
        isEnforced: newValue,
      });
    }
    else if (newValue === 'disabled') {
      const config = {
        isEnforced: newValue,
        isEnabled: false,
      };

      if (!isGlobal) {
        dispatch(onPutSecurityGate({org_id, config}));
      } else {
        dispatch(onPutGlobalConfigs({config}));
      }
    }
  };

  return (
    <Fragment>
      <>
        <div className={styles.selectContainer}>
          <FormControl fullWidth>
            <InputLabel id='isEnforced-select-label'>
              Enforce Security Gate
            </InputLabel>
            <Select
              labelId='isEnforced-select-label'
              id='isEnforced-select'
              value={selectedOption}
              label='Enforce Security Gate'
              onChange={handleSelectChange}
            >
              <MenuItem
                value='environment'
                disabled={selectedOption === 'environment'}
              >
                Environment
              </MenuItem>
              {!isGlobal && (
                <MenuItem
                  value='workspace'
                  disabled={selectedOption === 'workspace'}
                >
                  Workspace
                </MenuItem>
              )}
              <MenuItem
                value='disabled'
                disabled={selectedOption === 'disabled'}
              >
                Deactivate Security Gate
              </MenuItem>
            </Select>
          </FormControl>
          <div className={styles.subContainer}>
            <p className={styles.rulesetSub}>
              {selectedOption === 'environment' &&
                'Environment Level Security gate will be enforced'}
              {selectedOption === 'workspace' &&
                'Workspace Security Gate will be enforced'}
              {selectedOption === 'disabled' && 'No Security Gate Configured'}
            </p>
          </div>
          {globalConfigs?.securityGateConfigs?.isEnforced === 'disabled' &&
          selectedOption === 'environment' &&
          !isGlobal ? (
            <div className={styles.subContainer}>
              <p className={styles.rulesetSub}>
                Environment Level Security gate deactivated
              </p>
            </div>
          ) : null}
        </div>
      </>
      {
        <AppCard
          sxStyle={{maxWidth: 375}}
          title='Security Gate Details'
          className={
            selectedOption === 'disabled' ||
            (globalConfigs?.securityGateConfigs?.isEnforced === 'disabled' &&
              selectedOption === 'environment' &&
              !isGlobal)
              ? styles.hideContainer
              : styles.showContainer
          }
        >
          <form onSubmit={handleSubmit} className={styles.securityGateFrom}>
            <label className={`${styles} ${styles.required}`}>Critical:</label>
            <TextField
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000000000,
                },
              }}
              id='critical'
              type='number'
              variant='outlined'
              placeholder='Critical'
              onInput={handleChange}
              value={
                securityDataLocal.critical
              }
              name='critical'
              className={styles.textFieldInput}
              disabled={
                !isGlobal && selectedOption === 'environment'
              }
            />
            <label className={`${styles} ${styles.required}`}>High:</label>
            <TextField
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000000000,
                },
              }}
              id='high'
              type='number'
              variant='outlined'
              placeholder='High'
              onInput={handleChange}
              value={
                securityDataLocal.high
              }
              name='high'
              className={styles.textFieldInput}
              disabled={
                !isGlobal && selectedOption === 'environment'
              }
            />
            <label className={`${styles} ${styles.required}`}>Medium:</label>
            <TextField
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000000000,
                },
              }}
              id='medium'
              type='number'
              variant='outlined'
              placeholder='Medium'
              onInput={handleChange}
              value={
                  securityDataLocal.medium
              }
              name='medium'
              className={styles.textFieldInput}
              disabled={
                !isGlobal && selectedOption === 'environment'
              }
            />
            <label className={`${styles} ${styles.required}`}>Low:</label>
            <TextField
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000000000,
                },
              }}
              id='low'
              type='number'
              variant='outlined'
              placeholder='Low'
              onInput={handleChange}
              value={
                  securityDataLocal.low
              }
              name='low'
              className={styles.textFieldInput}
              disabled={
                !isGlobal && selectedOption === 'environment'
              }
            />
            <div className={styles.btnContainer}>
              <Button
                type='submit'
                className={styles.btn}
                variant='contained'
                disabled={
                  !isGlobal && selectedOption === 'environment'
                }
              >
                Save
              </Button>
            </div>
          </form>
        </AppCard>
      }
      <AppInfoView />
    </Fragment>
  );
};

export default SecurityGate;

SecurityGate.propTypes = {
  submitHandler: PropTypes.func,
  defaultValue: PropTypes.object,
  isGlobal: PropTypes.bool,
};
