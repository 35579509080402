import React, {useEffect, useRef} from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import {useDispatch, useSelector} from 'react-redux';
import {
  onGetAllOrgs,
  onGetSingleOrg,
  onRefreshDashboard,
  // onGetReportsData,
  // onGetIssuesData,
} from 'redux/actions';
import {useParams, useSearchParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {
  GET_ISSUES_CUR_PAGE,
  GET_SECURITY_DATA,
  SINGLE_ORG,
  // GET_CUR_REPORT_ID,
  // GET_REPORTS_DATA /*GITHUB_INFO*/,
} from 'shared/constants/ActionTypes';

const SwitchOrg = () => {
  let {org_id} = useParams();
  const [searchParams] = useSearchParams();
  let tab = useRef('dashboard');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAllOrgs = useSelector(({setup}) => setup.getAllOrgs);
  // const reportsData = useSelector(({reports}) => reports.reportsData);

  useEffect(() => {
    dispatch(onGetAllOrgs());
    return () => {};
  }, [dispatch, org_id]);

  const handleChange = (event) => {
    dispatch({type: GET_ISSUES_CUR_PAGE, payload: 1});
    if (searchParams.get('tab')) {
      tab = searchParams.get('tab');
    }
    let org_id = event.target.value;

    dispatch(onRefreshDashboard());

    if (tab === 'securitygate') {
      dispatch({type: GET_SECURITY_DATA, payload: null});
      dispatch(onGetSingleOrg({org_id}));
    }
    if (tab === 'integration') {
      dispatch({type: SINGLE_ORG, payload: null});
      // dispatch({type: GITHUB_INFO, payload: null});
      dispatch(onGetSingleOrg({org_id}));
    }

    // if (tab === 'issues') {
    //   if (org_id) {
    //     dispatch({type: GET_REPORTS_DATA, payload: null});
    //     dispatch(onGetReportsData({org_id}));
    //     if (reportsData) {
    //       dispatch({type: GET_CUR_REPORT_ID, payload: reportsData[0]._id});
    //       dispatch(onGetIssuesData({_id: reportsData[0]._id, orgId: org_id}));
    //     }
    //   }
    // }
    if (tab === 'issues') {
      navigate({
        pathname: `/dashboard/${org_id}`,
        search: `?tab=dashboard`,
      });
    } else {
      navigate({
        pathname: `/dashboard/${org_id}`,
        search: `?tab=${tab}`,
      });
    }
  };

  return (
    <>
      {getAllOrgs && (
        <FormControl sx={{minWidth: 200}} size='small'>
          <InputLabel id='demo-simple-select-label'>Switch Org</InputLabel>
          <Select
            value={`${org_id}`}
            label='Switch Org'
            onChange={handleChange}
            MenuProps={{style: {height: 300}}}
          >
            {getAllOrgs?.map((org) => {
              return (
                <MenuItem key={org._id} value={`${org._id}`}>
                  {org.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

SwitchOrg.propTypes = {};

export default SwitchOrg;
