export const mentionUser = (data) => {
  if (!data) {
    return [];
  }
  let emails = data.map((user) => user.email);
  if (!emails) {
    return [];
  }
  return emails.map((email) => {
    return {
      id: email,
      display: '@' + email,
    };
  });
};
