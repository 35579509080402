import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './SingleScanType.module.css';
import Switch from '@mui/material/Switch';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const label = {inputProps: {'aria-label': 'Switch demo'}};

function SingleScanType({title, name, value, onConfigChange, writeable}) {
  const [checked, setChecked] = useState(value);

  const switchHandler = (event) => {
    setChecked((cur) => !cur);
    onConfigChange(event);
  };

  return (
    <div className={styles.singleScanType}>
      <div className={styles.scanTypeInfo}>
        <div className={styles.settingIcon}>
          <SettingsOutlinedIcon color='primary' />
        </div>
        <div className={styles.scanTypeName}>
          <p>{title}</p>
        </div>
      </div>

      <div>
        <Switch
          {...label}
          name={name}
          onChange={switchHandler}
          checked={checked}
          disabled={!writeable}
        />
      </div>
    </div>
  );
}

export default SingleScanType;

SingleScanType.propTypes = {
  title: PropTypes.string,
  value: PropTypes.bool,
  onConfigChange: PropTypes.func,
  name: PropTypes.string,
  writeable: PropTypes.bool,
};
