import axios from 'axios';

const jwtAxios = axios.create({
  baseURL: `${window.env.REACT_APP_BACKEND_API}`,
  headers: {
    'Content-Type': 'application/json',
    // 'api-version':'v302'
  },
  // timeout: 120000,
});

export const setAuthToken = (token) => {

  if (token) {
    jwtAxios.defaults.headers.common['x-access-token'] = token;
    setLocalStorageToken(token);
  } else {
    delete jwtAxios.defaults.headers.common['x-access-token'];
    removeLocalStorageToken();
  }
};

export const setLocalStorageToken = (token) => {
  return localStorage.setItem('user_jwttoken', token);
};

export const removeLocalStorageToken = () => {
  localStorage.removeItem('user_jwttoken');
};

export const getLocalStorageToken = () => {
  return localStorage.getItem('user_jwttoken');
};

const params = new URLSearchParams(window.location.search);
const token = window.location.search.replace('?token=', '');

if (params.get('token')) {
  setAuthToken(token);
}

export default jwtAxios;
