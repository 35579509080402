import React, {useState} from 'react';
import {useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {TextField, Button} from '@mui/material';
import PropTypes from 'prop-types';
import StyleIcon from '@mui/icons-material/Style';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
// import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useDispatch, useSelector} from 'react-redux';
import {
  createTags,
  updateTags,
  createTagsandAssignmentSFDC,
  createTagsandAssignmentScan,
  createTagsandAssignmentIssue,
} from 'redux/actions/Tags';
// import {checkIfTagDuplicate} from 'redux/actions/Tags';

function ModalTags({
  open,
  handleClose,
  selectedRow,
  editMode,
  deselectRow,
  orgSFDCId,
  scanId,
  orgIdScan,
  issueId,
  issueOrgId,
  _id,
  page,
  pageSize,
  onCleanFields
}) {
  const dispatch = useDispatch();
  //handle tags
  const [tags, setTags] = useState([]);
  const [openSnackbarDuplicates, setOpenSnackbarDuplicates] = useState(false);
  const [snackbarMessageDuplicates, setSnackbarMessageDuplicates] =
    useState('');
  const handleSnackbarCloseDuplicates = () => {
    setOpenSnackbarDuplicates(false);
  };
  const isDuplicate = useSelector(({tags}) => tags.isDuplicate);
  const duplicateTags = useSelector(({tags}) => tags.duplicateTags);

  const [newTagData, setNewTagData] = useState({
    key: '',
    value: '',
    url: '',
    category: 'workspace',
  });

  useEffect(() => {
    if (editMode) {
      handleAddTag();
      setNewTagData({
        key: selectedRow.key,
        value: selectedRow.value,
        url: selectedRow.url,
        category: selectedRow.category,
      });
    }
  }, [editMode, selectedRow]);

  const updateCategoryBasedOnUrl = (url) => {
    if (url.includes('workspace')) {
      return 'workspace';
    } else if (url.includes('dashboard')) {
      return 'scan';
    } else if (url.includes('details')) {
      return 'issue';
    }
    return 'workspace';
  };

  useEffect(() => {
    const url = window.location.href;
    const category = updateCategoryBasedOnUrl(url);
    setNewTagData((prev) => ({
      ...prev,
      category,
    }));
  }, [window.location.href]);

  const handleAddTag = () => {
    const url = window.location.href;
    const category = updateCategoryBasedOnUrl(url);
    if (editMode) {
      setTags((prevTags) => [
        ...prevTags,
        {
          key: selectedRow.key,
          value: selectedRow.value,
          url: selectedRow.url,
          category: selectedRow.category,
        },
      ]);
    } else {
      setTags((prevTags) => [...prevTags, newTagData]);
      setNewTagData({
        key: '',
        value: '',
        url: '',
        category,
      });
    }
  };

  const removeTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const handleTagDataChange = (e, index) => {
    const {name, value, checked} = e.target;
    const updatedTags = [...tags];
    updatedTags[index] = {
      ...updatedTags[index],
      [name]: name === 'addLink' ? checked : value,
    };
    setTags(updatedTags);
  };

  const [openSnackbarCreated, setOpenSnackbarCreated] = useState(false);

  const handleCloseSnackbarCreated = () => {
    setOpenSnackbarCreated(false);
  };

  const [openSnackbarUpdated, setOpenSnackbarUpdated] = useState(false);

  const handleCloseSnackbarUpdated = () => {
    setOpenSnackbarUpdated(false);
  };

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const tagUrl = window.location.href;

    const setDisabledValue = () => {
      if (tagUrl.includes('configure?tab=tags')) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    };

    setDisabledValue();
  }, [dispatch]);

  const handleSaveTags = async () => {
    if (tags.some((tag) => tag.addLink && !tag.url)) {
      setOpenSnackbarDuplicates(true);
      setSnackbarMessageDuplicates(`Please add a URL`);
      return;
    }

    if (tags.length === 0) {
      setOpenSnackbarDuplicates(true);
      setSnackbarMessageDuplicates(`Use the '+ Tags' button to enter tag information`);
      return;
    }

    const hasDuplicates = tags.some((tag, index) => {
      if (!tag.key.trim() || !tag.value.trim()) {
        return false;
      }

      return tags.some((innerTag, innerIndex) => {
        if (!innerTag.key.trim() || !innerTag.value.trim()) {
          return false;
        }

        return (
          index !== innerIndex &&
          tag.key.trim().toLowerCase() === innerTag.key.trim().toLowerCase() &&
          tag.value.trim().toLowerCase() ===
            innerTag.value.trim().toLowerCase() &&
          tag.category.trim().toLowerCase() ===
            innerTag.category.trim().toLowerCase()
        );
      });
    });

    if (hasDuplicates) {
      setOpenSnackbarDuplicates(true);
      setSnackbarMessageDuplicates(`Duplicate values in category`);
      return;
    }

    const noEmptyFields = tags.every((tag) => tag.key && tag.value);

    if (!noEmptyFields) {
      setOpenSnackbarDuplicates(true);
      setSnackbarMessageDuplicates(`Please fill empty values`);
      return;
    }

    let tagsToDispatch = [];
    tags.forEach((tag) => {
      if (tag.category === 'all') {
        const categories = ['workspace', 'scan', 'issue'];
        categories.forEach((category) => {
          tagsToDispatch.push({
            ...tag,
            category,
          });
        });
      } else {
        tagsToDispatch.push(tag);
      }
    });

    // const removeAddLink = (obj) => {
    //   const clone = {...obj};
    //   delete clone.addLink;
    //   return clone;
    // };

    const tagIds = tagsToDispatch;

    // const duplicateCheckResult = await dispatch(checkIfTagDuplicate(tagIds));

    // if (!duplicateCheckResult) {
    const url = window.location.href;

    if (url.includes('configure?tab=tags')) {
      await dispatch(createTags(tagIds));
    } else if (url.includes('/app/salesforce')) {
      await dispatch(createTagsandAssignmentSFDC(tagIds, true, orgSFDCId));
      onCleanFields();
    } else if (url.includes('tab=dashboard')) {
      await dispatch(
        createTagsandAssignmentScan(tagIds, true, scanId, orgIdScan),
      );
    } else if (url.includes('/app/details/')) {
      await dispatch(
        createTagsandAssignmentIssue(
          tagIds,
          _id,
          true,
          issueId,
          issueOrgId,
          page,
          pageSize,
        ),
      );
    }
    handleClose();
    setTags([]);
    setOpenSnackbarCreated(false);
    // }
  };

  const handleUpdateTags = async () => {
    if (tags.some((tag) => tag.addLink && !tag.url)) {
      setOpenSnackbarDuplicates(true);
      setSnackbarMessageDuplicates(`Please add a URL`);
      return;
    }

    if (tags.length === 0) {
      setOpenSnackbarDuplicates(true);
      setSnackbarMessageDuplicates(`Use the '+ Tag' button to enter tag information`);
      return;
    }

    const hasDuplicates = tags.some((tag, index) => {
      if (!tag.key.trim() || !tag.value.trim()) {
        return false;
      }

      return tags.some((innerTag, innerIndex) => {
        if (!innerTag.key.trim() || !innerTag.value.trim()) {
          return false;
        }

        return (
          index !== innerIndex &&
          tag.key.trim().toLowerCase() === innerTag.key.trim().toLowerCase() &&
          tag.value.trim().toLowerCase() ===
            innerTag.value.trim().toLowerCase() &&
          tag.category.trim().toLowerCase() ===
            innerTag.category.trim().toLowerCase()
        );
      });
    });

    if (hasDuplicates) {
      setOpenSnackbarDuplicates(true);
      setSnackbarMessageDuplicates(`Duplicate values in category`);
      return;
    }

    const noEmptyFields = tags.every((tag) => tag.key && tag.value);

    if (!noEmptyFields) {
      setOpenSnackbarDuplicates(true);
      setSnackbarMessageDuplicates(`Please fill empty values`);
      return;
    }

    let tagsToDispatch = [];
    tags.forEach((tag) => {
      if (tag.category === 'all') {
        const categories = ['workspace', 'scan', 'issue'];
        categories.forEach((category) => {
          tagsToDispatch.push({
            ...tag,
            category,
            _id: selectedRow._id,
          });
        });
      } else {
        tagsToDispatch.push({
          ...tag,
          _id: selectedRow._id,
        });
      }
    });

    // const removeAddLink = (obj) => {
    //   const clone = {...obj};
    //   delete clone.addLink;
    //   return clone;
    // };
    // const tagIds = tagsToDispatch.map((tag) => removeAddLink(tag));

    // const duplicateCheckResult = await dispatch(checkIfTagDuplicate(tagIds));

    // if (!duplicateCheckResult) {
    // const key = tagsToDispatch[0]?.key;
    // const value = tagsToDispatch[0]?.value;
    // const url = tagsToDispatch[0]?.url;
    await dispatch(updateTags(tagsToDispatch));
    handleClose();
    deselectRow();
    setTags([]);
    setNewTagData({
      key: '',
      value: '',
      url: '',
      category: 'workspace',
    });
    // setOpenSnackbarUpdated(true);
    // }
  };

  useEffect(() => {
    if (isDuplicate) {
      setOpenSnackbarDuplicates(true);
      const messages = Object.keys(duplicateTags).map((tagKey) => {
        const tag = duplicateTags[tagKey];
        return `${tag.key}: ${tag.value}`;
      });
      const message = `Duplicate Values on Tag Library:\n${messages.join(
        '\n',
      )}`;
      setSnackbarMessageDuplicates(message);
    }
  }, [isDuplicate, duplicateTags]);

  const handleCloseModal = () => {
    handleClose();
    setTags([]);
    deselectRow();
    setNewTagData({
      key: '',
      value: '',
      url: '',
      category: 'workspace',
    });
  };

  const isTagsLibrary = window.location.href.includes('configure?tab=tags');

  const tagLabel = editMode
    ? 'Update Tag'
    : isTagsLibrary
    ? 'Create Tag'
    : 'Assign Tag';

  const tagButtonLabel = editMode
    ? 'Update'
    : isTagsLibrary
    ? 'Submit'
    : 'Assign';

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
            padding: 7,
            width: '50%',
            maxHeight: '500px',
            overflowX: 'auto',
          }}
        >
          <div>
            <div className={'headerTag'}>
              <StyleIcon />
              <Typography variant='h2'>{tagLabel}</Typography>
            </div>
            <div className={'addBtnContainer'}>
              <Button
                className={'closeBtn'}
                variant='contained'
                color='secondary'
                onClick={handleCloseModal}
              >
                Close
              </Button>
              {!editMode && (
                <Button
                  className={'closeBtn'}
                  variant='contained'
                  color='primary'
                  onClick={handleAddTag}
                >
                  + Tag
                </Button>
              )}
            </div>
            &nbsp;
            <TableContainer component={Paper}>
              <Table
                sx={{minWidth: 650}}
                aria-label='simple table'
                stickyHeader={true}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Tag</TableCell>
                    <TableCell align='left'>Value</TableCell>
                    <TableCell align='left'>URL</TableCell>
                    <TableCell align='left'>Category</TableCell>
                    {!editMode && <TableCell>Remove</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tags.map((tag, index) => (
                    <TableRow key={index}>
                      {/* <TableCell>
                        <Checkbox
                          name='addLink'
                          checked={tag.addLink}
                          onChange={(e) => handleTagDataChange(e, index)}
                        />
                      </TableCell> */}
                      <TableCell>
                        <TextField
                          label='Tag'
                          name='key'
                          defaultValue={editMode ? selectedRow.key : tag.key}
                          onChange={(e) => handleTagDataChange(e, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label='Value'
                          name='value'
                          defaultValue={
                            editMode ? selectedRow.value : tag.value
                          }
                          onChange={(e) => handleTagDataChange(e, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label='URL'
                          name='url'
                          defaultValue={editMode ? selectedRow.url : tag.url}
                          onChange={(e) => handleTagDataChange(e, index)}
                          required={tag.addLink}
                        />
                      </TableCell>
                      <TableCell>
                        <Select
                          disabled={isDisabled || editMode}
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          defaultValue={
                            editMode
                              ? selectedRow.category
                              : tags[index]?.category || ''
                          }
                          name='category'
                          label='Category'
                          onChange={(e) => handleTagDataChange(e, index)}
                          inputProps={
                            isDisabled || editMode ? {IconComponent: () => null} : {}
                          }
                        >
                          <MenuItem value='workspace'>Workspace</MenuItem>
                          <MenuItem value='scan'>Scans</MenuItem>
                          <MenuItem value='issue'>Issues</MenuItem>
                          <MenuItem value='all'>All Categories</MenuItem>
                        </Select>
                      </TableCell>
                      {!editMode && (
                        <TableCell>
                          <Button
                            className={'removeTag'}
                            onClick={() => removeTag(index)}
                            size='small'
                            variant='contained'
                            color='secondary'
                          >
                            Remove
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={'submitBtnContainer'}>
              <Button
                variant='contained'
                color='primary'
                onClick={editMode ? handleUpdateTags : handleSaveTags}
              >
                {tagButtonLabel}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      {/* Error snackbar for duplicate values */}
      <Snackbar
        sx={{alignItems: 'center'}}
        open={openSnackbarDuplicates}
        autoHideDuration={3000}
        onClose={handleSnackbarCloseDuplicates}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          sx={{alignItems: 'center'}}
          elevation={6}
          variant='filled'
          severity='error'
          onClose={handleSnackbarCloseDuplicates}
        >
          {snackbarMessageDuplicates}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={openSnackbarCreated}
        autoHideDuration={3000}
        onClose={handleCloseSnackbarCreated}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleCloseSnackbarCreated}
          severity='success'
          sx={{alignItems: 'center', color: 'white'}}
        >
          Tag Successfully Created
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={openSnackbarUpdated}
        autoHideDuration={3000}
        onClose={handleCloseSnackbarUpdated}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleCloseSnackbarUpdated}
          severity='success'
          sx={{alignItems: 'center', color: 'white'}}
        >
          Tag Successfully Updated
        </MuiAlert>
      </Snackbar>
      {/* {isDuplicate && (
        <Snackbar
          open={isDuplicate}
          autoHideDuration={3000}
          onClose={handleCloseSnackbarCreated}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MuiAlert
            elevation={6}
            variant='filled'
            onClose={handleCloseSnackbarCreated}
            severity='error'
            sx={{alignItems: 'center', color: 'white'}}
          >
            Tag Duplicate on Library
          </MuiAlert>
        </Snackbar>
      )} */}
    </>
  );
}

export default ModalTags;

ModalTags.propTypes = {
  open: PropTypes.bool,
  editMode: PropTypes.bool,
  selectedRow: PropTypes.array,
  handleClose: PropTypes.func,
  deselectRow: PropTypes.func,
  orgSFDCId: PropTypes.string,
  scanId: PropTypes.string,
  orgIdScan: PropTypes.string,
  issueId: PropTypes.string,
  issueOrgId: PropTypes.string,
  _id: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCleanFields: PropTypes.func,
};
