import React from 'react';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import PropTypes from 'prop-types';
import styles from './CopadoIntegration.module.css';

const CopadoIntegration = ({copadoModalClose}) => {
  return (
    <ModalContainer open={true} handleModalClose={copadoModalClose}>
      <div className={styles.mainContainer}>
        <h2>Please select the appropriate option for your Workspace </h2>

        <div className={styles.installCopado}>
          <h4 className={styles.installCopadoTitle}>Production Install</h4>
          <a
            href='https://login.salesforce.com/packaging/installPackage.apexp?p0=04t8c000000lE6x'
            target='_blank'
            rel='noreferrer'
            className={styles.copadoLink}
          >
            Production Install
          </a>
        </div>
        <div className={styles.installCopado}>
          <h4 className={styles.installCopadoTitle}>Sandbox Install</h4>
          <a
            href='https://login.salesforce.com/packaging/installPackage.apexp?p0=04t8c000000lE6x'
            target='_blank'
            rel='noreferrer'
            className={styles.copadoLink}
          >
            Sandbox Install
          </a>
        </div>
      </div>
    </ModalContainer>
  );
};

export default CopadoIntegration;
CopadoIntegration.propTypes = {
  copadoModalClose: PropTypes.func,
};
