export const getRandomKey = () => {
  return (Math.random() + 10).toString(36).substring(10);
};

export const getHumanReadableDate = (str) => {
  const readableDate = new Date(str);
  return readableDate.toString();
};

export const getDateTimeOnly = (str) => {
  if (!str) return null;
  const readableDate = new Date(str);
  return `${readableDate.getDate()}-${
    readableDate.getMonth() + 1
  }-${readableDate.getFullYear()} ${readableDate.toLocaleTimeString()}`;
};

export const trimEmail = (str) => {
  var index = str.indexOf('@');
  return str.substring(0, index);
};

export const extractDate = (str) => {
  if (!str) return null;
  const readableDate = new Date(str);
  return `${readableDate.getDate()}/${
    readableDate.getMonth() + 1
  }/${readableDate.getFullYear()}`;
};

export const differenceInDays = (initialDate) => {
  const initDate = new Date(initialDate);
  const todayDate = new Date();
  const diffTime = Math.abs(todayDate - initDate);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const capitalizeFirstLetter = (str) => {
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized;
};

export const checkIfUrl = (string) => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(string);
};

export const filterOrgLevelPermission = ({userInfo, org_id}) => {
  if (userInfo == null || org_id == null) {
    return [];
  }
  let permissions = [];
  userInfo?.rolesAndPermissionsObject?.orgLevel?.map((level) => {
    level.orgs.find((org) => {
      if (org.sfdcId === org_id) {
        permissions = level.permissions;
      }
    });
  });
  return permissions;
};

export const getRandomColor = () => {
  const hexArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F'];
  let code = '';
  for (let i = 0; i < 6; i++) {
    code += hexArray[Math.floor(Math.random() * 16)];
  }
  return `#${code}`;
};
