import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './ScanStatistics.module.css';
import ComponentScanned from './ComponentScanned';
import {AppCard} from '@crema';
import ActiveScanRule from './ActiveScanRule';
import {useSelector} from 'react-redux';
import {
  differenceInMinutes,
  differenceInSeconds
} from 'date-fns';

const AccordionScan = () => {
  let options1 = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  let options = { timeStyle: 'long', hour12: true };
  const scanById = useSelector(({scanStatistics}) => scanStatistics.scanById);
  const showDayTimeDiff = (startTime, endTime) => {
    let diffMin = differenceInMinutes(new Date(endTime), new Date(startTime));
    let diffSec = differenceInSeconds(new Date(endTime), new Date(startTime));

      if (diffMin >= 1) {
      return diffMin + ' Min(s)';
      } else if (diffMin < 1) {
        return diffSec + ' Sec(s)';
      }
  };

  function getSource(source){

      if(source){
      if (source === 'gitHub') {
        return 'Github';
      } else if (source === 'gitLab') {
        return 'Gitlab';
      } else if (source === 'bitbucket') {
        return 'Bitbucket';
      } else if (source === 'azure') {
        return 'Azure DevOps';
      } else if (source === 'digitsecWeb') {
        return 'Digitsec Web';
      } else if (source === 'vscode') {
        return 'DigitSec VSCode Extension';
      } else if (source === 'sfdxplugin') {
        return 'DigitSec SFDX Plugin';
      } else if (source === 'cli') {
        return 'DigitSec CLI';
      } else if (source === 'copadoEssentials') {
        return 'Copado Essentials';
      } else if (source === 'copadoFlow') {
        return 'Copado Flow';
      } else if (source === 'copado|gitHub') {
        return 'Copado Branch Scan (Github)';
      } else if (source === 'copado|gitLab') {
        return 'Copado Branch Scan (Gitlab)';
      } else if (source === 'copado|bitbucket') {
        return 'Copado Branch Scan (Bitbucket)';
      } else if (source === 'copado|azure') {
        return 'Copado Branch Scan (Azure DevOps)';
      } else if (source === 'scheduledscan') {
        return 'DigitSec scheduled scan';
      }
    }
    }
  
  return (
    <AppCard title='Scan Details'>
      <div className={styles.accordionContainer}>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography>Workspace & Scan Stats</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul className={styles.scanStatsList}>
                <li>Status:&nbsp;{scanById?.status.charAt(0).toUpperCase() + scanById?.status.slice(1)}</li>
                <li>Type:&nbsp;{scanById?.scan_type.trim().replaceAll(' ', ', ') || ''}</li>
                {/* <li>
                  API:&nbsp;
                  {scanById?.static_libraries?.apiVersion || 'Not Defined'}
                </li> */}
                <li>Source:&nbsp;{scanById?.source?getSource(scanById?.source):''}</li>
                <li>Initiated by:&nbsp;{scanById?.initiated_by?scanById?.initiated_by:'N/A'}</li>
                <li>
                  Scan Started:&nbsp;
                  {new Date(scanById?.Initiated_Scan_date).toLocaleString('en-us',
                    options1,
                  ) +
                  ' ' +
                  new Date(scanById?.Initiated_Scan_date).toLocaleString(
                    'en-us',
                    options,
                  )}
                </li>
                <li>
                  Scan Finished:&nbsp;
                  {new Date(scanById?.Completed_date).toLocaleString('en-us',
                    options1,
                  ) +
                  ' ' +
                  new Date(scanById?.Completed_date).toLocaleString(
                    'en-us',
                    options,
                  )}
                  </li>
                  <li>
                  Scan Duration:&nbsp;
                  {showDayTimeDiff(scanById?.Initiated_Scan_date, scanById?.Completed_date)}
                </li>
                <li>
                  Org Size:&nbsp;
                  {scanById?.size?parseFloat(scanById?.size / (1024 * 1024)).toFixed(2) + ' MB':''}
                </li>
                <li>
                  Track issues per Workspace Config:&nbsp;
                  {scanById?.configs?.track_finding_per_org? 'Enabled':'Disabled'}
                </li>
                <li>
                  Show new issues Config:&nbsp;
                  {scanById?.configs?.show_new_findings? 'Enabled':'Disabled'}
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography>Components Scanned</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {scanById?.static_libraries?.unpackaged?.types?.map(
              (library, i) => {
                return library ? (
                  <ComponentScanned key={i} name={library?.name || ''} />
                ) : null;
              },
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography>Active Scan Rules</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {scanById?.scanrules?.map((scanrule) => {
              return (
                <ActiveScanRule
                  key={scanrule?.id}
                  title={scanrule?.title || ''}
                  vulnid={scanrule?.vulnid || ''}
                  active={scanrule?.active}
                />
              );
            })}
          </AccordionDetails>
        </Accordion>
      </div>
    </AppCard>
  );
};

export default AccordionScan;
