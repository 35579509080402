import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Fragment} from 'react';
import {EDIT_SALESFORCE_ORG_RESULT} from 'shared/constants/ActionTypes';
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';
import {Button} from '@mui/material';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import PropTypes from 'prop-types';

function UpdateAgain({close, handleModalClose}) {
  const dispatch = useDispatch();

  const resetResult = () => {
    dispatch({type: EDIT_SALESFORCE_ORG_RESULT, payload: null});
  };
  const addingOrgInProcess = useSelector(
    ({salesforce}) => salesforce.addingOrgInProcess,
  );
  const editSalesforceOrgResult = useSelector(
    ({salesforce}) => salesforce.editSalesforceOrgResult,
  );

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {editSalesforceOrgResult == 'success' && addingOrgInProcess == false && (
          <>
            <DoneAllSharpIcon color='primary' sx={{fontSize: 40}} />
            <h2>Update Successfully!</h2>
            {close && (
              <Button
                variant='contained'
                onClick={handleModalClose}
                color={
                  editSalesforceOrgResult == 'success' ? 'primary' : 'secondary'
                }
              >
                Close
              </Button>
            )}
          </>
        )}

        {editSalesforceOrgResult == 'fail' && addingOrgInProcess == false && (
          <>
            <ErrorSharpIcon color='secondary' sx={{fontSize: 40}} />
            <h2>Update Failed</h2>
            <Button onClick={resetResult} variant='contained' color='secondary'>
              Try Again!
            </Button>
          </>
        )}
      </div>
    </Fragment>
  );
}

export default UpdateAgain;

UpdateAgain.propTypes = {
  close: PropTypes.string,
  handleModalClose: PropTypes.func,
};
