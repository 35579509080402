import React from 'react';

import Report from './Report';

export const reportPagesConfigs = [
  {
    path: '/report/:_id/:orgId',
    element: <Report />,
  },
  {
    path: '/report/:_id/:orgId/:violations/:isSummaryReport',
    element: <Report />,
  },
];
