import React from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

const ErrorRepresentation = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={6}>
        <ListItem sx={{justifyContent: {sm: 'center'}}}>
          <ListItemAvatar>
            <Avatar sx={{bgcolor: 'rgb(255, 0, 0)'}}>&nbsp;</Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{flex: '0 1 auto', minWidth: 100}}
            primary='Critical'
          />
        </ListItem>
      </Grid>
      <Grid item xs={6} sm={6}>
        <ListItem sx={{justifyContent: {sm: 'center'}}}>
          <ListItemAvatar>
            <Avatar sx={{bgcolor: 'rgb(171, 9, 6)'}}>&nbsp;</Avatar>
          </ListItemAvatar>
          <ListItemText sx={{flex: '0 1 auto', minWidth: 100}} primary='High' />
        </ListItem>
      </Grid>
      <Grid item xs={6} sm={6}>
        <ListItem sx={{justifyContent: {sm: 'center'}}}>
          <ListItemAvatar>
            <Avatar sx={{bgcolor: 'rgb(245, 162, 0)'}}>&nbsp;</Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{flex: '0 1 auto', minWidth: 100}}
            primary='Medium'
          />
        </ListItem>
      </Grid>
      <Grid item xs={6} sm={6}>
        <ListItem sx={{justifyContent: {sm: 'center'}}}>
          <ListItemAvatar>
            <Avatar sx={{bgcolor: 'rgb(15, 154, 238)'}}>&nbsp;</Avatar>
          </ListItemAvatar>
          <ListItemText sx={{flex: '0 1 auto', minWidth: 100}} primary='Low' />
        </ListItem>
      </Grid>
    </Grid>
  );
};

ErrorRepresentation.propTypes = {};

export default ErrorRepresentation;
