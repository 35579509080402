import jwtAxios from '@crema/services/auth/jwt-auth';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  IS_API_IN_PROCESS,
  // DETAILS_RESPONSE,
  SHOW_MESSAGE,
  GET_SCHEDULER_DATA,
  GET_ORG_SCHEDULER_DATA,
  POST_SCHEDULER_DATA,
  UPDATE_SCHEDULER_DATA,
  POST_CHECK_SCHEDULER_DATA,
  GET_SCHEDULER_ORG_DATA,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';

export const getAllScheduled = (customerId) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    jwtAxios
      .get(`/scheduler/getAllSchedules/${customerId}`)
      .then((response) => {
        dispatch({
          type: GET_SCHEDULER_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const getOrgSchedules = (orgId) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    jwtAxios
      .get(`/orgscheduler/getOrgSchedules?orgId=${orgId}`)
      .then((response) => {
        dispatch({
          type: GET_ORG_SCHEDULER_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

// get all ORGS for Scheduler

// export const getAllOrgsScheduler = () => {
//   return (dispatch) => {
//     dispatch({type: IS_API_IN_PROCESS, payload: true});
//     jwtAxios
//       .get(`/scheduler/listAllOrgs`)
//       .then((response) => {
//         dispatch({
//           type: GET_SCHEDULER_ORG_DATA,
//           payload: response.data,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: FETCH_ERROR,
//           payload: error.response?.data?.message || error.message,
//         });
//       });
//   };
// };

export const getAllOrgsScheduler = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`/scheduler/listAllOrgs`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          data.data?.sfdcinfo
            ? dispatch({
                type: GET_SCHEDULER_ORG_DATA,
                payload: data.data.sfdcinfo,
              })
            : dispatch({type: GET_SCHEDULER_ORG_DATA, payload: null});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: GET_SCHEDULER_ORG_DATA, payload: null});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: GET_SCHEDULER_ORG_DATA, payload: null});
      });
  };
};

// export const getScheduler = () => {
//   return (dispatch) => {
//     dispatch({type: IS_API_IN_PROCESS, payload: true});
//     jwtAxios
//       //   .get('/scheduler/' + schedulerId )
//       .then((response) => {
//         dispatch({
//           type: GET_SCHEDULER_DATA,
//           payload: response.data,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: FETCH_ERROR,
//           payload: error.response?.data?.message || error.message,
//         });
//       });
//   };
// };

export const createScheduler = (schedulData, isOrgSchedule = false) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    const postURL = isOrgSchedule
      ? '/orgscheduler/createSchedule'
      : '/scheduler/createSchedule';
    jwtAxios
      .post(postURL, schedulData)
      .then((response) => {
        const newData = response.data;
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({type: POST_SCHEDULER_DATA, payload: newData});
      })
      .catch(() => {
        // dispatch({
        //   type: FETCH_ERROR,
        //   payload: error.response?.data?.message || error.message,
        // });
      });
  };
};

// export const checkIfOrgInSchedule = (orgIdsToSend, startDateTime) => {
//   return async (dispatch) => {
//     dispatch({type: IS_API_IN_PROCESS, payload: true});
//     let object = {};
//     object.orgIds = orgIdsToSend;
//     object.startDateTime = startDateTime;

//     try {
//       const response = await jwtAxios.post(
//         '/scheduler/checkIfOrgInSchedule',
//         object,
//       );
//       const newData = response.data;

//       dispatch({type: IS_API_IN_PROCESS, payload: false});
//       dispatch({
//         type: SHOW_MESSAGE,
//         payload: 'Org id is already scheduled',
//       });
//       dispatch({type: POST_CHECK_SCHEDULER_DATA, payload: newData});

//       return newData;
//     } catch (error) {
//       dispatch({
//         type: FETCH_ERROR,
//         payload: error.response?.data?.message || error.message,
//       });
//       throw error;
//     }
//   };
// };

// export const updateScheduler = (scheduleId, updatedData) => {
//   return (dispatch) => {
//     dispatch({type: IS_API_IN_PROCESS, payload: true});
//     jwtAxios
//       .put(`/scheduler/updateSchedule/${scheduleId}`, updatedData)
//       .then((response) => {
//         dispatch({type: IS_API_IN_PROCESS, payload: false});
//         dispatch({
//           type: SHOW_MESSAGE,
//           payload: 'Scan Scheduler Successfully Updated',
//         });
//         dispatch({type: UPDATE_SCHEDULER_DATA, payload: response.data});
//       })
//       .catch((error) => {
//         dispatch({
//           type: FETCH_ERROR,
//           payload: error.response?.data?.message || error.message,
//         });
//       });
//   };
// };

export const checkIfOrgInSchedule = (
  {orgIdsToSend,
  startDateTime,
  isOrgSchedule = false,
  orgId}
) => {
  return async (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    let object = {};
    object.orgIds = orgIdsToSend;
    object.startDateTime = startDateTime;
    object.orgId = orgId;

    try {
      let url = isOrgSchedule
        ? `/orgscheduler/checkIfOrgInSchedule`
        : '/scheduler/checkIfOrgInSchedule';

      const response = await jwtAxios.post(url, object);

      const newData = response.data;

      dispatch({type: IS_API_IN_PROCESS, payload: false});
      // dispatch({
      //   type: SHOW_MESSAGE,
      //   payload: 'Org id is already scheduled',
      // });
      dispatch({type: POST_CHECK_SCHEDULER_DATA, payload: newData});
      return newData;
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: error.response?.data?.message || error.message,
      });
      throw error;
    }
  };
};

export const updateScheduler = (
  scheduleId,
  updatedData,
  isOrgSchedule = false,
) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    const putURL = isOrgSchedule
      ? `/orgscheduler/updateSchedule/${scheduleId}`
      : `/scheduler/updateSchedule/${scheduleId}`;
    jwtAxios
      .put(putURL, updatedData)
      .then((response) => {
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({type: UPDATE_SCHEDULER_DATA, payload: response.data});
      })
      .catch(() => {
        // dispatch({
        //   type: FETCH_ERROR,
        //   payload: error.response?.data?.message || error.message,
        // });
      });
  };
};

// export const deleteScheduler = (scheduleId) => {
//   return (dispatch) => {
//     dispatch({type: IS_API_IN_PROCESS, payload: true});
//     jwtAxios
//       .delete(`/scheduler/deleteSchedule/${scheduleId}`)
//       .then((data) => {
//         if (data.status == 200) {
//           dispatch({type: FETCH_SUCCESS});
//           dispatch({type: IS_API_IN_PROCESS, payload: false});
//           dispatch({
//             type: SHOW_MESSAGE,
//             payload: 'Scan Scheduler Successfully Deleted',
//           });
//         }
//       })
//       .catch((error) => {
//         dispatch({
//           type: FETCH_ERROR,
//           payload: error.response?.data?.message || error.message,
//         });
//       });
//   };
// };

export const deleteScheduler = (scheduleId, isOrgSchedule = false, orgId) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});

    const deleteURL = isOrgSchedule
      ? `/orgscheduler/deleteSchedule/${scheduleId}?orgId=${orgId}`
      : `/scheduler/deleteSchedule/${scheduleId}`;

    jwtAxios
      .delete(deleteURL)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

// export const deleteScheduleFromQueue = (scheduleId) => {
//   return (dispatch) => {
//     dispatch({type: IS_API_IN_PROCESS, payload: true});
//     jwtAxios
//       .delete(`/scheduler/deleteScheduleFromQueue/${scheduleId}`)
//       .then((data) => {
//         if (data.status == 200) {
//           dispatch({type: FETCH_SUCCESS});
//           dispatch({type: IS_API_IN_PROCESS, payload: false});
//           dispatch({
//             type: SHOW_MESSAGE,
//             payload: 'Scan Scheduler Successfully Deleted from Queue',
//           });
//         }
//       })
//       .catch((error) => {
//         dispatch({
//           type: FETCH_ERROR,
//           payload: error.response?.data?.message || error.message,
//         });
//       });
//   };
// };

// export const deleteScheduleFromQueue = (
//   scheduleId,
//   isOrgSchedule = false,
//   orgId,
// ) => {
//   return (dispatch) => {
//     dispatch({type: IS_API_IN_PROCESS, payload: true});

//     const deleteURL = isOrgSchedule
//       ? `/orgscheduler/deleteScheduleFromQueue/${scheduleId}?orgId=${orgId}`
//       : `/scheduler/deleteScheduleFromQueue/${scheduleId}`;

//     jwtAxios
//       .delete(deleteURL)
//       .then((data) => {
//         if (data.status === 200) {
//           dispatch({type: FETCH_SUCCESS});
//           dispatch({type: IS_API_IN_PROCESS, payload: false});
//           dispatch({
//             type: SHOW_MESSAGE,
//             payload: 'Scan Scheduler Successfully Deleted from Queue',
//           });
//         }
//       })
//       .catch((error) => {
//         dispatch({
//           type: FETCH_ERROR,
//           payload: error.response?.data?.message || error.message,
//         });
//       });
//   };
// };

// export const onPutTagScheduler = ({_id, tagsPayload, org_id, org_name}) => {
//   return (dispatch) => {
//     tagsPayload.org_id = org_id;
//     tagsPayload.org_name = org_name;

//     dispatch({type: IS_API_IN_PROCESS, payload: true});
//     dispatch({type: FETCH_START});
//     jwtAxios
//       .put(`/schedule/scheduler/tags/${_id}`, tagsPayload)
//       .then((data) => {
//         if (data.status === 200) {
//           dispatch({type: FETCH_SUCCESS});
//           dispatch({type: IS_API_IN_PROCESS, payload: false});
//           dispatch({
//             type: DETAILS_RESPONSE,
//             payload: {status: 'success', message: 'Tags Added Successfully!'},
//           });
//         } else {
//           dispatch({
//             type: FETCH_ERROR,
//             payload: <IntlMessages id='message.somethingWentWrong' />,
//           });
//           dispatch({type: IS_API_IN_PROCESS, payload: false});
//           dispatch({
//             type: DETAILS_RESPONSE,
//             payload: {status: 'fail', message: 'Failed! Please try again!'},
//           });
//         }
//       })
//       .catch((error) => {
//         dispatch({
//           type: FETCH_ERROR,
//           payload: error.response?.data?.message || error.message,
//         });
//         dispatch({type: IS_API_IN_PROCESS, payload: false});
//         dispatch({
//           type: DETAILS_RESPONSE,
//           payload: {status: 'fail', message: 'Failed! Please try again!'},
//         });
//       });
//   };
// };

export const deleteScheduleFromQueue = (
  scheduleId,
  isOrgSchedule = false,
  orgId,
) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});

    const deleteURL = isOrgSchedule
      ? `/orgscheduler/deleteScheduleFromQueue/${scheduleId}?orgId=${orgId}`
      : `/scheduler/deleteScheduleFromQueue/${scheduleId}`;

    jwtAxios
      .delete(deleteURL)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Scan Scheduler Successfully Deleted from Queue',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};
