import React, {useState} from 'react';
// import {useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import styles from './CommentSection.module.css';
import {onPutCommentDetail} from 'redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import AddTags from './Add Tags/AddTags';
import {useParams} from 'react-router-dom';
import MentionInput from 'components/mentioninput/MentionInput';
import {mentionUser} from 'transformers/Users';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Divider from '@mui/material/Divider';
// import AddTagsModal from 'components/configure/Tags/AddTagsModal';
// // import {getTags} from 'redux/actions/Tags';
// import {assignTagsIssue} from 'redux/actions/Tags';
// import {getIssuesCleanRequest} from 'redux/actions';

function CommentSection({_id, detailsTabChange}) {
  const currOrgName = useSelector(({dashboard}) => dashboard.curOrgName);
  let {report_id, issue_id, org_id} = useParams();

  const issuesCurPage = useSelector(({dashboard}) => dashboard.issuesCurPage);
  const loggedUser = useSelector(({common}) => common.userInfo);
  const allPermissions = useSelector(({roles}) => roles.getAllPermissions);
  const allRoles = useSelector(({roles}) => roles.getAllRoles);
  const users = useSelector(({common}) => common.users);
  const dispatch = useDispatch();

  const pageSize = useSelector(({dashboard}) => dashboard.pageSize);
  const curIssuesFilterState = useSelector(
    ({dashboard}) => dashboard.curIssuesFilterState,
  );

  const [comment, setComment] = useState('');

  const [openSnackbarCommentS, setOpenSnackbarCommentS] = useState(false);
  const [snackbarMessageCommentS, setSnackbarMessageCommentS] = useState('');

  const handleSnackbarCloseCommentS = () => {
    setOpenSnackbarCommentS(false);
  };

  function onSubmitComment(event) {
    event.preventDefault();

    let obj = {
      comment: comment,
      _scanID: report_id,
    };

    if (!comment.trim()) {
      setOpenSnackbarCommentS(true);
      setSnackbarMessageCommentS('Comment field is required');
      return;
    }

    dispatch(
      onPutCommentDetail({
        _id,
        obj,
        issue_id,
        issuesCurPage,
        report_id,
        org_id,
        org_name: currOrgName,
        pageSize,
        curIssuesFilterState,
      }),
    );

    detailsTabChange(event, 0);
  }

  // Tags Modal
  const [tagsModal, setTagsModal] = useState(false);
  const tagsModalClose = () => {
    setTagsModal(false);
  };
  // const tagsModalOpen = () => {
  //   setTagsModal(true);
  // };

  const mentionHandler = (event, newValue, newPlainTextValue) => {
    setComment(newPlainTextValue);
  };

  //Filtered orgs

  // const filteredRoles = allRoles.map((role) => ({
  //   roleId: role._id,
  //   permissionsId: role.permissionsId,
  // }));

  // const filteredPermissions = (allPermissions || []).map((permissions) => ({
  //   permissionsId: permissions._id,
  //   permissions: permissions.permissions,
  // }));

  // const filteredOrgs = loggedUser.usr.salesforceOrgs.map((org) => ({
  //   roleId: org.roleId,
  //   sfdc: org.sfdc,
  //   _id: org._id,
  //   sfdcId: org.sfdcId,
  // }));

  const currentOrg = loggedUser.usr.salesforceOrgs.find(
    (org) => org.sfdcId === org_id,
  );

  if (!currentOrg) {
    console.error('Org not found');
    return null;
  }

  const roleForCurrentOrg = allRoles.find(
    (role) => role._id === currentOrg.roleId,
  );

  if (!roleForCurrentOrg) {
    console.error('role not found');
    return null;
  }

  const permissionsForCurrentOrg = (allPermissions || []).filter(
    (permission) => permission._id === roleForCurrentOrg.permissionsId,
  );

  // const findingTagPermission = permissionsForCurrentOrg
  //   .flatMap((permission) => permission.permissions)
  //   .find((permission) => permission.object === 'findingTag');

  const findingCommentPermission = permissionsForCurrentOrg
    .flatMap((permission) => permission.permissions)
    .find((permission) => permission.object === 'findingComment');

  // const isDisabledTag =
  //   findingTagPermission &&
  //   findingTagPermission.permissions.length === 1 &&
  //   findingTagPermission.permissions.includes('READ');

  const isDisabledComment =
    findingCommentPermission &&
    findingCommentPermission.permissions.length === 1 &&
    findingCommentPermission.permissions.includes('READ');

  // const findingAssignPermission = permissionsForCurrentOrg
  //   .flatMap((permission) => permission.permissions)
  //   .find((permission) => permission.object === 'assignTags');

  // const findingCreatePermission = permissionsForCurrentOrg
  //   .flatMap((permission) => permission.permissions)
  //   .find((permission) => permission.object === 'createTags');

  // const isDisabledAssign =
  //   findingAssignPermission &&
  //   (!findingAssignPermission.permissions ||
  //     findingAssignPermission.permissions.length !== 1 ||
  //     !findingAssignPermission.permissions.includes('WRITE'));

  // const isDisabledCreate =
  //   findingCreatePermission &&
  //   (!findingCreatePermission.permissions ||
  //     findingCreatePermission.permissions.length !== 1 ||
  //     !findingCreatePermission.permissions.includes('WRITE'));

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  //modal for new tags
  // useEffect(() => {
  //   dispatch(getTags());
  // }, [dispatch]);

  // const allTags = useSelector(({tags}) => tags.allTagsData);

  // const options = allTags
  //   .filter(
  //     (tag) =>
  //       tag.category === 'issue' &&
  //       !tagsIds.includes(tag._id) &&
  //       tag.isDeleted === false,
  //   )
  //   .map((tag) => ({
  //     value: tag.key,
  //     label: tag.value,
  //     url: tag.url,
  //     tagLibrary: true,
  //     tagId: tag._id,
  //   }));

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };
  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  // const [selectedValues, setSelectedValues] = useState([]);

  // const handleChange = (newValue) => {
  //   setSelectedValues(newValue);
  // };

  // const handleSubmit = async () => {
  //   const filteredSelectedValues = selectedValues.filter(
  //     (item) => item.href !== '',
  //   );
  //   const tagIds = filteredSelectedValues.map((item) => item.tagId);
  //   let action = 'assign';
  //   await dispatch(
  //     assignTagsIssue(tagIds, report_id, issue_id, org_id, action),
  //   );
  //   dispatch(
  //     getIssuesCleanRequest({
  //       _id: report_id,
  //       page: issuesCurPage,
  //       orgId: org_id,
  //       pageSize: pageSize,
  //     }),
  //   );

  //   setIsModalOpen(false);
  // };

  return (
    <>
      <div style={{padding: 14}} className='App'>
        <form onSubmit={onSubmitComment}>
          <MentionInput
            textHandler={mentionHandler}
            data={mentionUser(users)}
            inputText={comment}
            placeholder='Add a Comment'
            rows='8'
          />
          <div className={styles.commentTagContainerBtn}>
            <Button
              variant='contained'
              type='submit'
              disabled={isDisabledComment}
            >
              Post Comment
            </Button>
            {/* <Button
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleOpenModal}
              disabled={isDisabledAssign}
              variant='contained'
            >
              Assign Tags
            </Button> */}
            {/* <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{width: '200'}}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
              <MenuItem
                disabled={isDisabledTag}
                variant='contained'
                onClick={tagsModalOpen}
              >
                Add Tags
              </MenuItem>
              <Divider sx={{my: 0.5}} />
              <MenuItem onClick={handleOpenModal} disabled={isDisabledAssign}>Assign Tags</MenuItem>
            </Menu> */}
          </div>
        </form>
      </div>
      <AddTags
        _id={_id}
        tagsModal={tagsModal}
        tagsModalClose={tagsModalClose}
        detailsTabChange={detailsTabChange}
      />
      {/* <AddTagsModal
        title='Assign Issue Tags'
        selectLabel='Tags'
        selectValue={selectedValues}
        onChange={handleChange}
        options={options}
        open={isModalOpen}
        onClose={handleCloseModal}
        handleSubmit={handleSubmit}
        isDisabledAssign={isDisabledAssign}
        isDisabledCreate={isDisabledCreate}
        issueId={issue_id}
        issueOrgId={org_id}
        _id={report_id}
        page={issuesCurPage}
        pageSize={pageSize}
      /> */}
      <Snackbar
        open={openSnackbarCommentS}
        autoHideDuration={3000}
        onClose={handleSnackbarCloseCommentS}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          severity='error'
          onClose={handleSnackbarCloseCommentS}
        >
          {snackbarMessageCommentS}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default CommentSection;
CommentSection.propTypes = {
  comments: PropTypes.array,
  // tagsIds: PropTypes.array,
  _id: PropTypes.string,
  detailsTabChange: PropTypes.func,
};
