import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';

const SalesforceCategoryDropdown = ({categoryHandler}) => {
  const onChangeDropDown = (event, value) => {
    value
      ? categoryHandler(value.value == 'sfcc' ? true : false)
      : categoryHandler('');
  };

  return (
    <Autocomplete
      disablePortal
      id='Auto-Compelte-Category'
      options={SalesforceProducts}
      sx={{minWidth: '300px'}}
      onChange={onChangeDropDown}
      renderInput={(params) => (
        <TextField {...params} variant='outlined' label='Type' />
      )}
    />
  );
};

export default SalesforceCategoryDropdown;

const SalesforceProducts = [
  {label: 'Salesforce', value: 'sfdc'},
  {label: 'Commerce Cloud (B2C)', value: 'sfcc'},
];

SalesforceCategoryDropdown.propTypes = {
  categoryHandler: PropTypes.func,
  category: PropTypes.string,
};
