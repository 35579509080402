import React from 'react';
import PropTypes from 'prop-types';
import FmdBadSharpIcon from '@mui/icons-material/FmdBadSharp';
import styles from './PlaceHolderDiv.module.css';
import DoDisturbAltSharpIcon from '@mui/icons-material/DoDisturbAltSharp';
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';

const iconSize = {fontSize: 100};

const PlaceHolderDiv = ({status, message, children}) => {
  return (
    <div className={styles.placeHolderContainer}>
      {status == 'notfound' && <FmdBadSharpIcon color='light' sx={iconSize} />}
      {(status == 'error' || status == 'fail') && (
        <FmdBadSharpIcon color='secondary' sx={iconSize} />
      )}
      {status == 'permissiondenied' && (
        <DoDisturbAltSharpIcon color='secondary' sx={iconSize} />
      )}
      {status == 'success' && (
        <DoneAllSharpIcon color='primary' sx={iconSize} />
      )}

      <h3>{message}</h3>
      {children}
    </div>
  );
};

PlaceHolderDiv.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
};

export default PlaceHolderDiv;
