import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ComplianceOverview.module.css';
import ChartStyles from 'style/Chart.module.css';

import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';

const getRandomColor = () => {
  const h = Math.floor(Math.random() * 360),
    s = Math.floor(Math.random() * 100) + '%',
    l = Math.floor(Math.random() * 60) + '%';
  return `hsl(${h},${s},${l})`;
};

const calcGraphHeight = (value) => {
  if (value < 10) {
    return 700;
  } else if (value > 10 && value < 20) {
    return 1000;
  } else if (value > 20) {
    return 1200;
  }
};

const renderLegend = (props) => {
  const {payload} = props;
  return (
    <ul className={ChartStyles.legendStyle}>
      {payload?.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{color: entry['color'], fontSize: 15, fontWeight: 'bold'}}
        >
          {entry.payload.complianceType}: {entry.payload.value}
        </li>
      ))}
    </ul>
  );
};

const CustomTooltip = ({active, payload}) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p className='label'>{`${payload[0].payload.complianceType} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  payload: PropTypes.any,
  active: PropTypes.bool,
  label: PropTypes.string,
};

function ComplianceOverview() {
  const getScanStats = useSelector(({reports}) => reports.getScanStats);

  return (
    <>
      {getScanStats?.compliancetypes?.length > 0 && (
        <div className={styles.ChartContainer}>
          <h2>Vulnerability Compliance Overview</h2>
          <div>
            <div>
              <ResponsiveContainer
                width='100%'
                height={
                  calcGraphHeight(getScanStats.compliancetypes.length) || 800
                }
                className='text-center'
                debounce={1}
              >
                <PieChart width={1000} margin={{top: 10, left: 50}} label>
                  <Tooltip content={<CustomTooltip />} />
                  <Legend
                    layout='horizontal'
                    verticalAlign='top'
                    align='center'
                    content={renderLegend}
                  />

                  <Pie
                    data={getScanStats.compliancetypes}
                    cx='50%'
                    cy='50%'
                    fill='#8884d8'
                    dataKey='count'
                    outerRadius={160}
                    innerRadius={110}
                    name={getScanStats.compliancetypes.complianceType}
                    isAnimationActive={false}
                  >
                    {getScanStats.compliancetypes.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getRandomColor()} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ComplianceOverview;
