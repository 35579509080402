import React from 'react';
import styles from './Overview.module.css';

function Overview() {
  return (
    <div className={styles.overviewContainer}>
      <h1>Overview</h1>
      <p>
        A successful security scan was executed against your Salesforce
        environment. This document lists the vulnerabilities identified by the
        DigitSec Security Platform for Salesforce. It is recommended to fix all
        critical risk and high risk vulnerabilities detailed in this report.
        Medium and low risk issues should be fixed as a best practice.
      </p>
    </div>
  );
}

export default Overview;
