import * as React from 'react';
import {AppCard} from '@crema';
import {ResponsiveContainer} from 'recharts';
import {LineChart, Line, XAxis, YAxis, Tooltip, Legend} from 'recharts';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ScanStatistics.module.css';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <p className='label'>{`${payload[0].payload.scanName} : ${payload[0].payload.Findings}`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
};

export default function TrendingScans() {
  const trendingScanPerOrg = useSelector(
    ({scanStatistics}) => scanStatistics.trendingScanPerOrg,
  );
  let data = [];
  trendingScanPerOrg &&
    trendingScanPerOrg[0][1].map((cur, i) => {
      data.push({
        name: trendingScanPerOrg[0][0],
        Findings: cur.Critical + cur.High + cur.Medium + cur.Low,
        index: (i+1),
        scanName: cur.Name?cur.Name:(i+1),
      });
    });

  return (
    <AppCard sxStyle={{height: 500}} title='Trending Scans'>
      <ResponsiveContainer height='100%' width='100%'>
        <LineChart data={data}>
          <XAxis dataKey='index' />
          <YAxis dataKey='Findings' />
          <Tooltip content={<CustomTooltip payload={data} />}/>
          <Legend />
          <Line
            type='monotone'
            dataKey='Findings'
            stroke='#8884d8'
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </AppCard>
  );
}
