import React, {useCallback, useState, useEffect} from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from 'recharts';
import {useSelector} from 'react-redux';
import {Fragment} from 'react';
import ErrorRepresentation from './ErrorRepresentation';
import styles from './BtcGraph.module.css';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {ScanColors} from 'shared/constants/AppEnums';
import {useDimensions} from '../../../utils/customHooks/Dimensions';

const data = [
  {name: 'Critical', value: 3234, color: ScanColors.critical},
  {name: 'High', value: 0, color: ScanColors.high},
  {name: 'Medium', value: 0, color: ScanColors.medium},
  {name: 'Low', value: 0, color: ScanColors.low},
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
        {props.windowWidth > 550
          ? payload.name
          : `Rate ${(percent * 100).toFixed(2)}% `}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 2}
        outerRadius={outerRadius + 4}
        fill={fill}
      />
      {props.windowWidth > 550 ? (
        <>
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill='none'
          />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill='#333'
          >{`${payload.name} ${value}`}</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill='#999'
          >
            {`(Rate ${(percent * 100).toFixed(2)}%)`}
          </text>
        </>
      ) : (
        ''
      )}
    </g>
  );
};

const ErrorMessage = ({message}) => {
  return (
    <div className={styles.reportPending}>
      {/* {status == 'pending' && (
        <RotatingLines width='200' strokeColor='#FF5733' />
      )}
      {status == 'error' && (
        <ErrorOutlineSharpIcon color='secondary' sx={{fontSize: 80}} />
      )}
      {status == 'noscan' && (
        <FmdBadSharpIcon color='primary' sx={{fontSize: 150}} />
      )} */}
      <h3 className={styles.errorMessage}>{message}</h3>
    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  status: PropTypes.string,
};

export default function App({scanreadable}) {
  const lastCompletedScan = useSelector(
    ({reports}) => reports.lastCompletedScan,
  );
  const {width} = useDimensions();

  let {org_id} = useParams();

  useEffect(() => {
    lastCompletedScan &&
      ((data[0].value = lastCompletedScan.Critical),
      (data[1].value = lastCompletedScan.High),
      (data[2].value = lastCompletedScan.Medium),
      (data[3].value = lastCompletedScan.Low));

    return () => {};
  }, [lastCompletedScan, org_id]);

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );
  return (
    <Fragment>
      {scanreadable && lastCompletedScan?.status === 'completed' && (
        <div>
          <ResponsiveContainer width='100%' height={300} minWidth='0'>
            <PieChart>
              <Pie
                activeIndex={activeIndex}
                activeShape={(props) =>
                  renderActiveShape({windowWidth: width, ...props})
                }
                data={data}
                cx='50%'
                cy='50%'
                innerRadius={70}
                outerRadius={90}
                fill='#8884d8'
                paddingAngle={5}
                dataKey='value'
                onMouseEnter={onPieEnter}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <ErrorRepresentation />
        </div>
      )}
      {/* {lastCompletedScan && lastCompletedScan?.status === 'pending' && (
        <ErrorMessage
          status='pending'
          // message='It seems there are no findings!'
        />
      )}
      {lastCompletedScan && lastCompletedScan?.status === 'error' && (
        <ErrorMessage
          status='noscan'
          message=''
        />
      )} */}
      {lastCompletedScan &&
        lastCompletedScan?.status === 'completed' &&
        scanreadable === false && (
          <ErrorMessage
            status='noscan'
            message='You do not have access to view latest scan infographics'
          />
        )}

      {lastCompletedScan &&
        lastCompletedScan?.status === 'inComplete' &&
        scanreadable && (
          <ErrorMessage
            status='noscan'
            message='Looks like there is no completed scan at this moment.'
          />
        )}
    </Fragment>
  );
}

App.propTypes = {
  scanreadable: PropTypes.bool,
};
