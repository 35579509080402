import React from 'react';

import Salesforce from './Salesforce';

export const SalesforcePagesConfigs = [
  {
    path: '/Salesforce',
    element: <Salesforce />,
  },
];
