import React from 'react';
import List from '@mui/material/List';

import routesConfig from '../../../../../pages/routesConfig';
import NavVerticalGroup from './VerticalNavGroup';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import {useSelector} from 'react-redux';

const VerticalNav = () => {
  const isAdmin = useSelector(({authentication}) => authentication.isAdmin);

  return (
    <List
      sx={{
        position: 'relative',
        padding: 0,
      }}
      component='div'
    >
      {routesConfig.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && <NavVerticalGroup item={item} level={0} />}

          {item.type === 'collapse' && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item.type === 'item' && isAdmin == false && item.id != 'Setup' && (
            <VerticalItem item={item} level={0} />
          )}

          {item.type === 'item' && isAdmin == true && (
            <VerticalItem item={item} level={0} />
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default VerticalNav;
