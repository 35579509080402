import React, {Fragment, useState} from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import AppCard from '../../../../@crema/core/AppCard';
import {Button} from '@mui/material';
import {InputLabel} from '@mui/material';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  onPostGithubSecret,
  onPostGithubInfo,
  onGetGithubInfo,
} from 'redux/actions';
import {GITHUB_SECRET, GITHUB_INFO} from 'shared/constants/ActionTypes';
import {useParams} from 'react-router-dom';
import {IconButton} from '@mui/material';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import styles from '../Integration.module.css';
import {onDeleteGithubRepo} from 'redux/actions';
import {onPutGithubInfo} from 'redux/actions';
import {SHOW_MESSAGE} from 'shared/constants/ActionTypes';
import PropTypes from 'prop-types';
import {Autocomplete, Chip, Box} from '@mui/material';
import AppMessageView from '@crema/core/AppMessageView';

const Github = ({writeable}) => {
  const dispatch = useDispatch();
  let {org_id} = useParams();
  const apiError = useSelector(({integration}) => integration.apiError);
  const githubSecret = useSelector(({integration}) => integration.githubSecret);
  const githubInfo = useSelector(({integration}) => integration.githubinfo);
  const singleOrg = useSelector(({dashboard}) => dashboard.singleOrg);

  const [githubForm, setGithubForm] = useState({
    access_token: '',
    github_username: '',
    is_pull_request_enabled: false,
    repo_name: '',
    githubevents: {
      opened: false,
      reopened: false,
      synchronize: false,
    },
  });

  useEffect(() => {
    dispatch(onPostGithubSecret({org_id}));
    if (singleOrg?.repo_id) {
      dispatch(onGetGithubInfo({githubId: singleOrg.repo_id, org_id: org_id}));
    }

    return () => {
      dispatch({type: GITHUB_SECRET, payload: null});
      dispatch({type: GITHUB_INFO, payload: null});
    };
  }, [org_id, singleOrg]);

  // console.log('githubinfo: ', githubInfo);

  useEffect(() => {
    if (githubInfo) {
      const gitEvents = githubInfo?.githubevents || {};

      const selectedGitOptions = Object.keys(gitEvents).filter(
        (event) => gitEvents[event] === true,
      );

      setGithubForm({
        access_token: '',
        github_username: githubInfo?.username || '',
        is_pull_request_enabled: githubInfo?.is_pull_request_enabled || false,
        repo_name: githubInfo?.name || '',
        githubevents: gitEvents,
      });

      setSelectedOptionsGit(selectedGitOptions);
    }
  }, [githubInfo]);

  const onFormChange = (event) => {
    setGithubForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  const checkboxPR = (event) => {
    setGithubForm((cur) => {
      return {
        ...cur,
        is_pull_request_enabled: event.target.checked,
      };
    });
  };

  const onFormSubmit = (event) => {
    event.preventDefault();

    const updatedGithubForm = {
      ...githubForm,
      githubevents: {
        selectedOptionsGit,
      },
    };

    selectedOptionsGit.forEach((option) => {
      const optionValue = typeof option === 'object' ? option.value : option;
      updatedGithubForm.githubevents[optionValue.toLowerCase()] = true;
    });

    if (githubSecret?.length > 1) {
      dispatch(
        onPutGithubInfo({
          ...updatedGithubForm,
          org_id,
          repo_id: githubSecret[0],
        }),
      );
    } else {
      dispatch(onPostGithubInfo({...updatedGithubForm, org_id}));
    }

    setGithubForm({
      access_token: '',
      github_username: '',
      is_pull_request_enabled: false,
      repo_name: '',
      githubevents: {
        opened: false,
        reopened: false,
        synchronize: false,
      },
    });
  };

  const copyGithubSecret = () => {
    navigator.clipboard.writeText(
      `https://s4.digitsec.com/webhooks/gitHub/${
        githubSecret[1] || githubSecret[0]
      }`,
    );
    dispatch({type: SHOW_MESSAGE, payload: 'Github Secret Copied'});
  };

  const deleteGithubRepo = () => {
    setSelectedOptionsGit([]);
    dispatch(onDeleteGithubRepo({githubId: githubSecret[0], org_id}));
    setGithubForm({
      access_token: '',
      github_username: '',
      is_pull_request_enabled: false,
      repo_name: '',
      githubevents: {
        opened: false,
        reopened: false,
        synchronize: false,
      },
    });
  };

  const options = [
    {value: 'opened', label: 'Opened'},
    {value: 'reopened', label: 'Reopened'},
    {value: 'synchronize', label: 'Synchronized'},
  ];

  const [selectedOptionsGit, setSelectedOptionsGit] = useState(options);

  const handleSelectGit = (event, value) => {
    setSelectedOptionsGit(value);
  };

  const handleDeleteTag = (index) => {
    const newSelectedOptions = [...selectedOptionsGit];
    newSelectedOptions.splice(index, 1);
    setSelectedOptionsGit(newSelectedOptions);
  };

  const getOptionDisabled = (option) =>
    selectedOptionsGit.some((selectedOption) =>
      typeof selectedOption === 'object'
        ? selectedOption.value === option.value
        : selectedOption === option.value,
    );

  return (
    <Fragment>
      <AppCard title='Github'>
        <form onSubmit={onFormSubmit} autoComplete='off'>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Github Secret</InputLabel>
            <TextField
              id='github-secret'
              placeholder='Github Secret'
              variant='outlined'
              type='password'
              className={styles.inputField}
              value={
                githubSecret == null ? '' : githubSecret[1] || githubSecret[0]
              }
              disabled
              // onClick={copyGithubSecret}
            />
            {
              <IconButton
                aria-label='copy'
                onClick={copyGithubSecret}
                disabled={!writeable}
              >
                <ContentCopySharpIcon />
              </IconButton>
            }
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Repository Name
            </InputLabel>
            <TextField
              id='repo_name'
              placeholder='Repository Name'
              variant='outlined'
              className={styles.inputField}
              name='repo_name'
              disabled={!writeable}
              value={githubForm.repo_name}
              onChange={onFormChange}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Github Organization
            </InputLabel>
            <TextField
              id='github_username'
              placeholder='Github Organization'
              variant='outlined'
              className={styles.inputField}
              name='github_username'
              disabled={!writeable}
              value={githubForm.github_username}
              onChange={onFormChange}
              autoComplete='off'
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Personal Access Token
            </InputLabel>
            <TextField
              id='personal-access-token'
              placeholder='Personal Access Token'
              variant='outlined'
              className={styles.inputField}
              name='access_token'
              value={githubForm.access_token}
              onChange={onFormChange}
              required
              disabled={!writeable}
              inputProps={{
                autoComplete: 'new-password',
              }}
              type='password'
            />
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
            Create additional pull request on scan completion
            </InputLabel>
            <Checkbox
              onChange={checkboxPR}
              checked={githubForm.is_pull_request_enabled}
              disabled={!writeable}
            />
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Enabled events to run scan
            </InputLabel>
            <Box style={{width: '600px'}}>
              <Autocomplete
                multiple
                id='multi-select'
                options={options}
                value={selectedOptionsGit}
                onChange={handleSelectGit}
                getOptionLabel={(option) => option.label}
                getOptionDisabled={getOptionDisabled}
                renderInput={(params) => (
                  <TextField {...params} label='Select Options' />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((selectedOption, index) => {
                    const selectedOptionLabel =
                      typeof selectedOption === 'object'
                        ? selectedOption.label
                        : options.find(
                            (option) => option.value === selectedOption,
                          )?.label;

                    return (
                      <Chip
                        key={index}
                        label={selectedOptionLabel}
                        color='primary'
                        onDelete={() => handleDeleteTag(index)}
                        {...getTagProps({index})}
                      />
                    );
                  })
                }
              />
            </Box>
          </div>
          <div className={styles.formButtonContainer}>
            <Button type='submit' variant='contained' disabled={!writeable}>
              Verify and Save
            </Button>
            {githubSecret?.length > 1 && (
              <Button
                variant='contained'
                onClick={deleteGithubRepo}
                disabled={!writeable}
              >
                Disconnect Github Repo
              </Button>
            )}
          </div>
        </form>
      </AppCard>
      
      {apiError &&
      <AppMessageView variant='error' message={apiError.toString()} />
      }
    </Fragment>
  );
};

export default Github;
Github.propTypes = {
  writeable: PropTypes.bool,
};
