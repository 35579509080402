import React, {Fragment, useState} from 'react';
import AppCard from '@crema/core/AppCard';
import styles from './Information.module.css';
import PropTypes from 'prop-types';
import VulnerabilityDetails from '../VulnerabilityDetails/VulnerabilityDetails';
import CodeSnippet from '../CodeSnippet/CodeSnippet';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import {Modal, Box, Typography, Button} from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism as prismStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';


function Information({
  description,
  remediationguidance,
  traces,
  codesnippets,
  exploits,
  vulnerablefunction,
  vulnerablefunctionline,
  lineCode,
}) {
  const [visibleCode, setVisibleCode] = useState(false);

  const openCodeSnippet = () => {
    setVisibleCode(true);
  };

  const closeCodeSnippet = () => {
    setVisibleCode(false);
  };

  // const preStyle = {
  //   backgroundColor: '#eee',
  // };

  const lineNumbers = (code, lineCode) => {
    return code
      .split('\n')
      .map((line, index) => {
        return `${lineCode + index} ${line}`;
      })
      .join('\n');
  };

  return (
    <Fragment>
      <AppCard sxStyle={{marginBottom: 7}}>
        <div className={styles.textContainer}>
          <h3 className={styles.title}>Background</h3>
          <p className={styles.textBrief}>{description}</p>
        </div>
        <div className={styles.textContainer}>
          <div className={styles.toggleContainerC}>
            <h3 className={styles.title}>Issue (Code Snippet, Exploit)</h3>
            <IntegrationInstructionsIcon
              className={
                vulnerablefunction === null || vulnerablefunctionline === null
                  ? styles.disableiconCode
                  : styles.iconCode
              }
              onClick={openCodeSnippet}
            />
          </div>
          <div className={styles.textBriefNew}>
            {traces != null && traces != undefined && traces.length > 0 && (
              <SyntaxHighlighter startingLineNumber={lineCode} language="java" customStyle={prismStyle}>{lineNumbers(traces[traces.length - 1]['codeSnippet'], lineCode)}</SyntaxHighlighter>
            )}
            {codesnippets != null && (
              <SyntaxHighlighter startingLineNumber={lineCode} language="java" customStyle={prismStyle}>{lineNumbers(codesnippets, lineCode)}</SyntaxHighlighter>
            )}
          </div>
        </div>
        <div className={styles.textContainer}>
          <h3 className={styles.title}>Remediation Guideline</h3>
          <ul className={styles.textBrief}>
            {remediationguidance.map((item) => {
              return (
                <li key={(Math.random() + 1).toString(36).substring(7)}>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.textContainer}>
          <h3 className={styles.title}>Vulnerability Trace</h3>
          <VulnerabilityDetails traces={traces} exploits={exploits} />
        </div>
      </AppCard>
      <Modal
        open={visibleCode}
        onClose={closeCodeSnippet}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
            padding: 7,
            width: '50%',
            maxHeight: '600px',
            maxWidth: 'auto',
            overflow: 'auto',
          }}
        >
          <div>
            <div className={styles.headerContent}>
              <div className={styles.titleContainer}>
                <div className={styles.subTitleContainer}>
                <IntegrationInstructionsIcon className={styles.iconCodeS} />
                <Typography variant='h2' className={styles.titleCs}>
                  Vulnerable Function
                </Typography>
                </div>
                <Button
                  onClick={closeCodeSnippet}
                  variant='contained'
                  color='secondary'
                >
                  Close
                </Button>
              </div>
            </div>
            &nbsp;
            <CodeSnippet vulnerablefunction={vulnerablefunction} vulnerablefunctionline={vulnerablefunctionline} />
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default Information;

Information.propTypes = {
  description: PropTypes.string,
  remediationguidance: PropTypes.array,
  traces: PropTypes.array,
  codesnippets: PropTypes.string,
  exploits: PropTypes.string,
  vulnerablefunction: PropTypes.string,
  vulnerablefunctionline: PropTypes.number,
  lineCode: PropTypes.number,
};
