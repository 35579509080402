import jwtAxios from '@crema/services/auth/jwt-auth';
import {
  FETCH_ERROR,
  IS_API_IN_PROCESS,
  POST_TAGS_DATA,
  FETCH_SUCCESS,
  GET_TAGS_DATA,
  DETAILS_RESPONSE,
  DUPLICATE_TAGS,
  DUPLICATE_TAGS_DATA,
  TAGS_RESPONSE,
  TAGS_SUCCESS,
  SHOW_MESSAGE,
  SFDC_ASSIGN_RESPONSE,
  SCAN_ASSIGN_RESPONSE,
} from 'shared/constants/ActionTypes';
// import IntlMessages from '@crema/utility/IntlMessages';
import {onSearchSfdcInfo} from 'redux/actions';
import {onGetReportsData} from 'redux/actions';
import {getIssuesCleanRequest} from 'redux/actions';
import {onGetAllOrgs} from 'redux/actions';
import {onGetSingleOrg} from 'redux/actions';
import {onPostTrendingScansPerOrg} from 'redux/actions/ScanStatistics';

export const getTags = () => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    jwtAxios
      .get(`/tags/getTags`)
      .then((response) => {
        dispatch({
          type: GET_TAGS_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const createTags = (tags, ui = true) => {
  return (dispatch) => {
    const postURL = '/tags/createTags';
    jwtAxios
      .post(postURL, {tags, ui})
      .then((data) => {
        if (data.status == 200 && data.data.tags.length > 0) {
          dispatch({type: IS_API_IN_PROCESS, payload: true});
          const newData = data.data;
          dispatch({type: POST_TAGS_DATA, payload: newData});
          dispatch({
            type: TAGS_SUCCESS,
            payload: {
              message: data.data.message,
            },
          });
          dispatch(getTags());
        } else if (data.status == 200 && data.data.tags.length === 0) {
          dispatch({
            type: TAGS_RESPONSE,
            payload: {
              message: 'No tag(s) created',
            },
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          dispatch({
            type: TAGS_RESPONSE,
            payload: {
              message: error.response.data.message,
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: error.message,
          });
        }
      });
  };
};

export const updateTags = (tags, ui = true) => {
  return (dispatch) => {
    const postURL = '/tags/updateTags';
    const data = {
      tags: tags,
      ui: ui,
    };
    jwtAxios
      .put(postURL, data)
      .then((data) => {
        if (
          data.status == 200 &&
          data.data.message.includes('update successful')
        ) {
          dispatch({type: IS_API_IN_PROCESS, payload: true});
          const newData = data.data;
          dispatch({type: POST_TAGS_DATA, payload: newData});
          dispatch({
            type: TAGS_SUCCESS,
            payload: {
              message: 'Tag Updated Successfully',
            },
          });
          dispatch(getTags());
        } else if (data.status == 200 && data.data.length === 0) {
          dispatch({
            type: TAGS_RESPONSE,
            payload: {
              message: 'Tag(s) could not be updated',
            },
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          dispatch({
            type: TAGS_RESPONSE,
            payload: {
              message: error.response.data.message,
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: error.message,
          });
        }
      });
  };
};

export const assignTagsSFDC = (tagIds, org_id, action = '') => {
  return (dispatch) => {
    let payload = {orgId: org_id, tagIds: tagIds, action: action};
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    const deleteURL = '/tags/sfdcinfo';
    jwtAxios
      .put(deleteURL, payload)
      .then((data) => {
        if (data.status === 200 && payload.action === 'assign') {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Tag(s) assigned successfully!',
          });
          dispatch(getTags());
          dispatch(onSearchSfdcInfo({pageNum: 1, search: '', isSfcc: undefined}));
          dispatch(onGetAllOrgs());
        } else if (data.status === 200 && payload.action === 'remove') {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Tag(s) removed successfully!',
          });
          dispatch(getTags());
          dispatch(onSearchSfdcInfo({pageNum: 1, search: '', isSfcc: undefined}));
          dispatch(onGetAllOrgs());
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          dispatch({
            type: SFDC_ASSIGN_RESPONSE,
            payload: {
              message: error.response.data.message,
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: error.message,
          });
        }
      });
  };
};

export const assignTagsScan = (tagIds, scanId, orgId, action = '') => {
  return (dispatch) => {
    let payload = {
      scanId: scanId,
      tagIds: tagIds,
      orgId: orgId,
      action: action,
    };
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    const deleteURL = '/tags/scan';
    jwtAxios
      .put(deleteURL, payload)
      .then((data) => {
        if (data.status === 200 && payload.action === 'assign') {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: SHOW_MESSAGE, payload: 'Tag(s) assigned successfully!'});
          dispatch(onGetReportsData({org_id: orgId, page: 1}));
          dispatch(onGetSingleOrg({org_id: orgId}));
          dispatch(onPostTrendingScansPerOrg({org_id: orgId}));
          dispatch(getTags());
        } else if (data.status === 200 && payload.action === 'remove') {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: SHOW_MESSAGE, payload: 'Tag(s) removed successfully!'});
          dispatch(onGetReportsData({org_id: orgId, page: 1}));
          dispatch(onGetSingleOrg({org_id: orgId}));
          dispatch(onPostTrendingScansPerOrg({org_id: orgId}));
          dispatch(getTags());
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const assignTagsIssue = (tagIds, report_id, issue_id, org_id, action = '', issuesCurPage, pageSize) => {
  return (dispatch) => {
    let payload = {
      issueId: issue_id,
      tagIds: tagIds,
      orgId: org_id,
      action: action,
      _scanID: report_id,
    };
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    const deleteURL = '/tags/finding';
    jwtAxios
      .put(deleteURL, payload)
      .then((data) => {
        if (data.status === 200 && payload.action === 'assign') {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {status: 'success', message: 'Tag assigned Successfully!'},
          });
          dispatch(getTags());
          dispatch(
            getIssuesCleanRequest({
              _id: report_id,
              page: issuesCurPage,
              orgId: org_id,
              pageSize: pageSize,
            }),
          );
        } else if (data.status === 200 && payload.action === 'remove') {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {status: 'success', message: 'Tag removed Successfully!'},
          });
          dispatch(getTags());
          dispatch(
            getIssuesCleanRequest({
              _id: report_id,
              page: issuesCurPage,
              orgId: org_id,
              pageSize: pageSize,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const createTagsandAssignmentSFDC = (tags, ui = true, orgSFDCId) => {
  return (dispatch) => {
    const postURL = '/tags/sfdcinfo/create_and_assign_tags';
    let action = 'assign';

    let body ={
      tags: tags,
      action: action,
      orgId: orgSFDCId,
      ui: ui
    };
    jwtAxios
      .post(postURL, body)
      .then((data) => {
        if (data.status == 200) {
          dispatch({ type: IS_API_IN_PROCESS, payload: false });
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Tag(s) assigned successfully!',
          });
          dispatch({type: FETCH_SUCCESS});
          dispatch(onSearchSfdcInfo({pageNum: 1, search: ''}));
          dispatch(getTags());
          dispatch(onGetAllOrgs());
          // const tagIds = data.data.map(item => item._id);
          // dispatch(assignTagsSFDC(tagIds, org_id, action));
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          dispatch({
            type: SFDC_ASSIGN_RESPONSE,
            payload: {
              message: error.response.data.message,
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: error.message,
          });
        }
      });
  };
};

export const createTagsandAssignmentScan = (tags, ui = true, scanId, orgIdScan) => {
  return (dispatch) => {
    const postURL = '/tags/scan/create_and_assign_tags';
    let action = 'assign';
    let body ={
      tags: tags,
      action: action,
      scanId: scanId,
      ui: ui,
      orgId: orgIdScan
    };
    jwtAxios
      .post(postURL, body)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: IS_API_IN_PROCESS, payload: false });
          dispatch({type: SHOW_MESSAGE, payload: 'Tag(s) created successfully!'});
          dispatch(onGetReportsData({org_id: orgIdScan, page: 1}));
          dispatch(onGetSingleOrg({org_id: orgIdScan}));
          dispatch(onPostTrendingScansPerOrg({org_id: orgIdScan}));
          dispatch(getTags());
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          dispatch({
            type: SCAN_ASSIGN_RESPONSE,
            payload: {
              message: error.response.data.message,
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: error.message,
          });
        }
      });
  };
};

export const createTagsandAssignmentIssue = (tags, report_id, ui = true, issueId, issueOrgId, page, pageSize) => {
  return (dispatch) => {
    const postURL = '/tags/finding/create_and_assign_tags';
    let action = 'assign';
    const orgId = issueOrgId;
    const _scanId = report_id;

    let body ={
      tags: tags,
      action: action,
      issueId: issueId,
      ui: ui,
      orgId: orgId,
      _scanID: _scanId
    };
    jwtAxios
      .post(postURL, body)
      .then((data) => {
        if (data.status == 200) {
          dispatch({ type: IS_API_IN_PROCESS, payload: true });
          // const tagIds = data.data.map(item => item._id);
          // dispatch(assignTagsIssue(tagIds, _scanId, issueId, orgId, action));
          dispatch(getTags());
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {
              status: 'success',
              message: 'Tag(s) created successfully!',
            },
          });
          dispatch(
            getIssuesCleanRequest({
              _id: _scanId,
              page: page,
              orgId: orgId,
              pageSize: pageSize,
            }),
          );
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {status: 'fail', message: error.response.data.message},
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: error.message,
          });
        }
      });
  };
};

export const restoreTags = (tagIds) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    const deleteURL = '/tags/restoreTags';
    jwtAxios
      .put(deleteURL, {tagIds})
      .then((data) => {
        if (data.status == 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(getTags());
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const deleteTags = (tagIds) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    const deleteURL = '/tags/deleteTags';
    jwtAxios
      .put(deleteURL, {tagIds})
      .then((data) => {
        if (data.status == 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(getTags());
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const checkIfTagDuplicate = (tags) => {
  return (dispatch) => {
    return jwtAxios
      .post('/tags/checkDuplicateTags', {tags})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          if (data.data && data.data.length > 0) {
            dispatch({type: DUPLICATE_TAGS, payload: true});
            dispatch({type: DUPLICATE_TAGS_DATA, payload: data.data});
            return true;
          } else {
            dispatch({type: DUPLICATE_TAGS, payload: false});
            return false;
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        return true;
      });
  };
};
