import React from 'react';

import Success from './Success';

export const successPagesConfigs = [
  {
    path: '/success',
    element: <Success />,
  },
];
