import React from 'react';
import {
  GLOBAL_CONFIGS,
  CONFIG_RESPONSE,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  IS_API_IN_PROCESS,
  DASHBOARD_RESPONSE,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {defaultError} from 'shared/constants/AppConst';
import {onGetSingleOrg} from './Dashboard';

// Update Issue Detail
export const onGetGlobalConfigs = (isDetailsPage) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    let url  = isDetailsPage?`/getconfigs?internal=${isDetailsPage}`:`/getconfigs`;
    jwtAxios
      .get(url)
      .then((data) => { 
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: GLOBAL_CONFIGS, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        if(!error.response?.data?.message.includes('Environment')){
          dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        }
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPutGlobalConfigs = ({config}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`saveSecurityGateConfigs`, config)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: CONFIG_RESPONSE,
            payload: {
              status: 'success',
              message: 'Configurations Updated Successfully!',
            },
          });
          dispatch(onGetGlobalConfigs());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: CONFIG_RESPONSE,
            payload: {
              status: 'fail',
              message: 'Something went wrong, Please try again later!',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: CONFIG_RESPONSE,
          payload: {
            status: 'fail',
            message: 'Network Error! Please try again later',
          },
        });
      });
  };
};

export const onPostUpdateConfigs = ({configsState}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`updateconfigs`, configsState)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});

          dispatch(onGetGlobalConfigs());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPostSubmitJira = ({jiraForm}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`submitJira`, jiraForm)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: CONFIG_RESPONSE,
            payload: {
              status: 'success',
              message: 'Jira Configured Successfully!',
            },
          });
          dispatch(onGetGlobalConfigs());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: CONFIG_RESPONSE,
            payload: {
              status: 'fail',
              message:
                data.error || 'Something went Wrong! Please try again later',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: CONFIG_RESPONSE,
          payload: {
            status: 'fail',
            message: error.error
              ? error.error
              : 'Something went Wrong! Please try again later',
          },
        });
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPostUpdateSSO = ({SSOForm}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`updateSSO`, {samlConfig: SSOForm})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: CONFIG_RESPONSE,
            payload: {status: 'success', message: 'SSO Added Successfully!'},
          });
          dispatch(onGetGlobalConfigs());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: CONFIG_RESPONSE,
            payload: {
              status: 'fail',
              message: 'Something went Wrong! Please try again later',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: CONFIG_RESPONSE,
          payload: {
            status: 'fail',
            message: 'Something went Wrong! Please try again later',
          },
        });
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPutDeleteSSO = () => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`deleteSSO`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});

          if (data.data.success == 'Deleted configs') {
            dispatch({
              type: CONFIG_RESPONSE,
              payload: {status: 'success', message: 'SSO Reset Successfully!'},
            });
            dispatch(onGetGlobalConfigs());
          } else {
            dispatch({
              type: CONFIG_RESPONSE,
              payload: {
                status: 'fail',
                message: 'Something went wrong, Please try again later!',
              },
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: CONFIG_RESPONSE,
            payload: {
              status: 'fail',
              message: 'Something went wrong, Please try again later!',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: CONFIG_RESPONSE,
          payload: {
            status: 'fail',
            message: 'Network Error! Please try again later',
          },
        });
      });
  };
};

export const onPostJiraSFDXUpdate = ({project_id, project_key, sfdcInfo}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`jiraOutputSFDXUpdate`, {project_id, project_key, sfdcInfo, orgId:sfdcInfo})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          if (data.data.success) {
            dispatch({
              type: DASHBOARD_RESPONSE,
              payload: {
                status: 'success',
                message: 'Jira Configs Saved!',
              },
            });
            dispatch(onGetGlobalConfigs());
            dispatch(onGetSingleOrg({org_id: sfdcInfo}));
          } else {
            dispatch({
              type: DASHBOARD_RESPONSE,
              payload: {
                status: 'fail',
                message: data.data.error || defaultError,
              },
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'fail',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'fail',
            message: error.message,
          },
        });
      });
  };
};
