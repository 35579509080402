import React, {useState} from 'react';
import {useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {TextField, Button} from '@mui/material';
import PropTypes from 'prop-types';
import StyleIcon from '@mui/icons-material/Style';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useDispatch} from 'react-redux';
// import {checkIfTagDuplicate} from 'redux/actions/Tags';
import {updateTags} from 'redux/actions/Tags';

function ModalTagsBulk({
  open,
  handleClose,
  selectedRows,
  editBulk,
  handleRemoveTag,
  deselectRow,
}) {
  const dispatch = useDispatch();
  // const isDuplicate = useSelector(({tags}) => tags.isDuplicate);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData([...selectedRows]);
  }, [selectedRows]);

  // const updateCategoryBasedOnUrl = (url) => {
  //   if (url.includes('workspace')) {
  //     return 'workspace';
  //   } else if (url.includes('dashboard')) {
  //     return 'scan';
  //   } else if (url.includes('details')) {
  //     return 'issue';
  //   }
  //   return 'workspace';
  // };

  // useEffect(() => {
  //   const url = window.location.href;
  //   const category = updateCategoryBasedOnUrl(url);
  //   setNewTagData((prev) => ({
  //     ...prev,
  //     category,
  //   }));
  // }, [window.location.href]);

  // const handleAddTag = () => {
  //   const url = window.location.href;
  //   const category = updateCategoryBasedOnUrl(url);
  //   setTags((prevTags) => [...prevTags, newTagData]);
  //   setNewTagData({
  //     key: '',
  //     value: '',
  //     url: '',
  //     category,
  //   });
  // };

  const handleTagDataChange = (e, index) => {
    const updatedTableData = [...tableData];
    updatedTableData[index] = {
      ...updatedTableData[index],
      [e.target.name]: e.target.value,
    };
    setTableData(updatedTableData);
  };

  const [openSnackbarCreated, setOpenSnackbarCreated] = useState(false);

  const handleCloseSnackbarCreated = () => {
    setOpenSnackbarCreated(false);
  };

  const [openSnackbarUpdated, setOpenSnackbarUpdated] = useState(false);

  const handleCloseSnackbarUpdated = () => {
    setOpenSnackbarUpdated(false);
  };

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const tagUrl = window.location.href;

    const setDisabledValue = () => {
      if (tagUrl.includes('configure?tab=tags')) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    };

    setDisabledValue();
  }, [dispatch]);

  const [openSnackbarDuplicates, setOpenSnackbarDuplicates] = useState(false);
  const [snackbarMessageDuplicates, setSnackbarMessageDuplicates] =
    useState('');
  const handleSnackbarCloseDuplicates = () => {
    setOpenSnackbarDuplicates(false);
  };

  const handleUpdateTags = async () => {
    const seenKeys = new Set();
    let duplicateValues = false;

    const updatedTags = selectedRows.map((originalTag) => {
      const modifiedTag = tableData.find((tag) => tag._id === originalTag._id);
      const tagToReturn = modifiedTag ? modifiedTag : originalTag;
      const key = `${tagToReturn.key.trim().toLowerCase()}-${tagToReturn.value
        .trim()
        .toLowerCase()}-${tagToReturn.category.trim().toLowerCase()}`;

      if (seenKeys.has(key)) {
        duplicateValues = true;
      } else {
        seenKeys.add(key);
      }

      return tagToReturn;
    });
    if (duplicateValues) {
      setOpenSnackbarDuplicates(true);
      setSnackbarMessageDuplicates(`Duplicate values in category`);
      return;
    } else {
      const tags = updatedTags;
      await dispatch(updateTags(tags));
      handleCloseModal();
      deselectRow();
      setTableData([]);
    }
  };

  const handleCloseModal = () => {
    handleClose();
    setTableData([]);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
            padding: 7,
            width: '50%',
            maxHeight: '500px',
            overflowX: 'auto',
          }}
        >
          <div>
            <div className={'headerTag'}>
              <StyleIcon />
              <Typography variant='h2'>
                {editBulk ? 'Edit Tag' : 'Add Tag'}
              </Typography>
            </div>
            <div className={'addBtnContainer'}>
              <Button
                className={'closeBtn'}
                variant='contained'
                color='secondary'
                onClick={handleCloseModal}
              >
                Close
              </Button>
              {!editBulk && (
                <Button
                  className={'closeBtn'}
                  variant='contained'
                  color='primary'
                  // onClick={handleAddTag}
                >
                  Add Tag
                </Button>
              )}
            </div>
            &nbsp;
            <TableContainer component={Paper}>
              <Table
                sx={{minWidth: 650}}
                aria-label='simple table'
                stickyHeader={true}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Tag</TableCell>
                    <TableCell align='left'>Value</TableCell>
                    <TableCell align='left'>URL</TableCell>
                    <TableCell align='left'>Category</TableCell>
                    {!editBulk && <TableCell>Remove</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRows.map((tag, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          label='Tag'
                          name='key'
                          defaultValue={tag.key}
                          onChange={(e) => handleTagDataChange(e, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label='Value'
                          name='value'
                          defaultValue={tag.value}
                          onChange={(e) => handleTagDataChange(e, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label='URL'
                          name='url'
                          defaultValue={tag.url}
                          onChange={(e) => handleTagDataChange(e, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <Select
                          disabled={isDisabled || editBulk}
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          defaultValue={tag.category}
                          name='category'
                          label='Category'
                          onChange={(e) => handleTagDataChange(e, index)}
                          inputProps={
                            isDisabled || editBulk ? {IconComponent: () => null} : {}
                          }
                        >
                          <MenuItem value='workspace'>Workspace</MenuItem>
                          <MenuItem value='scan'>Scans</MenuItem>
                          <MenuItem value='issue'>Issues</MenuItem>
                          <MenuItem value='all'>All Categories</MenuItem>
                        </Select>
                      </TableCell>
                      {!editBulk && (
                        <TableCell>
                          <Button
                            className={'removeTag'}
                            onClick={() => handleRemoveTag(index)}
                            size='small'
                            variant='contained'
                            color='secondary'
                          >
                            Remove
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={'submitBtnContainer'}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleUpdateTags}
              >
                Update
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbarCreated}
        autoHideDuration={3000}
        onClose={handleCloseSnackbarCreated}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleCloseSnackbarCreated}
          severity='success'
          sx={{alignItems: 'center', color: 'white'}}
        >
          Tag Successfully Created
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={openSnackbarUpdated}
        autoHideDuration={3000}
        onClose={handleCloseSnackbarUpdated}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleCloseSnackbarUpdated}
          severity='success'
          sx={{alignItems: 'center', color: 'white'}}
        >
          Tag Successfully Updated
        </MuiAlert>
      </Snackbar>
      {/* Error snackbar for duplicate values */}
      <Snackbar
        sx={{alignItems: 'center'}}
        open={openSnackbarDuplicates}
        autoHideDuration={3000}
        onClose={handleSnackbarCloseDuplicates}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          sx={{alignItems: 'center'}}
          elevation={6}
          variant='filled'
          severity='error'
          onClose={handleSnackbarCloseDuplicates}
        >
          {snackbarMessageDuplicates}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ModalTagsBulk;

ModalTagsBulk.propTypes = {
  open: PropTypes.bool.isRequired,
  editBulk: PropTypes.bool.isRequired,
  selectedRows: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleRemoveTag: PropTypes.func.isRequired,
  deselectRow: PropTypes.func.isRequired,
};