import React, {useEffect} from 'react';
import TrendingScans from 'components/scanstatistics/TrendingScans';
import AccordionScan from 'components/scanstatistics/AccordionScan';
import {useDispatch} from 'react-redux';
import {onGetScanById} from 'redux/actions/ScanStatistics';
import styles from './ScanStatistics.module.css';
import VulnerabilityTypes from 'components/scanstatistics/VulnerabilityTypes';
import SeverityOverview from 'components/scanstatistics/SeverityOverview';
import {useParams} from 'react-router-dom';
import {onGetScanStatsStat} from 'redux/actions';
import {onPostTrendingScansPerOrg} from 'redux/actions/ScanStatistics';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import {Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';

const ScanStatistics = () => {
  let {scan_id} = useParams();
  let {orgId} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetScanById({scan_id, orgId}));
    dispatch(onGetScanStatsStat({_id: scan_id, orgId: orgId}));
    dispatch(onPostTrendingScansPerOrg({org_id:orgId}));
    return () => {};
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.scanStatistics}>
      <div className={styles.scanStatisticsHeader}>
        <h2>Scan Statistics</h2>
        <Button
          variant='contained'
          startIcon={<ChevronLeftRoundedIcon />}
          onClick={goBack}
        >
          Back
        </Button>
      </div>
      <AccordionScan />
      <TrendingScans />
      <VulnerabilityTypes />
      <SeverityOverview />
    </div>
  );
};

export default ScanStatistics;
