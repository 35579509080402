import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  IS_API_IN_PROCESS,
  GET_TWO_STEP_AUTHENTICATION,
  API_RESPONSE,
  GET_COUNTRY_CODES,
  PROFILE_RESPONSE,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {onGetUserInfo} from './Common';
import { setAuthToken } from '@crema/services/auth/jwt-auth';

// 2 Step Verification Info

export const onGet2FaSettings = () => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get('/get2faSettings')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          if (data.data == '2faDisabled') {
            dispatch({type: GET_TWO_STEP_AUTHENTICATION, payload: false});
          } else if (data.data == '2faEnabled') {
            dispatch({type: GET_TWO_STEP_AUTHENTICATION, payload: true});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

// Update Profile

export const onUpdateProfile = (obj) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post('/user/save', obj)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});

          if (data.data?.success == 'User update succeeded') {
            dispatch({
              type: PROFILE_RESPONSE,
              payload: {
                message: 'Profile Updated Successfully!',
                status: 'success',
              },
            });
            dispatch(onGetUserInfo());
          } else if (data.data.success == 'verify2fa') {
            dispatch({
              type: PROFILE_RESPONSE,
              payload: {
                message: 'Verify2Fa',
                status: 'verify2fa',
              },
            });
          } else {
            dispatch({
              type: PROFILE_RESPONSE,
              payload: {
                message: 'Something went Wrong! Please Try again',
                status: 'fail',
              },
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});

          dispatch({
            type: PROFILE_RESPONSE,
            payload: {
              message: 'Something went Wrong! Please Try again',
              status: 'fail',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: PROFILE_RESPONSE,
          payload: {
            message: 'Something went Wrong! Please Try again',
            status: 'fail',
          },
        });
      });
  };
};


export const successUpdate = () => {
  return (dispatch) => {
    dispatch({
      type: PROFILE_RESPONSE,
      payload: {
        message: 'Two Step Verification Enabled',
        status: 'success',
      },
    });
  };
};

// Update 2Step Authentication

export const disable2fa = () => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post('disable2fa', {})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          if (data.data == '2faDisabled') {
            dispatch({
              type: PROFILE_RESPONSE,
              payload: {
                message: 'Two Step Verification Disabled',
                status: 'success',
              },
            });
            dispatch(onGetUserInfo());
          } else {
            dispatch({
              type: PROFILE_RESPONSE,
              payload: {
                message: 'Something went Wrong! Please Try again',
                status: 'fail',
              },
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: API_RESPONSE,
            payload: {
              result: 'fail',
              message: 'Something went Wrong! Please try again',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: API_RESPONSE,
          payload: {
            result: 'fail',
            message: 'Something went Wrong! Please try again',
          },
        });
      });
  };
};

export const reset2FaValues =()=>{
  return (dispatch)=>{
    dispatch({type: "Reset_Values"});

  };
};

// Enable2FA
export const generate2faOtpRequest =(email)=>{
  return (dispatch)=>{
    jwtAxios
    .post('/generate2faOtp', {email:email})
    .then((data) => {
      //console.log(data);
      dispatch({type: "Set_QRCode", payload: data.data});

    }).catch((error)=>{
      console.log(error);
    });
  };
};


export const emptyToken =()=>{
  return (dispatch)=>{
      dispatch({type: "Set_Token_Empty",payload:null});
  };
};



export const verify2faOtpRequest =(token)=>{
  return (dispatch)=>{
    dispatch({type: FETCH_START});
    jwtAxios
    .post('/verifymicro2fa', {token:token})
    .then((data) => {
      console.log(data);
      dispatch({type: "Enable_2FA"});
      dispatch({type: FETCH_SUCCESS});
      dispatch(onGetUserInfo());

    }).catch((error)=>{
      dispatch({type: FETCH_SUCCESS});

      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        console.log("error",error.message);
      dispatch({type: "Set_Error", payload: [error.message]});
      } else {
        // Handle other errors
        console.error('An error occurred:', error);
      }
    });
  };
};

export const validate2faOtpRequest =(email,password,token)=>{
  return (dispatch)=>{
    dispatch({type: FETCH_START});
    jwtAxios
    .post('/validate2faOtp', {email:email,password:password,token:token})
    .then((data) => {
      //console.log("dt",data);
      setAuthToken(data.data.token);
      dispatch({type: "Valid_2FA",payload:data.data});
      dispatch({type: FETCH_SUCCESS});
     

    }).catch((error)=>{
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        console.log("error",error.message);
      dispatch({type: "Set_Error", payload: [error.message]});
      } else {
        // Handle other errors
        console.error('An error occurred:', error);
      }
    });
    

  };
};

export const resetError =()=>{
  return (dispatch)=>{
    dispatch({type: "Reset_Error"});
  };
};

export const Disable2fa =()=>{
  return (dispatch)=>{
    dispatch({type: FETCH_START});
    jwtAxios
    .post('/disable2fa')
    .then((data) => {
      console.log(data);
      dispatch({type: "Disable_2FA"});
      dispatch({type: FETCH_SUCCESS});
      dispatch(onGetUserInfo());

    }).catch((error)=>{
      console.log(error);
    });
  };
};

export const enable2fa = (authType) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put('enable2fa', {authType:authType})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          if (data.data?.success == 'User update succeeded') {
            dispatch({
              type: PROFILE_RESPONSE,
              payload: {
                message: 'Two Step Verification Enabled',
                status: 'success',
              },
            });
            dispatch(onGetUserInfo());
          } else {
            dispatch({
              type: PROFILE_RESPONSE,
              payload: {
                message: 'Something went Wrong! Please Try again',
                status: 'fail',
              },
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: API_RESPONSE,
            payload: {
              result: 'fail',
              message: 'Something went Wrong! Please try again',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: API_RESPONSE,
          payload: {
            result: 'fail',
            message: 'Something went Wrong! Please try again',
          },
        });
      });
  };
};

// Get Country Codes

export const onGetCountryCodes = () => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get('/getCountryCodes')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: GET_COUNTRY_CODES, payload: data.data.countries});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPostUpdatePhone = ({internationalCode, phone, twofatoken}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post('/user/updatePhone', {internationalCode, phone, twofatoken})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});

          if (data.data?.success == 'User update succeeded') {
            dispatch({
              type: PROFILE_RESPONSE,
              payload: {
                message: 'Profile Updated Successfully!',
                status: 'success',
              },
            });
            dispatch(onGetUserInfo());
          } else {
            dispatch({
              type: PROFILE_RESPONSE,
              payload: {
                message: 'Phone Number not verified! Try Again',
                status: 'fail',
              },
            });
            dispatch(onGetUserInfo());
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});

          dispatch({
            type: PROFILE_RESPONSE,
            payload: {
              message: 'Phone Number not verified! Try Again',
              status: 'fail',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: PROFILE_RESPONSE,
          payload: {
            message: 'Phone Number not verified! Try Again',
            status: 'fail',
          },
        });
      });
  };
};
