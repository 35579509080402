import React from 'react';
import {Fragment} from 'react';
import Grid from '@mui/material/Grid';
import styles from './Copado.module.css';
import AppCard from '../../../../@crema/core/AppCard';
import PropTypes from 'prop-types';

function Copado({writeable}) {
  return (
    <Fragment>
      <AppCard sxStyle={{}} title='Copado'>
        {writeable && (
          <>
            <h3>
              Please select the appropriate option for your Workspace
            </h3>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div spacing={2} className={styles.buttonBoxContainer}>
                  <div className={styles.buttonBox}>
                    <h6 className={styles.buttonBoxHeading}>
                      Production Install
                    </h6>
                    <a
                      className={styles.link}
                      target='_blank'
                      rel='noreferrer'
                      href='https://login.salesforce.com/packaging/installPackage.apexp?p0=04t8c000000lE6x'
                    >
                      Production Install
                    </a>
                  </div>
                  <div className={styles.buttonBox}>
                    <h6 className={styles.buttonBoxHeading}>Sandbox Install</h6>
                    <a
                      className={styles.link}
                      target='_blank'
                      rel='noreferrer'
                      href='https://test.salesforce.com/packaging/installPackage.apexp?p0=04t8c000000lE6x'
                    >
                      Sandbox Install
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        )}
        {!writeable && (
          <p>You do not have access to COPADO in read-only roles!</p>
        )}
      </AppCard>
    </Fragment>
  );
}

export default Copado;
Copado.propTypes = {
  writeable: PropTypes.bool,
};
