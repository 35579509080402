import {GLOBAL_CONFIGS, CONFIG_RESPONSE} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  globalConfigs: null,
  configResponse: null,
};

const Configure = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GLOBAL_CONFIGS:
      return {
        ...state,
        globalConfigs: action.payload,
      };
    case CONFIG_RESPONSE:
      return {
        ...state,
        configResponse: action.payload,
      };
    default:
      return state;
  }
};
export default Configure;
