import React, {Fragment, useState} from 'react';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import styles from './EditSfdcOffline.module.css';
import DownloadForOfflineSharpIcon from '@mui/icons-material/DownloadForOfflineSharp';
import {useDispatch} from 'react-redux';
import {updateOfflineOrg} from 'redux/actions';
import PropTypes from 'prop-types';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import {Button} from '@mui/material';

function EditSfdcOffline({org_id, name, handleModalClose, open}) {
  const [offlineName, setName] = useState(name);
  const dispatch = useDispatch();

  const nameChangeHandler = (event) => {
    setName(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(updateOfflineOrg({name: offlineName, org_id}));
    handleModalClose();
  };

  return (
    <Fragment>
      <ModalContainer
        handleModalClose={handleModalClose}
        title='Edit'
        open={open}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <DownloadForOfflineSharpIcon />
          <h4> Add Scratch (Offline) Workspace</h4>
        </div>

        <div style={{display: 'flex', justifyContent: 'center'}}>
          <form onSubmit={submitHandler}>
            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>
                Workspace Name
              </InputLabel>
              <TextField
                id='filled-basic'
                label='Name'
                variant='filled'
                placeholder='Name to Remember your Salesforce'
                name='offlineName'
                value={offlineName}
                onChange={nameChangeHandler}
                className={styles.inputTextfield}
                required
              ></TextField>
            </div>
            <div className={styles.btnContainer}>
           
              <Button
                onClick={handleModalClose}
                variant='contained'
                color='secondary'
              >
                Close
              </Button>
              <Button type='submit' variant='contained'>
                Update
              </Button>
            </div>
          </form>
        </div>
      </ModalContainer>
    </Fragment>
  );
}

export default EditSfdcOffline;

EditSfdcOffline.propTypes = {
  org_id: PropTypes.string,
  name: PropTypes.string,
  handleModalClose: PropTypes.func,
  open: PropTypes.bool,
};
