import {useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import styles from './TwofaModal.module.css';
import {Button} from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {useSelector} from 'react-redux';
import { verify2faOtpRequest } from 'redux/actions/Profile';
import {useDispatch} from 'react-redux';
import { successUpdate } from 'redux/actions/Profile';


function TwofaModal({
  open,
  handleClosefun,
}) {
  const generatedOtp = useSelector(({profile}) => profile.generatedOtp);
  const generatedOtpbase32 = useSelector(({profile}) => profile.generatedOtpbase32);
  const twofaEnabled = useSelector(({profile}) => profile.twofaEnabled);

  const [qrurl,setQrUrl]=useState("");
  const [base32Code,set32BaseCode]=useState("");
  const [token,setToken] = useState("");
  const dispatch= useDispatch();


  function enable2fa(){
    dispatch(verify2faOtpRequest(token));
  }

  useEffect(() =>{
if(twofaEnabled==true){
  dispatch(successUpdate());
  setToken("");
  handleClosefun();
  
}
  },[twofaEnabled]);
  
  
  useEffect(()=>{
    if(generatedOtp!=""){
      setQrUrl(generatedOtp);
      set32BaseCode(generatedOtpbase32);
    }
  },[generatedOtp]);

  
//console.log("url",qrurl);
  return (
      <Modal
    open={open}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
    size={"lg"}
    onClose={handleClosefun}
    style={{ overflow: "auto" }}
  >
    <div className={styles.modal}>
    <Typography variant="h3" className={styles.firstHeading} gutterBottom>
    Two-Factor Authentication (2FA)
      </Typography>
      <Divider className={styles.divider}/>
        <ol>
          <li>Install Microsoft Authenticator (IOS - Android) or Authy (IOS - Android). </li>
          <li>In the Authenticator app, select `+` icon </li>
          <li>Select `Scan a barcode (or QR code)` and use the phone`s camera to scan the barcode.</li>
        </ol>
        <Typography variant="h3" className={styles.primaryText} gutterBottom>
        Scan QR Code
      </Typography>
      <Divider className={styles.divider}/>
      <div className={styles.imgContainer}>
        <img className={styles.QrImg} src={qrurl}/>
        </div>
        <Typography variant="h3" className={styles.primaryText} gutterBottom>
        Or Enter Code into Your App
      </Typography>
      <Divider className={styles.divider}/>
      <Typography variant="body1" gutterBottom>
      SecurityKey: {base32Code}
      </Typography>
      <Typography variant="h3" className={styles.primaryText} gutterBottom>
      Verify Code
      </Typography>
      <Divider className={styles.divider}/>

      <Typography variant="body1" gutterBottom>
      For changing  the setting , please verify the authentication code
      </Typography>

        <TextField sx={{mt:3}} id="outlined-basic" placeholder='Enter Code' value={token} onChange={(e)=>setToken(e.target.value)} variant="outlined" />


      <div className={styles.buttons}>
    <Button onClick={()=>handleClosefun()} size="large" variant="outlined">Cancel</Button>

      <Button onClick={()=>enable2fa()} sx={{ml:3}} size="large" variant="contained">Enable 2FA</Button>
    </div>
    </div>
  </Modal>
  );
}

export default TwofaModal;

TwofaModal.propTypes = {
  open: PropTypes.bool,
  handleClosefun:PropTypes.func
};
