import React, {useEffect, Fragment} from 'react';
import FindingDetails from 'components/details/Details';
import {useDispatch} from 'react-redux';
import {onGetUsers} from 'redux/actions';
import {useSelector} from 'react-redux';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import {DETAILS_RESPONSE, GET_ISSUE_DETAIL} from 'shared/constants/ActionTypes';
import ModalChildrenMessage from 'components/modals/ModalChildrenMessage/ModalChildrenMessage';
import {checkOrgRole} from 'roles/Org';
import {useParams, useSearchParams} from 'react-router-dom';

function Details() {
  const dispatch = useDispatch();
  let {org_id} = useParams();
  let [queryParams] = useSearchParams();
  const isFindingsNew = queryParams.get('isFindingsNew');
  const detailsResponse = useSelector(({details}) => details.detailsResponse);
  const orgRoles = useSelector(({roles}) => roles.orgRoles);
  let selectedOrgRole = orgRoles?.find((role) => role.sfdcId === org_id);
  let selectedOrgRolePermissions = selectedOrgRole?.permissions;

  const detailsModalClose = () => {
    dispatch({type: DETAILS_RESPONSE, payload: undefined});
  };

  useEffect(() => {
    dispatch(onGetUsers());
    return () => {
      dispatch({type: GET_ISSUE_DETAIL, payload: null});
    };
  }, []);

  if(selectedOrgRolePermissions){
  var finding = checkOrgRole({
    object: 'finding',
    permissions: selectedOrgRolePermissions,
  });
}

  return (
    <Fragment>
      {finding?.read && <FindingDetails writeable={finding?.write} isFindingsNew = {isFindingsNew === 'true'} />}
      {detailsResponse != undefined && (
        <ModalContainer open={true}>
          <ModalChildrenMessage
            status={detailsResponse?.status}
            message={detailsResponse?.message}
            close='Close'
            handleModalClose={detailsModalClose}
          />
        </ModalContainer>
      )}
    </Fragment>
  );
}

export default Details;
