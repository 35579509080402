import React, {useRef} from 'react';
import Button from '@mui/material/Button';
import {Form, Formik} from 'formik';
import * as yup from 'yup';
import AppInfoView from '@crema/core/AppInfoView';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import IntlMessages from '@crema/utility/IntlMessages';
import {useIntl} from 'react-intl';
import AppTextField from '@crema/core/AppFormComponents/AppTextField';
import {useAuthMethod} from '@crema/utility/AuthHooks';
import {Fonts} from '../../../shared/constants/AppEnums';
import ReCAPTCHA from 'react-google-recaptcha';
import {useSearchParams} from 'react-router-dom';
import './SetPassword.css';

const validationSchema = yup.object({
  password: yup
    .string()
    .required(<IntlMessages id='validation.passwordRequired' />),
});

const setPasswordBtn = {
  minWidth: 160,
  fontWeight: Fonts.REGULAR,
  fontSize: 15,
  textTransform: 'capitalize',
  padding: '4px 16px 8px',
};

const SetPasswordJwt = () => {
  const {verifyPassword} = useAuthMethod();
  const {messages} = useIntl();
  const recaptchaRef = useRef();

  const [searchParams] = useSearchParams();

  return (
    <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
      <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', mb: 5}}>
        <Formik
          validateOnChange={true}
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (data, {setSubmitting, setFieldError}) => {
            const token = await recaptchaRef.current.executeAsync();

            if (data.password !== data.confirmPassword) {
              setFieldError('password', 'Passwords do not match');
              setFieldError('confirmPassword', 'Passwords do not match');
              setSubmitting(false);
              return;
            }

            setSubmitting(true);
            verifyPassword({
              emailtoken: await searchParams.get('emailtoken'),
              password: data.password,
              recaptchtoken: token,
            });
            setSubmitting(false);
          }}
        >
          {({isSubmitting, errors}) => (
            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
              <ReCAPTCHA
                ref={recaptchaRef}
                size='invisible'
                sitekey={window.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
              />
              <Box sx={{mb: {xs: 3, xl: 4}}}>
                <AppTextField
                  type='password'
                  placeholder={messages['common.password']}
                  label={<IntlMessages id='common.password' />}
                  name='password'
                  variant='outlined'
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                />
              </Box>
              <Box sx={{mb: {xs: 3, xl: 4}}}>
                <AppTextField
                  type='password'
                  placeholder= 'Confirm Password'
                  label='Confirm Password'
                  name='confirmPassword'
                  variant='outlined'
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword}
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                />
              </Box>

              <div>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={isSubmitting}
                  sx={setPasswordBtn}
                >
                  Set new Password
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Box>

      <Box
        sx={{
          color: 'grey.500',
        }}
      >
        <span style={{marginRight: 4}}>Already have an account?</span>
        <Box
          component='span'
          sx={{
            fontWeight: Fonts.MEDIUM,
            '& a': {
              color: (theme) => theme.palette.primary.main,
              textDecoration: 'none',
            },
          }}
        >
          <Link to='/signin'>Signin</Link>
        </Box>
      </Box>
      <AppInfoView />
    </Box>
  );
};

export default SetPasswordJwt;
