import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// import SubscriptionSetup from './Subscription/SubscriptionSetup';
import CompanySetup from './Company/CompanySetup';
import UserSetup from './User/UserSetup';
import ManageProfile from 'components/setup/profile/ManageProfile';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Logging from 'components/setup/Logging/Logging';
import Roles from 'components/setup/Roles/Roles';
import {onCheckIfAdmin, onGetUsers} from 'redux/actions';

const AppBarSetup = () => {
  let {selectedTab} = useParams();

  const navigate = useNavigate();
  const company = useSelector(({roles}) => roles.company);
  const user = useSelector(({roles}) => roles.user);
  const logs = useSelector(({roles}) => roles.logs);
  const isAdmin = useSelector(({authentication}) => authentication.isAdmin);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onCheckIfAdmin());
    dispatch(onGetUsers());
  }, []);


  let initialTabSelected = selectedTab
    ? selectedTab
    : user?.read
    ? 'user'
    : company?.read
    ? 'company'
    : logs?.read
    ? 'logs'
    : isAdmin
    ? 'roles'
    : 'profile';

  const [value, setValue] = React.useState(initialTabSelected);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const activeTab = searchParams.get('tab');
    if (activeTab) {
      setValue(activeTab);
    } else {
      handleChange(null, 'profile');
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/setup?tab=${newValue}`);
  };

  return (
    <Box sx={{width: '100%'}}>
      {
        <TabContext value={value}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabList onChange={handleChange} aria-label='Setup Tabs'>
              <Tab label='Profile' value='profile' />
              {user?.read && <Tab label='Users' value='user' />}
              {isAdmin && <Tab label='Roles' value='roles' />}
              {company?.read && <Tab label='Company' value='company' />}
              {logs?.read && <Tab label='Logging' value='logging' />}
            </TabList>
          </Box>

          <TabPanel value='profile'>
            <ManageProfile />
          </TabPanel>

          {user?.read && (
            <TabPanel value='user'>
              <UserSetup writeable={user?.write} />{' '}
            </TabPanel>
          )}

          <TabPanel value='roles'>
            <Roles />
          </TabPanel>

          {company?.read && (
            <TabPanel value='company'>
              <CompanySetup writeable={company?.write} />
            </TabPanel>
          )}
          {logs?.read && (
            <TabPanel value='logging'>
              <Logging />
            </TabPanel>
          )}
        </TabContext>
      }
    </Box>
  );
};

export default AppBarSetup;
