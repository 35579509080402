import React from 'react';
import styles from './ScanStatistics.module.css';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import PropTypes from 'prop-types';

const ComponentScanned = ({name}) => {
  return (
    <div className={styles.componentScanned}>
      <CloudQueueIcon color='primary' />
      <p>{name}</p>
    </div>
  );
};

export default ComponentScanned;

ComponentScanned.propTypes = {
  name: PropTypes.string,
};
