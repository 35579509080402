import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_SELECTED_ROW,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {
  GET_SCAN_BY_ID,
  TRENDING_SCAN_PER_ORG,
} from 'shared/constants/ActionTypes';

export const onGetScanById = ({scan_id, orgId}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`/getscanbyid/${scan_id}?orgId=${orgId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_SCAN_BY_ID,
            payload: data.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const onPostTrendingScansPerOrg = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/dashboard/trendingscansperorg`, {orgId: org_id})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: TRENDING_SCAN_PER_ORG,
            payload: data.data.result || [],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const setSelectedRow = (selectedRow) => {
  return (dispatch) => {
    dispatch({type: SET_SELECTED_ROW, payload: selectedRow});
  };
};
