import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  IS_API_IN_PROCESS,
  GITHUB_SECRET,
  GITHUB_INFO,
  GITLAB_INFO,
  AZURE_INFO,
  BITBUCKET_INFO,
  BITBUCKET_SECRET,
  AZURE_SECRET,
  GITLAB_SECRET,
  COPADO_ESSENTIAL_SECRET,
  GITLAB_VERIFIED,
  AZURE_VERIFIED,
  JIRA_OUTPUT_SFDX,
  DASHBOARD_RESPONSE,
  API_SECRET_TOKEN,
  API_ERROR,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {onGetSingleOrg} from './Dashboard';
import {defaultError} from 'shared/constants/AppConst';

// Github Secret

export const onPostGithubSecret = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/getGithubSecret`, {orgId: org_id})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GITHUB_SECRET,
            payload: data.data,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        // dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: API_ERROR, payload: error.response?.data?.message || error.message});
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPostGithubInfo = ({
  org_id,
  access_token,
  github_username,
  is_pull_request_enabled,
  repo_name,
  githubevents,
}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/githubinfo`, {
        org_id,
        access_token,
        github_username,
        is_pull_request_enabled,
        repo_name,
        githubevents,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(
            onGetGithubClientId({
              github_username,
              repo_name,
              access_token,
              repoId: data.data._id,
              org_id,
            }),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPutGithubInfo = ({
  org_id,
  access_token,
  github_username,
  is_pull_request_enabled,
  repo_name,
  githubevents,
  repo_id,
}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`/githubinfo/${repo_id}`, {
        org_id,
        access_token,
        github_username,
        is_pull_request_enabled,
        repo_name,
        repo_id,
        githubevents,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(
            onGetGithubClientId({
              github_username,
              repo_name,
              access_token,
              repoId: data.data._id,
              org_id,
            }),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onGetGithubClientId = ({
  github_username,
  repo_name,
  access_token,
  repoId,
  org_id,
}) => {
  return (dispatch) => {
    const githubclienturl = `https://api.github.com/repos/${github_username}/${repo_name}`;
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get(
        `/api/check_github_client_id?id=${btoa(repoId)}&githubclienturl=${btoa(
          githubclienturl,
        )}&token=${btoa(access_token)}&orgId=${org_id}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: 'Github Successfully Integrated!',
            },
          });
          dispatch(onPostGithubSecret({org_id}));
          dispatch(onGetSingleOrg({org_id}));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'fail',
              message: 'Something went wrong! Please try again',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'fail',
            message: 'Something went wrong! Please try again',
          },
        });
      });
  };
};

export const onDeleteGithubRepo = ({githubId, org_id}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .delete(`githubinfo/${githubId}?orgId=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: 'Github Repo Deleted!',
            },
          });
          dispatch(onPostGithubSecret({org_id}));
          dispatch(onGetSingleOrg({org_id}));
        } else {
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'fail',
              message: data.data.message,
            },
          });
          dispatch({
            type: FETCH_ERROR,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'fail',
            message: defaultError,
          },
        });
      });
  };
};


export const onGetGithubInfo = ({githubId, org_id}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`githubinfo/${githubId}?orgId=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          // dispatch({
          //   type: DASHBOARD_RESPONSE,
          //   payload: {
          //     status: 'success',
          //     message: 'Github Repo Deleted!',
          //   },
          // });
          // dispatch(onPostGithubSecret({org_id}));
          // dispatch(onGetSingleOrg({org_id}));
          dispatch({type: GITHUB_INFO,
                    payload: data.data});
        } else {
          // dispatch({
          //   type: DASHBOARD_RESPONSE,
          //   payload: {
          //     status: 'fail',
          //     message: data.data.message,
          //   },
          // });
          dispatch({
            type: FETCH_ERROR,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        // dispatch({
        //   type: DASHBOARD_RESPONSE,
        //   payload: {
        //     status: 'fail',
        //     message: defaultError,
        //   },
        // });
      });
  };
};


// Bitbucket

export const onPostBitbucketSecret = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/getBitbucketSecret`, {orgId: org_id})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: BITBUCKET_SECRET,
            payload: data.data,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: API_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPostBitbucketInfo = ({
  workspace_id,
  username,
  repo_name,
  is_pull_request_enabled,
  app_password,
  org_id,
}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/bitbucketInfo`, {
        workspace_id,
        username,
        repo_name,
        is_pull_request_enabled,
        app_password,
        org_id,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(
            onGetCheckBitbucketApp({
              app_password,
              username,
              workspace_id,
              repo_name,
              id: data.data._id,
              org_id,
            }),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPutBitbucketInfo = ({
  workspace_id,
  username,
  repo_name,
  is_pull_request_enabled,
  app_password,
  org_id,
  repo_id,
}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`/bitbucketInfo/${repo_id}`, {
        workspace_id,
        username,
        repo_name,
        is_pull_request_enabled,
        app_password,
        org_id,
        repo_id,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(
            onGetCheckBitbucketApp({
              app_password,
              username,
              workspace_id,
              repo_name,
              id: data.data._id,
              org_id,
            }),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onGetCheckBitbucketApp = ({
  app_password,
  username,
  workspace_id,
  repo_name,
  id,
  org_id,
}) => {
  return (dispatch) => {
    const bitbucketUrl = `https://api.bitbucket.org/2.0/repositories/${workspace_id}/${repo_name}`;
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get(
        `/api/checkBitbucketAppPassword?id=${btoa(id)}&bitbucketUrl=${btoa(
          bitbucketUrl,
        )}&username=${btoa(username)}&appPassword=${btoa(app_password)}&orgId=${org_id}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          if (data.data.message == 'success') {
            dispatch({
              type: DASHBOARD_RESPONSE,
              payload: {
                status: 'success',
                message: 'Bitbucket Successfully Configured!',
              },
            });
            dispatch(onGetSingleOrg({org_id}));
            dispatch(onPostBitbucketSecret({org_id}));
          } else {
            dispatch({
              type: DASHBOARD_RESPONSE,
              payload: {
                status: 'fail',
                message: defaultError,
              },
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'fail',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'fail',
            message: defaultError,
          },
        });
      });
  };
};

export const onDeleteBitbucketRepo = ({bitbucketId, org_id}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .delete(`bitbucketinfo/${bitbucketId}?orgId=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: 'Bitbucket Repo Deleted!',
            },
          });
          dispatch(onPostBitbucketSecret({org_id}));
          dispatch(onGetSingleOrg({org_id}));
        } else {
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'fail',
              message: data.data.message,
            },
          });
          dispatch({type: FETCH_ERROR});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'fail',
            message: defaultError,
          },
        });
      });
  };
};


export const onGetBitbucketInfo = ({bitbucketId, org_id}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`bitbucketinfo/${bitbucketId}?orgId=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          console.log(data);
          // dispatch({
          //   type: DASHBOARD_RESPONSE,
          //   payload: {
          //     status: 'success',
          //     message: 'Github Repo Deleted!',
          //   },
          // });
          // dispatch(onPostGithubSecret({org_id}));
          // dispatch(onGetSingleOrg({org_id}));
          dispatch({type: BITBUCKET_INFO,
                    payload: data.data});
        } else {
          // dispatch({
          //   type: DASHBOARD_RESPONSE,
          //   payload: {
          //     status: 'fail',
          //     message: data.data.message,
          //   },
          // });
          dispatch({
            type: FETCH_ERROR,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        // dispatch({
        //   type: DASHBOARD_RESPONSE,
        //   payload: {
        //     status: 'fail',
        //     message: defaultError,
        //   },
        // });
      });
  };
};


// Azure


export const onGetAzureInfo = ({azureId, org_id}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`azureinfo/${azureId}?orgId=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          console.log(data);
          // dispatch({
          //   type: DASHBOARD_RESPONSE,
          //   payload: {
          //     status: 'success',
          //     message: 'Github Repo Deleted!',
          //   },
          // });
          // dispatch(onPostGithubSecret({org_id}));
          // dispatch(onGetSingleOrg({org_id}));
          dispatch({type: AZURE_INFO,
                    payload: data.data});
        } else {
          // dispatch({
          //   type: DASHBOARD_RESPONSE,
          //   payload: {
          //     status: 'fail',
          //     message: data.data.message,
          //   },
          // });
          dispatch({
            type: FETCH_ERROR,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        // dispatch({
        //   type: DASHBOARD_RESPONSE,
        //   payload: {
        //     status: 'fail',
        //     message: defaultError,
        //   },
        // });
      });
  };
};


export const onPostAzureSecret = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/getAzureSecret`, {orgId: org_id})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: AZURE_SECRET,
            payload: data.data,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: API_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPostAzureInfo = ({
  azurePat,
  azureRepoName,
  azureUsername,
  azureProjectName,
  is_pull_request_enabled,
  org_id,
}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/saveAzureCredentials`, {
        azurePat,
        azureRepoName,
        azureUsername,
        azureProjectName,
        is_pull_request_enabled,
        orgId: org_id,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          if (data.data.message.toLowerCase() == 'success') {
            dispatch({type: AZURE_VERIFIED, payload: 'success'});
            dispatch(onPostAzureSecret({org_id}));
            dispatch({
              type: DASHBOARD_RESPONSE,
              payload: {
                status: 'success',
                message: 'Azure Integrated Successfully!',
              },
            });
            dispatch(onGetSingleOrg({org_id}));
          } else {
            dispatch({type: AZURE_VERIFIED, payload: 'fail'});
            let message = 'Something went Wrong! Please try again';
            if (data.data.message == 'UNAUTHORIZED') {
              message =
                'It appears you do not have access to the given Repo. Please make sure the Access Token is not expired, has required scopes and the organization name is correct.';
            } else if (
              data.data.message == 'NO_PROJECTS' ||
              data.data.message == 'ORGANIZATION_NOT_FOUND'
            ) {
              message =
                'Your organization could not be verified with Azure. Please check your organization name and try again.';
            }
            dispatch({
              type: DASHBOARD_RESPONSE,
              payload: {
                status: 'fail',
                message: message,
              },
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: AZURE_VERIFIED, payload: 'fail'});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({type: AZURE_VERIFIED, payload: 'fail'});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'success',
            message: defaultError,
          },
        });
      });
  };
};

export const onPostAzureDelete = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/doDeleteAzureRepoRequest`, {orgId: org_id})
      .then((data) => {
        if (data.status === 200 && data.data == 'done') {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(onPostAzureSecret({org_id}));
          dispatch(onGetSingleOrg({org_id}));
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: 'Azure Repo Delete!',
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'fail',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'fail',
            message: error.message,
          },
        });
      });
  };
};

// Gitlab

export const onGetGitlabInfo = ({gitlabId, org_id}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`gitlabinfo/${gitlabId}?orgId=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          console.log(data);
          // dispatch({
          //   type: DASHBOARD_RESPONSE,
          //   payload: {
          //     status: 'success',
          //     message: 'Github Repo Deleted!',
          //   },
          // });
          // dispatch(onPostGithubSecret({org_id}));
          // dispatch(onGetSingleOrg({org_id}));
          dispatch({type: GITLAB_INFO,
                    payload: data.data});
        } else {
          // dispatch({
          //   type: DASHBOARD_RESPONSE,
          //   payload: {
          //     status: 'fail',
          //     message: data.data.message,
          //   },
          // });
          dispatch({
            type: FETCH_ERROR,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        // dispatch({
        //   type: DASHBOARD_RESPONSE,
        //   payload: {
        //     status: 'fail',
        //     message: defaultError,
        //   },
        // });
      });
  };
};


export const onPostGitlabSecret = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/getGitlabSecret`, {orgId: org_id})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GITLAB_SECRET,
            payload: data.data,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: API_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPostGitlabInfo = ({
  org_id,
  gitlabUsername,
  gitlabPat,
  gitlabPatName,
  is_pull_request_enabled,
  gitlabRepoName,
}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/saveGitlabCredentials`, {
        orgId: org_id,
        gitlabUsername,
        gitlabPat,
        gitlabPatName,
        is_pull_request_enabled,
        gitlabRepoName,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          if (data.data.message == 'SUCCESS') {
            dispatch({type: GITLAB_VERIFIED, payload: 'success'});
            dispatch(onPostGitlabSecret({org_id}));
            dispatch({
              type: DASHBOARD_RESPONSE,
              payload: {
                status: 'success',
                message: 'Gitlab Integrated Successfully',
              },
            });
            dispatch(onGetSingleOrg({org_id}));
          } else {
            dispatch({type: GITLAB_VERIFIED, payload: 'fail'});
            let message = 'Something went Wrong! Please try again';
            if (data.data.message == 'UNAUTHORIZED') {
              message =
                'It appears you do not have access to the given Repo. Please make sure the Access Token is valid and has the required scopes.';
            } else if (data.data.message == 'NO_PROJECTS') {
              message =
                'It appears there are no projects assigned to this user. Please check the username and try again';
            }
            dispatch({
              type: DASHBOARD_RESPONSE,
              payload: {
                status: 'fail',
                message: message,
              },
            });
            dispatch({
              type: FETCH_ERROR,
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: GITLAB_VERIFIED, payload: 'fail'});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({type: GITLAB_VERIFIED, payload: 'fail'});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'success',
            message: defaultError,
          },
        });
      });
  };
};

export const onPostGitlabDelete = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/doDeleteGitlabRepoRequest`, {orgId: org_id})
      .then((data) => {
        if (data.status === 200 && data.data == 'done') {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: 'Gitlab Repo Deleted Successfully',
            },
          });
          dispatch(onPostGitlabSecret({org_id}));
          dispatch(onGetSingleOrg({org_id}));
        } else {
          dispatch({
            type: FETCH_ERROR,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'fail',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'fail',
            message: error.message,
          },
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

// Copado Essential Secret

export const onPostCopadoEssentialSecret = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/getCopadoSecret`, {orgId: org_id})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: COPADO_ESSENTIAL_SECRET,
            payload: data.data,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

// Jira

export const onPostJiraOutputSFDX = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/jiraOutputSFDX`, {orgId: org_id})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: JIRA_OUTPUT_SFDX, payload: data.data});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPostJiraOutputSFDXDelete = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/jiraOutputSFDXDeleteOrgSpecific`, {sfdcInfo: org_id})
      .then((data) => {
        if (data.status === 200 && data.data == 'success') {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: 'Success!',
            },
          });
          dispatch(onGetSingleOrg({org_id}));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'fail',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'fail',
            message: error.message,
          },
        });
      });
  };
};

/// new api token

export const onPostAPISecret = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/getAPISecret`, {orgId: org_id})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: API_SECRET_TOKEN,
            payload: data.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};
