import {
  GET_REPORTS_PAGE_COUNT,
  GET_REPORTS_DATA,
  GET_ALL_SCANS_DATA,
  GET_REPORTS_CUR_PAGE,
  POST_SCAN_DATA,
  POST_START_SCAN_DATA,
  GET_SCAN_STATS,
  GET_FINDINGS_DATA,
  POST_DOWNLOAD_REPORTS,
  DOWNLOAD_REPORTS_CUR_PAGE,
  LAST_SCAN_STATUS,
  LAST_COMPLETED_SCAN,
  TOTAL_SCANS,
} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  reportsData: null,
  allScansData: null,
  totalScans: null,
  reportsPageCount: 1,
  reportsCurPage: 1,
  postScanData: {},
  postStartScanData: {},
  getScanStats: null,
  getFindingsData: null,
  downloadableReports: null,
  downloadReportsCurPage: 1,
  lastScanStatus: null,
  lastCompletedScan: null,
};

const Reports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORTS_PAGE_COUNT:
      return {
        ...state,
        reportsPageCount: action.payload,
      };
    case GET_REPORTS_DATA:
      return {
        ...state,
        reportsData: action.payload,
      };
      case GET_ALL_SCANS_DATA:
      return {
        ...state,
        allScansData: action.payload,
      };
      case TOTAL_SCANS:
      return {
        ...state,
        totalScans: action.payload,
      };
    case GET_REPORTS_CUR_PAGE:
      return {
        ...state,
        reportsCurPage: action.payload,
      };
    case POST_SCAN_DATA:
      return {
        ...state,
        postScanData: action.payload,
      };
    case POST_START_SCAN_DATA:
      return {
        ...state,
        postStartScanData: action.payload,
      };
    case GET_SCAN_STATS:
      return {
        ...state,
        getScanStats: action.payload,
      };
    case GET_FINDINGS_DATA:
      return {
        ...state,
        getFindingsData: action.payload,
      };
    case POST_DOWNLOAD_REPORTS:
      return {
        ...state,
        downloadableReports: action.payload,
      };
    case DOWNLOAD_REPORTS_CUR_PAGE:
      return {
        ...state,
        downloadReportsCurPage: action.payload,
      };
    case LAST_SCAN_STATUS:
      return {
        ...state,
        lastScanStatus: action.payload,
      };
    case LAST_COMPLETED_SCAN:
      return {
        ...state,
        lastCompletedScan: action.payload,
      };

    default:
      return state;
  }
};
export default Reports;
