import {
  BITBUCKET_SECRET,
  GITHUB_SECRET,
  AZURE_SECRET,
  GITLAB_SECRET,
  COPADO_ESSENTIAL_SECRET,
  GITLAB_VERIFIED,
  AZURE_VERIFIED,
  JIRA_OUTPUT_SFDX,
  DASHBOARD_RESPONSE,
  API_SECRET_TOKEN,
  GITHUB_INFO,
  GITLAB_INFO,
  BITBUCKET_INFO,
  AZURE_INFO,
  API_ERROR,
} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  githubSecret: null,
  bitbucketSecret: null,
  azureSecret: null,
  azureVerified: null,
  gitlabSecret: null,
  gitlabVerified: false,
  copadoEssentialSecret: null,
  jiraOutputSfdx: null,
  dashboardResponse: null,
  apiSecretToken: null,
  githubinfo: null,
  bitbucketinfo:null,
  gitlabinfo: null,
  azureinfo:null,
  apiError: null,
};

const Integration = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GITHUB_SECRET:
      return {
        ...state,
        githubSecret: action.payload,
        apiError: null,
      };
    case BITBUCKET_SECRET:
      return {
        ...state,
        bitbucketSecret: action.payload,
        apiError: null,
      };
    case AZURE_SECRET:
      return {
        ...state,
        azureSecret: action.payload,
        apiError: null,
      };

    case GITLAB_SECRET:
      return {
        ...state,
        gitlabSecret: action.payload,
        apiError: null,
      };
    case COPADO_ESSENTIAL_SECRET:
      return {
        ...state,
        copadoEssentialSecret: action.payload,
      };
    case GITLAB_VERIFIED:
      return {
        ...state,
        gitlabVerified: action.payload,
      };
    case AZURE_VERIFIED:
      return {
        ...state,
        azureVerified: action.payload,
      };
    case JIRA_OUTPUT_SFDX:
      return {
        ...state,
        jiraOutputSfdx: action.payload,
      };
    case DASHBOARD_RESPONSE:
      return {
        ...state,
        dashboardResponse: action.payload,
      };
    case API_SECRET_TOKEN:
      return {
        ...state,
        apiSecretToken: action.payload,
      };
    case GITHUB_INFO:
      return {
        ...state,
        githubinfo: action.payload,
      };
    case BITBUCKET_INFO:
      return {
        ...state,
        bitbucketinfo: action.payload,
      };
    case GITLAB_INFO:
      return {
        ...state,
        gitlabinfo: action.payload,
      };
    case AZURE_INFO:
    return {
      ...state,
      azureinfo: action.payload,
    };
    case API_ERROR:
    return {
      ...state,
      apiError: action.payload,
    };
    default:
      return state;
  }
};
export default Integration;
