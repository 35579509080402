import React, {useEffect} from 'react';
import AppBarDashboard from 'components/dashboard/AppBarDashboard';
import {Fragment} from 'react';
import {onGetSingleOrg} from 'redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {
  DASHBOARD_RESPONSE,
  SETUP_RESPONSE,
  SINGLE_ORG,
  SCAN_ASSIGN_RESPONSE,
} from 'shared/constants/ActionTypes';
 import {onPostTrendingScansPerOrg} from 'redux/actions/ScanStatistics';
import {onGetUsers} from 'redux/actions';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import ModalChildrenMessage from 'components/modals/ModalChildrenMessage/ModalChildrenMessage';
import PlaceHolderDiv from 'components/PlaceHolderDiv/PlaceHolderDiv';
import SwitchOrg from 'components/dashboard/SwitchOrg/SwitchOrg';
import styles from './Dashboard.module.css';
import {AppInfoView} from '@crema';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function Dashboard() {
  let {org_id} = useParams();
  const dispatch = useDispatch();
  const setupResponse = useSelector(({setup}) => setup.setupResponse);
  const dashboardResponse = useSelector(
    ({dashboard}) => dashboard.dashboardResponse,
  );
  const subscriptionActive = useSelector(
    ({common}) => common.subscriptionActive,
  );
  const scanAssignResponse = useSelector(({dashboard}) => dashboard.scanAssignResponse);

  useEffect(() => {
    dispatch(onGetUsers());
    dispatch(onGetSingleOrg({org_id}));
    dispatch(onPostTrendingScansPerOrg({org_id}));
    return () => {
      dispatch({type: SINGLE_ORG, payload: null});
    };
  }, [dispatch, org_id]);

  const setupModalClose = () => {
    dispatch({type: SETUP_RESPONSE, payload: undefined});
  };
  const dashboardModalClose = () => {
    dispatch({type: DASHBOARD_RESPONSE, payload: null});
  };

  const handleModalCloseT = () => {
    dispatch({type: SCAN_ASSIGN_RESPONSE, payload: null});
  };

  return (
    <Fragment>
      {subscriptionActive == false ? (
        <PlaceHolderDiv
          status='permissiondenied'
          message='Valid subscription is required to view results. Please contact sales@digitsec.com to purchase a license.'
        />
      ) : (
        <div className={styles.dashboardContainer}>
          <div className={styles.switchOrgContainer}>
            <SwitchOrg />
          </div>
          <AppBarDashboard />
        </div>
      )}
      {setupResponse != null && (
        <ModalContainer open={true}>
          <ModalChildrenMessage
            status={setupResponse?.status}
            message={setupResponse?.message}
            close='Close'
            handleModalClose={setupModalClose}
          />
        </ModalContainer>
      )}
      {dashboardResponse != null && (
        <ModalContainer open={true} handleModalClose={dashboardModalClose}>
          <ModalChildrenMessage
            status={dashboardResponse.status}
            close='Close'
            handleModalClose={dashboardModalClose}
            message={dashboardResponse.message}
          />
        </ModalContainer>
      )}
       {scanAssignResponse != null && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={handleModalCloseT}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MuiAlert
            onClose={handleModalCloseT}
            elevation={6}
            variant='filled'
            severity='error'
            sx={{width: '100%'}}
          >
            {scanAssignResponse?.message}
          </MuiAlert>
        </Snackbar>
      )}
      <AppInfoView />
    </Fragment>
  );
}

export default Dashboard;

Dashboard.propTypes = {
  props: PropTypes.any,
  match: PropTypes.any,
};
