import {
  GET_GLOBAL_PERMISSION,
  GET_ALL_ROLES,
  GET_ALL_PERMISSIONS,
  SFDC_PERMISSION,
  SFCC_PERMISSION,
  SUBSCRIPTION_PERMISSION,
  COMPANY_PERMISSION,
  USER_PERMISSION,
  ORG_ROLES,
  GLOBAL_ROLE,
  CONFIGURE_PERMISSION,
  GLOBAL_SECURITYGATE,
  GLOBAL_SCAN_SETTINGS,
  GLOBAL_INTEGRATIONS,
  GLOBAL_SCHEDULER,
  GLOBAL_TAGS,
  LOGS,
} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  getGlobalPermission: null,
  getAllRoles: null,
  getAllPermissions: null,
  sfdc: null,
  sfcc: null,
  subscription: null,
  company: null,
  user: null,
  orgRoles: null,
  configure: null,
  globalSecurityGate: null,
  globalScanSettings: null,
  globalIntegrations: null,
  globalScheduler: null,
  globalTags: null,
  logs: null,
  globalRole: null,
};

const Roles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GLOBAL_PERMISSION:
      return {
        ...state,
        getGlobalPermission: action.payload,
      };
    case SFDC_PERMISSION:
      return {
        ...state,
        sfdc: action.payload,
      };
    case SFCC_PERMISSION:
      return {
        ...state,
        sfcc: action.payload,
      };
    case GET_ALL_ROLES:
      return {
        ...state,
        getAllRoles: action.payload,
      };
      case GET_ALL_PERMISSIONS:
      return {
        ...state,
        getAllPermissions: action.payload,
      };
    case SUBSCRIPTION_PERMISSION:
      return {
        ...state,
        subscription: action.payload,
      };
    case COMPANY_PERMISSION:
      return {
        ...state,
        company: action.payload,
      };
    case USER_PERMISSION:
      return {
        ...state,
        user: action.payload,
      };
    case ORG_ROLES:
      return {
        ...state,
        orgRoles: action.payload,
      };
    case GLOBAL_ROLE:
      return {
        ...state,
        globalRole: action.payload,
      };
    case CONFIGURE_PERMISSION:
      return {
        ...state,
        configure: action.payload,
      };
    case GLOBAL_SECURITYGATE:
      return {
        ...state,
        globalSecurityGate: action.payload,
      };
    case GLOBAL_INTEGRATIONS:
      return {
        ...state,
        globalIntegrations: action.payload,
      };
    case GLOBAL_SCAN_SETTINGS:
      return {
        ...state,
        globalScanSettings: action.payload,
      };
    case GLOBAL_SCHEDULER:
    return {
      ...state,
      globalScheduler: action.payload,
    };
    case GLOBAL_TAGS:
      return {
        ...state,
        globalTags: action.payload,
      };
    case LOGS:
      return {
        ...state,
        logs: action.payload,
      };
    default:
      return state;
  }
};
export default Roles;
