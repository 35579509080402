import {authRouteConfig} from './auth';
import {initialUrl} from 'shared/constants/AppConst';
import {Navigate} from 'react-router-dom';
import Error403 from './errorPages/Error403';
import React from 'react';
import {errorPagesConfigs} from './errorPages';
import {extraPagesConfigs} from './extraPages';
import {DashboardPagesConfigs} from './dashboard';
import {setupPagesConfigs} from './setup';
import {SalesforcePagesConfigs} from './salesforce';
import {detailPagesConfigs} from './details';
import {successPagesConfigs} from './success';
import {reportPagesConfigs} from './report';
import {configurePagesConfigs} from './configure';
import {helpPagesConfigs} from './help';
import {authenticatePagesConfigs} from './authenticate';
import {scanStatisticsPagesConfigs} from './scanstatistics';
import {scanOldLinkRedirect} from './scan';
localStorage.setItem(
  'returnUrl',
  location.pathname.replace('/app', '') + location.search,
);

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...DashboardPagesConfigs,
    ...extraPagesConfigs,
    ...setupPagesConfigs,
    ...SalesforcePagesConfigs,
    ...detailPagesConfigs,
    ...successPagesConfigs,
    ...reportPagesConfigs,
    ...configurePagesConfigs,
    ...helpPagesConfigs,
    ...authenticatePagesConfigs,
    ...scanStatisticsPagesConfigs,
    ...scanOldLinkRedirect,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      element: <Navigate to='/error-pages/error-404' />,
    },
  ]),
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
