import React, {useCallback, useState, useEffect} from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from 'recharts';
import {useSelector} from 'react-redux';
import {Fragment} from 'react';
import {useParams} from 'react-router-dom';
import {ScanColors} from 'shared/constants/AppEnums';
import {AppCard} from '@crema';

const data = [
  {name: 'Critical', value: 0, color: ScanColors.critical},
  {name: 'High', value: 0, color: ScanColors.high},
  {name: 'Medium', value: 0, color: ScanColors.medium},
  {name: 'Low', value: 0, color: ScanColors.low},
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#333'
      >{`${payload.name} ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill='#999'
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default function SeverityOverview() {
  const scanById = useSelector(({scanStatistics}) => scanStatistics.scanById);

  let {org_id} = useParams();

  useEffect(() => {
    scanById &&
      ((data[0].value = scanById.Critical),
      (data[1].value = scanById.High),
      (data[2].value = scanById.Medium),
      (data[3].value = scanById.Low));

    return () => {};
  }, [scanById, org_id]);

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );
  return (
    <Fragment>
      {scanById && (
        <>
          <AppCard
            sxStyle={{height: 500}}
            title='Vulnerability Severity Overview'
          >
            <ResponsiveContainer height='100%' width='100%'>
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  innerRadius={110}
                  outerRadius={130}
                  fill='#8884d8'
                  paddingAngle={5}
                  dataKey='value'
                  onMouseEnter={onPieEnter}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </AppCard>
        </>
      )}
    </Fragment>
  );
}
