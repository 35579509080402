import React, {useEffect, useState} from 'react';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import './Logging.css';
import {TextField} from '@mui/material';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import Button from '@mui/material/Button';
// import FilterListIcon from '@mui/icons-material/FilterList';
import {DataGrid} from '@mui/x-data-grid';
// import {Box /*IconButton*/} from '@mui/material';
// import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useSelector, useDispatch} from 'react-redux';
import {getData} from 'redux/actions/Logging';
import {
  gridClasses,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import CloudIcon from '@mui/icons-material/Cloud';
import LoginIcon from '@mui/icons-material/Login';
import WifiTetheringErrorIcon from '@mui/icons-material/WifiTetheringError';
import TaskIcon from '@mui/icons-material/Task';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import {useSearchParams} from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import {FETCH_ERROR} from 'shared/constants/ActionTypes';
import PlaceHolderDiv from 'components/PlaceHolderDiv/PlaceHolderDiv';
import {checkPermissions} from '@crema/utility/helper/Utils';
import {AppLoaderLogging} from '@crema';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import copy from 'clipboard-copy';
import {useNavigate} from 'react-router-dom';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Tooltip from '@mui/material/Tooltip';
import ChevronLeftSharpIcon from '@mui/icons-material/ChevronLeftSharp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport printOptions={{disableToolbarButton: true}} />
      </GridToolbarContainer>
    </GridToolbarContainer>
  );
}

const buttonStyle = {
  marginRight: '10px',
  minWidth: '32px',
  padding: '0px',
  height: '32px',
};

const Logging = () => {
  const columns = [
    // {field: 'objectId', headerName: 'Object Id', width: 250},
    {
      field: 'object',
      headerName: 'Scan',
      width: 100,
      hide: true,
      valueFormatter: ({value}) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
    },
    {field: 'originator', headerName: 'Originator', width: 200, hide: true},
    {
      field: 'level',
      headerName: 'Level',
      width: 100,
      renderCell: (params) => {
        const level = params.value;

        if (level === 'info') {
          return (
            <div className='level-containers-info'>
              <Tooltip title='Info'>
                <TaskIcon style={{color: 'var(--info-color)'}} />
              </Tooltip>
            </div>
          );
        }
        if (level === 'low') {
          return (
            <div className='level-containers-low'>
              <Tooltip title='Low'>
                <MenuBookIcon style={{color: 'var(--low-color)'}} />
              </Tooltip>
            </div>
          );
        }
        if (level === 'medium') {
          return (
            <div className='level-containers-m'>
              <Tooltip title='Medium'>
                <WarningIcon style={{color: 'var(--medium-color)'}} />
              </Tooltip>
            </div>
          );
        }
        if (level === 'high') {
          return (
            <div className='level-containers-h'>
              <Tooltip title='High'>
                <ErrorIcon style={{color: 'var(--high-color)'}} />
              </Tooltip>
            </div>
          );
        }
        if (level === 'critical') {
          return (
            <div className='level-containers-c'>
              <Tooltip title='Critical'>
                <CancelIcon style={{color: 'var(--critical-color)'}} />
              </Tooltip>
            </div>
          );
        }
      },
    },
    {
      field: 'date_created',
      headerName: 'Date',
      width: 170,
      valueFormatter: ({value}) => {
        return new Date(value).toLocaleString();
      },
    },
    {
      field: 'summary',
      headerName: 'Summary',
      width: 400,
      renderCell: (params) => {
        return <div style={{whiteSpace: 'pre-wrap'}}>{params.value}</div>;
      },
    },
    {
      field: 'detailed_trace',
      headerName: 'Detailed Trace',
      width: 250,
      flex: 1,
      renderCell: (params) => {
        const content = params.value || '';
        const maxLength = 150;

        if (content.length > maxLength) {
          const shortContent = content.substring(0, maxLength);

          const handleCopy = () => {
            if (content) {
              copy(content);
            }
          };

          return (
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              {shortContent}
              {/* <span
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  background: 'white',
                  padding: '5px',
                }}
              >
                <button>Show More</button>
              </span> */}
              <div>
                <p
                  style={{
                    textDecoration: 'underline',
                    fontSize: '11px',
                    color: 'blue',
                    cursor: 'pointer',
                  }}
                  aria-describedby={id}
                  type='button'
                  onClick={handleClick}
                >
                  See More
                </p>
                <div>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    BackdropProps={{invisible: true}}
                  >
                    <div className='modal-content'>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '65%',
                          transform: 'translate(-50%, -50%)',
                          maxWidth: 400,
                          maxHeight: 200,
                          overflow: 'auto',
                          border: '1px solid #f5e7e7',
                          p: 1,
                          bgcolor: 'background.paper',
                          minWidth: 'auto',
                        }}
                      >
                        <div className='buttons-wrapper'>
                          <Button className='btn-sr' onClick={handleClose}>
                            <CloseIcon></CloseIcon>
                          </Button>
                          <Button className='btn-sr' onClick={handleCopy}>
                            <ContentCopyIcon></ContentCopyIcon>
                          </Button>
                        </div>
                        <div className='modal-content'>{content}</div>
                      </Box>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          );
        }

        return (
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'auto',
              overflowY: 'auto !important',
            }}
          >
            {content}
          </div>
        );
      },
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const {userInfo} = useSelector(({common}) => common);

  //Search bar
  const dispatch = useDispatch();
  useEffect(() => {
    if (object && objectId) {
      searchEntries(true);
    }
  }, [dispatch]);

  const [workspaceUrl, setWorkspaceUrl] = useState('');

  useEffect(() => {
    const currentURL = window.location.href;
    // console.log('Current URL:', currentURL);

    if (currentURL.includes('/setup?tab=logging&object=scheduler&objectId=')) {
      setWorkspaceUrl('logging');
    } else if (currentURL.includes('/setup?tab=logging&object=')) {
      setWorkspaceUrl('workspace');
    }
  }, [dispatch]);
  // console.log('Workspace type: ', workspaceUrl);
  //Get search params
  const [searchParams] = useSearchParams();
  const objectFromSearch = searchParams.get('object');
  const objectIdFromSearch = searchParams.get('objectId');
  const originatorFromSearch = searchParams.get('originator');
  const summaryFromSearch = searchParams.get('summary');

  // App states
  const [searchText] = useState('');
  const [enableNext, setEnableNext] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState('50');
  const [queryString, setQueryString] = useState('');
  // const [paginationQueryString] = useState(`&page=${page}&pageSize=${pageSize}`);
  const [object, setObject] = useState(objectFromSearch || '');
  const [objectId, setObjectId] = useState(objectIdFromSearch || '');
  const [originator, setOriginator] = useState(originatorFromSearch || '');
  const [summary, setSummary] = useState(summaryFromSearch || '');
  const [level, setLevel] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [SelectedDateTo, setSelectedDateTo] = React.useState(null);
  // const [alertOpen, setAlertOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  // loads fields with info locally
  useEffect(() => {
    const storedObject = localStorage.getItem('object');
    const storedObjectId = localStorage.getItem('objectId');
    const storedOriginator = localStorage.getItem('originator');
    const storedSummary = localStorage.getItem('summary');
    const storedSelectedDate = localStorage.getItem('selectedDate');
    const storedSelectedDateTo = localStorage.getItem('selectedDateTo');
    const storedLevel = localStorage.getItem('level');

    if (storedObject) {
      setObject(storedObject);
    }
    if (storedObjectId) {
      setObjectId(storedObjectId);
    }
    if (storedOriginator) {
      setOriginator(storedOriginator);
    }
    if (storedSummary) {
      setSummary(storedSummary);
    }
    if (storedSelectedDate) {
      setSelectedDate(new Date(storedSelectedDate));
    }
    if (storedSelectedDateTo) {
      setSelectedDateTo(new Date(storedSelectedDateTo));
    }
    if (storedLevel) {
      setLevel(storedLevel);
    }
  }, []);

  useEffect(() => {
    const storedObject = localStorage.getItem('object');
    const storedObjectId = localStorage.getItem('objectId');
    const storedOriginator = localStorage.getItem('originator');
    const storedSummary = localStorage.getItem('summary');
    const storedSelectedDate = localStorage.getItem('selectedDate');
    const storedSelectedDateTo = localStorage.getItem('SelectedDateTo');
    const storedLevel = localStorage.getItem('level');

    if (storedObject) setObject(storedObject);
    if (storedObjectId) setObjectId(storedObjectId);
    if (storedOriginator) setOriginator(storedOriginator);
    if (storedSummary) setSummary(storedSummary);
    if (storedSelectedDate) setSelectedDate(new Date(storedSelectedDate));
    if (storedSelectedDateTo) setSelectedDateTo(new Date(storedSelectedDateTo));
    if (storedLevel) setLevel(storedLevel);

    const handleUnload = () => {
      if (
        localStorage.getItem('object') ||
        localStorage.getItem('objectId') ||
        localStorage.getItem('originator') ||
        localStorage.getItem('summary') ||
        localStorage.getItem('selectedDate') ||
        localStorage.getItem('SelectedDateTo') ||
        localStorage.getItem('level')
      ) {
        localStorage.clear();
      }
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [
    object,
    objectId,
    originator,
    summary,
    selectedDate,
    SelectedDateTo,
    level,
  ]);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const id = open ? 'simple-popper' : undefined;

  //Date pickers - set Min and Max date
  const maxDate = new Date(); // Current date
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 90);

  const data = useSelector(({logging}) => logging.loggingData);

  const navigate = useNavigate();

  const [orgId, setOrgId] = useState(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const orgIdFromURL = urlSearchParams.get('orgId');

    if (orgIdFromURL) {
      setOrgId(orgIdFromURL);
    }
  }, []);

  useEffect(() => {
    // console.log('data responded', data);
    if (
      data.records?.length &&
      (data.records.length === 25 ||
        data.records.length === 50 ||
        data.records.length === 100)
    ) {
      setEnableNext(true);
    } else {
      setEnableNext(false);
    }
    if (
      data &&
      data.object_status === 'pending' &&
      data.object_status !== null
    ) {
      setIsLoading(true);
      const delay = 30000;

      const timeoutId = setTimeout(() => {
        navigate(`/setup?tab=logging&object=scan&objectId=${objectId}`);
        searchEntries(true);
      }, delay);

      return () => {
        clearTimeout(timeoutId);
        setIsLoading(false);
      };
    }
  }, [data, objectId, navigate]);

  // const objectStatus = data?.object_status;

  // console.log('Data:', data);
  // console.log('Object Status:', objectStatus);

  const permissions =
    userInfo?.rolesAndPermissionsObject?.global?.permissions?.filter(
      (item) => item.object === 'logs',
    );
  let readLogsEntries = false;
  if (permissions && permissions?.length !== 0) {
    readLogsEntries = checkPermissions(permissions);
  }

  function handlePrevious() {
    // console.log(page);
    if (page == 0) {
      return true;
    }
    const previousPage = page - 1;

    const query = `${queryString}&page=${previousPage}&pageSize=${pageSize}`;
    setPage(previousPage);
    dispatch(getData(query));
  }
  function handleNext() {
    // console.log('handleNext clicked');
    const nextPage = page + 1;
    const query = `${queryString}&page=${nextPage}&pageSize=${pageSize}`;
    setPage(nextPage);
    dispatch(getData(query));
  }

  function searchEntries(refresh = false) {
    setIsLoading(true);
    setPage(0);
    let searchPage = 0;
    let filters = {};
    if (object !== '') filters.object = object;
    if (objectId !== '') filters.objectId = objectId;
    if (originator !== '') filters.originator = originator;
    if (summary !== '') filters.summary = summary;
    if (level !== '') filters.level = level;
    if (selectedDate !== null) {
      let fromDate = selectedDate;
      fromDate.setHours(0, 0, 0, 0);
      filters.date_start = fromDate.toUTCString();

      if (SelectedDateTo !== null) {
        let toDate = SelectedDateTo;
        toDate.setHours(23, 59, 59, 999);
        filters.date_end = toDate.toUTCString();

        if (toDate <= fromDate) {
          dispatch({
            type: FETCH_ERROR,
            payload: 'To date must be greater or equal to From date',
          });
          setIsLoading(false);
          return false;
        }
      }
    }

    if (Object.keys(filters).length === 0) {
      dispatch({type: FETCH_ERROR, payload: 'Select At Least One Filter'});
      setIsLoading(false);
      return false;
    }

    filters.refresh = refresh;
    const searchQueryString = new URLSearchParams(filters).toString();

    //validation for object field required
    if (object === '') {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Object is required',
      });
      setIsLoading(false);
      return false;
    }
    filters.object = object;

    dispatch(
      getData(searchQueryString + `&page=${searchPage}&pageSize=${pageSize}`),
    );

    if (object === 'scan' && originator !== '') {
      setIsLoading(true);
    }

    setQueryString(searchQueryString);
    setIsLoading(false);
  }
  // const handleSearch = (e) => {
  //   setSearchText(e.target.value);
  // };

  const filteredRows = data.records
    ? data.records.filter((row) =>
        Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : [];

  return (
    <>
      {readLogsEntries && (
        <div className='main'>
          <div>
            {workspaceUrl === 'workspace' && (
              <Button
                variant='contained'
                onClick={() => {
                  const currentURL = window.location.href;

                  if (currentURL.includes('orgId')) {
                    navigate(`/dashboard/${orgId}?tab=dashboard`);
                  } else {
                    navigate(-1);
                  }
                }}
                startIcon={<ChevronLeftSharpIcon />}
              >
                Back to workspace
              </Button>
            )}
            {workspaceUrl === 'logging' && (
              <Button
                variant='contained'
                onClick={() => navigate(-1)}
                startIcon={<ChevronLeftSharpIcon />}
              >
                Back to scheduler
              </Button>
            )}
          </div>
          <br></br>
          <div className='filters-input'>
            <FormControl id='object-list' className='item' sx={{width: 300}}>
              <InputLabel id='demo-simple-select-label' required>
                Object
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={object}
                label='Object'
                onChange={(e) => setObject(e.target.value)}
              >
                <MenuItem value=''>&nbsp;</MenuItem>
                <MenuItem value='scan'>
                  <WifiTetheringErrorIcon />
                  &nbsp;Scan
                </MenuItem>
                <MenuItem value='scheduler'>
                  <EventAvailableIcon />
                  &nbsp;Schedule
                </MenuItem>
                <MenuItem value='org' disabled>
                  <CloudIcon />
                  &nbsp;Org
                </MenuItem>
                <MenuItem value='login' disabled>
                  <LoginIcon />
                  &nbsp;Login
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl className='item' sx={{width: 300}}>
              {/* <InputLabel id='demo-simple-select-label'>Object</InputLabel> */}
              <TextField
                id='demo-simple-select'
                value={objectId}
                label='Object Id'
                onChange={(e) => setObjectId(e.target.value)}
              ></TextField>
            </FormControl>
            <FormControl className='item' sx={{width: 300}}>
              {/* <InputLabel id='demo-simple-select-label'>Object</InputLabel> */}
              <TextField
                id='demo-simple-select'
                value={originator}
                label='Originator'
                onChange={(e) => setOriginator(e.target.value)}
              ></TextField>
            </FormControl>
            <FormControl className='item' sx={{width: 300}}>
              {/* <InputLabel id='demo-simple-select-label'>Object</InputLabel> */}
              <TextField
                id='demo-simple-select'
                value={summary}
                label='Summary'
                onChange={(e) => setSummary(e.target.value)}
              ></TextField>
            </FormControl>

            <LocalizationProvider className='item' dateAdapter={AdapterDateFns}>
              <DatePicker
                label='From'
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                renderInput={(params) => <TextField {...params} />}
                minDate={minDate}
                maxDate={maxDate}
              />
            </LocalizationProvider>

            <LocalizationProvider className='item' dateAdapter={AdapterDateFns}>
              <DatePicker
                label='To'
                value={SelectedDateTo}
                onChange={(date) => setSelectedDateTo(date)}
                renderInput={(params) => <TextField {...params} />}
                minDate={minDate}
                maxDate={maxDate}
              />
            </LocalizationProvider>

            <FormControl className='item' sx={{width: 300}}>
              <InputLabel id='demo-simple-select-label'>Level</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={level}
                label='Level'
                onChange={(e) => setLevel(e.target.value)}
              >
                <MenuItem value=''>&nbsp;</MenuItem>
                <MenuItem value='info'>
                  <TaskIcon
                    sx={{
                      color: 'var(--info-color)',
                    }}
                  ></TaskIcon>
                  &nbsp;Info
                </MenuItem>
                <MenuItem value='low'>
                  <MenuBookIcon
                    sx={{
                      color: 'var(--low-color)',
                    }}
                  ></MenuBookIcon>
                  &nbsp;Low
                </MenuItem>
                <MenuItem value='medium'>
                  <WarningIcon
                    sx={{
                      color: 'var(--medium-color)',
                    }}
                  ></WarningIcon>
                  &nbsp;Medium
                </MenuItem>
                <MenuItem value='high'>
                  <ErrorIcon
                    sx={{
                      color: 'var(--high-color)',
                    }}
                  ></ErrorIcon>
                  &nbsp;High
                </MenuItem>
                <MenuItem value='critical'>
                  <CancelIcon
                    sx={{
                      color: 'var(--critical-color)',
                    }}
                  ></CancelIcon>
                  &nbsp;Critical
                </MenuItem>
              </Select>
            </FormControl>
            <Button
              className='search-btn'
              variant='contained'
              onClick={searchEntries}
            >
              <SearchIcon />
            </Button>
            {/* <Autocomplete
            size='small'
            disablePortal
            id='combo-box-demo'
            options={severity}
            sx={{width: 300}}
            renderInput={(params) => <TextField {...params} label='User' />}
          /> */}
          </div>

          {/* <div className='btn-container'>
          <Button variant='text'>
            <FilterListIcon />
            Add Filters
          </Button>
        </div> */}

          <div className='dt-container'>
            {/* <div className='filters-input'>
            <Autocomplete
              size='small'
              disablePortal
              id='combo-box-demo'
              options={options1}
              sx={{width: 300}}
              renderInput={(params) => <TextField {...params} label='Severity' />}
            />

            <Autocomplete
              size='small'
              disablePortal
              id='combo-box-demo'
              options={options2}
              sx={{width: 300}}
              renderInput={(params) => <TextField {...params} label='User' />}
            />
          </div> */}

            {/* <div className='btn-container'>
            <Button variant='text'>
              <FilterListIcon />
              Add Filters
            </Button>
          </div> */}

            <div className='dt-container'>
              <div style={{height: 500, width: '100%', overflow: 'auto'}}>
                <DataGrid
                  id='logs-dt'
                  rows={filteredRows}
                  getRowId={(row) => row._id}
                  hideFooterPagination={true}
                  hideFooter={true}
                  hideFooterSelectedRowCount={true}
                  columns={columns}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  sx={{
                    '& .MuiDataGrid-cell': {
                      lineHeight: 'unset !important',
                      maxHeight: 'none !important',
                      whiteSpace: 'normal',
                      justifyContent: 'center !important',
                      textAlign: 'center !important',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className='pagination-container'>
            <Button
              style={buttonStyle}
              variant='outlined'
              onClick={handlePrevious}
              disabled={page === 0}
            >
              <ChevronLeftIcon></ChevronLeftIcon>
            </Button>
            <Button
              style={buttonStyle}
              variant='outlined'
              disabled={!enableNext}
            >
              <ChevronRightIcon onClick={handleNext}></ChevronRightIcon>
            </Button>
          </div>
          <div className='sf-container'>
            <FormControl sx={{s: 1, minWidth: 80}}>
              <Select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(e.target.value);
                }}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            Records per page:&nbsp;
          </div>
          <div>{isLoading && <AppLoaderLogging />}</div>
        </div>
      )}
      {!readLogsEntries && (
        <PlaceHolderDiv
          status='permissiondenied'
          message={`You do not have permissions to view Configurations`}
        />
      )}
    </>
  );
};

export default Logging;
