import React, {useState} from 'react';
import styles from './Integrations.module.css';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import {AppCard} from '@crema';
import Jira from 'assets/images/configure/jira.svg';
import Jenkins from 'assets/images/configure/jenkins.svg';
import Copado from 'assets/images/configure/copado.svg';
import Saml from 'assets/images/configure/saml.svg';
import JiraIntegration from './JiraIntegration/JiraIntegration';
import CopadoIntegration from './CopadoIntegration/CopadoIntegration';
import SSOIntegration from './SSOIntegration/SSOIntegration';
import {onPostUpdateConfigs, onPutDeleteSSO} from 'redux/actions';
import {useDispatch} from 'react-redux';
import {Switch} from '@mui/material';
import {useSelector} from 'react-redux';

const SingleIntegration = ({children, imgSrc, name}) => {
  return (
    <div className={styles.SingleIntegration}>
      <div className={styles.singleIntegrationInfo}>
        <img src={imgSrc} className={styles.intLogo} />
        <h4>{name}</h4>
      </div>
      {children}
    </div>
  );
};

SingleIntegration.propTypes = {
  children: PropTypes.node,
  imgSrc: PropTypes.string,
  name: PropTypes.string,
};

const Integrations = ({writeable}) => {
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState({
    jiraModal: false,
    copadoModal: false,
    ssoModal: false,
  });
  const jiraModalOpen = () => {
    setShowModal((cur) => {
      return {
        ...cur,
        jiraModal: true,
      };
    });
  };
  const showModalClose = () => {
    setShowModal({jiraModal: false, copadoModal: false, ssoModal: false});
  };
  const copadoModalOpen = () => {
    setShowModal((cur) => {
      return {
        ...cur,
        copadoModal: true,
      };
    });
  };
  const ssoModalOpen = () => {
    setShowModal((cur) => {
      return {
        ...cur,
        ssoModal: true,
      };
    });
  };

  const onDeleteSSO = () => {
    dispatch(onPutDeleteSSO());
  };

  const jiraUpdateHandler = (event) => {
    if (event.target.checked) {
      setShowModal((cur) => {
        return {
          ...cur,
          jiraModal: true,
        };
      });
    } else {
      let configsState = {
        jira_output: false,
        configuration_testing: globalConfigs?.configuration_testing,
        send_results_to_all_users: globalConfigs?.send_results_to_all_users,
        data_persistent_attacks: globalConfigs?.data_persistent_attacks,
        runtime_testing: globalConfigs?.runtime_testing,
        static_code: globalConfigs?.static_code,
        auto_scanning: globalConfigs?.auto_scanning,
        software_composition_analysis:
          globalConfigs?.software_composition_analysis,
        third_party_packages: globalConfigs?.third_party_packages,
      };
      dispatch(onPostUpdateConfigs({configsState}));
    }
  };

  return (
    <AppCard sxStyle={{maxWidth: 900}} title='Integrations'>
      <div className={styles.IntegrationContainer}>
        <SingleIntegration imgSrc={Jira} name='Jira'>
          <div className={styles.integrationActionContainer}>
            <Switch
              checked={globalConfigs?.jira_output || false}
              onChange={jiraUpdateHandler}
              disabled={!writeable}
            />
            <Button
              onClick={jiraModalOpen}
              variant='outlined'
              disabled={!writeable}
            >
              Configure
            </Button>
          </div>
        </SingleIntegration>
        <SingleIntegration imgSrc={Jenkins} name='Jenkins'>
          <div className={styles.integrationActionContainer}>
            <a
              href='https://www.dropbox.com/scl/fi/gli2g2lkplqppp4ybbu4v/S4Cli.zip?rlkey=7ok4z3k7512vf4m0eijnaspou&dl=0'
              target='_blank'
              rel='noopener noreferrer'
              download='S4CLI.zip'
              className={styles.downloadLink}
            >
              <Button variant='outlined' disabled={!writeable}>
                Download
              </Button>
            </a>
          </div>
        </SingleIntegration>
        <SingleIntegration imgSrc={Copado} name='Copado'>
          <div className={styles.integrationActionContainer}>
            <Button
              onClick={copadoModalOpen}
              variant='outlined'
              disabled={!writeable}
            >
              Configure
            </Button>
          </div>
        </SingleIntegration>
        <SingleIntegration imgSrc={Saml} name='Set Up Single Sign-On'>
          <div className={styles.integrationActionContainer}>
            <Button
              onClick={onDeleteSSO}
              variant='contained'
              className={styles.resetSSOIntegration}
              disabled={!writeable}
            >
              Reset SSO Integration
            </Button>
            <Button
              onClick={ssoModalOpen}
              variant='outlined'
              disabled={!writeable}
            >
              Configure
            </Button>
          </div>
        </SingleIntegration>
      </div>

      {showModal.jiraModal && (
        <JiraIntegration jiraModalClose={showModalClose} />
      )}
      {showModal.copadoModal && (
        <CopadoIntegration copadoModalClose={showModalClose} />
      )}
      {showModal.ssoModal && <SSOIntegration SSOModalClose={showModalClose} />}
    </AppCard>
  );
};

export default Integrations;

Integrations.propTypes = {
  writeable: PropTypes.bool,
};
