import React, {Fragment, useState, useEffect} from 'react';
import AppCard from '@crema/core/AppCard';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import {Button} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {onPostJiraSFDXUpdate} from 'redux/actions';
import {useParams} from 'react-router-dom';
import styles from '../Integration.module.css';
import {onPostJiraOutputSFDXDelete} from 'redux/actions';
import PropTypes from 'prop-types';

const Jira = ({writeable}) => {
  let {org_id} = useParams();
  const singleOrg = useSelector(({dashboard}) => dashboard.singleOrg);
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);
  const dispatch = useDispatch();
  const [jiraForm, setJiraForm] = useState({project_id: '', project_key: ''});

  const onInputChange = (event) => {
    setJiraForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  useEffect(() => {
    if (singleOrg) {
      setJiraForm({
        project_id: singleOrg?.project_id || '',
        project_key: singleOrg?.project_key || '',
      });
    }
  }, [singleOrg]);

  const onJiraSubmit = () => {
    dispatch(
      onPostJiraSFDXUpdate({
        project_id: jiraForm.project_id,
        project_key: jiraForm.project_key,
        sfdcInfo: org_id,
      }),
    );
  };

  const deleteHandler = () => {
    dispatch(onPostJiraOutputSFDXDelete({org_id}));
  };

  return (
    <Fragment>
      <AppCard sxStyle={{}} title='Jira'>
        {globalConfigs?.jira_output && (
          <form>
            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>
                Project Title
              </InputLabel>
              <TextField
                id='project_id'
                name='project_id'
                label='Project Title'
                variant='outlined'
                className={styles.inputField}
                value={jiraForm.project_id}
                onChange={onInputChange}
                disabled={!writeable}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>Project Key</InputLabel>
              <TextField
                id='project_key'
                name='project_key'
                label='Project Key'
                variant='outlined'
                className={styles.inputField}
                value={jiraForm.project_key}
                onChange={onInputChange}
                disabled={!writeable}
              />
            </div>
            <div className={styles.formButtonContainer}>
              <Button
                variant='contained'
                onClick={onJiraSubmit}
                disabled={!writeable}
              >
                Verify and Save
              </Button>
              {singleOrg.project_id.length > 0 &&
                singleOrg.project_key.length > 0 && (
                  <Button
                    variant='contained'
                    onClick={deleteHandler}
                    disabled={!writeable}
                  >
                    Delete
                  </Button>
                )}
            </div>
          </form>
        )}
        {!globalConfigs?.jira_output && (
          <div className={styles.jiraNotConfigure}>
            <h3>
              Please connect to a Jira instance first on the intergrations tab
              of&nbsp;
              {writeable ? <Link to='/configure'>Configure</Link> : 'Configure'}
              &nbsp;page
            </h3>
          </div>
        )}
      </AppCard>
    </Fragment>
  );
};

export default Jira;
Jira.propTypes = {
  writeable: PropTypes.bool,
};
