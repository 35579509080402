import React, {useState, useEffect} from 'react';
import ModalContainer from '../../../modals/ModalContainer/ModalContainer';
import TagForm from './TagForm/TagForm';
import {Button} from '@mui/material';
import styles from './AddTags.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {onPutTagDetail, onPutTagDetailScan} from 'redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import StyleIcon from '@mui/icons-material/Style';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Header = () => {
  return (
    <div className={styles.headerTag}>
      <StyleIcon />
      <h3>Add Tag</h3>
    </div>
  );
};

const AddTags = ({
  scanId,
  tagsModal,
  tagsModalClose,
  _id,
  detailsTabChange,
  reportsCurPage,
}) => {
  let {report_id, issue_id, org_id} = useParams();
  const dispatch = useDispatch();
  const issuesCurPage = useSelector(({dashboard}) => dashboard.issuesCurPage);
  const currOrgName = useSelector(({dashboard}) => dashboard.curOrgName);

  const pageSize = useSelector(({dashboard}) => dashboard.pageSize);
  const curIssuesFilterState = useSelector(
    ({dashboard}) => dashboard.curIssuesFilterState,
  );

  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    return () => {
      setAllTags([]);
    };
  }, []);

  const addTagHandler = () => {
    setAllTags((cur) => {
      return [
        ...cur,
        {
          addLink: true,
          fieldName: '',
          fieldValue: '',
          href: '',
        },
      ];
    });
  };

  const onCheckedChange = (event, index) => {
    setAllTags((cur) => {
      let updateTag = [...cur];
      updateTag[index][event.target.name] = event.target.checked;
      return [...updateTag];
    });
  };

  const onTextValueChange = (event, index) => {
    setAllTags((cur) => {
      let updateTag = [...cur];
      updateTag[index][event.target.name] = event.target.value;
      return [...updateTag];
    });
  };

  const removeTag = (index) => {
    setAllTags((cur) => {
      let updateTag = [...cur];
      updateTag.splice(index, 1);
      return [...updateTag];
    });
  };

  const [openSnackbarURL, setOpenSnackbarURL] = useState(false);
  const [snackbarMessageURL, setSnackbarMessageURL] = useState('');

  const handleSnackbarCloseURL = () => {
    setOpenSnackbarURL(false);
  };

  const onSubmitTag = (event) => {
    event.preventDefault();

    const isValidHref = allTags.every((item) => {
      if (item.href) {
        try {
          new URL(item.href);
          return true;
        } catch (error) {
          return false;
        }
      }
      return true;
    });
    
    let url = window.location.href;
    
    if (!isValidHref && url.includes('/details')) {
      setOpenSnackbarURL(true);
      setSnackbarMessageURL("Link cannot be validated. Please check the web address and try again");
      return;
    }
    
  
    const trimTags = allTags.map((item) => {
      return {
        fieldName: item.fieldName,
        fieldValue: item.fieldValue,
        href: item.href ? item.href : undefined,
      };
    });
    // dispatch(onPutTagDetail(_id, tagsPayload, cur_issue_id, issuesCurPage));
    if (event.target.baseURI.includes('details')) {
      let tagsPayload = {customFields: trimTags, _scanID: report_id};
      dispatch(
        onPutTagDetail({
          _id,
          tagsPayload,
          report_id,
          issue_id,
          issuesCurPage,
          org_id,
          org_name: currOrgName,
          pageSize,
          curIssuesFilterState
        }),
        detailsTabChange(event, 0),
      );
    } else {
      let tagsPayload = {customFields: trimTags, _scanID: scanId};
      dispatch(
        onPutTagDetailScan({tagsPayload, scanId, reportsCurPage, org_id}),
      );
    }
    closeAndRemoveTag();
    // detailsTabChange(event, 0);
  };

  const closeAndRemoveTag = () => {
    setAllTags([]);
    tagsModalClose();
  };

  return (
    <>
    <ModalContainer open={tagsModal} handleModalClose={closeAndRemoveTag}>
      <Header />
      <div className={styles.addBtnContainer}>
        <Button
          className={styles.closeBtn}
          variant='contained'
          color='secondary'
          onClick={tagsModalClose}
        >
          Close
        </Button>
        <Button
          className={styles.closeBtn}
          variant='contained'
          color='primary'
          onClick={addTagHandler}
        >
          Add Tag
        </Button>
      </div>
      <Box
        component='form'
        // noValidate
        autoComplete='off'
        onSubmit={onSubmitTag}
      >
        <div style={{maxHeight: '300px', overflowY: 'auto'}}>
          <TableContainer component={Paper}>
            <Table
              sx={{minWidth: 650}}
              aria-label='simple table'
              stickyHeader={true}
            >
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Add Link</TableCell>
                  <TableCell align='left'>Tag</TableCell>
                  <TableCell align='left'>Value</TableCell>
                  <TableCell align='left'>URL</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allTags?.map((item, index) => {
                  return (
                    <TagForm
                      key={index}
                      addLink={item.addLink}
                      fieldName={item.fieldName}
                      fieldValue={item.fieldValue}
                      href={item.href}
                      onCheckedChange={onCheckedChange}
                      onTextValueChange={onTextValueChange}
                      index={index}
                      removeTag={removeTag}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={styles.submitBtnContainer}>
          <Button
            variant='contained'
            type='submit'
            disabled={allTags.length == 0}
          >
            Submit
          </Button>
        </div>
      </Box>
      <Snackbar
          open={openSnackbarURL}
          onClose={handleSnackbarCloseURL}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={handleSnackbarCloseURL}
          >
            {snackbarMessageURL}
          </MuiAlert>
        </Snackbar>
    </ModalContainer>
    </>
  );
};

export default AddTags;

AddTags.propTypes = {
  tagsModalClose: PropTypes.func,
  tagsModal: PropTypes.bool,
  _id: PropTypes.string,
  detailsTabChange: PropTypes.func,
  tagsModalOpen: PropTypes.func,
  scanId: PropTypes.string,
  reportsCurPage: PropTypes.number,
};
