import React, {useState, useEffect} from 'react';
import ModalContainer from '../../../modals/ModalContainer/ModalContainer';
import {Button} from '@mui/material';
import styles from '../../../details/CommentSection/Add Tags/AddTags.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import StyleIcon from '@mui/icons-material/Style';
import OrgRoleForm from './OrgRoleForm/OrgRoleForm';
import {useSelector, useDispatch} from 'react-redux';
import {assignRole} from 'redux/actions/Roles';
import {onPostEditUserSfdc} from 'redux/actions';
import {useParams} from 'react-router-dom';

const Header = () => {
  return (
    <div className={styles.headerTag}>
      <StyleIcon />
      <h3>Assign ORG Roles</h3>
    </div>
  );
};

const AssignRoles = ({assignModal, assignModalClose, userId}) => {
  let {org_id} = useParams();
  const [allAssignRoles, setAllAssignRoles] = useState([]);
  // const sfdcInfo = useSelector(({salesforce}) => salesforce.sfdcInfo);
  const allRoles = useSelector(({allRoles}) => allRoles.roles);
  const dispatch = useDispatch();

  const clonedOrgRoles = allRoles.filter((item) => item.type === 'ORG_LEVEL');
  const getAllOrgs = useSelector(({setup}) => setup.getAllOrgs);
  const [assignedOrg, setAssignedOrg] = useState([]);

  useEffect(() => {
    return () => {
      setAllAssignRoles([]);
    };
  }, []);

  const addTagHandler = () => {
    setAllAssignRoles((cur) => {
      return [
        ...cur,
        {
          sfdc: '',
          orgRole: '',
        },
      ];
    });
  };

  const onSelectChange = (event, index) => {
    setAllAssignRoles((cur) => {
      let updateTag = [...cur];
      updateTag[index][event.target.name] = event.target.value;
      return [...updateTag];
    });
  };

  const onOrgSelectChange = (event, sfdc) => {
    setAssignedOrg((cur) => {
      const orgName = sfdc.find(
        (org) => org?._id === event?.target?.value,
      )?.name;
      return [...cur, `${orgName}/*/*/${event?.target?.value}`];
    });
  };

  const removeAssignRole = (index) => {
    setAllAssignRoles((cur) => {
      let updateTag = [...cur];
      updateTag.splice(index, 1);
      return [...updateTag];
    });
  };

  const onSubmitTag = (event) => {
    event.preventDefault();
    let trimTags = allAssignRoles.map((item) => {
      return {
        sfdcId: item.sfdc,
        roleId: item.orgRole,
      };
    });
    const obj = {
      method: 'assign',
      orgs: trimTags,
      flag: 'ORG_LEVEL',
      userId: userId,
      orgId: org_id,
    };
    dispatch(assignRole(obj));
    dispatch(onPostEditUserSfdc({userId, orgName: assignedOrg, org_id:org_id}));
    assignModalClose();
  };

  const closeAndRemoveAssginRole = () => {
    setAllAssignRoles([]);
    assignModalClose();
  };

  return (
    <ModalContainer
      open={assignModal}
      handleModalClose={closeAndRemoveAssginRole}
    >
      <Header />
      <div className={styles.addBtnContainer}>
        <Button
          className={styles.closeBtn}
          variant='contained'
          color='primary'
          onClick={addTagHandler}
        >
          Add Org Role
        </Button>
      </div>
      <Box
        component='form'
        // noValidate
        autoComplete='off'
        onSubmit={onSubmitTag}
      >
        <TableContainer
          component={Paper}
          sx={{maxHeight: 500, overflow: 'auto'}}
        >
          <Table
            sx={{minWidth: 650}}
            aria-label='simple table'
            stickyHeader={true}
          >
            <TableHead>
              <TableRow>
                <TableCell align='left'>Org List</TableCell>
                <TableCell align='left'>Org Roles</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAssignRoles?.map((item, index) => {
                return (
                  <OrgRoleForm
                    key={index}
                    sfdc={item.sfdc}
                    orgRole={item.orgRole}
                    onSelectChange={onSelectChange}
                    index={index}
                    removeAssignRole={removeAssignRole}
                    sfdcInfo={getAllOrgs}
                    clonedOrgRoles={clonedOrgRoles}
                    onOrgSelectChange={onOrgSelectChange}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.submitBtnContainer}>
          <Button
            variant='contained'
            type='submit'
            disabled={allAssignRoles.length == 0}
          >
            Submit
          </Button>
          <Button
            className={styles.closeBtn}
            variant='contained'
            color='secondary'
            onClick={closeAndRemoveAssginRole}
          >
            Close
          </Button>
        </div>
      </Box>
    </ModalContainer>
  );
};

export default AssignRoles;

AssignRoles.propTypes = {
  assignModalClose: PropTypes.func,
  assignModal: PropTypes.bool,
  userId: PropTypes.string,
};
