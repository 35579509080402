import React from 'react';
import PropTypes from 'prop-types';
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import styles from './ModalChildrenMessage.module.css';
import {Button} from '@mui/material';

const ModalChildrenMessage = ({status, message, close, handleModalClose}) => {
  return (
    <div className={styles.modalChildrenMessage}>
      {status != undefined && status == 'success' ? (
        <DoneAllSharpIcon sx={{fontSize: 100}} color='primary' />
      ) : (
        <ErrorOutlineSharpIcon sx={{fontSize: 100}} color='secondary' />
      )}

      {message != undefined && <h2 className={styles.message}>{message}</h2>}

      {close && (
        <Button
          variant='contained'
          onClick={handleModalClose}
          color={status == 'success' ? 'primary' : 'secondary'}
        >
          {close}
        </Button>
      )}
    </div>
  );
};

ModalChildrenMessage.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  close: PropTypes.string,
  handleModalClose: PropTypes.func,
};

export default ModalChildrenMessage;
