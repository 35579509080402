import React, {useState} from 'react';
import Button from '@mui/material/Button';
import {Checkbox} from '@mui/material';
import {Form, Formik} from 'formik';
import AppInfoView from '@crema/core/AppInfoView';
import Box from '@mui/material/Box';
import IntlMessages from '@crema/utility/IntlMessages';
import AppTextField from '@crema/core/AppFormComponents/AppTextField';
import {useAuthMethod} from '@crema/utility/AuthHooks';
import {Fonts} from '../../../shared/constants/AppEnums';
import {Link} from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ReCAPTCHA from 'react-google-recaptcha';
import {useRef} from 'react';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import Terms from '../Terms/Terms';

const nameContainer = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
  flexWrap: 'wrap',
};

const SignupJwtAuth = () => {
  const {signUpUser} = useAuthMethod();
  const [role, setRole] = useState('');
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const [termsModal, setTermsModal] = useState(false);
  const termsModalOpen = () => {
    setTermsModal(true);
  };
  const termsModalClose = () => {
    setTermsModal(false);
  };
  const recaptchaRef = useRef();

  return (
    <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
      <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', mb: 5}}>
        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: '',
            company: '',
          }}
          onSubmit={async (data, {setSubmitting}) => {
            setSubmitting(true);
            const token = await recaptchaRef.current.executeAsync();
            // recaptchaRef.current.reset();
            signUpUser({
              email: data.email,
              password: data.password,
              firstname: data.firstname,
              lastname: data.lastname,
              role: role,
              company: data.company,
              recaptchtoken: token,
              terms: true,
            });
            setSubmitting(false);
          }}
        >
          {({isSubmitting}) => (
            <Form style={{textAlign: 'left'}} autoComplete='off'>
              <ReCAPTCHA
                ref={recaptchaRef}
                size='invisible'
                sitekey={window.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
              />
              <div style={nameContainer}>
                <Box sx={{mb: {xs: 4, xl: 5}}}>
                  <AppTextField
                    required
                    label='First Name'
                    name='firstname'
                    variant='outlined'
                    sx={{
                      '& .MuiInputBase-input': {
                        width: 150,
                        fontSize: 14,
                      },
                    }}
                  />
                </Box>

                <Box sx={{mb: {xs: 4, xl: 5}}}>
                  <AppTextField
                    required
                    label='Last Name'
                    name='lastname'
                    variant='outlined'
                    sx={{
                      '& .MuiInputBase-input': {
                        width: 150,
                        fontSize: 14,
                      },
                    }}
                  />
                </Box>
              </div>

              <Box sx={{mb: {xs: 4, xl: 5}}}>
                <AppTextField
                  required
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  label='Email'
                  name='email'
                  variant='outlined'
                  type='email'
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                />
              </Box>

              <Box sx={{mb: {xs: 4, xl: 5}}}>
                <AppTextField
                  required
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  label='Password'
                  name='password'
                  type='password'
                  variant='outlined'
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                />
              </Box>

              <Box sx={{mb: {xs: 4, xl: 5}}}>
                <FormControl variant='outlined' sx={{width: '100%'}}>
                  <InputLabel id='role-select'>Role</InputLabel>
                  <Select
                    required
                    labelId='role-select'
                    id='role'
                    label='Role'
                    name='role'
                    value={role}
                    onChange={handleRoleChange}
                  >
                    <MenuItem value='Salesforce_Developer'>
                      Salesforce Developer
                    </MenuItem>
                    <MenuItem value='Salesforce_Admin'>
                      Salesforce Admin
                    </MenuItem>
                    <MenuItem value='Salesforce_Architect'>
                      Salesforce Architect
                    </MenuItem>
                    <MenuItem value='Salesforce_Consultant'>
                      Salesforce Consultant
                    </MenuItem>
                    <MenuItem value='Security_Professional'>
                      Security Professional
                    </MenuItem>
                    <MenuItem value='Privacy_Professional'>
                      Compliance Professional
                    </MenuItem>
                    <MenuItem value='Executive'>C Level Executive</MenuItem>
                    <MenuItem value='Other'>Other</MenuItem>
                    <MenuItem value='Not_Say'>Rather not say</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{mb: {xs: 4, xl: 5}}}>
                <AppTextField
                  required
                  label='Company'
                  name='company'
                  variant='outlined'
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: 14,
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  mb: {xs: 3, xl: 4},
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    required
                    sx={{
                      ml: -3,
                    }}
                  />
                  <Box
                    component='span'
                    sx={{
                      mr: 2,
                      color: 'grey.500',
                    }}
                  >
                    <IntlMessages id='common.iAgreeTo' />
                  </Box>
                </Box>
                <Box
                  component='button'
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: 'pointer',
                    border: 'none',
                    background: 'transparent',
                  }}
                  onClick={termsModalOpen}
                  type='button'
                >
                  <IntlMessages id='common.termConditions' />
                </Box>
              </Box>

              <div>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting}
                  sx={{
                    minWidth: 160,
                    fontWeight: Fonts.REGULAR,
                    fontSize: 16,
                    textTransform: 'capitalize',
                    padding: '4px 16px 8px',
                  }}
                  type='submit'
                >
                  <IntlMessages id='common.signup' />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
      <Box
        sx={{
          color: 'grey.500',
        }}
      >
        <span style={{marginRight: 4}}>
          <IntlMessages id='common.alreadyHaveAccount' />
        </span>
        <Box
          component='span'
          sx={{
            fontWeight: Fonts.MEDIUM,
            '& a': {
              color: (theme) => theme.palette.primary.main,
              textDecoration: 'none',
            },
          }}
        >
          <Link to='/signIn'>
            <IntlMessages id='common.signIn' />
          </Link>
        </Box>
      </Box>

      <ModalContainer
        open={termsModal}
        handleModalClose={termsModalClose}
        proceedButtonText='Close'
        handleModalProceed={termsModalClose}
      >
        <Terms />
      </ModalContainer>
      <AppInfoView />
    </Box>
  );
};

export default SignupJwtAuth;
