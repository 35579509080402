import React, {useState} from 'react';
import Box from '@mui/material/Box';
import AppCard from '@crema/core/AppCard';
import PropTypes from 'prop-types';
import styles from './Profile.module.css';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import {Button} from '@mui/material';
import GroupRemoveSharpIcon from '@mui/icons-material/GroupRemoveSharp';
import {onPostEditUserSfdc} from 'redux/actions';
import Tooltip from '@mui/material/Tooltip';
import {Link} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import AddUser from 'components/setup/User/AddUser';
import {useSelector} from 'react-redux';
import {FETCH_ERROR} from 'shared/constants/ActionTypes';

const profileCard = {
  minWidth: 500,
  maxWidth: 580,
  minHeight: 250,
  marginTop: 10,
  boxShadow:
    ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexWrap: 'wrap',
};

const removeIcon = {
  fontSize: 90,
};
const Profile = ({user, /*userwriteable,*/ writeable}) => {
  const loggedUser = useSelector(({common}) => common.userInfo);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const allRoles = useSelector(({roles}) => roles.getAllRoles);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let options1 = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  let options = {timeStyle: 'long', hour12: true};
  // const [editEnable, setEditEnable] = useState(true);

  let {org_id} = useParams();

  const dispatch = useDispatch();

  // function editProfile() {
  //   setEditEnable((cur) => !cur);
  // }

  const [removeUserModal, setRemoveUserModal] = useState(false);
  const removeUserModalOpen = () => {
    if (loggedUser.usr._id === user._id) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Logged in user cannot delete their own user',
      });
    } else {
      setRemoveUserModal(true);
    }
  };
  const removeUserModalClose = () => {
    setRemoveUserModal(false);
  };

  const [editUserModal, setEditUserModal] = useState(false);
  const editUserModalClose = () => {
    setEditUserModal(false);
  };
  const editUserModalOpen = () => {
    // if (loggedUser.usr._id === user._id) {
    //   dispatch({
    //     type: FETCH_ERROR,
    //     payload: 'Logged in user cannot edit their own user',
    //   });
    // } else {
      setEditUserModal(true);
    // }
  };

  const removeUser = async ({user}) => {
    let orgAssigned = [];
    await user.salesforceOrgs?.map((singleOrg) => {
      singleOrg.access == true &&
        singleOrg.sfdcId != org_id &&
        orgAssigned.push(`${singleOrg.sfdc}/*/*/${singleOrg.sfdcId}`);
    });
    dispatch(
      onPostEditUserSfdc({
        userId: user._id,
        orgName: orgAssigned,
        org_id,
        isUserDelete: true,
      }),
    );
    removeUserModalClose();
  };

  var role = '';
  var orgRole = '';
  var orgRoleId = user.salesforceOrgs?.find(
    (item) => item.sfdcId === org_id && item.access,
  );
  if (orgRoleId.roleId) {
    orgRole = allRoles?.find(
      (item) => item.type === 'ORG_LEVEL' && item._id === orgRoleId.roleId,
    );
  }
  if (user.roleId) {
    role = allRoles?.find(
      (item) => item.type === 'GLOBAL' && user.roleId === item._id,
    );
  } else if (user.role === 'admin' && user.permissions === 'write') {
    role = allRoles?.find((item) => item.name === 'Admin');
  } else if (user.role === 'user' && user.permissions === 'write') {
    role = allRoles?.find((item) => item.name === 'Environment Manager');
  } else if (user.role === 'user' && user.permissions === 'read') {
    role = allRoles?.find((item) => item.name === 'Environment User Read Only');
  }
  return (
    <div className={styles.teamProfileContainer}>
      <AppCard
        sxStyle={profileCard}
        className={styles.profileCard}
        contentStyle={{paddingLeft: '30px'}}
      >
        {/* <Box
          sx={{
            mb: 8,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <div className={styles.profileImage}>
            {user.email.toString().charAt(0).toUpperCase()}
          </div>
        </Box> */}
        <Box
          sx={{position: 'relative', display: 'flex', justifyContent: 'end'}}
        >
          <Button
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreHorizIcon
              sx={{fontSize: 30, margin: 0, padding: '0 !important'}}
              color='primary'
            />
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
          >
            <MenuItem disabled={!writeable} onClick={editUserModalOpen}>
              &nbsp;Edit
            </MenuItem>
            <Divider sx={{my: 0.5}} />
            <MenuItem disabled={!writeable} onClick={removeUserModalOpen}>
              Delete
            </MenuItem>
          </Menu>
        </Box>

        {/* <Link
              onClick={removeUserModalOpen}
              className={styles.routeLink}
              title='Click to View Details'
            >
              {/* <Box sx={{maxWidth: 170}}>
                <img
                  src={props.data.isSfcc == true ? sfcc : salesforceOrg}
                  style={{minHeight: 90, minWidth: 90}}
                  alt='Profile Pic'
                />
              </Box> }
            </Link> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            // alignItems: 'center',
            flexWrap: 'wrap',
            // textAlign: 'center'
          }}
        >
          <div className={styles.lastLogin}>
            Username:
            <p className={styles.username}>
              {user.email ? user.email : 'Not Available'}
            </p>
          </div>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            // alignItems: 'center',
            flexWrap: 'wrap',
            // textAlign: 'center'
          }}
        >
          <div className={styles.lastLogin}>
            Environment Role:
            <p className={styles.role}>{role ? role?.name : 'Not Assigned'}</p>
          </div>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            // alignItems: 'center',
            flexWrap: 'wrap',
            // textAlign: 'center'
          }}
        >
          <div className={styles.lastLogin}>
            Workspace Role:
            <p className={styles.role}>
              {orgRole ? orgRole?.name : 'Not Assigned'}
            </p>
          </div>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            // alignItems: 'center',
            flexWrap: 'wrap',
            // textAlign: 'center'
          }}
        >
          <div className={styles.lastLogin}>
            Last Login:
            <p className={styles.lastLoginDate}>
              {user.last_login
                ? new Date(user.last_login).toLocaleString('en-us', options1) +
                  ' ' +
                  new Date(user.last_login).toLocaleString('en-us', options)
                : 'Not Found'}
            </p>
          </div>
        </Box>

        <Box>
          <div className={styles.adminInformation}>
            <Link to=''>
              <Tooltip
                title={user?.name ? user?.name : user?.email ? user?.email : ''}
              >
                <Avatar
                  style={{
                    alignSelf: 'end',
                    float: 'right',
                    margin: '4px',
                    padding: 0,
                    backgroundColor: '#' + Math.floor(Math.random() * 255),
                  }}
                >
                  {(user?.name?.split(' ')[0]?.split('')[0]
                    ? user?.name?.split(' ')[0]?.split('')[0]?.toUpperCase()
                    : user?.email?.split('@')[0]?.split('')[0]
                    ? user?.email?.split('@')[0]?.split('')[0]?.toUpperCase()
                    : '') +
                    (user?.name?.split(' ')[1]?.split('')[0]
                      ? user?.name?.split(' ')[1]?.split('')[0]?.toUpperCase()
                      : '')}
                </Avatar>
              </Tooltip>
            </Link>
          </div>
        </Box>

        {/* <Box sx={userInfoContainer} className={styles.userInfoContainer}>
          <Box
            sx={{
              px: {xs: 2, xl: 4},
            }}
          >
            <Box
              component='span'
              sx={{
                display: 'block',
                mb: 2,
                fontWeight: Fonts.BOLD,
                fontSize: 10,
              }}
            >
              {user.last_login
                ? new Date(user.last_login).toLocaleString(
                  'en-us',
                  options1,
                ) +
                ' ' +
                new Date(user.last_login).toLocaleString(
                  'en-us',
                  options,
                )
                : 'Not Found'}
            </Box>
            <Box
              component='span'
              sx={{
                color: 'text.secondary',
                display: 'block',
                fontSize: 14,
                textTransform: 'uppercase',
              }}
            >
              Last Sign-in
            </Box>
          </Box>
          <Box
            sx={{
              px: {xs: 2, xl: 4},
            }}
          >
            <Box
              component='span'
              sx={{
                display: 'block',
                mb: 2,
                fontWeight: Fonts.BOLD,
                fontSize: 10,
              }}
            >
              {role?.name ? role.name : 'Not Assigned'}
            </Box>
            <Box
              component='span'
              sx={{
                color: 'text.secondary',
                display: 'block',
                fontSize: 14,
                textTransform: 'uppercase',
              }}
            >
              Role
            </Box>
          </Box>
          <Box
            sx={{
              px: {xs: 2, xl: 4},
            }}
          >
            <Box
              component='span'
              sx={{
                display: 'block',
                mb: 2,
                fontWeight: Fonts.BOLD,
                fontSize: 10,
              }}
            >
              {user.email ? user.email : 'Not Available'}
            </Box>
            <Box
              component='span'
              sx={{
                color: 'text.secondary',
                display: 'block',
                fontSize: 14,
                textTransform: 'uppercase',
              }}
            >
              Username
            </Box>
          </Box> */}
        {/* </Box> */}
      </AppCard>

      <ModalContainer
        open={removeUserModal}
        handleModalClose={removeUserModalClose}
        handleModalProceed={() => removeUser({user})}
        proceedButtonText='Remove'
        closeButtonText='Cancel'
      >
        <div className={styles.removeContainer}>
          <GroupRemoveSharpIcon color='secondary' sx={removeIcon} />
          <h3>Do you want to remove user from this team? </h3>
        </div>
      </ModalContainer>
      <ModalContainer
        open={editUserModal}
        handleModalClose={editUserModalClose}
      >
        <AddUser
          addModalClose={editUserModalClose}
          title='Review Users'
          user={user}
          editMode={true}
          userGlobalRole={role}
          teamMenu={true}
        />
      </ModalContainer>
    </div>
  );
};

export default Profile;

Profile.propTypes = {
  user: PropTypes.object,
  // userwriteable: PropTypes.bool,
  writeable: PropTypes.bool,
};
