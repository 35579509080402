import React, {Fragment, useEffect} from 'react';
import AppCard from '@crema/core/AppCard';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import styles from './CopadoEssentials.module.css';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {COPADO_ESSENTIAL_SECRET} from 'shared/constants/ActionTypes';
import {onPostCopadoEssentialSecret} from 'redux/actions';
import {IconButton} from '@mui/material';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import {SHOW_MESSAGE} from 'shared/constants/ActionTypes';
import PropTypes from 'prop-types';


function CopadoEssentials({writeable}) {
  const dispatch = useDispatch();
  let {org_id} = useParams();
  const copadoEssentialSecret = useSelector(
    ({integration}) => integration.copadoEssentialSecret,
  );

  useEffect(() => {
    dispatch(onPostCopadoEssentialSecret({org_id}));

    return () => {
      dispatch({type: COPADO_ESSENTIAL_SECRET, payload: null});
    };
  }, [org_id]);

  const copyCopadoEssentialSecret = () => {

    navigator.clipboard.writeText(`{"payload": "${copadoEssentialSecret}"}`);
    dispatch({type: SHOW_MESSAGE, payload: 'Copado Secret Copied'});
    
  };

  return (
    <Fragment>
      <AppCard title='Copado Essentials'>
        <form>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Copado Secret</InputLabel>
            <TextField
              id='copado-secret'
              placeholder='Copado Secret'
              variant='outlined'
              type='password'
              className={styles.inputField}
              value={
                (copadoEssentialSecret == null ? '' : copadoEssentialSecret[0])
              }
              disabled
              // onClick={copyCopadoEssentialSecret}
            ></TextField>
            {(
              <IconButton aria-label='copy' onClick={copyCopadoEssentialSecret} disabled={!writeable}>
                <ContentCopySharpIcon />
              </IconButton>
            )}
          </div>
        </form>
      </AppCard>
    </Fragment>
  );
}

export default CopadoEssentials;
CopadoEssentials.propTypes = {
  writeable: PropTypes.bool,
};