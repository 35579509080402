import React from 'react';
import PropTypes from 'prop-types';

function BackgroundIssues({description}) {
  return (
    <div>
      <h3>Background</h3>
      <p>{description}</p>
    </div>
  );
}

BackgroundIssues.propTypes = {
  description: PropTypes.string,
};

export default BackgroundIssues;
