import React from 'react';
import Overview from '../Overview/Overview';
import styles from './ReportPdf.module.css';
import Process from '../Process/Process';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {onGetScanStats} from 'redux/actions';
import SimpleSeverityOverview from '../SimpleSeverityOverview/SimpleSeverityOverview';
import Contact from '../Contact/Contact';
import {useParams} from 'react-router-dom';
import {useSearchParams} from 'react-router-dom';
import IssuesPdf from '../FindingsDetail/FindingsDetail';
import SummaryReport from '../SummaryReport/SummaryReport';
import NonSummary from '../NonSummary/NonSummary';
import { SimpleFrontPage } from '../SimpleFrontPage/SimpleFrontPage';
import SimpleFindingsOverview from '../SimpleFindingsOverview/SimpleFindingsOverview';
import SimpleComplianceOverview from '../SimpleComplianceOverview/SimpleComplianceOverview';

export const SimpleReportPdf = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const fullReport = searchParams.get('simpleReport');
  let {_id, isSummaryReport, violations, orgId} = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const downloadNew = urlParams.get('newFindings');

  if(violations){
  violations = violations.replace('ISO%2027001', 'ISO 27001');
  }
  useEffect(() => {
    dispatch(onGetScanStats({_id, orgId, downloadNew}));
  }, []);

  return (
    <div ref={ref} className={styles.pdfStyle}>
      <SimpleFrontPage></SimpleFrontPage>
      <div className={styles.pagebreak} />
      <Overview></Overview>
      <Process />
      <div className={styles.pagebreak} />
      <SimpleFindingsOverview />
      <div className={styles.pagebreak} />
      <SimpleSeverityOverview />
      <div className={styles.pagebreak} />
      <SimpleComplianceOverview />
      {isSummaryReport == 'true' && (
        <SummaryReport _id={_id} violations={violations} orgId={orgId} />
      )}
      {isSummaryReport == 'false' && (
        <NonSummary _id={_id} violations={violations} orgId={orgId} />
      )}
      {fullReport && <IssuesPdf _id={_id} orgId={orgId} />}
      <div className={styles.pagebreak} />
      <Contact />
    </div>
  );
});
