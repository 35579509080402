import React, {Fragment, useEffect} from 'react';
import {DOWNLOAD_REPORTS_CUR_PAGE} from 'shared/constants/ActionTypes';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {onPostDownloadableReports} from 'redux/actions';
import AppCard from '@crema/core/AppCard';
import Pagination from '@mui/material/Pagination';
import {Box} from '@mui/material';
import SingleReportTilesDesign from './SingleReportTilesDesign/SingleReportTilesDesign';
import styles from './ReportsDownload.module.css';
import PropTypes from 'prop-types';

const ReportsDownload = ({readable, writeable}) => {
  let {org_id} = useParams();
  const dispatch = useDispatch();
  const downloadableReports = useSelector(({reports}) =>
    reports.downloadableReports?.data ? reports.downloadableReports.data : [],
  );
  const pageCount = useSelector(({reports}) =>
    reports.downloadableReports?.pageCount
      ? reports.downloadableReports.pageCount
      : 0,
  );
  const downloadReportsCurPage = useSelector(
    ({reports}) => reports.downloadReportsCurPage,
  );

  useEffect(() => {
    dispatch({type: DOWNLOAD_REPORTS_CUR_PAGE, payload: 1});
    dispatch(onPostDownloadableReports({org_id}));
  }, [dispatch, org_id]);

  function paginationChange(event, value) {
    dispatch({type: DOWNLOAD_REPORTS_CUR_PAGE, payload: value});
    dispatch(
      onPostDownloadableReports({
        org_id: org_id,
        page: value,
      }),
    );
  }

  return (
    <Fragment>
      <AppCard sxStyle={{}} title=''>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <h3>Download Reports</h3>
        </div>

        <div className={styles.reportsContainer}>
          {downloadableReports?.length > 0 &&
            downloadableReports.map((item) => {
              if (item.status == 'completed') {
                return (
                  <SingleReportTilesDesign
                    key={item._id}
                    Critical={item.Critical}
                    High={item.High}
                    Medium={item.Medium}
                    Low={item.Low}
                    Info={item.Info}
                    date={item.Initiated_Scan_date}
                    status={item.status}
                    message={item.message ? item.message : 'undefined'}
                    _id={item._id}
                    name = {item.name}
                    readable={readable}
                    writeable={writeable}
                    orgId={org_id}
                    show_new_findings={item.configs?.show_new_findings?item.configs.show_new_findings:false}
                  />
                );
              }
            })}
        </div>

        {downloadableReports?.length == 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: '150px',
              alignItems: 'center',
            }}
          >
            <h2>...</h2>
          </div>
        )}

        {pageCount > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Pagination
              count={pageCount}
              shape='rounded'
              onChange={paginationChange}
              page={downloadReportsCurPage}
              color='primary'
            />
          </Box>
        )}
      </AppCard>
    </Fragment>
  );
};

export default ReportsDownload;

ReportsDownload.propTypes = {
  readable: PropTypes.bool,
  writeable: PropTypes.bool,
};
