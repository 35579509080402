import React from 'react';
import styles from './FindingsOverview.module.css';
import SimpleVulnerabilityTypes from './VulnerabilityTypes/SimpleVulnerabilityTypes';
function SimpleFindingsOverview() {
  return (
    <div className={styles.findingsOverviewContainer}>
      <h1 className={styles.findHeading}>Findings Overview</h1>
      <p>Following is a visualization of vulnerabilities identified by DigitSec.</p>
      <SimpleVulnerabilityTypes />
    </div>
  );
}

export default SimpleFindingsOverview;
