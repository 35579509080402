import jwtAxios from '@crema/services/auth/jwt-auth';
import {
  FETCH_SUCCESS,
  FETCH_ERROR,
  GET_ALL_ROLES,
  GET_ALL_PERMISSIONS,
  IS_API_IN_PROCESS,
  SHOW_MESSAGE,
  ORG_ROLES,
  GLOBAL_ROLE,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';
import {onGetUserInfo, onGetUsers} from './Common';
import {GlobalPermissions} from 'shared/constants/AppConst';
import { onGetSpecificOrgUsers } from './Team';

export const getAllRoles = () => {
  return (dispatch) => {
    jwtAxios
      .get('/roles')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: GET_ALL_ROLES, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};


export const getAllPermissions = () => {
  return (dispatch) => {
    jwtAxios
      .get('/permissions')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: GET_ALL_PERMISSIONS, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const createNewRole = (obj) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    jwtAxios
      .post('/role/create_custom_roles', obj)
      .then(() => {
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Role Successfully Created',
        });

        dispatch(getAllRoles());
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const updateRole = (obj) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    jwtAxios
      .put('/role', obj)
      .then(() => {
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Role Successfully Updated',
        });

        dispatch(getAllRoles());
        dispatch(getAllPermissions());
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};


export const deleteRole = (roleId, permissionsId) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    jwtAxios
      .delete(`/role/${roleId}/${permissionsId}`, )
      .then((data) => {
        if (data.status == 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const assignRole = (payload) => {
  return (dispatch) => {
    jwtAxios
      .put('/users/assign_role', payload)
      .then((response) => {
        if(response.status === 200){
        const{orgId}=payload;
        dispatch(onGetUserInfo());
        dispatch(onGetUsers());
        orgId && dispatch(onGetSpecificOrgUsers({org_id: orgId}));
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Role Successfully Assigned',
        });
      }else if (response.status === 201){
        dispatch({
          type: FETCH_ERROR,
          payload: response?.data?.success || 'We encountered an error! Please try again.',
        });
      }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const processGlobalPermissions = ({rolesAndPermissionsObject}) => {
  return (dispatch) => {
    if (!rolesAndPermissionsObject) return;
    let globalRole = {};
    (globalRole.roleId = rolesAndPermissionsObject.global.roleId),
      (globalRole.roleName = rolesAndPermissionsObject.global.roleName),
      (globalRole.permissions = transformGlobalPermission({
        permissions: rolesAndPermissionsObject.global.permissions,
      }));
   
    dispatch({
      type: GLOBAL_ROLE,
      payload: globalRole,
    });
    GlobalPermissions?.map((cur) => {
      dispatch(
        processPermission({
          globalPermission: rolesAndPermissionsObject.global || null,
          permissionName: cur,
        }),
      );
      return;
    });
  };
};

export const processPermission = ({globalPermission, permissionName}) => {
  return (dispatch) => {
    if (!globalPermission)
      dispatch({
        type: permissionName,
        payload: {
          read: false,
          write: false,
        },
      });

    let payload = {
      read: false,
      write: false,
    };
    globalPermission?.permissions?.map((singlePermission) => {
      if (singlePermission.object === permissionName) {
        payload = {
          read: singlePermission?.permissions.includes('READ') || false,
          write: singlePermission?.permissions.includes('WRITE') || false,
        };
      }
      return;
    });
    dispatch({
      type: permissionName,
      payload: payload,
    });
  };
};

export const transformGlobalPermission = ({permissions}) => {
  if (!permissions) {
    return;
  }
  let transPermission = [];
  permissions.map((perm) => {
    transPermission.push({
      object: perm.object,
      read: perm.permissions.includes('READ'),
      write: perm.permissions.includes('WRITE'),
    });
  });
  return transPermission;
};

export const prcessOrgPermissions = ({orgLevel}) => {
  return (dispatch) => {
    if (!orgLevel) {
      return;
    }

    let orgRoles = [];

    orgLevel?.map((role) => {
      role?.orgs?.map((org) => {
        orgRoles.push({
          orgName: org.sfdc,
          sfdcId: org.sfdcId,
          roleId: org.roleId,
          permissions:
            transformOrgPermission({permissions: role.permissions}) || [],
        });
      });
    });

    dispatch({type: ORG_ROLES, payload: orgRoles});
  };
};

export const transformOrgPermission = ({permissions}) => {
  if (!permissions) {
    return;
  }
  let transPermission = [];
  permissions.map((perm) => {
    transPermission.push({
      object: perm.object,
      read: perm.permissions.includes('READ'),
      write: perm.permissions.includes('WRITE'),
    });
  });
  return transPermission;
};
