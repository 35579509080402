import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  IS_API_COMPLETED,
  IS_API_IN_PROCESS,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';
import jwtAxios from '@crema/services/auth/jwt-auth';

// Security Gate GET

export const onGetOrgIdfromScanId = ({scan_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`/getsfdcid/${scan_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_COMPLETED, payload: false});
          document.location.href = `/app/dashboard/${data.data.orgId}?tab=issues&reportId=${scan_id}`;

          //should update to dispatch but ugly way to just reload the app.
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};
