import React, {useState, useEffect} from 'react';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import PropTypes from 'prop-types';
import styles from './JiraIntegration.module.css';
import {Button} from '@mui/material';
import {InputLabel} from '@mui/material';
import TextField from '@mui/material/TextField';
import {useDispatch} from 'react-redux';
import {onPostSubmitJira} from 'redux/actions';
import {useSelector} from 'react-redux';

const JiraIntegration = ({jiraModalClose}) => {
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);

  const [jiraForm, setJiraForm] = useState({
    jira_username: '',
    jira_email: '',
    api_token: '',
    project_id: '',
    project_key: '',
  });

  const dispatch = useDispatch();
  const jiraFormSubmit = (event) => {
    event.preventDefault();
    dispatch(onPostSubmitJira({jiraForm}));
  };

  const onJiraChangeHandler = (event) => {
    setJiraForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  useEffect(() => {
    setJiraForm({
      jira_username: globalConfigs.jira_username || '',
      jira_email: globalConfigs.jira_email || '',
      api_token: '',
      project_id: globalConfigs.project_id || '',
      project_key: globalConfigs.project_key || '',
    });
  }, [globalConfigs]);

  return (
    <ModalContainer open={true} handleModalClose={jiraModalClose}>
      <div className={styles.mainContainer}>
        <h2>Enter Jira Details </h2>
        <form onSubmit={jiraFormSubmit}>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Jira Domain</InputLabel>
            <TextField
              id='jira-domain'
              placeholder='Jira Domain'
              variant='outlined'
              className={styles.inputField}
              name='jira_username'
              value={jiraForm.jira_username}
              onChange={onJiraChangeHandler}
              required
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Jira Email</InputLabel>
            <TextField
              id='jira-email'
              placeholder='Jira Email'
              variant='outlined'
              className={styles.inputField}
              name='jira_email'
              value={jiraForm.jira_email}
              onChange={onJiraChangeHandler}
              required
              type='email'
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>API Token</InputLabel>
            <TextField
              id='api-token'
              placeholder='API Token'
              variant='outlined'
              className={styles.inputField}
              name='api_token'
              value={jiraForm.api_token}
              required
              onChange={onJiraChangeHandler}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Project Title</InputLabel>
            <TextField
              id='project-title'
              placeholder='Project Title'
              variant='outlined'
              className={styles.inputField}
              name='project_id'
              value={jiraForm.project_id}
              required
              onChange={onJiraChangeHandler}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Project Key</InputLabel>
            <TextField
              id='project-key'
              placeholder='Project Key'
              variant='outlined'
              className={styles.inputField}
              name='project_key'
              value={jiraForm.project_key}
              required
              onChange={onJiraChangeHandler}
            ></TextField>
          </div>

          <div className={styles.btnContainer}>
            <Button type='submit' variant='contained'>
              Verify and Save
            </Button>
            <Button
              onClick={jiraModalClose}
              variant='contained'
              color='secondary'
            >
              Cancel
            </Button>
          </div>
        </form>

        <div className={styles.helpManual}>
          <h4>Help</h4>
          <p>
            Please refer to the following JIRA documentation if you are having
            trouble finding the required information.
          </p>
          <div className={styles.helpLinks}>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://support.atlassian.com/jira-core-cloud/docs/edit-a-projects-details/'
              className={styles.singleHelpLink}
            >
              How to find Project title &amp; Project key
            </a>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://confluence.atlassian.com/cloud/api-tokens-938839638.html'
              className={styles.singleHelpLink}
            >
              How to find API token
            </a>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default JiraIntegration;
JiraIntegration.propTypes = {
  jiraModalClose: PropTypes.func,
};
