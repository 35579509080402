import React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import styles from './HelpQuestions.module.css';
import {styled} from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
// import EditImg from '../../../src/assets/help/Edit.png';
import {Link} from 'react-router-dom';

const Accordion = styled((props) => <MuiAccordion {...props} />)(({theme}) => ({
  borderRadius: '10px',
  padding: '10px',
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  margin: '15px auto',
  '&:before': {
    display: 'none',
  },
}));

const Help = () => {
  return (
    <div className={styles.accordionContainer}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color='primary' />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography variant='h4'>
            invalid_grant: expired access/refresh token
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This error occurs when the Salesforce issued access token and
            refresh token for DigitSec becomes invalid due to a change in the
            Salesforce configuration. This can be fixed by editing the org in S4
            and authorizing a new access and refresh token.
          </Typography>
          {/* <img src={EditImg} alt='Edit Image Guide' /> */}
          <div className={styles.navigation}>
            <Link className={styles.link} to='/salesforce'>
              Edit the Org
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color='primary' />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='h4'>
            Polling time out. Process Id = &#123;ID&#125;
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This error occurs when Salesforce doesn&apos;t resolve and return
            source code to S4. This can occur periodically due to connectivity.
            This issue can be resolved by retrying. If you are still having
            issues please reach out to us. Support
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color='primary' />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='h4'>
            Destination URL not reset. The URL returned from login must be set
            in the SforceService.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This error occurs when the consumer key is invalid or access has
            expired. Please, edit the Salesforce org details and grant access to
            S4 again. Click on the edit icon of the error org as shown below:
          </Typography>
          {/* <img src={EditImg} alt='Edit Image Guide' /> */}
          <div className={styles.navigation}>
            <Link className={styles.link} to='/salesforce'>
              Edit the Org
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color='primary' />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='h4'>API Not Enabled Error</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Enable API in your org so that S4 can obtain source code. Please
            note that API is not available in trial professional orgs. More
            information here
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color='primary' />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='h4'>
            CLIENT_NOT_ACCESSIBLE_FOR_USER: Sorry, your administrator has
            blocked access to this client.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This error occurs when a user no longer has access to the Salesforce
            environment. Check the user profile and permission set to ensure the
            user has the S4 app enabled. https://www.digitsec.com/support
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color='primary' />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='h4'>
            {
              '{"error":true,"message":"{"error":"unsupported_grant_type","error_description":"grant type not supported"}}'
            }
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This error occurs when a user attempts to add an org to S4 using an
            instance URL ending in lightning.force.com. The instance URL should
            be in the format of https://*.my.salesforce.com, where * is your sub
            domain. (Note: Do not use *.lightning.force.com URL here)
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color='primary' />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='h4'>Other</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Contact us if you see an error which is not listed here. &nbsp;
            <a
              href='https://digitsec.com/support/'
              target='_blank'
              className={styles.link}
              rel='noreferrer'
            >
              Contact Support
            </a>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Help;
