import React from 'react';
import {AppCard} from '@crema';
import SingleScanType from './SingleScanType';
import {useSelector, useDispatch} from 'react-redux';
import {onPostUpdateConfigs} from 'redux/actions';
import PropTypes from 'prop-types';

function ScanSettings({writeable}) {
  const dispatch = useDispatch();
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);

  const onConfigChange = (event) => {
    let configsState = {
      jira_output: globalConfigs?.jira_output,
      configuration_testing: globalConfigs?.configuration_testing,
      send_results_to_all_users: globalConfigs?.send_results_to_all_users,
      data_persistent_attacks: globalConfigs?.data_persistent_attacks,
      runtime_testing: globalConfigs?.runtime_testing,
      static_code: globalConfigs?.static_code,
      auto_scanning: globalConfigs?.auto_scanning,
      software_composition_analysis:
        globalConfigs?.software_composition_analysis,
      third_party_packages: globalConfigs?.third_party_packages,
      [event.target.name]: event.target.checked,
    };
    dispatch(onPostUpdateConfigs({configsState}));
  };

  return (
    <AppCard sxStyle={{maxWidth: '900px'}} title='Edit Scan Types'>
      {globalConfigs && (
        <>
          {/* <SingleScanType
            title='Auto Scan'
            value={globalConfigs?.auto_scanning}
            name='auto_scanning'
            onConfigChange={onConfigChange}
            writeable={writeable}
          /> */}
          <SingleScanType
            title='Static Code Analysis (SAST)
'
            value={globalConfigs?.static_code}
            name='static_code'
            onConfigChange={onConfigChange}
            writeable={writeable}
          />
          <SingleScanType
            title='Dynamic Testing (IAST)
'
            value={globalConfigs?.runtime_testing}
            name='runtime_testing'
            onConfigChange={onConfigChange}
            writeable={writeable}
          />
          <SingleScanType
            title='Software Composition Analysis (SCA)
'
            value={globalConfigs?.software_composition_analysis}
            name='software_composition_analysis'
            onConfigChange={onConfigChange}
            writeable={writeable}
          />
          <SingleScanType
            title='Configuration Testing
'
            value={globalConfigs?.configuration_testing}
            name='configuration_testing'
            onConfigChange={onConfigChange}
            writeable={writeable}
          />
          <SingleScanType
            title='Third Party Packages
'
            value={globalConfigs?.third_party_packages}
            name='third_party_packages'
            onConfigChange={onConfigChange}
            writeable={writeable}
          />
          {/* <SingleScanType
            title='Notify All Users of Vulnerabilities

'
            value={globalConfigs?.send_results_to_all_users}
            name='send_results_to_all_users'
            onConfigChange={onConfigChange}
            writeable={writeable}
          /> */}
        </>
      )}
    </AppCard>
  );
}

export default ScanSettings;
ScanSettings.propTypes = {
  writeable: PropTypes.bool,
};
