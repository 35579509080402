import React, {Fragment, useEffect} from 'react';
import AppCard from '@crema/core/AppCard';
import styles from './CustomRules.module.css';
import WebhookOutlinedIcon from '@mui/icons-material/WebhookOutlined';
import IgnoredScans from './IgnoredScans';
import {useDispatch, useSelector} from 'react-redux';
import {onGetCustomRules} from 'redux/actions';
import {useParams} from 'react-router-dom';
import AddIgnoreFile from './AddIgnoreFile';
import PropTypes from 'prop-types';

const CustomRules = ({writeable}) => {
  let {org_id} = useParams();
  const customRules = useSelector(({dashboard}) => dashboard.customRules);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetCustomRules({org_id}));
  }, [org_id]);

  return (
    <Fragment>
      <AppCard sxStyle={{}}>
        <div className={styles.customRulesHeader}>
          <WebhookOutlinedIcon />
          <h3>Custom Rules</h3>
        </div>
        <h4 className={styles.subHeader}>
          These files will be ignored from scans
        </h4>
        <div className={styles.addButton}>
          <AddIgnoreFile writeable={writeable} />
        </div>
        <div>
        <IgnoredScans writeable={writeable} customRules={customRules} />
        </div>
      </AppCard>
    </Fragment>
  );
};

export default CustomRules;

CustomRules.propTypes = {
  writeable: PropTypes.bool,
};
