import React, {Fragment, useEffect} from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import styles from './Configure.module.css';
import ConfigureTab from 'components/configure/Configure';
import {useDispatch, useSelector} from 'react-redux';
import {onGetGlobalConfigs, onCheckIfAdmin} from 'redux/actions';
import {CONFIG_RESPONSE, TAGS_RESPONSE, TAGS_SUCCESS} from 'shared/constants/ActionTypes';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import ModalChildrenMessage from 'components/modals/ModalChildrenMessage/ModalChildrenMessage';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import PlaceHolderDiv from 'components/PlaceHolderDiv/PlaceHolderDiv';

const Header = () => {
  return (
    <Fragment>
      <div className={styles.title}>
        <SettingsOutlinedIcon sx={{fontSize: 25}} color='primary' />
        <h2>Global Configurations</h2>
      </div>

      <div className={styles.subHeading}>
        <p>
          Use these configurations to manage the default settings for all orgs.
        </p>
        <p>
          You can modify these settings on a per org basis by using the Edit Org
          menu accessible from the salesforce tab.
        </p>
      </div>
    </Fragment>
  );
};

const Configure = () => {
  const dispatch = useDispatch();
  const configResponse = useSelector(({configure}) => configure.configResponse);
  const tagsResponse = useSelector(({tags}) => tags.tagsResponse);
  const tagsSuccess = useSelector(({tags}) => tags.tagsSuccess);
  // const isAdmin = useSelector(({authentication}) => authentication.isAdmin);
  // const configure = useSelector(({roles}) => roles.configure);

  useEffect(() => {
    dispatch(onCheckIfAdmin());
    dispatch(onGetGlobalConfigs());

  }, []);

  // useEffect(() => {
  //   dispatch(onGetGlobalConfigs());

  //   return () => {};
  // }, []);

  const handleModalClose = () => {
    dispatch({type: CONFIG_RESPONSE, payload: null});
  };

  const handleModalCloseT = () => {
    dispatch({type: TAGS_RESPONSE, payload: null});
  };

  const handleModalCloseS = () => {
    dispatch({type: TAGS_SUCCESS, payload: null});
  };

  return (
    <Fragment>
      <>
        <Header />
        <ConfigureTab />
      </>
      {configResponse != null && (
        <ModalContainer open={true} handleModalClose={handleModalClose}>
          <ModalChildrenMessage
            status={configResponse.status}
            close='Close'
            handleModalClose={handleModalClose}
            message={configResponse.message}
          />
        </ModalContainer>
      )}
      {tagsResponse != null && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleModalCloseT}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MuiAlert
            onClose={handleModalCloseT}
            elevation={6}
            variant='filled'
            severity='error'
            sx={{width: '100%'}}
          >
            {tagsResponse?.message}
          </MuiAlert>
        </Snackbar>
      )}
      {tagsSuccess != null && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleModalCloseS}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MuiAlert
            onClose={handleModalCloseS}
            elevation={6}
            variant='filled'
            severity='success'
            sx={{width: '100%', color: 'white'}}
          >
            {tagsSuccess?.message}
          </MuiAlert>
        </Snackbar>
      )}
    </Fragment>
  );
};

export default Configure;
