import React from 'react';
import PropTypes from 'prop-types';

const Exploits = ({exploit}) => {
  return (
    <div>
      {/* <h3>Vulnerability Trace</h3> */}
      <p>{exploit}</p>
    </div>
  );
};

export default Exploits;
Exploits.propTypes = {
  exploit: PropTypes.string,
};
