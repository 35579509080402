import React, {useEffect} from 'react';
import BtcGraph from './BtcGraph';
import AppCard from '../../../../@crema/core/AppCard';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {onGetLastCompletedScan} from 'redux/actions';
import {useParams} from 'react-router-dom';
import {LAST_COMPLETED_SCAN} from 'shared/constants/ActionTypes';

const ErrorsPieDashboard = ({scanreadable}) => {

  const dispatch = useDispatch();
  let {org_id} = useParams();

  useEffect(() => {
    dispatch(onGetLastCompletedScan({org_id}));
    return () => {
      dispatch({type: LAST_COMPLETED_SCAN, payload: null});
    };
  }, [org_id]);

  return (
    <AppCard sxStyle={{height: 1, minHeight: 500}} title='Latest Scan'>
      <BtcGraph scanreadable={scanreadable} />
    </AppCard>
  );
};

export default ErrorsPieDashboard;

ErrorsPieDashboard.propTypes = {
  scanreadable: PropTypes.bool,
};