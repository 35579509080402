import React from 'react';
import {Fragment} from 'react';
import CloudQueueSharpIcon from '@mui/icons-material/CloudQueueSharp';
import SalesforceInfoForm from './AddSalesforceInfo/SalesforceInfoForm';
import PropTypes from 'prop-types';

function SalesforceOrgInfo({closeModal}) {
  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <CloudQueueSharpIcon />
        <h4> Workspace Info</h4>
      </div>

      <div>
        <SalesforceInfoForm closeModal={closeModal} />
      </div>
    </Fragment>
  );
}

export default SalesforceOrgInfo;

SalesforceOrgInfo.propTypes = {
  closeModal: PropTypes.func,
};
