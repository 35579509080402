import React, {Suspense, lazy, useState, useEffect} from 'react';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import styles from './ScanRules.module.css';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {AppLoader} from '@crema';

const SingleScanRule = lazy(() => import('./SingleScanRule'));
const AppCard = lazy(() => import('@crema/core/AppCard'));
import {Button} from '@mui/material';
import Box from '@mui/material/Box';

function ScanRules({writeable}) {
  const [showMore, setShowMore] = useState(true);

  const scanRules = useSelector(({dashboard}) => dashboard.scanRules);
  const [lessData, setLessData] = useState([]);

  useEffect(() => {
    if(scanRules){
    if (scanRules.length <= 10) {
      setShowMore(false);
    }
    setLessData(scanRules.slice(0, 10));
  }
  }, []);

  const showAll = () => {
    if(scanRules){
    setLessData(scanRules);
    setShowMore(false);
    }
  };

  return (
    <Suspense fallback={<AppLoader />}>
      <AppCard>
        <div className={styles.scanRulesHeader}>
          <SecurityOutlinedIcon />
          <h3>Edit Scan Ruleset</h3>
        </div>

        {lessData?.length > 0 &&
          lessData.map((item) => {
            return (
              <SingleScanRule
                key={item.id}
                scanRuleHeading={item.title}
                scanRuleSubtitle={item.vulnid}
                scanRuleSeverity={item.severity}
                scanRuleSwitch={item.active}
                scanRuleId={item.id}
                writeable={writeable}
              />
            );
          })}
        {showMore && (
          <Box className={styles.scanRulesHeader} sx={{m: 2}}>
            <Button onClick={showAll} variant='contained'>
              Show more
            </Button>
          </Box>
        )}
      </AppCard>
    </Suspense>
  );
}

export default ScanRules;

ScanRules.propTypes = {
  writeable: PropTypes.bool,
};
