import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SalesforceOrgInfo from './SalesforceInfoForm/SalesforceOrgInfo';
import ScratchOfflineOrg from './SalesforceInfoForm/ScratchOfflineOrg/ScratchOfflineOrg';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {ADD_SALESFORCE_ORG_RESULT} from 'shared/constants/ActionTypes';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AddSalesforceTab({closeModal}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({type: ADD_SALESFORCE_ORG_RESULT, payload: null});
    };
  }, []);

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Workspace Info' {...a11yProps(0)} />
          <Tab label='Scratch (Offline) Workspace' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SalesforceOrgInfo closeModal={closeModal} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ScratchOfflineOrg closeModal={closeModal} />
      </TabPanel>
    </Box>
  );
}

AddSalesforceTab.propTypes = {
  closeModal: PropTypes.func,
};
