import React from 'react';
import AppLogo from '@crema/core/AppLayout/components/AppLogo';

const TermsHeader = () => {
  return (
    <div>
      <AppLogo />
    </div>
  );
};

export default TermsHeader;
