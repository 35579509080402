import React from 'react';
import PropTypes from 'prop-types';
import styles from './TermsGroup.module.css';

const TermsGroup = ({heading, children}) => {
  return (
    <>
      <h2 className={styles.heading}>{heading}</h2>
      <p className={styles.text}>{children}</p>
    </>
  );
};

TermsGroup.propTypes = {heading: PropTypes.string, children: PropTypes.node};

export default TermsGroup;
