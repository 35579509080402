import React, {Fragment, useState, useEffect} from 'react';
import AppCard from '@crema/core/AppCard';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import {Button} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {
  onPostAzureInfo,
  onPostAzureSecret,
  onPostAzureDelete,
  onGetAzureInfo
} from 'redux/actions';
import {useParams} from 'react-router-dom';
import {AZURE_SECRET, AZURE_INFO} from 'shared/constants/ActionTypes';
import {useDispatch, useSelector} from 'react-redux';
import {IconButton} from '@mui/material';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import styles from '../Integration.module.css';
import {SHOW_MESSAGE} from 'shared/constants/ActionTypes';
import PropTypes from 'prop-types';
import AppMessageView from '@crema/core/AppMessageView';

const Azure = ({writeable}) => {
  const dispatch = useDispatch();
  let {org_id} = useParams();
  const apiError = useSelector(({integration}) => integration.apiError);
  const azureSecret = useSelector(({integration}) => integration.azureSecret);
  const azureInfo = useSelector(({integration}) => integration.azureinfo);

  const singleOrg = useSelector(({dashboard}) => dashboard.singleOrg);


  const [azureForm, setAzureForm] = useState({
    azurePat: '',
    azureRepoName: '',
    azureUsername: '',
    azureProjectName: '',
    is_pull_request_enabled: false,
  });

  useEffect(() => {
    dispatch(onPostAzureSecret({org_id}));
    if(singleOrg?.repoInfo?.hasAzureRepo){
      dispatch(onGetAzureInfo({azureId: singleOrg.repoInfo?.azureRepoId, org_id: org_id}));
    }
    return () => {
      dispatch({type: AZURE_SECRET, payload: null});
      dispatch({type: AZURE_INFO, payload: null});
    };
  }, [org_id]);

  useEffect(() => {
    if(azureInfo){

      setAzureForm({
        
        azurePat: '',
        azureRepoName: azureInfo.azureRepoName||'',
        azureUsername: azureInfo.azureUsername||'',
        azureProjectName: azureInfo.azureProjectName||'',
        is_pull_request_enabled: azureInfo.is_pull_request_enabled||false,
      });
    }
}, [azureInfo]);

  const onFormChange = (event) => {
    setAzureForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  const checkboxPR = (event) => {
    setAzureForm((cur) => {
      return {
        ...cur,
        is_pull_request_enabled: event.target.checked,
      };
    });
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    dispatch(onPostAzureInfo({...azureForm, org_id}));
    setAzureForm({
      azurePat: '',
      azureRepoName: azureForm.azureRepoName,
      azureUsername: azureForm.azureUsername,
      azureProjectName: azureForm.azureProjectName,
      is_pull_request_enabled: azureForm.is_pull_request_enabled,
    });
  };

  const deleteAzureRepo = () => {
    dispatch(onPostAzureDelete({org_id}));
    setAzureForm({
      azurePat: '',
      azureRepoName: '',
      azureUsername: '',
      azureProjectName: '',
      is_pull_request_enabled: '',
    });
  };

  const copyAzureSecret = () => {

    navigator.clipboard.writeText(
      `https://s4.digitsec.com/webhooks/azure/${azureSecret[0]}`,
    );
    dispatch({type: SHOW_MESSAGE, payload: 'Azure Secret Copied'});
  
  };

  return (
    <Fragment>
      <AppCard sxStyle={{}} title='Azure'>
        <form onSubmit={onFormSubmit} autoComplete='off'>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Azure Secret</InputLabel>
            <TextField
              id='Azure-secret'
              placeholder='Azure Secret'
              variant='outlined'
              type='password'
              className={styles.inputField}
              value={(azureSecret == null ? '' : azureSecret[0])}
              disabled
              // onClick={copyAzureSecret}
            ></TextField>
            {(
              <IconButton aria-label='copy' onClick={copyAzureSecret} disabled={!writeable}>
                <ContentCopySharpIcon />
              </IconButton>
            )}
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Organization</InputLabel>
            <TextField
              id='username'
              placeholder='Username'
              variant='outlined'
              className={styles.inputField}
              value={azureForm.azureUsername}
              required
              disabled={!writeable}
              name='azureUsername'
              onChange={onFormChange}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Repo Name</InputLabel>
            <TextField
              id='repo-name'
              placeholder='Repo Name'
              variant='outlined'
              className={styles.inputField}
              value={azureForm.azureRepoName}
              required
              disabled={!writeable}
              name='azureRepoName'
              onChange={onFormChange}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Project Name</InputLabel>
            <TextField
              id='project-name'
              placeholder='Project Name'
              variant='outlined'
              className={styles.inputField}
              value={azureForm.azureProjectName}
              required
              disabled={!writeable}
              name='azureProjectName'
              onChange={onFormChange}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Azure Pat</InputLabel>
            <TextField
              id='azure-pat'
              placeholder='Azure Pat'
              variant='outlined'
              className={styles.inputField}
              value={azureForm.azurePat}
              required
              disabled={!writeable}
              name='azurePat'
              onChange={onFormChange}
              type='password'
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
            Create additional pull request on scan completion
            </InputLabel>
            <Checkbox
              checked={azureForm.is_pull_request_enabled}
              onChange={checkboxPR}
              disabled={!writeable}
            />
          </div>
          <div className={styles.formButtonContainer}>
            <Button type='submit' variant='contained' disabled={!writeable}>
              Verify and Save
            </Button>
            {azureSecret?.length > 1 && azureSecret[1].length > 1 && (
              <Button
                variant='contained'
                onClick={deleteAzureRepo}
                disabled={!writeable}
              >
                Disconnect Azure Repo
              </Button>
            )}
          </div>
        </form>
      </AppCard>
      {apiError &&
      <AppMessageView variant='error' message={apiError.toString()} />
      }
    </Fragment>
  );
};

export default Azure;
Azure.propTypes = {
  writeable: PropTypes.bool,
};
