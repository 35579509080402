import React from 'react';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import styles from './PasswordResetConfirmation.module.css';
import PropTypes from 'prop-types';
import LiveHelpSharpIcon from '@mui/icons-material/LiveHelpSharp';
import {Box} from '@mui/material';

import {Button} from '@mui/material';

const resetIcon = {fontSize: 90};

const PasswordResetConfirmation = ({
  passwordModal,
  passwordModalClose,
  passwordReset,
}) => {
  return (
    <ModalContainer open={passwordModal} handleModalClose={passwordModalClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className={styles.passwordContainer}>
          <div>
            <LiveHelpSharpIcon sx={resetIcon} />
          </div>
          <h3>
            Are you sure you want to send a password reset email to this user?
          </h3>

          <div className={styles.deleteBtnContainer}>
            <Button
              variant='contained'
              onClick={passwordModalClose}
              color='secondary'
            >
              No
            </Button>
            <Button variant='contained' onClick={passwordReset} color='primary'>
              Yes
            </Button>
          </div>
        </div>
      </Box>
    </ModalContainer>
  );
};

PasswordResetConfirmation.propTypes = {
  passwordModal: PropTypes.bool,
  passwordModalClose: PropTypes.func,
  passwordReset: PropTypes.func,
};

export default PasswordResetConfirmation;
