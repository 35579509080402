import React, {useEffect} from 'react';
import {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {AppInfoView} from '@crema';
import {onGetOrgIdfromScanId} from 'redux/actions/Scan';
import {useDispatch} from 'react-redux';


function Scan() {
  let {scan_id} = useParams();
const dispatch = useDispatch();
useEffect(() => {
    dispatch(onGetOrgIdfromScanId({scan_id}));
    return () => {
    };
  }, [dispatch, scan_id]);

  return (
    <Fragment>
      <AppInfoView />
    </Fragment>
  );
};

export default Scan;

Scan.propTypes = {
  props: PropTypes.any,
  match: PropTypes.any,
};
