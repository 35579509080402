import React, {useEffect} from 'react';
import {onGetFindingsData} from 'redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import HeaderIssues from './HeaderFindings/HeaderFindings';
import {GET_FINDINGS_DATA} from 'shared/constants/ActionTypes';
import BackgroundIssues from './BackgroundFindings/BackgroundFindings';
import styles from './FindingsDetail.module.css';
import RemediationGuidelines from './RemediationGuidelines/RemediationGuidelines';
import VulnerabilityTrace from './VulnerabilityTrace/VulnerabilityTrace';
import IssueFindings from './IssueFindings/IssueFindings';
import {useSearchParams} from 'react-router-dom';
import Exploits from '../Exploits/Exploits';
import SimpleHeaderIssues from './SimpleHeaderFindings/SimpleSimpleHeaderFindings';
import { CircularProgress } from '@mui/material';


function IssuesPdf({_id, orgId}) {
  const dispatch = useDispatch();
  const getFindingsData = useSelector(({reports}) => reports.getFindingsData);
  const [searchParams] = useSearchParams();
  const fullReport = searchParams.get('fullReport');
  const urlParams = new URLSearchParams(window.location.search);
  const downloadNew = urlParams.get('newFindings');

  useEffect(() => {
    dispatch(onGetFindingsData({_id, orgId, downloadNew}));

    return () => {
      dispatch({type: GET_FINDINGS_DATA, payload: null});
    };
  }, []);

  return (
    <div className={styles.issuesContainer}>
      {getFindingsData != null?
        getFindingsData.map((finding, index) => {
          return (
            <div key={finding._id}>
              <div className={styles.pagebreak} />
              <div className={styles.detailContainer}>
                {fullReport=='true'?
                 <HeaderIssues
                 index={index}
                 severity={finding.severity}
                 issue={finding.issue}
                 filename={finding.filename}
                 line={finding.line}
                 status={finding.status}
                 founddate={finding.founddate}
                 issueId={finding?.issueId}
                 _id={finding._id}
               />
                :
                <SimpleHeaderIssues
                index={index}
                severity={finding.severity}
                issue={finding.issue}
                filename={finding.filename}
                line={finding.line}
                status={finding.status}
                founddate={finding.founddate}
                issueId={finding?.issueId}
                _id={finding._id}
              />
                
                }
               
                <BackgroundIssues description={finding.description} />
                <IssueFindings
                  traces={finding.traces?finding?.traces[0]:null}
                  codesnippets={
                    finding.codesnippets ? finding.codesnippets : null
                  }
                />
                <RemediationGuidelines
                  remediationguidance={finding.remediationguidance}
                />
                {(finding.traces?finding?.traces[0]?.length > 0:false) || (finding?.exploits?.length >0) && (
                  <VulnerabilityTrace traces={finding.traces?finding?.traces[0]:null} exploits={finding.exploits} />
                )}
                {finding?.exploits?.length > 0 && (
                    <Exploits exploit={finding.exploits} />
                  )}
              </div>
            </div>
          );
        }):
        <div style={{display:"flex",justifyContent:"center"}}>
        <CircularProgress/>

        </div>
        }
    </div>
  );
}

export default IssuesPdf;

IssuesPdf.propTypes = {
  _id: PropTypes.string,
  orgId: PropTypes.string
};
