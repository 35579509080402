import React from 'react';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {onPostCustomRules} from 'redux/actions';
import {useParams} from 'react-router-dom';
import {getDateTimeOnly} from 'components/utils/UtilFunctions';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './IgnoredScansTable.module.css';
import Checkbox from '@mui/material/Checkbox';
import {MoreHoriz as MoreHorizIcon} from '@mui/icons-material';
import {Button} from '@mui/material';
import {Menu, MenuItem} from '@mui/material';


const IgnoredScansTable = ({row, writeable, onCheckboxChange, isSelected}) => {
  let {org_id} = useParams();
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModal = () => setDeleteModal(true);

  const closeDeleteModal = () => setDeleteModal(false);
  const deleteCustomRule = () => {
    dispatch(onPostCustomRules({org_id, ignorelistid: row._id}));
    closeDeleteModal();
  };

  const handleChange = () => {
    onCheckboxChange(row._id);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableRow
        key={row._id}
        sx={{'&:last-child td, &:last-child th': {border: 0}}}
      >
        <TableCell align='center'>
        <Checkbox
          checked={isSelected}
          onChange={handleChange}
          disabled={!writeable}
        />
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.filename}
        </TableCell>
        <TableCell align='center'>{row.reason}</TableCell>
        <TableCell align='center'>{row.addedby}</TableCell>
        <TableCell align='center'>{getDateTimeOnly(row.date)}</TableCell>
        <TableCell align='center'>
          <Button
            aria-controls='custom-menu'
            aria-haspopup='true'
            onClick={handleClick}
            variant='text'
          >
            <MoreHorizIcon />
          </Button>
          <Menu
            id='custom-menu-options'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => openDeleteModal(row._id)}>Delete</MenuItem>
          </Menu>
          {/* <ClearIcon
            disabled={!writeable}
            aria-label='delete'
            onClick={() => openDeleteModal(row._id)}
          />
          <DeleteIcon color='secondary'  /> */}
        </TableCell>
      </TableRow>
      <ModalContainer
        open={deleteModal}
        handleModalClose={closeDeleteModal}
        handleModalProceed={deleteCustomRule}
        closeButtonText='No'
        proceedButtonText='Yes'
      >
        <div className={styles.deleteContainer}>
          <DeleteIcon color='secondary' sx={{fontSize: 100}} />
          <h2>Do you want to delete this ignore rule?</h2>
          <p>This will remove this file from the ignore list</p>
        </div>
      </ModalContainer>
    </>
  );
};

IgnoredScansTable.propTypes = {
  row: PropTypes.object,
  writeable: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default IgnoredScansTable;
