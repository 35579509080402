import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CustomRules from './CustomRules/CustomRules';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import PlaceHolderDiv from 'components/PlaceHolderDiv/PlaceHolderDiv';
import {checkOrgRole} from 'roles/Org';
import {useParams} from 'react-router-dom';
import {onGetScanRules} from 'redux/actions';
import {useDispatch} from 'react-redux';
import ScanSettings from './Scan Settings/ScanSettings';
import ScanRules from './ScanRules/ScanRules';
import {AppLoader} from '@crema';

// const ScanSettings = React.lazy(() => import('./Scan Settings/ScanSettings'));
// const ScanRules = React.lazy(() => import('./ScanRules/ScanRules'));

const tabsAccess = ({orgscanrules, orgscansettings, orgignorelist}) => {
  return orgscansettings?.read || orgscanrules?.read || orgignorelist?.read;
};

export default function SettingsBar() {
  const orgRoles = useSelector(({roles}) => roles.orgRoles);
  let {org_id} = useParams();
  const dispatch = useDispatch();
  let selectedOrgRole = orgRoles?.find((role) => role.sfdcId === org_id);
  let selectedOrgRolePermissions = selectedOrgRole?.permissions;

  const [value, setValue] = React.useState('scansettings');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (selectedOrgRolePermissions) {
    var orgscanrules = checkOrgRole({
      object: 'orgscanrules',
      permissions: selectedOrgRolePermissions,
    });

    var orgscansettings = checkOrgRole({
      object: 'orgscansettings',
      permissions: selectedOrgRolePermissions,
    });

    var orgignorelist = checkOrgRole({
      object: 'orgignorelist',
      permissions: selectedOrgRolePermissions,
    });
  }

  const showTabs = tabsAccess({
    orgscanrules,
    orgscansettings,
    orgignorelist,
  });

  useEffect(() => {
    let initialTabSelected = orgscansettings?.read
      ? 'scansettings'
      : orgignorelist?.read
      ? 'customrules'
      : orgscanrules?.read
      ? 'scanrules'
      : '';
    if (initialTabSelected !== '') {
      setValue(initialTabSelected);
    }
    dispatch(onGetScanRules({org_id}));
    return () => {};
  }, []);

  return (
    <React.Suspense fallback={<AppLoader />}>
      <Box sx={{width: '100%'}}>
        {orgscansettings && orgignorelist && orgscanrules && showTabs && (
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginBottom: 5,
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label='Settings Tab'
                variant='scrollable'
                scrollButtons
                allowScrollButtonsMobile
              >
                {orgscansettings?.read && (
                  <Tab label='Scan Settings' value='scansettings' />
                )}
                {orgscanrules?.read && (
                  <Tab label='Scan Rules' value='scanrules' />
                )}
                {orgignorelist?.read && (
                  <Tab label='Custom Rules' value='customrules' />
                )}
              </TabList>
            </Box>
            <TabPanel value='scansettings'>
              <ScanSettings writeable={orgscansettings?.write} />
            </TabPanel>
            <TabPanel value='scanrules'>
              <ScanRules writeable={orgscanrules?.write} />
            </TabPanel>
            <TabPanel value='customrules'>
              <CustomRules writeable={orgignorelist?.write} />
            </TabPanel>
          </TabContext>
        )}
        {orgscanrules && orgscansettings && orgignorelist && !showTabs && (
          <PlaceHolderDiv
            status='permissiondenied'
            message={`You don't have permission to view Settings`}
          />
        )}
      </Box>
    </React.Suspense>
  );
}
