import React from 'react';
import HelpQuestions from 'components/help/HelpQuestions';

const Help = () => {
  return (
    <>
      <h1>Help!</h1>
      <p>Seems like you are lost, let us help you get back on track...</p>
      <HelpQuestions></HelpQuestions>
      <p>Summer 2023 version 31</p>
    </>
  );
};

export default Help;
