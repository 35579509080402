import React, {useEffect} from 'react';
import orange from '@mui/material/colors/orange';
import {useAuthMethod, useAuthUser} from '../../../../utility/AuthHooks';
import {useDispatch} from 'react-redux';
import {Box, Tooltip} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Fonts} from '../../../../../shared/constants/AppEnums';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {getUserNotifications} from 'redux/actions';

const UserInfo = ({color}) => {
  const dispatch = useDispatch();
  const {logout} = useAuthMethod();
  const {user} = useAuthUser();
  const navigate = useNavigate();
  const loggedUser = useSelector(({common}) => common.userInfo);
  const originator = [loggedUser?.usr?._id];
  useEffect(() => {
      dispatch(getUserNotifications({originator: originator}));
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => {

    if (loggedUser?.usr?.name) {
      if(loggedUser?.usr?.name?.includes(' ')){
        return (
          loggedUser?.usr?.name?.split(' ')[0]?.charAt(0)?.toUpperCase() +
          loggedUser?.usr?.name.split(' ')[1]?.charAt(0)?.toUpperCase()
        );
      }else{
        return loggedUser?.usr?.name?.charAt(0)?.toUpperCase();
      }
    }
    if (loggedUser?.usr?.email) {
      return loggedUser?.usr?.email?.charAt(0)?.toUpperCase();
    }
  };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          py: 3,
          px: 3,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        className='user-info-view'
      >
        <Box sx={{py: 0.5}}>
          {user?.photoURL ? (
            <Avatar
              sx={{
                height: 40,
                width: 40,
                fontSize: 12,
                backgroundColor: orange[500],
              }}
              src={user.photoURL}
            />
          ) : (
            <Tooltip title={loggedUser?.usr?.name ? loggedUser?.usr?.name :loggedUser?.usr?.email ? loggedUser?.usr?.email :''}>
            <Avatar
              sx={{
                height: 40,
                width: 40,
                fontSize: 12,
                backgroundColor: orange[500],
              }}
            >
              {getUserAvatar()}
            </Avatar>
            </Tooltip>
          )}
        </Box>
        <Box
          sx={{
            width: {xs: 'calc(100% - 62px)', xl: 'calc(100% - 50px)'},
            ml: 4,
            color: color,
          }}
          className='user-info-view'
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                mb: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: 14,
                fontWeight: Fonts.MEDIUM,
                color: 'inherit',
              }}
              component='span'
            >
              {loggedUser?.usr?.email || ''}
            </Box>
            <Box
              sx={{
                ml: 3,
                color: 'inherit',
                display: 'flex',
              }}
            >
              <ExpandMoreIcon />
            </Box>
          </Box>
          {/* <Box
            sx={{
              mt: -0.5,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: 'inherit',
            }}
          >
            {loggedUser?.role || ''}
          </Box> */}
        </Box>
      </Box>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
         <MenuItem
          onClick={() => {
            handleClose();
            navigate('/setup?tab=profile');
          }}
        >
          Edit Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate('/help');
          }}
        >
          Help
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default UserInfo;

UserInfo.defaultProps = {
  color: 'text.secondary',
};

UserInfo.propTypes = {
  color: PropTypes.string,
};
