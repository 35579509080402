import React, {useState} from 'react';
import VerifiedUserSharpIcon from '@mui/icons-material/VerifiedUserSharp';
import styles from './SingleReportTilesDesign.module.css';
import ReportPieChart from './ReportPieChart';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import FindInPageSharpIcon from '@mui/icons-material/FindInPageSharp';
import PropTypes from 'prop-types';
import {IconButton} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useDispatch} from 'react-redux';
import {onGetJsonData} from 'redux/actions';
import ExcelDownload from '../ExcelDownload/ExcelDownload';
// import DeleteReport from '../DeleteReport/DeleteReport';
import ComplianceReport from '../ComplianceReport/ComplianceReport';
import {useNavigate} from 'react-router-dom';
import Chip from '@mui/material/Chip';
const SingleReportTilesDesign = ({Critical, High, Medium, Low, date, _id, name, readable, orgId, show_new_findings}) => {
  
  
  let options1 = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  let options = { timeStyle: 'long', hour12: true };
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Download
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // View
  const [anchorElView, setAnchorElView] = useState(null);
  const openView = Boolean(anchorElView);
  const viewHandleClick = (event) => {
    setAnchorElView(event.currentTarget);
  };
  const viewHandleClose = () => {
    setAnchorElView(null);
  };

  const getJsonHandler = () => {
    setAnchorEl(null);
    dispatch(onGetJsonData({_id, orgId, show_new_findings}));
  };

  //Excel CSV STATE
  const [excelModal, setExcelModal] = useState(false);
  const handleModalClose = () => {
    setExcelModal(false);
  };

  const excelModalOpen = () => {
    setAnchorEl(null);
    setExcelModal(true);
  };

  // Delete Modal Close
  // const [deleteModal, setDeleteModal] = useState(false);
  // const deleteModalClose = () => {
  //   setDeleteModal(false);
  // };

  // const deleteModalOpen = () => {
  //   setAnchorElView(null);
  //   setDeleteModal(true);
  // };

  // Compliance Report

  const [complianceModal, setComplianceModal] = useState({
    open: false,
    isSummaryReport: false,
  });
  const complianceModalClose = () => {
    setComplianceModal({open: false, isSummaryReport: false});
  };
  const complianceModalOpen = (isSummaryReport) => {
    setAnchorElView(null);
    setComplianceModal({
      open: true,
      isSummaryReport: isSummaryReport,
    });
  };

  const viewReport = () => {
    navigate(`/report/${_id}/${orgId}?fullReport=true&newFindings=${show_new_findings}`);
  };  
  const viewSimpleReport = () => {
    navigate(`/report/${_id}/${orgId}?simpleReport=true&newFindings=${show_new_findings}`);
  };

  return (
    <>
      <div className={styles.singleReport}>
        <div className={styles.securityGateInfo}>
          <div className={styles.securityGateIcon}>
            <VerifiedUserSharpIcon className={styles.icon} />
          </div>
          <div className={styles.name}>
          {name !== '' ? (
              <p>
                {name} @{new Date(date).toLocaleString('en-us', options1)}{' '}
                {new Date(date).toLocaleString('en-us', options)}
              </p>
            ) : (
              <p>
                Scanned @ {new Date(date).toLocaleString('en-us', options1)}{' '}
                {new Date(date).toLocaleString('en-us', options)}
              </p>
            )}
          </div>
        </div>
        <div className={styles.pieChart}>
        {show_new_findings && (
                <Chip
                    size='small'
                    label={'NEW ISSUES' }
                    className={styles.showNewFindings}
                  />
                )
            }
          <ReportPieChart
            Critical={Critical}
            High={High}
            Medium={Medium}
            Low={Low}
          />
        </div>
        <div className={styles.valueContainer}>
          <div>
            <p className={styles.criticalText}>{Critical} Critical</p>
          </div>
          <div>
            <p className={styles.highText}>{High} High</p>
          </div>
          <div>
            <p className={styles.mediumText}>{Medium} Medium</p>
          </div>
          <div>
            <p className={styles.lowText}>{Low} Low</p>
          </div>
        </div>
        <div className={styles.actionContainer}>
          <IconButton
            aria-label='download'
            aria-controls={open ? 'download-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <DownloadSharpIcon color='primary' />
          </IconButton>
          <IconButton
            aria-label='View'
            aria-controls={openView ? 'view-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={openView ? 'true' : undefined}
            onClick={viewHandleClick}
          >
            <FindInPageSharpIcon color='primary' />
          </IconButton>
          
            {/* <IconButton disabled = {!writeable} aria-label='delete' onClick={deleteModalOpen}>
              {writeable==true? (
                  <DeleteIcon color='secondary' />
              ):<DeleteIcon color='#808080' />
            }
            </IconButton> */}
          
        </div>
      </div>
      <Menu
        id='download-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'download-button',
        }}
      >
        <MenuItem disabled = {!readable} onClick={getJsonHandler}>Get JSON</MenuItem>
        <MenuItem disabled = {!readable} onClick={excelModalOpen}>Get CSV/Excel</MenuItem>
      </Menu>
      <Menu
        id='view-menu'
        anchorEl={anchorElView}
        open={openView}
        onClose={viewHandleClose}
        MenuListProps={{
          'aria-labelledby': 'view-button',
        }}
      >
        <MenuItem disabled = {!readable} onClick={() => complianceModalOpen(false)}>
          Compliance Report
        </MenuItem>
        <MenuItem disabled = {!readable} onClick={() => complianceModalOpen(true)}>
          Compliance Summary
        </MenuItem>
        <MenuItem disabled = {!readable} onClick={viewSimpleReport}>View Lite Report</MenuItem>
        <MenuItem disabled = {!readable} onClick={viewReport}>View Detailed Report</MenuItem>
      </Menu>
      <ExcelDownload
        excelModal={excelModal}
        handleModalClose={handleModalClose}
        _id={_id}
        orgId={orgId}
        show_new_findings={show_new_findings}
      ></ExcelDownload>
      {/* <DeleteReport
        deleteModal={deleteModal}
        deleteModalClose={deleteModalClose}
        _id={_id}
      ></DeleteReport> */}

      <ComplianceReport
        complianceModal={complianceModal.open}
        handleModalClose={complianceModalClose}
        _id={_id}
        isSummaryReport={complianceModal.isSummaryReport}
        orgId={orgId}
      ></ComplianceReport>
    </>
  );
};

export default SingleReportTilesDesign;

SingleReportTilesDesign.propTypes = {
  Critical: PropTypes.number,
  High: PropTypes.number,
  Medium: PropTypes.number,
  Low: PropTypes.number,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  status: PropTypes.string,
  message: PropTypes.string,
  _id: PropTypes.string,
  name: PropTypes.string,
  // writeable: PropTypes.bool,
  readable: PropTypes.bool,
  orgId: PropTypes.string,
  show_new_findings: PropTypes.bool,
};
