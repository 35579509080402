import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ComplianceOverview.module.css';


const CustomTooltip = ({active, payload}) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p className='label'>{`${payload[0].payload.complianceType} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  payload: PropTypes.any,
  active: PropTypes.bool,
  label: PropTypes.string,
};

function SimpleComplianceOverview() {
  const getScanStats = useSelector(({reports}) => reports.getScanStats);
  return (
    <>
      {getScanStats?.compliancetypes?.length > 0 && (
        <div className={styles.ChartContainer}>
          <h2>Vulnerability Compliance Overview</h2>
          <div className='vulnerabilityTable'>
          <table className='vulnerabilityTable'>
            <thead>
            <tr>
              <th>Vulnerability Type</th>
              <th>Count</th>
            </tr>
            </thead>
            <tbody>

            {getScanStats.compliancetypes.map((issue,index)=>(
              <tr key={index}>
              <td>{issue.complianceType}</td>
              <td>{issue.count}</td>
            </tr>
            )
            
            )}
            </tbody>
          </table>
          </div>
 
        </div>
      )}
    </>
  );
}

export default SimpleComplianceOverview;
