import React, {useState} from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  Autocomplete,
  TextField,
  Chip,
} from '@mui/material';
import PropTypes from 'prop-types';
import StyleIcon from '@mui/icons-material/Style';
import styles from './AddTagsModal.module.css';
import ModalTags from './ModalTags';

function AddTagsModal({
  selectLabel,
  selectValue,
  onChange,
  options,
  open,
  onClose,
  title,
  handleSubmit,
  isDisabledCreate,
  orgSFDCId,
  scanId,
  orgIdScan,
  issueId,
  issueOrgId,
  _id,
  page,
  pageSize,
  onCleanFields,
}) {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setOpenModal(false);
  };

  const getFilteredOptions = (allOptions, selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.tagId);
    return allOptions.filter((option) => !selectedIds.includes(option.tagId));
  };

  const filteredOptions = getFilteredOptions(options, selectValue);
  
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
            padding: 7,
            width: '50%',
            minHeight: '250px',
            maxWidth: '700px',
            overflowY: 'auto',
          }}
        >
          <div>
            <div className={styles.headerContent}>
              <div className={styles.headerTag}>
                <StyleIcon />
                <Typography variant='h2'>{title}</Typography>
              </div>
              <div>
                <Button
                  sx={{marginRight: 1}}
                  variant='contained'
                  onClick={handleOpenModal}
                  disabled={isDisabledCreate}
                >
                  + Add a New Tag
                </Button>{' '}
              </div>
            </div>
            &nbsp;
            <div className={styles.formContainer}>
              <Autocomplete
                multiple
                id='tags-autocomplete'
                options={filteredOptions}
                value={options.filter((option) =>
                  selectValue.some(
                    (item) =>
                      item.fieldValue === option.value &&
                      item.tagId === option.tagId,
                  ),
                )}
                getOptionLabel={(option) => `${option.value} : ${option.label}`}
                onChange={(event, newValue) => {
                  const updatedValue = newValue.map((item) => ({
                    fieldName: item.label,
                    fieldValue: item.value,
                    href: item.url ? item.url : undefined,
                    tagLibrary: true,
                    tagId: item.tagId,
                  }));

                  onChange(updatedValue);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const tagProps = getTagProps({index});
                    return (
                      <Chip
                        key={option.tagId}
                        label={`${option.value} : ${option.label}`}
                        {...tagProps}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label={selectLabel}
                    placeholder='Tags'
                  />
                )}
              />
            </div>
            <div className={styles.btnContainer}>
              <Button onClick={onClose} variant='contained' color='secondary'>
                Close
              </Button>
              <Button type='submit' variant='contained' onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <ModalTags
        open={openModal}
        handleClose={handleCloseModal}
        orgSFDCId={orgSFDCId}
        scanId={scanId}
        orgIdScan={orgIdScan}
        issueId={issueId}
        issueOrgId={issueOrgId}
        _id={_id}
        page={page}
        pageSize={pageSize}
        onCleanFields={onCleanFields}
      />
    </>
  );
}

AddTagsModal.propTypes = {
  selectLabel: PropTypes.string.isRequired,
  selectValue: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  isDisabledCreate: PropTypes.bool,
  orgSFDCId: PropTypes.string,
  scanId: PropTypes.string,
  orgIdScan: PropTypes.string,
  issueId: PropTypes.string,
  issueOrgId: PropTypes.string,
  _id: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCleanFields: PropTypes.func,
};

export default AddTagsModal;
