import React from 'react';

import Setup from './Setup';

export const setupPagesConfigs = [
  {
    path: '/setup',
    element: <Setup />,
  },
  {
    path: '/setup/:selectedTab',
    element: <Setup />,
  },
];
