import React, {Fragment, useEffect} from 'react';
import AppCard from '@crema/core/AppCard';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import styles from '../Integration.module.css';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {API_SECRET_TOKEN} from 'shared/constants/ActionTypes';
import {onPostAPISecret} from 'redux/actions';
import {IconButton} from '@mui/material';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import {SHOW_MESSAGE} from 'shared/constants/ActionTypes';
import PropTypes from 'prop-types';
const ApiTokenSecret = ({writeable}) => {
  const dispatch = useDispatch();
  let {org_id} = useParams();
  const apiSecretToken = useSelector(
    ({integration}) => integration.apiSecretToken,
  );

  useEffect(() => {
    dispatch(onPostAPISecret({org_id}));

    return () => {
      dispatch({type: API_SECRET_TOKEN, payload: null});
    };
  }, [org_id]);

  const copyAPITokenSecret = () => {
    
    navigator.clipboard.writeText(`${apiSecretToken}`);
    dispatch({type: SHOW_MESSAGE, payload: 'API Secret Copied'});
    
  };

  return (
    <Fragment>
      <AppCard title='API Token'>
        <form>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>API Token</InputLabel>
            <TextField
              id='api-secret'
              placeholder='API Secret'
              variant='outlined'
              type='password'
              className={styles.inputField}
              value={(apiSecretToken == null ? '' : apiSecretToken[0])}
              disabled
              // onClick={copyAPITokenSecret}
            ></TextField>
            {(
              <IconButton aria-label='copy' onClick={copyAPITokenSecret} disabled={!writeable}>
                <ContentCopySharpIcon />
              </IconButton>
            )}
          </div>
        </form>
      </AppCard>
    </Fragment>
  );
};

export default ApiTokenSecret;
ApiTokenSecret.propTypes={
  writeable: PropTypes.bool,
};
