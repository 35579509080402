import React, {Fragment, useState, useEffect} from 'react';
import AppCard from '@crema/core/AppCard';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import styles from '../Integration.module.css';
import {Button} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {onPostBitbucketSecret} from 'redux/actions';
import {BITBUCKET_SECRET, BITBUCKET_INFO} from 'shared/constants/ActionTypes';
import {onPostBitbucketInfo, onGetBitbucketInfo} from 'redux/actions';
import {IconButton} from '@mui/material';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import {onDeleteBitbucketRepo} from 'redux/actions';
import {onPutBitbucketInfo} from 'redux/actions';
import {SHOW_MESSAGE} from 'shared/constants/ActionTypes';
import PropTypes from 'prop-types';
import AppMessageView from '@crema/core/AppMessageView';

const Bitbucket = ({writeable}) => {
  const dispatch = useDispatch();
  let {org_id} = useParams();
  const apiError = useSelector(({integration}) => integration.apiError);
  const bitbucketSecret = useSelector(
    ({integration}) => integration.bitbucketSecret,
  );
  const bitbucketInfo = useSelector(({integration}) => integration.bitbucketinfo);
  const singleOrg = useSelector(({dashboard}) => dashboard.singleOrg);



  const [bitbucketForm, setBitbucketForm] = useState({
    workspace_id: '',
    username: '',
    repo_name: '',
    is_pull_request_enabled: false,
    app_password: '',
  });

  useEffect(() => {
    dispatch(onPostBitbucketSecret({org_id}));
    if(singleOrg?.bit_repo_id){
        dispatch(onGetBitbucketInfo({bitbucketId: singleOrg.bit_repo_id, org_id: org_id}));
    }

    return () => {
      dispatch({type: BITBUCKET_SECRET, payload: null});
      dispatch({type:BITBUCKET_INFO, payload: null});

    };
  }, [org_id]);

  useEffect(() => {
    if(bitbucketInfo){
      setBitbucketForm({
        workspace_id: bitbucketInfo.workspace_id||'',
        username: bitbucketInfo.username||'',
        repo_name: bitbucketInfo.name||'',
        is_pull_request_enabled: bitbucketInfo.is_pull_request_enabled||false,
        app_password: '',
      });
    }
}, [bitbucketInfo]);

  const onFormChange = (event) => {
    setBitbucketForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  const checkboxPR = (event) => {
    setBitbucketForm((cur) => {
      return {
        ...cur,
        is_pull_request_enabled: event.target.checked,
      };
    });
  };

  const onFormSubmit = (event) => {
    event.preventDefault();

    if (bitbucketSecret?.length > 1) {
      dispatch(
        onPutBitbucketInfo({
          ...bitbucketForm,
          repo_id: bitbucketSecret[0],
          org_id,
        }),
      );
    } else {
      dispatch(onPostBitbucketInfo({...bitbucketForm, org_id}));
    }

    setBitbucketForm({
      workspace_id: bitbucketForm.workspace_id,
      username: bitbucketForm.username,
      repo_name: bitbucketForm.repo_name,
      is_pull_request_enabled: bitbucketForm.is_pull_request_enabled,
      app_password: '',
    });
  };

  const copyBitbucketSecret = () => {

    navigator.clipboard.writeText(
      `https://s4.digitsec.com/webhooks/bitbucket/${
        bitbucketSecret[1] || bitbucketSecret[0]
      }`,
    );
    dispatch({type: SHOW_MESSAGE, payload: 'Bitbucket Secret Copied'});
  };

  const deleteBitbucketRepo = () => {
    dispatch(onDeleteBitbucketRepo({bitbucketId: bitbucketSecret[0], org_id}));
    setBitbucketForm({
      workspace_id: '',
      username: '',
      repo_name: '',
      is_pull_request_enabled: false,
      app_password: '',
    });
  };

  return (
    <Fragment>
      <AppCard title='Bitbucket'>
        <form onSubmit={onFormSubmit}>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Bitbucket Secret
            </InputLabel>
            <TextField
              id='bitbucket-secret'
              placeholder='Bitbucket Secret'
              variant='outlined'
              type='password'
              className={styles.inputField}
              value={
                (bitbucketSecret == null
                  ? ''
                  : bitbucketSecret[1] || bitbucketSecret[0])
              }
              // onClick={copyBitbucketSecret}
              disabled
            ></TextField>
            {(
              <IconButton aria-label='copy' onClick={copyBitbucketSecret} disabled={!writeable}>
                <ContentCopySharpIcon />
              </IconButton>
            )}
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Workspace Id</InputLabel>
            <TextField
              id='workspace-id'
              placeholder='Workspace Id
              '
              variant='outlined'
              className={styles.inputField}
              value={bitbucketForm.workspace_id}
              name='workspace_id'
              disabled={!writeable}
              required
              onChange={onFormChange}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Repository Name
            </InputLabel>
            <TextField
              id='repository-name'
              placeholder='Repository Name
              '
              variant='outlined'
              className={styles.inputField}
              value={bitbucketForm.repo_name}
              disabled={!writeable}
              name='repo_name'
              required
              onChange={onFormChange}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Bitbucket Username
            </InputLabel>
            <TextField
              id='bitbucket-username'
              placeholder='Bitbucket Username
              '
              variant='outlined'
              className={styles.inputField}
              value={bitbucketForm.username}
              disabled={!writeable}
              name='username'
              required
              onChange={onFormChange}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>App Password</InputLabel>
            <TextField
              id='app-password'
              placeholder='App Password
              '
              variant='outlined'
              className={styles.inputField}
              value={bitbucketForm.app_password}
              onChange={onFormChange}
              required
              disabled={!writeable}
              name='app_password'
              type='password'
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
            Create additional pull request on scan completion
            </InputLabel>
            <Checkbox
              onChange={checkboxPR}
              checked={bitbucketForm.is_pull_request_enabled}
              disabled={!writeable}
            />
          </div>
          <div className={styles.formButtonContainer}>
            <Button type='submit' variant='contained' disabled={!writeable}>
              Verify and Save
            </Button>
            {bitbucketSecret?.length > 1 && (
              <Button
                variant='contained'
                onClick={deleteBitbucketRepo}
                disabled={!writeable}
              >
                Disconnect Bitbucket Repo
              </Button>
            )}
          </div>
        </form>
      </AppCard>
      {apiError &&
      <AppMessageView variant='error' message={apiError.toString()} />
      }
    </Fragment>
  );
};

export default Bitbucket;
Bitbucket.propTypes = {
  writeable: PropTypes.bool,
};
