import React from 'react';
import {PieChart, Pie, Cell, Tooltip} from 'recharts';
import {AppCard} from '@crema';
import {ResponsiveContainer} from 'recharts';
import {useSelector} from 'react-redux';
import {getRandomColor} from 'components/utils/UtilFunctions';
import PropTypes from 'prop-types';
import styles from './ScanStatistics.module.css';

// const data = [
//   {name: 'Group A', value: 400},
//   {name: 'Group B', value: 300},
//   {name: 'Group C', value: 300},
//   {name: 'Group D', value: 200},
// ];

const CustomTooltip = ({active, payload}) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <p className='label'>{`${payload[0].name} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string,
};

export default function VulnerabilityTypes() {
  const getScanStats = useSelector(({reports}) => reports.getScanStats);

  let data = [];
  getScanStats?.findingtypes?.map((cur) => {
    data.push({
      name: cur.issuetype || '',
      value: cur.count || 0,
      color: getRandomColor(),
    });
  });

  return (
    <AppCard sxStyle={{height: 500}} title='Vulnerability Types'>
      {data?.length > 0 && (
        <ResponsiveContainer height='100%' width='100%'>
          <PieChart>
            <Pie
              data={data}
              // cx={200}
              // cy={200}
              innerRadius={120}
              // outerRadius={80}
              fill='#8884d8'
              dataKey='value'
              paddingAngle={2}
            >
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </AppCard>
  );
}
