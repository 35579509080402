import React from 'react';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import {Box, alpha} from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import {Link} from 'react-router-dom';

const alertStyles = {
  backgroundColor: 'transparent !important',
  padding: 0,
  textAlign: 'center',
  color: 'inherit',
  '& .MuiAlert-message': {
    flex: 1,
  },
  '& .MuiAlert-action': {
    ml: 2.5,
  },
};

const alterMain = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

const linkSetup = {
  textDecoration: 'none',
};

const NotificationBar = () => {
  const [open, setOpen] = React.useState(true);
  const {sidebarBgColor, sidebarTextColor} = useSidebarContext();

  return (
    <Collapse in={open}>
      <Box
        sx={{
          borderBottom: (theme) =>
            `solid 1px ${alpha(theme.palette.common.black, 0.15)}`,
          padding: '2px 0',
          backgroundColor: sidebarBgColor,
          color: sidebarTextColor,
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: {lg: 1140, xl: 1420},
            mx: 'auto',
            px: 5,
          }}
        >
          <Alert
            sx={alertStyles}
            icon={false}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            <div style={alterMain}>
              <ErrorSharpIcon color='secondary' />
              <p>
                Trial Expired! &nbsp;
                <Link to='/setup' style={linkSetup}>
                  Subscribe Now
                </Link>
              </p>
            </div>
          </Alert>
        </Box>
      </Box>
    </Collapse>
  );
};

export default NotificationBar;
