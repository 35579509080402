import React from 'react';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import styles from './AuthenticationConfirmation.module.css';
import PropTypes from 'prop-types';
import ForwardToInboxSharpIcon from '@mui/icons-material/ForwardToInboxSharp';

import {Button} from '@mui/material';

const authIcon = {fontSize: 90};

const AuthenticationConfirmation = ({
  authenticationModal,
  authenticationModalClose,
  authenticationEnable,
  authenticationDisable,
  activated,
}) => {
  return (
    <ModalContainer
    open={authenticationModal}
    handleModalClose={authenticationModalClose}
  >
    <div className={styles.authenticationContainer}>
      <div>
        <ForwardToInboxSharpIcon color='primary' sx={authIcon} />
      </div>

      <h3>{!activated ? 'Do you want to disable Two-Factor Authentication for all users?' : 'Do you want to enable Two-Factor Authentication for all users?' }</h3>

      <div className={styles.deleteBtnContainer}>
        <Button
          variant='contained'
          onClick={authenticationModalClose}
          color='secondary'
        >
          No
        </Button>

        <Button
          variant='contained'
          onClick={!activated ? authenticationDisable : authenticationEnable}
          color='primary'
        >
          Yes
        </Button>
      </div>
    </div>
  </ModalContainer>
  );
};

AuthenticationConfirmation.propTypes = {
  authenticationModal: PropTypes.bool,
  activated: PropTypes.bool,
  authenticationModalClose: PropTypes.func,
  authenticationEnable: PropTypes.func,
  authenticationDisable: PropTypes.func,
};

export default AuthenticationConfirmation;
