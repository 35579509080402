import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './SSOIntegration.module.css';
import {Button} from '@mui/material';
import {InputLabel} from '@mui/material';
import TextField from '@mui/material/TextField';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import {useDispatch} from 'react-redux';
import {onPostUpdateSSO} from 'redux/actions';

const SSOIntegration = ({SSOModalClose}) => {
  const dispatch = useDispatch();
  const [SSOForm, setSSOForm] = useState({
    samlURL: '',
    issuer: '',
    certificate: '',
  });

  const SSOFormSubmit = (event) => {
    event.preventDefault();
    dispatch(onPostUpdateSSO({SSOForm}));
  };

  const onSSOFormChangeHandler = (event) => {
    setSSOForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  return (
    <ModalContainer open={true} handleModalClose={SSOModalClose}>
      <div className={styles.mainContainer}>
        <h2>Enter SSO Details </h2>
        <form onSubmit={SSOFormSubmit}>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Identity Provider SSO Url
            </InputLabel>
            <TextField
              id='sso-url'
              placeholder='Identity Provider SSO Url'
              variant='outlined'
              className={styles.inputField}
              name='samlURL'
              required
              onChange={onSSOFormChangeHandler}
              value={SSOForm.samlURL}
              type='url'
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Identity Provider Issuer
            </InputLabel>
            <TextField
              id='provider-issuer'
              placeholder='Identity Provider Issuer'
              variant='outlined'
              className={styles.inputField}
              name='issuer'
              required
              onChange={onSSOFormChangeHandler}
              value={SSOForm.issuer}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              X.509 Certificate
            </InputLabel>
            <TextField
              id='x509-certificate'
              placeholder='X.509 Certificate'
              variant='outlined'
              className={styles.inputField}
              name='certificate'
              required
              onChange={onSSOFormChangeHandler}
              value={SSOForm.certificate}
            ></TextField>
          </div>

          <div className={styles.btnContainer}>
            <Button type='submit' variant='contained'>
              Verify and Save
            </Button>
          </div>
        </form>

        <div className={styles.helpManual}>
          <h4>Help</h4>
          <p>SAML Implementation guides:</p>
          <div className={styles.helpLinks}>
            <a
              className={styles.singleHelpLink}
              rel='noreferrer'
              target='_blank'
              href='https://docs.digitsec.com/hc/en-us/articles/360062119892-Azure-Active-Directory-SAML-Integration'
            >
              Azure Active Directory
            </a>
            <a
              className={styles.singleHelpLink}
              rel='noreferrer'
              target='_blank'
              href='https://docs.digitsec.com/hc/en-us/articles/360062457251-Okta-SAML-Integration'
            >
              Okta
            </a>
            <a
              className={styles.singleHelpLink}
              rel='noreferrer'
              target='_blank'
              href='https://docs.digitsec.com/hc/en-us/articles/360062123952-OneLogin-SAML-Integration'
            >
              OneLogin
            </a>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

SSOIntegration.propTypes = {
  SSOModalClose: PropTypes.func,
};

export default SSOIntegration;
