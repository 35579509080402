import React, {useState} from 'react';
import {useEffect} from 'react';
import {AppCard} from '@crema';
import {Button} from '@mui/material';
import styles from './Tags.module.css';
import {DataGrid} from '@mui/x-data-grid';
import {MoreHoriz as MoreHorizIcon} from '@mui/icons-material';
import {Menu, MenuItem} from '@mui/material';
import {
  gridClasses,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import ModalTags from './ModalTags';
import ModalTagsBulk from './ModalTagsBulk';
import {deleteTags} from 'redux/actions/Tags';
import {restoreTags} from 'redux/actions/Tags';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import {getTags} from 'redux/actions/Tags';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import {onGetScanTypes} from 'redux/actions';
// import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  DUPLICATE_TAGS,
  DUPLICATE_TAGS_DATA,
} from 'shared/constants/ActionTypes';
// import {GET_SCAN_TYPES} from 'shared/constants/ActionTypes';
// import {onGetGlobalConfigs} from 'redux/actions';

const Tags = (isActiveTab) => {
  //   const scanTypes = useSelector(({dashboard}) => dashboard.scanTypes);
  //   const globalConfigs = useSelector(({configure}) => configure.globalConfigs);
  //   const isAdmin = useSelector(({authentication}) => authentication.isAdmin);
  //   let {org_id} = useParams();
  //   const dispatch = useDispatch();

  //   useEffect(() => {
  //     if (!isGlobal) {
  //       dispatch(onGetScanTypes({org_id}));
  //     }
  //     return () => {
  //       dispatch({type: GET_SCAN_TYPES, payload: null});
  //     };
  //   }, [org_id]);

  //   useEffect(() => {
  //     dispatch(onGetGlobalConfigs());
  //   }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTags());
  }, [dispatch]);
  const deleteIcon = {fontSize: 60};
  //Modal for creation
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [idsSelected, setIdsSelected] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editBulk, setEditBulk] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openModalBulk, setOpenModalBulk] = useState(false);
  const allTags = useSelector(({tags}) => tags.allTagsData);
  const globalTags = useSelector(({roles}) => roles.globalTags);

  // const handleRowClick = (params) => {
  //   setSelectedRow(params.row);
  // };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalBulk = () => {
    setOpenModalBulk(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch({type: DUPLICATE_TAGS, payload: false});
    dispatch({type: DUPLICATE_TAGS_DATA, payload: []});
  };

  const handleCloseModalBulk = () => {
    setOpenModalBulk(false);
    dispatch({type: DUPLICATE_TAGS, payload: false});
    dispatch({type: DUPLICATE_TAGS_DATA, payload: []});
  };

  //DataGrid Elements
  const columns = [
    {field: '_id', headerName: 'ID', width: 200},
    {
      field: 'category',
      headerName: 'Category',
      width: 200,
      editable: false,
      valueFormatter: ({value}) => {
        if (value) {
          const capitalizedStatus =
            value.charAt(0).toUpperCase() + value.slice(1);
          return capitalizedStatus;
        }
        return '';
      },
    },
    {
      field: 'key',
      headerName: 'Key',
      width: 200,
      editable: false,
      valueFormatter: ({value}) => {
        if (value) {
          // const capitalizedStatus =
          //   value.charAt(0).toUpperCase() + value.slice(1);
          // return capitalizedStatus;
          return value;
        }
        return '';
      },
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 200,
      editable: false,
      valueFormatter: ({value}) => {
        if (value) {
          // const capitalizedStatus =
          //   value.charAt(0).toUpperCase() + value.slice(1);
          // return capitalizedStatus;
          return value;
        }
        return '';
      },
    },
    {
      field: 'deletedBy',
      headerName: 'Deactivated by',
      width: 250,
      editable: false,
      hide: isActiveTab.isActiveTab === true,
    },
    {
      field: 'url',
      headerName: 'URL',
      width: 200,
      editable: false,
      renderCell: (params) => {
        const row = params.row;
        const urlvalue = row.url;

        if (!urlvalue) {
          return (
            <div>
              <span>No URL</span>
            </div>
          );
        }
      },
    },
    {
      field: isActiveTab.isActiveTab ? 'created_at' : 'deletedAt',
      headerName: isActiveTab.isActiveTab ? 'Date Created' : 'Date Deactivated',
      width: 200,
      editable: false,
      valueFormatter: ({value}) => {
        if (value) {
          const date = new Date(value);
          const formattedDate = date.toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
          });
          return formattedDate;
        }
        return '';
      },
    },
    {
      field: '           ',
      headerName: 'Actions',
      width: 200,
      editable: false,
      renderCell: (params) => {
        const row = params.row;
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
          setSelectedRow(row);
          setAnchorEl(event.currentTarget);
          setIdsSelected((prevIdsSelected) => [...prevIdsSelected, row._id]);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        return (
          <div>
            <Button
              aria-controls='custom-menu'
              aria-haspopup='true'
              onClick={handleClick}
              variant='text'
            >
              <MoreHorizIcon />
            </Button>
            <Menu
              id='custom-menu-options'
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                disabled={!globalTags.write}
                sx={{display: isActiveTab.isActiveTab ? 'block' : 'none'}}
                onClick={() => {
                  setAnchorEl(null);
                  setSelectedRow(row);
                  setEditMode(true);
                  handleOpenModal();
                  setEditBulk(false);
                }}
              >
                Edit
              </MenuItem>

              <MenuItem onClick={deleteModalOpen} disabled={!globalTags.write}>
                {isActiveTab.isActiveTab ? 'Deactivate Tag' : 'Restore Tag'}
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport printOptions={{disableToolbarButton: true}} />
        </GridToolbarContainer>
      </GridToolbarContainer>
    );
  }

  //delete modal
  const [openSnackbarDeleted, setOpenSnackbarDeleted] = useState(false);

  const handleCloseSnackbarDeleted = () => {
    setOpenSnackbarDeleted(false);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const deleteModalOpen = () => {
    setDeleteModal(true);
  };
  const deleteModalClose = () => {
    setDeleteModal(false);
  };

  //  const [newTagData, setNewTagData] = useState({
  //   key: '',
  //   value: '',
  //   url: '',
  //   category: '',
  // });

  const handleSelectionModelChange = (newSelection) => {
    setIdsSelected(newSelection);
    setSelectedRows(
      newSelection.map((id) => {
        const row = filteredRows.find((row) => row._id === id);
        return {
          _id: row._id,
          key: row.key,
          value: row.value,
          url: row.url,
          category: row.category,
        };
      }),
    );
  };

  const handleDeleteClick = async () => {
    let tagIds = idsSelected;
    await dispatch(deleteTags(tagIds));
    setDeleteModal(false);
    setOpenSnackbarDeleted(true);
  };

  const handleRestore = async () => {
    let tagIds = idsSelected;
    await dispatch(restoreTags(tagIds));
    setDeleteModal(false);
    setOpenSnackbarDeleted(true);
  };

  const [deleteBulkModal, setDeleteBulkModal] = useState(false);
  const openDeleteModal = () => {
    setDeleteBulkModal(true);
  };

  const openBulkEdit = () => {
    setEditBulk(true);
  };

  const closeDeleteBulkModal = () => {
    setDeleteBulkModal(false);
  };

  const deleteBulkTags = async () => {
    let tagIds = idsSelected;
    await dispatch(deleteTags(tagIds));
    setOpenSnackbarDeleted(true);
    closeDeleteBulkModal();
  };

  const handleRestoreBulk = async () => {
    let tagIds = idsSelected;
    await dispatch(restoreTags(tagIds));
    setOpenSnackbarDeleted(true);
    closeDeleteBulkModal();
  };

  let filteredRows = [];
  if (isActiveTab.isActiveTab) {
    filteredRows = (allTags || []).filter((tag) => tag.isDeleted === false);
  } else {
    filteredRows = (allTags || []).filter((tag) => tag.isDeleted === true);
  }

  const handleRemoveTag = (indexToRemove) => {
    const updatedRows = selectedRows.filter(
      (_, index) => index !== indexToRemove,
    );
    setSelectedRows(updatedRows);
  };

  const handleSaveElements = () => {
    setSelectedRows([]);
  };

  const handleDeselectRow = () => {
    setSelectedRows([]);
    setSelectedRows([]);
    setIdsSelected([]);
    setSelectedRow([]);
    setEditMode(false);
  };

  return (
    <>
      <AppCard>
        <div className={styles.main}>
          <div className={styles.headerContainer}>
            <h2>{isActiveTab.isActiveTab ? 'Active Tags' : 'Inactive Tags'}</h2>
            <Button
              disabled={!globalTags.write}
              sx={{
                marginRight: 1,
                display: isActiveTab.isActiveTab ? 'block' : 'none',
              }}
              variant='contained'
              onClick={() => {
                setEditMode(false);
                handleOpenModal();
              }}
            >
              + Create a New Tag
            </Button>
          </div>
          {idsSelected.length > 0 && (
            <div className={styles.btnContainer}>
              <p className={`${styles.pContainer} ${globalTags.write ? '' : styles.disabled}`} onClick={openDeleteModal}>
                {isActiveTab.isActiveTab
                  ? 'Deactivate all selections'
                  : 'Restore all selections'}
              </p>
            </div>
          )}
          {idsSelected.length > 0 && isActiveTab.isActiveTab && (
            <div className={styles.btnContainer}>
              <p
                className={`${styles.pContainer} ${globalTags.write ? '' : styles.disabled}`}
                onClick={() => {
                  openBulkEdit();
                  handleOpenModalBulk();
                }}
              >
                Edit all selections
              </p>
            </div>
          )}
          <div
            style={{
              height: 500,
              width: '100%',
              overflow: 'auto',
              paddingTop: '1%',
            }}
          >
            <DataGrid
              // id='logs-dt'
              rows={filteredRows}
              getRowId={(row) => row._id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              checkboxSelection
              // onRowClick={handleRowClick}
              onSelectionModelChange={handleSelectionModelChange}
              disableSelectionOnClick={true}
              hideFooterPagination={false}
              hideFooter={false}
              hideFooterSelectedRowCount={false}
              columns={columns}
              components={{
                Toolbar: CustomToolbar,
              }}
              sx={{
                '& .MuiDataGrid-cell': {
                  lineHeight: 'unset !important',
                  maxHeight: 'none !important',
                  whiteSpace: 'normal',
                  justifyContent: 'center !important',
                  textAlign: 'center !important',
                },
              }}
              selectionModel={selectedRows.map((row) => row._id)}
              onRowClick={(params) => {
                setSelectedRows([params.row]);
              }}
            />
          </div>
        </div>
        <ModalTags
          open={openModal}
          handleClose={handleCloseModal}
          selectedRow={selectedRow}
          editMode={editMode}
          deselectRow={handleDeselectRow}
        />
        <ModalTagsBulk
          selectedRows={selectedRows}
          editBulk={editBulk}
          open={openModalBulk}
          handleClose={handleCloseModalBulk}
          handleRemoveTag={handleRemoveTag}
          handleSaveElements={handleSaveElements}
          deselectRow={handleDeselectRow}
        />
      </AppCard>
      {/* Delete Modal*/}
      <Modal open={deleteModal} onClose={deleteModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
            padding: 7,
            width: '800px',
            minHeight: '300px',
            overflowY: 'auto',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
            }}
          >
            <DeleteIcon sx={deleteIcon} color='secondary' />
            <h2>
              {isActiveTab.isActiveTab
                ? 'Do you want to deactivate this Tag?'
                : 'Do you want to restore this Tag?'}
            </h2>
            &nbsp;
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: 16,
              }}
            >
              <Button
                variant='contained'
                color='secondary'
                onClick={deleteModalClose}
                style={{marginLeft: 8}}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                variant='contained'
                color='primary'
                onClick={
                  isActiveTab.isActiveTab ? handleDeleteClick : handleRestore
                }
              >
                {isActiveTab.isActiveTab ? 'Deactivate Tag' : 'Restore Tag'}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      {/* Delete Modal*/}
      <ModalContainer
        open={deleteBulkModal}
        handleModalClose={closeDeleteBulkModal}
        handleModalProceed={
          isActiveTab.isActiveTab ? deleteBulkTags : handleRestoreBulk
        }
        closeButtonText='No'
        proceedButtonText='Yes'
      >
        <div className={styles.deleteContainer}>
          <DeleteIcon color='secondary' sx={{fontSize: 100}} />
          <h2>
            {isActiveTab.isActiveTab
              ? 'Do you want to deactivate all the tags selected?'
              : 'Do you want to restore all the tags selected? '}
          </h2>
        </div>
      </ModalContainer>
      {/*Snack bar delete */}
      <Snackbar
        open={openSnackbarDeleted}
        autoHideDuration={3000}
        onClose={handleCloseSnackbarDeleted}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleCloseSnackbarDeleted}
          severity='success'
          sx={{alignItems: 'center', color: 'white'}}
        >
          {isActiveTab.isActiveTab
            ? 'Tags Successfully Deactivated'
            : 'Tags Successfully Restored'}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Tags;
Tags.propTypes = {
  writeable: PropTypes.bool,
  isGlobal: PropTypes.bool,
  isActiveTab: PropTypes.bool,
};
