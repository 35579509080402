import React from 'react';
import Scan from './Scan.js';

if(document.location.href.includes('#scan')){
        document.location.href = '/app/scan/'+document.location.hash.split('/')[document.location.hash.split('/').length-1];
      }
export const scanOldLinkRedirect = [
  {
    path: '/scan/:scan_id',
    element: <Scan />,
  },
];
