import {
  GET_ERRORS_DATA,
  GET_TRENDING_DATA,
  GET_SECURITY_DATA,
  GET_ISSUES_DATA,
  SET_SELECTED_IDS,
  SET_SOCKET_VALUE,
  TOTAL_ISSUES,
  GET_ISSUES_PAGE_COUNT,
  GET_ISSUES_CUR_PAGE,
  GET_ISSUES_FILTERS,
  GET_CUR_ORG_ID,
  GET_CUR_ORG_NAME,
  GET_CUR_REPORT_ID,
  GET_SCAN_TYPES,
  GET_CUSTOM_RULES,
  APP_BAR_DASHBOARD,
  GET_SCAN_RULES,
  DASHBOARD_RESPONSE,
  SINGLE_ORG,
  CUR_ISSUES_FILTER_STATE,
  Set_Filter_Data,
  Set_Initial,
  Set_Total_Rows,
  Set_PageSize,
  Set_PageCount,
  Set_Initial_True,
  Remove_Filter_Data,
  GET_ISSUES_DATA_FOR_DOWNLOAD,
  GET_TAGS_DATA_ISSUES,
  SCAN_ASSIGN_RESPONSE,
// Roles
} from 'shared/constants/ActionTypes';

const initialState = {
  scanAssignResponse: null,
  errorData: null,
  trendingData: null,
  securityData: null,
  issuesData: [],
  selectedIds: [],
  downloadData:[],
  tagsIssuesData: [],
  issuesPageCount: 0,
  issuesCurPage: parseInt(new URLSearchParams(window.location.search).get('page'), 10) || 1,
  issuesFilters: {
    issueTypes: [],
    assignTo: [],
    severity: [],
    status: [],
    category: [],
    customFields: [],
    compliance: [],
    cwe: [],
    owasp: [],
    tags: [],
  },
  curOrgId: null,
  curOrgName: null,
  curReportId: null,
  scanTypes: null,
  customRules: null,
  scanRules: null,
  appBarDashboard: 'dashboard',
  dashboardResponse: null,
  singleOrg: null,
  curIssuesFilterState: null,
  filterData:{
    items:[],
  },
  initial:true,
  pageSize:25,
  pageCount:0,
  totalRows:0,
  columns:[],
  socketValue: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERRORS_DATA:
      return {
        ...state,
        errorData: action.payload,
      };
    case GET_TRENDING_DATA:
      return {
        ...state,
        trendingData: action.payload,
      };
    case GET_SECURITY_DATA:
      return {
        ...state,
        securityData: action.payload,
      };
    case GET_ISSUES_DATA_FOR_DOWNLOAD:
      return {
        ...state,
        downloadData: action.payload,
      };
      case   GET_TAGS_DATA_ISSUES:
      return {
        ...state,
        tagsIssuesData: action.payload,
      };
    case GET_ISSUES_DATA:
      return {
        ...state,
        issuesData: action.payload,
      };
    case SET_SELECTED_IDS:
      return {
        ...state,
        selectedIds: action.payload,
      };
    case SET_SOCKET_VALUE:
      return {
        ...state, 
        socketValue: action.payload};
    case TOTAL_ISSUES:
      return {
        ...state,
        totalIssues: action.payload,
      };
    case GET_ISSUES_PAGE_COUNT:
      return {
        ...state,
        issuesPageCount: action.payload,
      };

    case GET_ISSUES_CUR_PAGE:
      return {
        ...state,
        issuesCurPage: action.payload,
      };

    case GET_ISSUES_FILTERS:
      return {
        ...state,
        issuesFilters: action.payload,
      };

    case GET_CUR_ORG_ID:
      return {
        ...state,
        curOrgId: action.payload,
      };
    case GET_CUR_ORG_NAME:
      return {
        ...state,
        curOrgName: action.payload,
      };
    case GET_CUR_REPORT_ID:
      return {
        ...state,
        curReportId: action.payload,
      };
    case GET_SCAN_TYPES:
      return {
        ...state,
        scanTypes: action.payload,
      };
    case GET_CUSTOM_RULES:
      return {
        ...state,
        customRules: action.payload,
      };
    case GET_SCAN_RULES:
      return {
        ...state,
        scanRules: action.payload,
      };

    case APP_BAR_DASHBOARD:
      return {
        ...state,
        appBarDashboard: action.payload,
      };
    case DASHBOARD_RESPONSE:
      return {
        ...state,
        dashboardResponse: action.payload,
      };
    case SINGLE_ORG:
      return {
        ...state,
        singleOrg: action.payload,
      };
    case CUR_ISSUES_FILTER_STATE:
      return {
        ...state,
        curIssuesFilterState: action.payload,
      };
    case Set_Filter_Data:
      return {
        ...state,
        filterData: action.payload,
      };
    case Set_Initial:
      return {
        ...state,
        initial: false,
      };
    case Set_Total_Rows:
      return {
        ...state,
        totalRows: action.payload,
      };
    case Set_PageSize:
      return {
        ...state,
        pageSize: action.payload,
      };
    case Set_PageCount:
      return {
        ...state,
        pageCount: action.payload,
      };
    case Set_Initial_True:
      return {
        ...state,
        initial: true,
      };
    case Remove_Filter_Data:
      return {
        ...state,
        filterData: {items: []},
      };
      case SCAN_ASSIGN_RESPONSE:
      return {
        ...state,
        scanAssignResponse: action.payload,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
