import React, {Fragment, useState, useEffect} from 'react';
import AppCard from '@crema/core/AppCard';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import styles from '../Integration.module.css';
import {Button} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {GITLAB_SECRET, GITLAB_INFO} from 'shared/constants/ActionTypes';
import {
  onPostGitlabSecret,
  onPostGitlabInfo,
  onPostGitlabDelete,
  onGetGitlabInfo
} from 'redux/actions';
import {IconButton} from '@mui/material';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import {SHOW_MESSAGE} from 'shared/constants/ActionTypes';
import PropTypes from 'prop-types';
import AppMessageView from '@crema/core/AppMessageView';

function Gitlab({writeable}) {
  const dispatch = useDispatch();
  let {org_id} = useParams();
  const apiError = useSelector(({integration}) => integration.apiError);
  const gitlabSecret = useSelector(({integration}) => integration.gitlabSecret);
  const gitlabInfo = useSelector(({integration}) => integration.gitlabinfo);

  const singleOrg = useSelector(({dashboard}) => dashboard.singleOrg);

  const [gitlabForm, setGitlabForm] = useState({
    gitlabUsername: '',
    gitlabPat: '',
    gitlabPatName: '',
    is_pull_request_enabled: false,
    gitlabRepoName: '',
  });

  useEffect(() => {
    dispatch(onPostGitlabSecret({org_id}));
    if(singleOrg?.repoInfo?.hasGitLabRepo){
      dispatch(onGetGitlabInfo({gitlabId: singleOrg.repoInfo?.gitlabRepoId, org_id: org_id}));
    }
    return () => {
      dispatch({type: GITLAB_SECRET, payload: null});
      dispatch({type: GITLAB_INFO, payload: null});

    };
  }, [org_id]);

  useEffect(() => {
    if(gitlabInfo){
      setGitlabForm({
        
        gitlabUsername: gitlabInfo.gitlabUsername || '',
        gitlabPatName:  gitlabInfo.gitlabPatName || '',
        gitlabPat: '',
        is_pull_request_enabled: gitlabInfo.is_pull_request_enabled || false,
        gitlabRepoName: gitlabInfo.gitlabRepoName || '',
      });
    }
}, [gitlabInfo]);

  const onFormChange = (event) => {
    setGitlabForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  const checkboxPR = (event) => {
    setGitlabForm((cur) => {
      return {
        ...cur,
        is_pull_request_enabled: event.target.checked,
      };
    });
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    dispatch(onPostGitlabInfo({...gitlabForm, org_id}));
    setGitlabForm({
      gitlabUsername: gitlabForm.gitlabUsername,
      gitlabPat: '',
      gitlabPatName: gitlabForm.gitlabPatName,
      is_pull_request_enabled: gitlabForm.is_pull_request_enabled,
      gitlabRepoName: gitlabForm.gitlabRepoName,
    });
  };

  const deleteGitlabRepo = () => {
    dispatch(onPostGitlabDelete({org_id}));
    setGitlabForm({
      gitlabUsername: '',
      gitlabPat: '',
      gitlabPatName: '',
      is_pull_request_enabled: false,
      gitlabRepoName: '',
    });
  };

  const copyGitlabSecret = () => {

    navigator.clipboard.writeText(
      `https://s4.digitsec.com/webhooks/gitLab/${gitlabSecret[0]}`,
    );
    dispatch({type: SHOW_MESSAGE, payload: 'Gitlab Secret Copied'});

  };
  return (
    <Fragment>
      <AppCard title='Gitlab'>
        <form onSubmit={onFormSubmit}>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Gitlab Secret</InputLabel>
            <TextField
              id='gitlab-secret'
              placeholder='Gitlab Secret'
              variant='outlined'
              type='password'
              className={styles.inputField}
              value={(gitlabSecret == null ? '' : gitlabSecret[0])}
              disabled
              // onClick={copyGitlabSecret}
            ></TextField>
            {(
              <IconButton aria-label='copy' onClick={copyGitlabSecret} disabled={!writeable}>
                <ContentCopySharpIcon />
              </IconButton>
            )}
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Username</InputLabel>
            <TextField
              id='gitlab-username'
              placeholder='Gitlab Username'
              variant='outlined'
              className={styles.inputField}
              value={gitlabForm.gitlabUsername}
              name='gitlabUsername'
              required
              onChange={onFormChange}
              disabled={!writeable}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Repo Name</InputLabel>
            <TextField
              id='repo-name'
              placeholder='Repo Name'
              variant='outlined'
              className={styles.inputField}
              value={gitlabForm.gitlabRepoName}
              name='gitlabRepoName'
              required
              onChange={onFormChange}
              disabled={!writeable}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Gitlab PAT Name
            </InputLabel>
            <TextField
              id='gitlab-pat-name'
              placeholder='Gitlab Pat Name'
              variant='outlined'
              className={styles.inputField}
              value={gitlabForm.gitlabPatName}
              name='gitlabPatName'
              required
              onChange={onFormChange}
              disabled={!writeable}
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>Gitlab PAT</InputLabel>
            <TextField
              id='gitlab-pat'
              placeholder='Gitlab Pat'
              variant='outlined'
              className={styles.inputField}
              value={gitlabForm.gitlabPat}
              name='gitlabPat'
              required
              onChange={onFormChange}
              disabled={!writeable}
              type='password'
            ></TextField>
          </div>
          <div className={styles.inputContainer}>
            <InputLabel
              className={styles.labelInput}
            >
              Create additional pull request on scan completion
            </InputLabel>
            <Checkbox 
            checked={gitlabForm.is_pull_request_enabled}
            onChange={checkboxPR}
            disabled={!writeable} />
          </div>
          <div className={styles.formButtonContainer}>
            <Button type='submit' variant='contained' disabled={!writeable}>
              Verify and Save
            </Button>
            {gitlabSecret?.length > 1 && gitlabSecret[1].length > 0 && (
              <Button
                variant='contained'
                onClick={deleteGitlabRepo}
                disabled={!writeable}
              >
                Disconnect Gitlab Repo
              </Button>
            )}
          </div>
        </form>
      </AppCard>
      {apiError &&
      <AppMessageView variant='error' message={apiError.toString()} />
      }
    </Fragment>
  );
}

export default Gitlab;
Gitlab.propTypes = {
  writeable: PropTypes.bool,
};
