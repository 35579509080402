import React from 'react';
import {Box} from '@mui/material';
import PropsTypes from 'prop-types';
import Splash from 'assets/images/Dashboard/splash2.svg';

const FooterWrapper = ({children, ...rest}) => {
  return (
    <Box
      sx={{
        textAlign:'center',
        backgroundColor: (theme) => theme.palette.background.paper,
        backgroundImage: `url(${Splash})`,
        backgroundSize: 'cover',
        color:'#7691ab',
        '& .footerContainer': {
          textAlign:'center',
          flexDirection: 'row',
          display: 'flex',
          padding: {xs: '5px 20px', md: '5px 32px', xl: '10px 32px'},
        },
        '&.full': {
          position: 'relative',
          width: '100vw',
          left: 'calc(-50vw + 50%)',
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default FooterWrapper;

FooterWrapper.propTypes = {
  children: PropsTypes.node,
};
