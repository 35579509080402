import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ERRORS_DATA,
  GET_TRENDING_DATA,
  IS_API_COMPLETED,
  IS_API_IN_PROCESS,
  GET_SECURITY_DATA,
  TOTAL_ISSUES,
  GET_ISSUES_DATA,
  GET_ISSUES_PAGE_COUNT,
  GET_ISSUES_FILTERS,
  GET_SCAN_TYPES,
  GET_CUSTOM_RULES,
  GET_SCAN_RULES,
  DASHBOARD_RESPONSE,
  SINGLE_ORG,
  GET_CUR_REPORT_ID,
  APP_BAR_DASHBOARD,
  GET_ISSUES_CUR_PAGE,
  Set_Filter_Data,
  Set_Initial,
  Set_Total_Rows,
  Set_PageSize,
  Set_PageCount,
  Set_Initial_True,
  Remove_Filter_Data,
  GET_ISSUES_DATA_FOR_DOWNLOAD,
  SET_SELECTED_IDS,
  GET_TAGS_DATA_ISSUES,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {defaultError} from 'shared/constants/AppConst';

// Refresh Dashboard

export const onRefreshDashboard = () => {
  return (dispatch) => {
    dispatch({type: APP_BAR_DASHBOARD, payload: 'dashboard'});
    dispatch({type: GET_CUR_REPORT_ID, payload: null});
  };
};

// GetErrors

export const onGetErrors = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get('/dashboard/stats')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_ERRORS_DATA, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

// Trending Scans

export const onGetTrendingScans = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get('/dashboard/trendingscans')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_TRENDING_DATA, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

// Security Gate PUT

export const onPutSecurityGate = ({config, org_id}) => {
  return (dispatch) => {
    config.orgId = org_id;
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`saveOrgSecurityGateConfigs/${org_id}`, config)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_COMPLETED, payload: true});
          dispatch(onGetSingleOrg({org_id}));
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: 'Security Gate Configs Updated!',
            },
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });

          dispatch({
            type: IS_API_COMPLETED,
            payload: true,
          });
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              result: 'fail',
              message: 'Something went Wrong! Please try again',
            },
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_COMPLETED, payload: true});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            result: 'fail',
            message: 'Network Error',
          },
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPutSecurityGateDisabled = ({config, org_id}) => {
  return (dispatch) => {
    config.orgId = org_id;
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`saveOrgSecurityGateConfigs/${org_id}`, config)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_COMPLETED, payload: true});
          dispatch(onGetSingleOrg({org_id}));
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });

          dispatch({
            type: IS_API_COMPLETED,
            payload: true,
          });
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              result: 'fail',
              message: 'Something went Wrong! Please try again',
            },
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_COMPLETED, payload: true});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            result: 'fail',
            message: 'Network Error',
          },
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

// Security Gate GET

export const onGetSingleOrg = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`/sfdcinfo/${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_SECURITY_DATA,
            payload: data.data.securityGateConfigs,
          });
          dispatch({type: SINGLE_ORG, payload: data.data});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};


export const getTagsBulk = () => {
  return (dispatch) => {
    jwtAxios
      .get(`/tags/getTags`)
      .then((response) => {
        dispatch({
          type: GET_TAGS_DATA_ISSUES,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const createTagsIssues = (tags, orgId, ui = true) => {
  return (dispatch) => {
    const postURL = '/tags/createTags';
    jwtAxios
      .post(postURL, {tags, ui, orgId})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(getTagsBulk());
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          console.log('error 400');
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: error.message,
          });
        }
      });
  };
};

// On Get Issues Data

const transIssuesRequest = (arr, needLowerCase = null) => {
  return arr !== 'null' && arr?.length > 0
    ? needLowerCase
      ? arr.map((item) => item.toLowerCase())
      : arr
    : 'null';
};

export const getIssuesCleanRequest = ({
  _id,
  page = 1,
  search = '',
  historyIssueId = '',
  severityType = 'null',
  assign = 'null',
  type = 'null',
  status = 'null',
  tagIds = 'null',
  category = 'null',
  compliance = 'null',
  cwe = 'null',
  owasp = 'null',
  orgId,
  filterData=null,
  pageSize=null,
  isDownload=false,
  isFindingsNew=false,
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
    dispatch(
      onGetIssuesData({
        _id,
        page,
        search,
        historyIssueId,
        severityType: transIssuesRequest(severityType, true),
        assign: transIssuesRequest(assign),
        type: transIssuesRequest(type),
        status: transIssuesRequest(status),
        tagIds: transIssuesRequest(tagIds),
        category: transIssuesRequest(category, true),
        compliance: transIssuesRequest(compliance),
        cwe: transIssuesRequest(cwe),
        owasp: transIssuesRequest(owasp),
        orgId: orgId,
        filterData:filterData,
        pageSize:pageSize,
        isDownload:isDownload,
        isFindingsNew:isFindingsNew,
      }),
    ).then((data)=>{
      resolve(data);
    }).catch((error)=>{
      reject(error);
    });
    });
  };
};

export const onGetIssuesData = ({
  _id,
  page = 1,
  search = '',
  historyIssueId = '',
  severityType = 'null',
  assign = 'null',
  type = 'null',
  status = 'null',
  tagIds = 'null',
  category = 'null',
  compliance = 'null',
  cwe = 'null',
  owasp = 'null',
  orgId,
  filterData=null,
  pageSize=null,
  // download=download,
  isDownload=false,
  isFindingsNew=false,
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({type: IS_API_IN_PROCESS, payload: true});
      dispatch({type: FETCH_START});
      
      const filterDataObj = filterData?.items[0];
      const obj = {
        filterData: filterDataObj,
        pageSize: pageSize,
        isDownload: isDownload,
      };
      const downloadPath = isFindingsNew?'downloadnew':'default';

      jwtAxios
        .get(
          `/scan/${_id}/finding/${downloadPath}?page=${page}&search=${search}&historyIssueId=${historyIssueId}&type=${type}&severityType=${severityType}&status=${status}&assign=` +
            encodeURIComponent(`${assign}`) +
            `&tagIds=${tagIds}&category=${category}&compliance=${compliance}&cwe=${cwe}&owasp=${owasp}&orgId=${orgId}`,
          {params: obj},
          )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if(isDownload){
          dispatch({
              type: GET_ISSUES_DATA_FOR_DOWNLOAD,
              payload: data.data.findings,
            });
          }
          else{
            /*Backwards Compatibility for owasp*/
            /*try{
              if(data.data.findings.length>0){
                if(!data.data.findings[0].owasp || data.data.findings[0].owasp == ""){
                  data.data.findings.map(function(fnnd){
                          const index = fnd.findIndex(item => item.title === fnnd.issue);
                          fnnd.owasp = fnd[index].owasp;
                          });
                }
              }
            }catch(e){console.log(e);}*/
            dispatch({
              type: GET_ISSUES_DATA,
              payload: data.data.findings,
            });
          }
     
          dispatch({
            type: TOTAL_ISSUES,
            payload: data.data.total,
          });
          dispatch({
            type: GET_ISSUES_PAGE_COUNT,
            payload: Math.ceil(data.data.total / 100),
          });
          dispatch({
            type:Set_Total_Rows,
            payload:data.data.total
          });
          dispatch(
            {type:Set_PageCount,payload:Math.ceil(data.data.total/pageSize)}
            
           );
          dispatch({
            type: GET_ISSUES_CUR_PAGE,
            payload: page,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          resolve(data);
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        reject(error);
      });
  }
    );
};
};

export const storeFilterData=(data)=>{
  return (dispatch) => {
    dispatch(
     {type:Set_Filter_Data,payload:data}
     
    );   dispatch(
     {type:Set_Initial}
    );
  };
};
export const setPageSize=(data)=>{
  return (dispatch) => {
    dispatch(
     {type:Set_PageSize,payload:data}
     
    );
  };
};
export const setPageCount=(data)=>{
  return (dispatch) => {
    dispatch(
     {type:Set_PageCount,payload:data}
     
    );
  };
};

export const removeFilterData=()=>{
  return (dispatch) => {
    dispatch(
     {type:Remove_Filter_Data}
    );
    dispatch(
      {type:Set_Initial_True}
     );
  };
};

/// Issues Filter Data

export const setSelectedIds = (ids) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_IDS,
      payload: ids,
    });
  };
};

export const onGetIssuesFilters = ({_id, orgId}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`scan/findingsFilter/${_id}?orgId=${orgId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ISSUES_FILTERS,
            payload: data.data,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

// Scan Settings

export const onGetScanTypes = ({org_id}) => {
  return (dispatch) => {
    jwtAxios
      .get(`getsfdcconfigs/${org_id}?orgId=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: GET_SCAN_TYPES, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const onGetCustomRules = ({org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`sfdcinfo/ignore/${org_id}?orgId=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: GET_CUSTOM_RULES,
            payload: data.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onGetScanRules = ({org_id}) => {
  return (dispatch) => {
    jwtAxios
      .get(`sfdcinfo/${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_SCAN_RULES,
            payload: data.data.scanrules,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const onPutCustomRules = ({org_id, fileName, reason}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`sfdcinfo/ignore/${org_id}`, {
        filename: fileName,
        reason: reason,
        orgId: org_id,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(onGetCustomRules({org_id}));
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: 'Custom Rule Added Successfully',
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPutCustomRulesBulk = (obj) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    const { org_id, ignoreFiles, createdBy, createdAt } = obj;
    const params = {
      org_id: org_id,
      ignoreFiles: ignoreFiles,
      createdBy: createdBy,
      createdAt: createdAt,
    };
      jwtAxios.put(`sfdcinfo/ignore/${org_id}`, params)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(onGetCustomRules({org_id}));
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: 'Custom Rules Added Successfully',
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPutScanRules = ({org_id, action, rule_id, severity}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`customrules/${org_id}`, {
        action: action,
        rule_id: rule_id,
        severity: severity,
        org_id: org_id,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(onGetScanRules({org_id}));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPostScanTypes = (org_id, obj) => {
  return (dispatch) => {
    jwtAxios
      .post(`updatesfdcconfigs`, {
        ...obj,
        orgId: org_id,
        jira_output: false,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(onGetScanTypes({org_id}));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const onPostCustomRules = ({org_id, ignorelistid}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`sfdcinfo/ignore/${org_id}`, {ignorelistid, orgId: org_id})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(onGetCustomRules({org_id}));
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: 'Custom Rule Deleted Successfully!',
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'error',
              message: defaultError,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'fail',
            message: error.message || defaultError,
          },
        });
      });
  };
};

export const onPostCustomRulesBulk = (obj) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    const { org_id, ignorelistIds } = obj;
    const params = {
      org_id: org_id,
      ignorelistIds: ignorelistIds,
    };
      jwtAxios.post(`sfdcinfo/ignore/${org_id}`, params)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(onGetCustomRules({org_id}));
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'success',
              message: 'Custom Rules Deleted Successfully',
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};
