import {
  GET_TWO_STEP_AUTHENTICATION,
  GET_COUNTRY_CODES,
  PROFILE_RESPONSE,
} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  twoStepAuthentication: '',
  countryCodes: [],
  profileResponse: null,
  generatedOtp:"",
  generatedOtpbase32:"",
  twofaEnabled: false,
  validated: false,
  token:"",
  user2FaData:{},
  error:[]
};

const Profile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TWO_STEP_AUTHENTICATION:
      return {
        ...state,
        twoStepAuthentication: action.payload,
      };
    case GET_COUNTRY_CODES:
      return {
        ...state,
        countryCodes: action.payload,
      };
      case "Set_QRCode":
        return {
          ...state,
          generatedOtp: action.payload.otpauth_url,
          generatedOtpbase32:action.payload.base32
        };

        case "Set_Token_Empty":
          return {
            ...state,
            token:action.payload
          };
        case "Set_Error":
        return {
          ...state,
          error: action.payload,
        };
        case "Reset_Error":
          return {
            ...state,
            error: [],
          };
        case "Enable_2FA":
          return {
            ...state,
            twofaEnabled: true,
          };
          case "Disable_2FA":
            return {
              ...state,
              twofaEnabled: false,
            };
            case "Valid_2FA":
              return {
                ...state,
                validated: true,
                token:action.payload.token
              };
              case "Logout_2FA":
                return {
                  ...state,
                  validated: false,
                };
                case "Set_User_2FA":
                  // console.log("ac",action);
                  return {
                    ...state,
                    user2FaData: action.payload,
                  };

                  case "Reset_Values":
                    return{
                      ...state,
                      twofaEnabled:false,
                      generatedOtp:""
                    };
    case PROFILE_RESPONSE:
      return {
        ...state,
        profileResponse: action.payload,
      };
    default:
      return state;
  }
};
export default Profile;
