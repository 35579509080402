import React, {useEffect} from 'react';
import {onGetComplianceFindingsData} from 'redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {GET_FINDINGS_DATA} from 'shared/constants/ActionTypes';
import styles from './NonSummary.module.css';
import HeaderIssues from '../FindingsDetail/HeaderFindings/HeaderFindings';
import BackgroundIssues from '../FindingsDetail/BackgroundFindings/BackgroundFindings';
import IssueFindings from '../FindingsDetail/IssueFindings/IssueFindings';
import RemediationGuidelines from '../FindingsDetail/RemediationGuidelines/RemediationGuidelines';
import VulnerabilityTrace from '../FindingsDetail/VulnerabilityTrace/VulnerabilityTrace';
import ComplianceViolations from '../ComplianceViolations/ComplianceViolations';
import Exploits from '../Exploits/Exploits';
const NonSummary = ({_id, violations, orgId}) => {
  const dispatch = useDispatch();
  const getFindingsData = useSelector(({reports}) => reports.getFindingsData);

  useEffect(() => {
    dispatch(onGetComplianceFindingsData({_id, violations, orgId}));

    return () => {
      dispatch({type: GET_FINDINGS_DATA, payload: null});
    };
  }, []);

  return (
    <div className={styles.issuesContainer}>
      {getFindingsData != null &&
        getFindingsData.map((finding, index) => {
          return (
            <div key={finding._id} className={styles.nonSummaryContainer}>
              <div className={styles.pagebreak} />
              <div className={styles.detailContainer}>
                <HeaderIssues
                  index={index}
                  severity={finding.severity}
                  issue={finding.issue}
                  filename={finding.filename}
                  line={finding.line}
                  status={finding.status}
                  founddate={finding.founddate}
                  issueId={finding?.issueId}
                  _id={finding._id}
                />
                <ComplianceViolations finding={finding} />
                <BackgroundIssues description={finding.description} />
                <IssueFindings
                  traces={finding.traces?finding?.traces[0]:null}
                  codesnippets={
                    finding.codesnippets ? finding.codesnippets : null
                  }
                />
                <RemediationGuidelines
                  remediationguidance={finding.remediationguidance}
                />
                {(finding.traces?finding?.traces[0]?.length > 0:false) || (finding?.exploits?.length>0) && (
                  <VulnerabilityTrace traces={finding.traces?finding?.traces[0]:null} exploits={finding.exploits} />
                )}
                {finding?.exploits?.length > 0 && (
                  <Exploits exploit={finding.exploits} />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default NonSummary;

NonSummary.propTypes = {
  _id: PropTypes.string,
  violations: PropTypes.string,
  orgId: PropTypes.string
};
