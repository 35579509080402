import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../details/CommentSection/Add Tags/TagForm/TagForm.module.css';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';

const OrgRoleForm = ({
  index,
  removeAssignRole,
  onSelectChange,
  sfdcInfo,
  clonedOrgRoles,
  onOrgSelectChange,
  sfdcId,
  roleId,
  teamMenu,
  deletedUserModal,
}) => {
  return (
    <TableRow>
      <TableCell width={250}>
        <FormControl fullWidth>
          <InputLabel id='org_list'>Select</InputLabel>
          <Select
            labelId='org_list'
            id='org_list'
            label='org_list'
            name='sfdc'
            value={sfdcId}
            disabled={deletedUserModal || teamMenu}
            onChange={(event) => {
              onSelectChange(event, index);
              onOrgSelectChange(event, sfdcInfo);
            }}
          >
            {sfdcInfo?.map((item) => (
              <MenuItem value={item._id} key={item._id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell width={250}>
        <FormControl fullWidth>
          <InputLabel id='org_roles'>Select</InputLabel>
          <Select
            labelId='org_roles'
            id='org_roles'
            label='org_roles'
            name='orgRole'
            value={roleId}
            onChange={(event) => onSelectChange(event, index)}
            disabled={deletedUserModal}
          >
            {clonedOrgRoles?.map((list) => (
              <MenuItem value={list._id} key={list._id}>
                {list.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      {!deletedUserModal && (
        <TableCell size='small' width={100}>
          <Button
            className={styles.removeTag}
            onClick={() => removeAssignRole(index)}
            size='small'
            variant='contained'
            color='secondary'
            disabled={deletedUserModal}
          >
            Remove
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

OrgRoleForm.propTypes = {
  orgRole: PropTypes.string,
  sfdc: PropTypes.string,
  onPermissionChange: PropTypes.func,
  index: PropTypes.number,
  removeAssignRole: PropTypes.func,
  sfdcInfo: PropTypes.array,
  clonedOrgRoles: PropTypes.array,
  onSelectChange: PropTypes.func,
  onOrgSelectChange: PropTypes.func,
  sfdcId: PropTypes.string,
  roleId: PropTypes.string,
  teamMenu: PropTypes.bool,
  deletedUserModal: PropTypes.bool,
};

export default OrgRoleForm;
