import {
    POST_TAGS_DATA,
    FETCH_ERROR,
    GET_TAGS_DATA,
    DUPLICATE_TAGS,
    DUPLICATE_TAGS_MESSAGE,
    DUPLICATE_TAGS_DATA,
    TAGS_RESPONSE,
    TAGS_SUCCESS,
  } from 'shared/constants/ActionTypes';
  
  const initialState = {
    tagsData: [],
    allTagsData: [{ label: 'No Tags', value: 'No Tags' }],
    error: null,
    isDuplicate: false,
    duplicateTags: [],
    tagsResponse: null,
    tagsSuccess: null,
    addTagResult: null,
  };
  
  const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
      case POST_TAGS_DATA:
        return {
          ...state,
          tagsData: action.payload,
          error: null,
        };
      case GET_TAGS_DATA:
        return {
          ...state,
          allTagsData: action.payload,
          error: null,
        };
        case DUPLICATE_TAGS_DATA:
        return {
          ...state,
          duplicateTags: action.payload,
          error: null,
        };
        case DUPLICATE_TAGS_MESSAGE:
          return {
            ...state,
            addTagResult: action.payload,
          };
      case FETCH_ERROR:
        return {
          ...state,
          error: action.payload,
        };
        case DUPLICATE_TAGS:
          return {
            ...state,
            isDuplicate: action.payload,
          };
      case TAGS_RESPONSE:
      return {
        ...state,
        tagsResponse: action.payload,
      };
      case TAGS_SUCCESS:
      return {
        ...state,
        tagsSuccess: action.payload,
      };
        default:
          return state;
  }
  };
  
  export default tagsReducer;
  