import React from 'react';

import Configure from './Configure';

export const configurePagesConfigs = [
  {
    path: '/configure',
    element: <Configure />,
  },
];
