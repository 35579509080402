import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_COMPANY_INFO,
  IS_API_IN_PROCESS,
  GET_ALL_ORGS,
  GET_ALL_ORGS_IN_ACCOUNT,
  SETUP_RESPONSE,
  GET_SUBSCRIPTION_INFO,
  SETUP_PROCESS_API,
  SUBSCRIPTION_ACTIVE,
  SHOW_MESSAGE,
  UPDATE_USER_STATUS
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {onGetUsers} from './Common';
import {onGetSpecificOrgUsers} from './Team';
import {assignRole} from './Roles';

// Getting Company Info

export const onGetCompanyInfo = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`/companyinfo`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_COMPANY_INFO, payload: data.data[0]});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        if(!error.response?.data?.message.includes('company')){
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        }

        // dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

// Update Company Info

export const onPostCompanyInfo = ({companyid, company, tfa}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/companyinfo/update`, {
        companyid: companyid,
        company: company,
        tfa: tfa,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});

          data.data.success == 'Company profile updated'
            ? dispatch({
                type: SETUP_RESPONSE,
                payload: {
                  status: 'success',
                  message: 'Company Name Updated!',
                },
              })
            : dispatch({
                type: SETUP_RESPONSE,
                payload: {
                  status: 'fail',
                  message: 'Failed to Change Name!',
                },
              });
          dispatch(onGetCompanyInfo());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: SETUP_RESPONSE,
            payload: {
              status: 'fail',
              message: 'Failed to Change Name!',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: SETUP_RESPONSE,
          payload: {
            status: 'fail',
            message: 'Failed to Change Name!',
          },
        });
      });
  };
};

// get all ORGS

export const onGetAllOrgs = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get('/sfdcinfo')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          data.data?.sfdcinfo
            ? dispatch({type: GET_ALL_ORGS, payload: data.data.sfdcinfo})
            : dispatch({type: GET_ALL_ORGS, payload: null});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: GET_ALL_ORGS, payload: null});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: GET_ALL_ORGS, payload: null});
      });
  };
};


// get all ORGS_IN_ACCOUNT

export const onGetAllOrgsInAccount = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get('/sfdcinfo/getAllOrgsInAccount')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          data.data?.sfdcinfo
            ? dispatch({type: GET_ALL_ORGS_IN_ACCOUNT, payload: data.data.sfdcinfo})
            : dispatch({type: GET_ALL_ORGS_IN_ACCOUNT, payload: null});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: GET_ALL_ORGS_IN_ACCOUNT, payload: null});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: GET_ALL_ORGS_IN_ACCOUNT, payload: null});
      });
  };
};


// Change User Status

export const onPostAddUser = ({
  userAdd,
  globalRolePayload,
  orgRolePayload,
  editSFDCPayload,
  org_id,
}) => {
  return (dispatch) => {
    dispatch({
      type: SETUP_PROCESS_API,
      payload: true,
    });
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/users/add`, userAdd)
      .then((data) => {
        dispatch({
          type: SETUP_PROCESS_API,
          payload: false,
        });
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (data.data.success == 'User registered') {
            if (orgRolePayload && data.data.user_added._id) {
              dispatch(
                onPostEditUserSfdc({
                  userId: data.data?.user_added?._id,
                  ...editSFDCPayload,
                  //orgId:org_id,
                  org_id:org_id,
                  globalRolePayload,
                  orgRolePayload,
                  flag: 'GLOBAL_AND_ORG_LEVEL',
                  isUserAdd: true
                }),
              );
            } else if (data.data.user_added) {
              dispatch(
                assignRole({
                  ...globalRolePayload,
                  userId: data.data.user_added._id,
                  orgId:org_id,
                }),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: data.data.error,
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({
          type: SETUP_PROCESS_API,
          payload: false,
        });
      });
  };
};

// Delete User

export const onPostDeleteUser = ({userid, del, org_id}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`users/edit`, {userid, delete: del})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload: 'User Deleted Successfully'});
          data.data.success == 'User update succeeded'
            ? dispatch({
                type: SHOW_MESSAGE,
                payload: 'User Deleted Successfully',
              })
            : dispatch({
                type: FETCH_ERROR,
                payload: data.data?.error
                  ? data.data.error
                  : 'Failed to Delete User!',
              });

          dispatch(onGetUsers());
          org_id && dispatch(onGetSpecificOrgUsers({org_id}));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

// Reset Email

export const onPostResetUser = ({userid, reset_token}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`users/edit`, {userid, reset_token})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});

          data.data.success == 'User update succeeded2'
            ? dispatch({
                type: SETUP_RESPONSE,
                payload: {
                  status: 'success',
                  message: 'Password Reset Email Sent',
                },
              })
            : dispatch({
                type: SETUP_RESPONSE,
                payload: {
                  status: 'fail',
                  message: data.data?.error
                    ? data.data.error
                    : 'Failed to Send Reset Email!',
                },
              });
          dispatch(onGetUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: SETUP_RESPONSE,
            payload: {
              status: 'fail',
              message: 'Failed to Send Reset Email!',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: SETUP_RESPONSE,
          payload: {
            status: 'fail',
            message: 'Failed to Send Reset Email!',
          },
        });
      });
  };
};

// Two Step Verification

export const onPutAllTwoFA = (isEnabled) => {
  return (dispatch) => {
    dispatch({
      type: SETUP_PROCESS_API,
      payload: true,
    });
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`enableAll2fa`, {isEnabled: isEnabled})
      .then((data) => {
        dispatch({
          type: SETUP_PROCESS_API,
          payload: false,
        });
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          data.data.require2fa == true
            ? dispatch({
                type: SETUP_RESPONSE,
                payload: {
                  status: 'success',
                  message: 'Two Step Verification Enabled!',
                },
              })
            : dispatch({
                type: SETUP_RESPONSE,
                payload: {
                  status: 'success',
                  message: 'Two Step Verification Disabled!',
                },
              });
          dispatch(onGetUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({
            type: SETUP_RESPONSE,
            payload: {
              status: 'fail',
              message: 'Failed to enable two Step Verification!',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({
          type: SETUP_PROCESS_API,
          payload: false,
        });
        dispatch({
          type: SETUP_RESPONSE,
          payload: {
            status: 'fail',
            message: 'Failed to enable two Step Verification!',
          },
        });
      });
  };
};

// Subscription

export const onGetSubscription = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dispatch({
      type: SETUP_PROCESS_API,
      payload: true,
    });
    jwtAxios
      .get(`/subscription`)
      .then((data) => {
        dispatch({
          type: SETUP_PROCESS_API,
          payload: false,
        });
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          data.data?.subscription
            ? (dispatch({
                type: GET_SUBSCRIPTION_INFO,
                payload: data.data.subscription,
              }),
              dispatch({
                type: SUBSCRIPTION_ACTIVE,
                payload:
                  new Date(data.data?.subscription[0]?.expiration_date) <
                  Date.now()
                    ? false
                    : true,
              }))
            : dispatch({type: GET_SUBSCRIPTION_INFO, payload: null});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: GET_SUBSCRIPTION_INFO, payload: null});
        }
      })
      .catch((error) => {
        dispatch({
          type: SETUP_PROCESS_API,
          payload: false,
        });
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: GET_SUBSCRIPTION_INFO, payload: null});
      });
  };
};

// Update Company Info

export const onPostEditUserSfdc = ({
  userId,
  orgName,
  org_id,
  globalRolePayload,
  orgRolePayload,
  flag,
  isUserAdd,
  isUserEdit,
  isUserDelete,
}) => {
  return (dispatch) => {
    let orgId = org_id;
    dispatch({type: FETCH_START});
      return new Promise((resolve, reject) => {
      jwtAxios
        .post(`users/editSFDC`, {
          userId,
          orgName,
          orgId,
        })
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            if (isUserAdd || isUserEdit) {
              dispatch(assignRole({
                ...globalRolePayload,
                ...orgRolePayload,
                flag,
                userId,
                orgId,
              }));
            } else if (isUserDelete) {
              dispatch(onGetSpecificOrgUsers({org_id}));
            }
            resolve();
          }else if (data.status === 201){
            dispatch({
              type: FETCH_ERROR,
              payload: data?.data?.success || 'We encountered an error! Please try again.',
            });
          }else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: error.response?.data?.message || error.message,
          });
          reject(error);
        });
    });
  };
};

// Admin User Conversion

export const onPostAdminUserConvert = ({userid, permission, role}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`users/edit`, {userid, permission, role})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});

          data.data.success == 'User update succeeded2'
            ? dispatch({
                type: SETUP_RESPONSE,
                payload: {
                  status: 'success',
                  message: 'User Updated!',
                },
              })
            : dispatch({
                type: SETUP_RESPONSE,
                payload: {
                  status: 'fail',
                  message: data.data?.error
                    ? data.data.error
                    : 'Failed to Update User Profile!',
                },
              });
          dispatch(onGetUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: SETUP_RESPONSE,
            payload: {
              status: 'fail',
              message: 'Failed to Update User Profile!',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: SETUP_RESPONSE,
          payload: {
            status: 'fail',
            message: 'Failed to Update User Profile!',
          },
        });
      });
  };
};
//user status
export const updateUserStatus = (userid, updatedStatusData) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    const putURL = `/users/updateStatus/${userid}`;
    jwtAxios
      .put(putURL, updatedStatusData)
      .then((response) => {
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({type: UPDATE_USER_STATUS, payload: response.data});
      })
      .catch(() => {
        dispatch({
          type: FETCH_ERROR,
          payload: {
            status: 'fail',
            message: 'Failed to Update User!',
          },
        });
      });
  };
};

