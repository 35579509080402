import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import DashboardDropdown from '../../components/salesforce/SalesforceCategoryDropdown/DashboardDropdown';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '@mui/material/Pagination';
import {Box} from '@mui/material';
import AddSalesforceDropdown from 'components/salesforce/AddSalesforceCloud/AddSalesforceDropdown';
import {useDispatch, useSelector} from 'react-redux';
import {onSearchSfdcInfo, onGetAllOrgs} from 'redux/actions';
import {
  GET_CUR_PAGE,
  GET_SFDC_INFO,
  SALESFORCE_RESPONSE,
  SFDC_ASSIGN_RESPONSE,
} from 'shared/constants/ActionTypes';
import SalesforceCard from 'components/salesforce/SalesforceCard/SalesforceCard';
import Button from '@mui/material/Button';
import styles from './Salesforce.module.css';
import RefreshIcon from '@mui/icons-material/Refresh';
import {APP_BAR_DASHBOARD} from 'shared/constants/ActionTypes';
import {onCheckIfAdmin} from 'redux/actions';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import ModalChildrenMessage from 'components/modals/ModalChildrenMessage/ModalChildrenMessage';
import PlaceHolderDiv from 'components/PlaceHolderDiv/PlaceHolderDiv';
import {AppInfoView} from '@crema';
import {checkPermissions} from '@crema/utility/helper/Utils';
import Chip from '@mui/material/Chip';
import {getTags} from 'redux/actions/Tags';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Salesforce = () => {
  const dispatch = useDispatch();
  const sfdcInfo = useSelector(({salesforce}) => salesforce.sfdcInfo);
  const getAllOrgs = useSelector(({setup}) => setup.getAllOrgs);
  const {userInfo} = useSelector(({common}) => common);

  const pageCount = useSelector(({salesforce}) => salesforce.pageCount);
  const curPage = useSelector(({salesforce}) => salesforce.curPage);
  const sfdcAssignResponse = useSelector(({salesforce}) => salesforce.sfdcAssignResponse);
  // const isAdmin = useSelector(({authentication}) => authentication.isAdmin);
  const salesforceResponse = useSelector(
    ({salesforce}) => salesforce.salesforceResponse,
  );

  useEffect(() => {
    dispatch(onGetAllOrgs());
    dispatch(getTags());
    return () => {};
  }, [dispatch]);

  const sfdc = useSelector(({roles}) => roles.sfdc);
  const sfcc = useSelector(({roles}) => roles.sfcc);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');
  let sfdcInfoList = sfdcInfo?.sfdcinfo || [];

  function containsAnyLetter(inputString) {
    const lettersArray = ['*', '(', ')', '"', '?', '[', '\\']; // Include the backslash character

    const regex = new RegExp(
      `[${lettersArray
        .map((letter) => (letter === '\\' ? '\\\\' : letter))
        .join('')}]`,
      'i',
    );
    return regex.test(inputString);
  }

  const searchHandler = (e) => {
    if (!containsAnyLetter(e.target.value)) {
      setSearch(e.target.value);
      dispatch({type: GET_SFDC_INFO, payload: null});
      dispatch(
        onSearchSfdcInfo({
          pageNum: curPage,
          search: e.target.value,
          isSfcc: category,
        }),
      );
    }
  };

  const categoryHandler = (value = undefined) => {
    setCategory(value);
    dispatch({type: GET_SFDC_INFO, payload: null});
    dispatch(
      onSearchSfdcInfo({pageNum: curPage, search: search, isSfcc: value}),
    );
  };

  const permissions =
    userInfo?.rolesAndPermissionsObject?.global?.permissions?.filter(
      (item) => item.object === 'sfdcinfo',
    );

  let isSfccPermissions =
    userInfo?.rolesAndPermissionsObject?.global?.permissions?.filter(
      (item) => item.object === 'sfccinfo',
    );
  sfdcInfoList = sfdcInfo?.sfdcinfo?.filter((item) => {
    if (item.isSfcc === false && sfdc?.read) {
      return item;
    }
    if (item.isSfcc === true && sfcc?.read) {
      return item;
    }
  });
  let readOnlySfdcInfo = true;
  if (permissions && permissions?.length !== 0) {
    readOnlySfdcInfo = checkPermissions(permissions);
  }
  let readOnlySfccInfo = true;
  if (isSfccPermissions && isSfccPermissions?.length !== 0) {
    readOnlySfccInfo = checkPermissions(isSfccPermissions);
  }

  // let readOnly =
  //   (readOnlySfdcinfo && readOnlyisSfcc === true) ||
  //   (readOnlyisSfcc && readOnlySfdcinfo == true);

  useEffect(() => {
    dispatch(onCheckIfAdmin());
  }, []);

  useEffect(() => {
    // if (isAdmin) {
    onRefresh();
    dispatch({type: APP_BAR_DASHBOARD, payload: 'dashboard'});
    // }
  }, []);

  // const {role} = useSelector(({roles}) => roles);
  const paginationChange = (event, value) => {
    dispatch({type: GET_SFDC_INFO, payload: null});
    dispatch(
      onSearchSfdcInfo({pageNum: value, search: search, isSfcc: category}),
    );
    dispatch({type: GET_CUR_PAGE, payload: value});
  };

  const onRefresh = () => {
    setSearch('');
    setCategory('');
    setSelectedTags([]);
    dispatch({type: GET_CUR_PAGE, payload: 1});
    dispatch({type: GET_SFDC_INFO, payload: null});
    dispatch(onSearchSfdcInfo({pageNum: 1, search: '', isSfcc: undefined}));
  };

  const onCleanFields = () => {
    setSearch('');
    setCategory('');
    setSelectedTags([]);
  };

  const handleModalClose = () => {
    dispatch({type: SALESFORCE_RESPONSE, payload: null});
  };

  const handleModalCloseT = () => {
    dispatch({type: SFDC_ASSIGN_RESPONSE, payload: null});
  };


  let customFieldsIds = getAllOrgs?.tags ? getAllOrgs.tags : [];

  if (Array.isArray(getAllOrgs)) {
    customFieldsIds = getAllOrgs.map((item) => (item.tags ? item.tags : []));
  }

  customFieldsIds = customFieldsIds.flat();

  const allTags = useSelector(({ tags }) => Array.isArray(tags.allTagsData) ? tags.allTagsData : []);

  const filteredTags = Array.isArray(allTags)
    ? allTags.filter((tag) =>
        customFieldsIds.some((customFieldId) => customFieldId === tag._id),
      )
    : [];

  const options = allTags
    .filter((tag) => tag.category === 'workspace')
    .map((tag) => ({
      name: tag.key,
      label: tag.value,
      url: tag.url,
      tagId: tag._id,
    }));

  const filteredOptions = options.filter((option) =>
    filteredTags.some((tag) => tag._id === option.tagId),
  );

  const [selectedTags, setSelectedTags] = useState([]);

  const handleTagsChange = (event, value) => {
    const selectedTagIds = value.map((tag) => tag.tagId);

    // console.log('values', value);
    // console.log('selectedTagIds', selectedTagIds);

    setSelectedTags(value);
    dispatch({type: GET_SFDC_INFO, payload: null});
    dispatch(
      onSearchSfdcInfo({
        pageNum: curPage,
        search: search,
        isSfcc: category,
        tagId: selectedTagIds,
      }),
    );
  };

  return (
    <>
      <div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          <h1>Workspaces</h1>
          {
            <AddSalesforceDropdown
              readOnlySfdcInfo={readOnlySfdcInfo}
              readOnlySfccInfo={readOnlySfccInfo}
            />
          }
        </Box>
        <div className={styles.topSalesforceBarContainer}>
          <div className={styles.searchCategoryContainer}>
            <TextField
              sx={{width: '100%'}}
              id='outlined-basic'
              label='Search'
              variant='outlined'
              onChange={searchHandler}
              value={search}
              className={styles.searchField}
            />

            <div className={styles.dropdownContainer}>
              <DashboardDropdown
                search={search}
                categoryHandler={categoryHandler}
                category={category}
              />
            </div>

            <Autocomplete
              disablePortal
              id='Auto-Complete-Category'
              sx={{minWidth: '300px'}}
              options={filteredOptions
                .filter(
                  (option) =>
                    !selectedTags.find((tag) => tag.tagId === option.tagId),
                )
                .sort((a, b) => a.name.localeCompare(b.name))}
              getOptionLabel={(option) => `${option.name} : ${option.label}`}
              onChange={handleTagsChange}
              value={selectedTags}
              multiple
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.tagId}
                    label={`${option.name} : ${option.label}`}
                    {...getTagProps({index})}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant='outlined' label='Tags' />
              )}
            />
          </div>

          <div className={styles.refreshContainer}>
            <Button variant='outlined' onClick={onRefresh}>
              <RefreshIcon />
            </Button>
          </div>
        </div>
        <Grid container spacing={2} sx={{marginBottom: 10}}>
          {sfdcInfoList &&
            sfdcInfo &&
            Object.keys(sfdcInfo)?.length > 0 &&
            Array.isArray(sfdcInfo?.sfdcinfo) &&
            sfdcInfoList?.map((obj) => {
              return (
                <Grid key={obj._id} item xs={12} md={6}>
                  <SalesforceCard
                    onCleanFields={onCleanFields}
                    status={sfdcInfo?.valid_in_days}
                    data={obj}
                    _id={sfdcInfoList._id}
                    writeable={obj.isSfcc ? sfcc?.write : sfdc?.write}
                  />
                </Grid>
              );
            })}

          {sfdcInfo?.sfdcinfo?.total == 0 && (
            <Grid item xs={12}>
              <PlaceHolderDiv status='notfound' message='No Workspaces found' />
            </Grid>
          )}
          {sfdcInfoList && Object.keys(sfdcInfoList).length === 0 && (
            <Grid item xs={12}>
              <PlaceHolderDiv
                //status='success'
                message=' ... '
              />
            </Grid>
          )}
        </Grid>
        {pageCount > 1 && sfdcInfoList && Object.keys(sfdcInfoList).length > 0 && (
          <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
            <Pagination
              count={pageCount}
              shape='rounded'
              onChange={paginationChange}
              page={curPage}
              color='primary'
            />
          </Box>
        )}
        {salesforceResponse && (
          <ModalContainer open={true} handleModalClose={handleModalClose}>
            <ModalChildrenMessage
              status={salesforceResponse.status}
              close='Close'
              handleModalClose={handleModalClose}
              message={salesforceResponse.message}
            />
          </ModalContainer>
        )}
        {sfdcAssignResponse != null && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleModalCloseT}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiAlert
              onClose={handleModalCloseT}
              elevation={6}
              variant='filled'
              severity='error'
              sx={{width: '100%'}}
            >
              {sfdcAssignResponse?.message}
            </MuiAlert>
          </Snackbar>
        )}
      </div>
      <AppInfoView />
    </>
  );
};

export default Salesforce;
