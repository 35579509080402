import React from 'react';
import {MentionsInput, Mention} from 'react-mentions';
import PropTypes from 'prop-types';
import styles from './MentionInput.module.css';

const MentionInput = ({
  data,
  inputText,
  textHandler,
  placeholder,
  rows,
  readOnly,
}) => {
  return (
    <div style={{minHeight: '180px'}}>
      <MentionsInput
        value={inputText}
        onChange={textHandler}
        classNames={styles}
        placeholder={placeholder || ''}
        allowSuggestionsAboveCursor={true}
        disabled={readOnly}
      >
        <Mention
          trigger='@'
          data={data}
          className={styles.mentions__mention}
          rows={rows || 1}
        />
      </MentionsInput>
    </div>
  );
};

export default MentionInput;

MentionInput.propTypes = {
  data: PropTypes.array,
  inputText: PropTypes.string,
  textHandler: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  readOnly: PropTypes.bool,
};
