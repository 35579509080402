import React from 'react';

import Help from './Help';

export const helpPagesConfigs = [
  {
    path: '/help',
    element: <Help />,
  },
];
