import React, {useEffect, useState} from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {AppCard} from '@crema';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import styles from './FindingsAreaChart.module.css';
import {useParams} from 'react-router-dom';
import {ScanColors} from 'shared/constants/AppEnums';

const ErrorMessage = ({message}) => {
  return (
    <div className={styles.reportPending}>
      {/* {status == 'noscan' && (
        <FmdBadSharpIcon color='primary' sx={{fontSize: 150}} />
      )} */}
      <h3 className={styles.errorMessage} align="center">{message}</h3>

    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  status: PropTypes.string,
};

const FindingsAreaChart = ({scanreadable}) => {
  let options1 = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  let options = { timeStyle: 'long', hour12: true };
  const reportsData = useSelector(({reports}) => reports.reportsData);
  const trendingScanPerOrg = useSelector(
    ({scanStatistics}) => scanStatistics.trendingScanPerOrg,
  );

  const [data, setData] = useState([]);
  let temp = [];
  let {org_id} = useParams();

  useEffect(async () => {
    temp = [];
    await trendingScanPerOrg &&
    trendingScanPerOrg[0][1].map((singleReport, index) => {
      if(singleReport?.Name){
      temp.push({
          index: `${index + 1}`,
          Name: singleReport?.Name?singleReport?.Name:`${index + 1}`,
          Critical: singleReport.Critical,
          High: singleReport.High,
          Medium: singleReport.Medium,
          Low: singleReport.Low,
          Date: new Date(singleReport.Initiated_Scan_date).toLocaleString('en-us',
          options1,
        ) +
        ' ' +
        new Date(singleReport.Initiated_Scan_date).toLocaleString(
          'en-us',
          options,
        )
        });
      }else{
        temp.push({
          index: `${index + 1}`,
          Critical: singleReport.Critical,
          High: singleReport.High,
          Medium: singleReport.Medium,
          Low: singleReport.Low,
          Date: new Date(singleReport.Initiated_Scan_date).toLocaleString('en-us',
          options1,
        ) +
        ' ' +
        new Date(singleReport.Initiated_Scan_date).toLocaleString(
          'en-us',
          options,
        )
        });
      }
      });
    setData(temp);
    return () => {
      temp = [];
    };
  }, [trendingScanPerOrg, org_id]);

  return (
    <AppCard sxStyle={{height: 1}} title='Findings Trend'>
      
      {scanreadable && reportsData?.length > 0 && (
        <ResponsiveContainer width='100%' height='100%'>
          <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id='colorCritical' x1='0' y1='0' x2='0' y2='1'>
                <stop
                  offset='5%'
                  stopColor={ScanColors.critical}
                  stopOpacity={0.8}
                />
                <stop
                  offset='95%'
                  stopColor={ScanColors.critical}
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient id='colorHigh' x1='0' y1='0' x2='0' y2='1'>
                <stop
                  offset='5%'
                  stopColor={ScanColors.high}
                  stopOpacity={0.8}
                />
                <stop
                  offset='95%'
                  stopColor={ScanColors.high}
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient id='colorMedium' x1='0' y1='0' x2='0' y2='1'>
                <stop
                  offset='5%'
                  stopColor={ScanColors.medium}
                  stopOpacity={0.8}
                />
                <stop
                  offset='95%'
                  stopColor={ScanColors.medium}
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient id='colorLow' x1='0' y1='0' x2='0' y2='1'>
                <stop
                  offset='5%'
                  stopColor={ScanColors.low}
                  stopOpacity={0.8}
                />
                <stop offset='95%' stopColor={ScanColors.low} stopOpacity={0} />
              </linearGradient>
              <linearGradient id='colorDate' x1='0' y1='0' x2='0' y2='1'>
                <stop
                  offset='5%'
                  stopColor={ScanColors.date}
                  stopOpacity={0.8}
                />
                <stop offset='95%' stopColor={ScanColors.date} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey='index' />
            <YAxis />
            <CartesianGrid strokeDasharray='3 3' />
            <Tooltip/>
            <Area
              type='monotone'
              dataKey='Critical'
              stroke={ScanColors.critical}
              fillOpacity={1}
              fill='url(#colorCritical)'
            />
            <Area
              type='monotone'
              dataKey='High'
              stroke={ScanColors.high}
              fillOpacity={1}
              fill='url(#colorHigh)'
            />
            <Area
              type='monotone'
              dataKey='Medium'
              stroke={ScanColors.medium}
              fillOpacity={1}
              fill='url(#colorMedium)'
            />
            <Area
              type='monotone'
              dataKey='Low'
              stroke={ScanColors.low}
              fillOpacity={1}
              fill='url(#colorLow)'
            />
            
            <Area
              type='monotone'
              dataKey='Date'
              stroke={ScanColors.date}
              fillOpacity={1}
              fill='url(#colorDate)'
            />
              <Area
              type='monotone'
              dataKey='Name'
              stroke={ScanColors.date}
              fillOpacity={1}
              fill='url(#colorDate)'
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
      {reportsData && reportsData?.length === 0 && scanreadable && (
        <ErrorMessage
          status='noscan'
          message='Looks like there is no completed scan at this moment.'
        />
      )}
      {reportsData && reportsData?.length !== 0 && scanreadable === false && (
        <ErrorMessage
          status='noscan'
          message='You do not have access to view the findings trend.'
        />
      )}
    </AppCard>
  );
};



export default FindingsAreaChart;
FindingsAreaChart.propTypes = {
  scanreadable: PropTypes.bool,
};