import React, {useState, useEffect} from 'react';
import styles from './AdvanceSetting.module.css';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import {onPostScanTypes, onPostUpdateConfigs} from 'redux/actions';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const label = {inputProps: {'aria-label': 'Switch demo'}};

const AdvanceSetting = ({
  advanceHeading,
  advanceSubtitle,
  name,
  disabled,
  isGlobal,
  advanceSwitch,
}) => {
  let {org_id} = useParams();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);

  const scanTypes = useSelector(({dashboard}) => dashboard.scanTypes);

  useEffect(() => {
    setChecked(advanceSwitch);
  }, [advanceSwitch]);
  const actionChangeHandler = (event) => {
    setChecked(event.target.checked);
    if (isGlobal) {
      let configsState = {
        ...globalConfigs,
        [event.target.name]: event.target.checked,
      };
      dispatch(onPostUpdateConfigs({configsState}));
    } else {
      dispatch(
        onPostScanTypes(
          org_id,
          Object.assign(scanTypes, {[name]: event.target.checked}),
        ),
      );
    }
  };

  return (
    <div className={styles.singleScanRuleContainer}>
      <div className={styles.rulesetInfo}>
        <div className={styles.rulesetIcon}>
          <SettingsOutlinedIcon color='primary' />
        </div>
        <div>
          <p>{advanceHeading}</p>
          <p className={styles.rulesetSubheading}>{advanceSubtitle}</p>
        </div>
      </div>
      <div className={styles.rulesetSwitch}>
        <Switch
          {...label}
          name={name}
          onChange={actionChangeHandler}
          checked={checked}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default AdvanceSetting;

AdvanceSetting.propTypes = {
  advanceHeading: PropTypes.string,
  advanceSubtitle: PropTypes.string,
  advanceSwitch: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isGlobal: PropTypes.bool,
};
