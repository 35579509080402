import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloudDoneSharpIcon from '@mui/icons-material/CloudDoneSharp';
import Box from '@mui/material/Box';
import Copado from './Copado/Copado';
import Github from './Github/Github';
import Jira from './Jira/Jira';
import Bitbucket from './Bitbucket/Bitbucket';
import Azure from './Azure/Azure';
import Gitlab from './Gitlab/Gitlab';
import CopadoEssentials from './CopadoEssentials/CopadoEssentials';
import {useSelector, useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import {onPostJiraOutputSFDX} from 'redux/actions';
import {useParams} from 'react-router-dom';
import {AppInfoView} from '@crema';
import ApiTokenSecret from './ApiTokenSecret/ApiTokenSecret';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Integration({writeable}) {
  let {org_id} = useParams();

//   const loggedUser = useSelector(({common})=>common.userInfo);
//   let isGlobalAdmin = checkGlobalAdmin(loggedUser);
//   let isTeamAdmin = checkTeamAdmin(loggedUser);
//   function checkGlobalAdmin(loggedUser){
//     let perm= [];
//     if (loggedUser?.rolesAndPermissionsObject?.global?.permissions){
//       for (let permission of loggedUser?.rolesAndPermissionsObject?.global?.permissions){
//         if((permission.permissions.indexOf('WRITE')!==-1 && permission.object === 'sfdcinfo') || (permission.permissions.indexOf('WRITE')!==-1 && permission.object === 'sfccinfo') || (permission.permissions.indexOf('WRITE')!==-1 && permission.object === 'user') || (permission.permissions.indexOf('WRITE')!==-1 && permission.object === 'company') || (permission.permissions.indexOf('WRITE')!==-1 && permission.object === 'globalintegrations') || (permission.permissions.indexOf('WRITE')!==-1 && permission.object === 'globalscansettings') || (permission.permissions.indexOf('WRITE')!==-1 && permission.object === 'globalsecuritygate') || (permission.permissions.indexOf('READ')!==-1 && permission.object === 'logs')){
//           perm.push(permission);
//       } 
//     }
//     return (perm.length === 8);
//   }
// }

// function checkTeamAdmin(loggedUser){
//   let permissionArray= [];
//   if (loggedUser?.rolesAndPermissionsObject?.orgLevel){
//     for (let permission of loggedUser.rolesAndPermissionsObject.orgLevel){
//       if(permission.orgs.filter(org=>org.sfdcId === org_id).length){
//         for(let perm of permission.permissions){
//         if((perm.permissions.indexOf('WRITE')!==-1 && perm.object === 'scan') || (perm.permissions.indexOf('WRITE')!==-1 && perm.object === 'finding') || (perm.permissions.indexOf('WRITE')!==-1 && perm.object === 'team') || (perm.permissions.indexOf('WRITE')!==-1 && perm.object === 'orgsecuritygate') || (perm.permissions.indexOf('WRITE')!==-1 && perm.object === 'orgintegrations') || (perm.permissions.indexOf('WRITE')!==-1 && perm.object === 'orgscansettings') || (perm.permissions.indexOf('WRITE')!==-1 && perm.object === 'orgscanrules') || (perm.permissions.indexOf('WRITE')!==-1 && perm.object === 'orgignorelist') || (perm.permissions.indexOf('WRITE')!==-1 && perm.object === 'reports')){
//           permissionArray.push(perm);
//         } 
//       }
//       }
//   }
//   return (permissionArray.length === 9);
// }
// }

  const [value, setValue] = useState(0);
  const singleOrg = useSelector(({dashboard}) => dashboard.singleOrg);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onPostJiraOutputSFDX({org_id}));
    return () => {};
  }, [org_id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getIcon = (name) => {
    return singleOrg &&
      singleOrg[name] != undefined &&
      singleOrg[name] == true ? (
      <CloudDoneSharpIcon />
    ) : undefined;
  };

  const jiraIcon = () => {
    return singleOrg &&
      singleOrg.project_id.length > 0 &&
      singleOrg.project_key.length > 0 ? (
      <CloudDoneSharpIcon />
    ) : undefined;
  };

  const getIconRepo = (name) => {
    return singleOrg &&
      'repoInfo' in singleOrg &&
      singleOrg['repoInfo'][name] ? (
      <CloudDoneSharpIcon />
    ) : undefined;
  };

  const getIconGitlab = () => {
    return singleOrg &&
      'repoInfo' in singleOrg &&
      singleOrg['repoInfo']['gitlabRepoName'] &&
      singleOrg['repoInfo']['gitlabRepoName'].length > 0 ? (
      <CloudDoneSharpIcon />
    ) : undefined;
  };

  return (
    <>
      <Box sx={{width: '100%'}}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            marginBottom: 5,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              iconPosition='start'
              icon={getIcon('hasGitHubRepo')}
              label='Github'
              {...a11yProps(0)}
            />
            <Tab
              iconPosition='start'
              icon={getIcon('hasBitbucketRepo')}
              label='Bitbucket'
              {...a11yProps(1)}
            />
            <Tab
              iconPosition='start'
              icon={getIconRepo('hasAzureRepo')}
              label='Azure'
              {...a11yProps(2)}
            />
            <Tab
              iconPosition='start'
              icon={getIconGitlab()}
              label='Gitlab'
              {...a11yProps(3)}
            />
            <Tab label='Copado' {...a11yProps(4)} />
            <Tab label='Copado Essentials' {...a11yProps(5)} />
            <Tab
              label='Jira'
              iconPosition='start'
              icon={jiraIcon()}
              {...a11yProps(6)}
            />
            <Tab label='API' {...a11yProps(7)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Github writeable={writeable} /*isGlobalAdmin={isGlobalAdmin} isTeamAdmin={isTeamAdmin}*/></Github>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Bitbucket writeable={writeable} /*isGlobalAdmin={isGlobalAdmin} isTeamAdmin={isTeamAdmin}*/></Bitbucket>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Azure writeable={writeable} /*isGlobalAdmin={isGlobalAdmin} isTeamAdmin={isTeamAdmin}*/></Azure>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <Gitlab writeable={writeable} /*isGlobalAdmin={isGlobalAdmin} isTeamAdmin={isTeamAdmin}*/></Gitlab>
        </TabPanel>

        <TabPanel value={value} index={4}>
          <Copado writeable={writeable}></Copado>
        </TabPanel>

        <TabPanel value={value} index={5}>
          <CopadoEssentials writeable={writeable} /*isGlobalAdmin={isGlobalAdmin} isTeamAdmin={isTeamAdmin}*/></CopadoEssentials>
        </TabPanel>

        <TabPanel value={value} index={6}>
          <Jira writeable={writeable}></Jira>
        </TabPanel>

        <TabPanel value={value} index={7}>
          <ApiTokenSecret writeable={writeable} /*isGlobalAdmin={isGlobalAdmin} isTeamAdmin={isTeamAdmin}*/></ApiTokenSecret>
        </TabPanel>
      </Box>
      <AppInfoView />
    </>
  );
}
Integration.propTypes = {
  writeable: PropTypes.bool,
};
