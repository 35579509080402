import React from 'react';

import Authenticate from './Authenticate';

export const authenticatePagesConfigs = [
  {
    path: '/api/get_oauth_token',
    element: <Authenticate />,
  },

];
