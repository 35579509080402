import React, {useRef} from 'react';
import ReactToPrint from 'react-to-print';
import {Button} from '@mui/material';
import {ReportPdf} from 'components/report-pdf/ReportPdf/ReportPdf';
import styles from './Report.module.css';
import {useNavigate} from 'react-router-dom';
import {APP_BAR_DASHBOARD} from 'shared/constants/ActionTypes';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import { SimpleReportPdf } from 'components/report-pdf/ReportPdf/SimpleReportPdf';



const Report = () => {
  const componentRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const fullReport = searchParams.get('fullReport');
  const backRoute = () => {
    dispatch({type: APP_BAR_DASHBOARD, payload: 'reports'});
    navigate(-1);
  };
  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Button variant='contained' onClick={backRoute}>
          Back
        </Button>
        <ReactToPrint
          trigger={() => <Button variant='contained'>Print this out!</Button>}
          content={() => componentRef.current}
        />
      </div>
    {fullReport=='true'?
     <div className={styles.pdfContainer}>
     <ReportPdf ref={componentRef} />
   </div>
    :
    <div className={styles.pdfContainer}>
    <SimpleReportPdf ref={componentRef} />
  </div>
    }
    </div>
  );
};

export default Report;
