import {useState, useEffect} from 'react';

export function useDimensions() {
  const [dimensions, setDimensions] = useState({height: 0, width: 0});

  useEffect(() => {
    const updateDimensions = () => {
      const {clientHeight, clientWidth} = document.documentElement;
      setDimensions({height: clientHeight, width: clientWidth});
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return dimensions;
}
