import {DETAILS_RESPONSE, GET_ISSUE_DETAIL, GET_USER_NOTIFICATIONS} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  detailsResponse: undefined,
  issueDetail: null,
  userNotifications: [],
};

const Details = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DETAILS_RESPONSE:
      return {
        ...state,
        detailsResponse: action.payload,
      };
    case GET_ISSUE_DETAIL:
      return {
        ...state,
        issueDetail: action.payload,
      };
      case GET_USER_NOTIFICATIONS:
      return {
        ...state,
        userNotifications: action.payload,
      };
    default:
      return state;
  }
};
export default Details;
