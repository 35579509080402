import {IS_ADMIN} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  isAdmin: false,
};

const Authentication = (state = INIT_STATE, action) => {
  switch (action.type) {
    case IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };
    default:
      return state;
  }
};
export default Authentication;
