import React from 'react';
import {FrontPage} from '../FrontPage/FrontPage';
import Overview from '../Overview/Overview';
import styles from './ReportPdf.module.css';
import Process from '../Process/Process';
import FindingsOverview from '../FindingsOverview/FindingsOverview';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {onGetScanStats} from 'redux/actions';
import SeverityOverview from '../SeverityOverview/SeverityOverview';
import Contact from '../Contact/Contact';
import {useParams} from 'react-router-dom';
import {useSearchParams} from 'react-router-dom';
import IssuesPdf from '../FindingsDetail/FindingsDetail';
import SummaryReport from '../SummaryReport/SummaryReport';
import NonSummary from '../NonSummary/NonSummary';
import ComplianceOverview from '../ComplianceOverview/ComplianceOverview';
import { AppLoader } from '@crema';



export const ReportPdf = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const fullReport = searchParams.get('fullReport');
  let {_id, isSummaryReport, violations, orgId} = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const downloadNew = urlParams.get('newFindings');
  console.log("fullReport: ");
  if(violations){
  violations = violations.replace('ISO%2027001', 'ISO 27001');
  }
  useEffect(() => {
    dispatch(onGetScanStats({_id, orgId, downloadNew}));
  }, []);
  // const {getScanStats,getFindingsData} = useSelector(({reports}) => reports);
  const {getScanStats} = useSelector(({reports}) => reports);
  return (
    (getScanStats!=null)?(
      <div ref={ref} className={styles.pdfStyle}>
      <FrontPage></FrontPage>
      <div className={styles.pagebreak} />
      <Overview></Overview>
      <Process />
      <div className={styles.pagebreak} />
      <FindingsOverview />
      <div className={styles.pagebreak} />
      <SeverityOverview />
      <div className={styles.pagebreak} />
      <ComplianceOverview />
      {isSummaryReport == 'true' && (
        <SummaryReport _id={_id} violations={violations} orgId={orgId} />
      )}
      {isSummaryReport == 'false' && (
        <NonSummary _id={_id} violations={violations} orgId={orgId} />
      )}
      {fullReport && <IssuesPdf _id={_id} orgId={orgId} downloadNew={downloadNew} />}
      <div className={styles.pagebreak} />
      <Contact />
    </div>
    ):(
      <AppLoader/>
    )

  );
});
