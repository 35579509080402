import * as React from 'react';
import Button from '@mui/material/Button';
// import {useEffect} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import AddTags from 'components/details/CommentSection/Add Tags/AddTags';
import {useNavigate} from 'react-router-dom';
import AddTagsModal from 'components/configure/Tags/AddTagsModal';
import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
// import {getTags} from 'redux/actions/Tags';
import {assignTagsScan} from 'redux/actions/Tags';
// import {getAllPermissions} from 'redux/actions/Roles';

const EllipsisDropdown = ({
  reportsCurPage,
  scanId,
  editModalOpen,
  deleteModalOpen,
  writeable,
  orgId,
  logsRead,
  matchingTags,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();

  // Tags Modal
  const [tagsModal, setTagsModal] = useState(false);

  const tagsModalOpen = () => {
    setTagsModal(true);
  };
  const tagsModalClose = () => {
    setTagsModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   dispatch(getAllPermissions());
  // }, []);

  const modalOpen = (name) => {
    switch (name) {
      case 'editModalOpenWrapper':
        editModalOpen();
        handleClose();
        break;
      case 'tagsModalOpenWrapper':
        tagsModalOpen();
        handleClose();
        break;
      case 'deleteModalOpenWrapper':
        deleteModalOpen();
        handleClose();
        break;
      default:
        break;
    }
  };

  //modal tags
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getTags());
  // }, [dispatch]);

  const allTags = useSelector(({tags}) => tags.allTagsData);
  const filteredTagIds = matchingTags.map((tag) => tag._id);

  const options = allTags
    .filter((tag) => tag.category === 'scan' && tag.isDeleted === false)
    .filter((tag) => !filteredTagIds.includes(tag._id))
    .map((tag) => ({
      value: tag.key,
      label: tag.value,
      url: tag.url ? tag.url : undefined,
      tagLibrary: true,
      tagId: tag._id,
    }));

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setAnchorEl(null);
  };

  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (newValue) => {
    setSelectedValues(newValue);
  };

  const handleSubmit = async () => {
    const filteredSelectedValues = selectedValues.filter(
      (item) => item.url !== '',
    );

    const tagIds = filteredSelectedValues.map((item) => item.tagId);
    let action = 'assign';
    await dispatch(assignTagsScan(tagIds, scanId, orgId, action));
    setSelectedValues([]);
    setIsModalOpen(false);
  };

  //tags permissions
  const loggedUser = useSelector(({common}) => common.userInfo);
  const allRoles = useSelector(({roles}) => roles.getAllRoles);
  const allPermissions = useSelector(({roles}) => roles.getAllPermissions);

  const currentOrg = loggedUser.usr.salesforceOrgs.find(
    (org) => org.sfdcId === orgId,
  );

  if (!currentOrg) {
    console.error('Org not found');
    return null;
  }

  const roleForCurrentOrg = allRoles?.find(
    (role) => role._id === currentOrg.roleId,
  );

  if (!roleForCurrentOrg) {
    console.error('role not found');
    return null;
  }

  const permissionsForCurrentOrg = (allPermissions || []).filter(
    (permission) => permission._id === roleForCurrentOrg.permissionsId,
  );

  const isDisabledAssign = !permissionsForCurrentOrg
  .flatMap((permission) => permission.permissions)
  .some((permission) => permission.object === 'assignTags');

  const isDisabledCreate = !permissionsForCurrentOrg
    .flatMap((permission) => permission.permissions)
    .some((permission) => permission.object === 'createTags');

  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon
          sx={{fontSize: 30, margin: 0, padding: '0 !important'}}
          color='primary'
        />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {writeable !== null && writeable !== undefined && (
          <div>
            <MenuItem
              disabled={!writeable}
              onClick={() => modalOpen('editModalOpenWrapper')}
            >
              Edit
            </MenuItem>
            <Divider sx={{my: 0.5}} />
            {/* <MenuItem
              disabled={!writeable}
              onClick={() => modalOpen('tagsModalOpenWrapper')}
            >
              Add Tags
            </MenuItem>
            <Divider sx={{my: 0.5}} /> */}
            <MenuItem
              onClick={() => navigate(`/scan-statistics/${scanId}/${orgId}`)}
            >
              Scan Statistics
            </MenuItem>
            <Divider sx={{my: 0.5}} />
            <MenuItem
              disabled={!logsRead}
              onClick={() =>
                navigate(`/setup?tab=logging&object=scan&objectId=${scanId}`)
              }
            >
              View Logs
            </MenuItem>
            <Divider sx={{my: 0.5}} />
            <MenuItem
              disabled={!writeable || isDisabledAssign}
              onClick={handleOpenModal}
            >
              Assign Tags
            </MenuItem>
            <Divider sx={{my: 0.5}} />
            <MenuItem
              disabled={!writeable}
              onClick={() => modalOpen('deleteModalOpenWrapper')}
            >
              Delete
            </MenuItem>
          </div>
        )}
      </Menu>
      <AddTags
        scanId={scanId}
        tagsModal={tagsModal}
        tagsModalClose={tagsModalClose}
        reportsCurPage={reportsCurPage}
      />
      <AddTagsModal
        title='Assign Scan Tags'
        selectLabel='Tags'
        selectValue={selectedValues}
        onChange={handleChange}
        options={options}
        open={isModalOpen}
        onClose={handleCloseModal}
        handleSubmit={handleSubmit}
        isDisabledAssign={isDisabledAssign}
        isDisabledCreate={isDisabledCreate}
        scanId={scanId}
        orgIdScan={orgId}
      />
    </div>
  );
};

export default EllipsisDropdown;

EllipsisDropdown.propTypes = {
  editModalOpen: PropTypes.func,
  matchingTags: PropTypes.array,
  deleteModalOpen: PropTypes.func,
  readOnly: PropTypes.bool,
  scanId: PropTypes.string,
  reportsCurPage: PropTypes.number,
  writeable: PropTypes.bool,
  orgId: PropTypes.string,
  orgIdScan: PropTypes.string,
  logsRead: PropTypes.bool,
};
