import React, {Fragment} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {TimelineOppositeContent} from '@mui/lab';
import HistoryItem from './HistoryItem';
import PropTypes from 'prop-types';

function History({history, assigned}) {
  return (
    <Fragment>
      <Timeline>
        {history
          ?.slice(0)
          .reverse()
          .map((historyItem, index) => {
            return (
              <TimelineItem key={index}>
                <TimelineOppositeContent
                  style={{display: 'none'}}
                ></TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <HistoryItem historyItem={historyItem} assigned={assigned} />
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
    </Fragment>
  );
}

export default History;

History.propTypes = {
  history: PropTypes.array,
  assigned: PropTypes.object,
};
