import React, {useEffect} from 'react';
import {AppCard} from '@crema';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AdvanceSetting from './AdvanceSetting';
import styles from './Advanced.module.css';
import {useSelector, useDispatch} from 'react-redux';
import {onGetScanTypes} from 'redux/actions';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {GET_SCAN_TYPES} from 'shared/constants/ActionTypes';
import {onGetGlobalConfigs} from 'redux/actions';
// import Switch from '@mui/material/Switch';
// import {
//   GET_CUR_REPORT_ID,
// } from 'shared/constants/ActionTypes';

const Advanced = ({writeable, isGlobal}) => {
  const scanTypes = useSelector(({dashboard}) => dashboard.scanTypes);
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);
  const isAdmin = useSelector(({authentication}) => authentication.isAdmin);
  // const reportsData = useSelector(({reports}) => reports.reportsData);
  let {org_id} = useParams();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch({
  //     type: GET_CUR_REPORT_ID,
  //     payload: reportsData?.length ? reportsData[0]?._id : null,
  //   });
  // }, [reportsData]);

  // console.log('Current report Id: ', reportsData);
  useEffect(() => {
    if (!isGlobal) {
      dispatch(onGetScanTypes({org_id}));
    }
    return () => {
      dispatch({type: GET_SCAN_TYPES, payload: null});
    };
  }, [org_id]);

  useEffect(() => {
    dispatch(onGetGlobalConfigs(true));
  }, []);

  // const [switchState, setSwitchState] = useState(false);

  // const handleSwitchChange = () => {
  //   setSwitchState((prev) => !prev);
  //   localStorage.setItem('getNew', !switchState ? 'true' : 'false');
  // };

  // useEffect(() => {
  //   const storedSwitchState = localStorage.getItem('getNew');
  //   if (storedSwitchState) {
  //     setSwitchState(storedSwitchState === 'true');
  //   }
  // }, []);

  return (
    <AppCard>
      <div
        className={isGlobal ? styles.globalAdvanceHeader : styles.advanceHeader}
      >
        {!isGlobal && <SettingsOutlinedIcon />}
        <h3>Advanced</h3>
      </div>

      {!isGlobal && (
        <div className={styles.switchContainer}>
          <p className={styles.rulesetSub}>
            {scanTypes?.isenabled
              ? 'Scan Settings as defined on this Workspace page are now ACTIVE'
              : 'Scan Settings as defined at the Environment level are now ACTIVE'}
          </p>
        </div>
      )}

      <div className={styles.scanAdvancedInfo}>
        <div className={isGlobal ? styles.hiddenDesc : ''}>
          <p className={styles.rulesetSub}>
            Advanced settings managed on this tab apply only to this workspace.
          </p>
          <p className={styles.rulesetSub}>
            Changes will not impact scans that have already completed, only
            future scans.
          </p>
        </div>
      </div>

      {((isGlobal && globalConfigs) || (!isGlobal && scanTypes)) && (
        <>
          <AdvanceSetting
            advanceHeading='Code Quality'
            disabled={
              !writeable ||
              (isGlobal === false && scanTypes?.isenabled === false)
            }
            advanceSubtitle='by PMD'
            name='code_quality'
            advanceSwitch={
              isGlobal === false && scanTypes?.isenabled === false
                ? globalConfigs?.code_quality
                : isGlobal
                ? globalConfigs?.code_quality
                : scanTypes?.code_quality || false
            }
            isGlobal={isGlobal || false}
          />

          <AdvanceSetting
            advanceHeading='Track Issues per Workspace'
            disabled={
              !writeable ||
              (isGlobal === false && scanTypes?.isenabled === false)
            }
            advanceSubtitle='Create and track issues per workspace'
            name='track_finding_per_org'
            advanceSwitch={
              isGlobal === false && scanTypes?.isenabled === false
                ? globalConfigs?.track_finding_per_org
                : isGlobal
                ? globalConfigs?.track_finding_per_org
                : scanTypes?.track_finding_per_org || false
            }
            isGlobal={isGlobal || false}
          />

          <AdvanceSetting
            advanceHeading='New Issues Scan'
            disabled={
              !writeable ||
              (isGlobal === false && scanTypes?.isenabled === false)
            }
            advanceSubtitle='Scan will default to displaying new issues only'
            name='show_new_findings'
            advanceSwitch={
              isGlobal === false && scanTypes?.isenabled === false
                ? globalConfigs?.show_new_findings
                : isGlobal
                ? globalConfigs?.show_new_findings
                : scanTypes?.show_new_findings || false
            }
            isGlobal={isGlobal || false}
          />

          {isAdmin && isGlobal && (
            <AdvanceSetting
              advanceHeading='User Notifications'
              disabled={!writeable}
              advanceSubtitle='When "on" this setting allows the system to send scan activity, scheduler activity, and issue detail update notifications to all users. Indvidual users can override this setting in their User Profile. When "off", this setting will block all notifications except User Account Management Notifications, such as Password Reset.'
              name='account_wide_alerts'
              advanceSwitch={
                isGlobal
                  ? globalConfigs?.account_wide_alerts
                  : scanTypes?.account_wide_alerts || false
              }
              isGlobal={isGlobal || false}
            />
          )}

          {isAdmin && isGlobal && (
            <>
              <AdvanceSetting
                advanceHeading='Required Comments'
                disabled={!writeable}
                advanceSubtitle='Every status change would require a comment from users'
                name='required_comments_findings'
                advanceSwitch={
                  isGlobal
                    ? globalConfigs?.required_comments_findings
                    : scanTypes?.required_comments_findings || false
                }
                isGlobal={isGlobal || false}
              />
            </>
          )}

          {/* <div className={styles.singleScanRuleContainer}>
            <div className={styles.rulesetInfo}>
              <div className={styles.rulesetIcon}>
                <SettingsOutlinedIcon color='primary' />
              </div>
              <div>
                <p>New Findings</p>
                <p className={styles.rulesetSubheading}>
                  Show only findings
                </p>
              </div>
            </div>
            <div className={styles.rulesetSwitch}>
              <Switch
                checked={switchState}
                onChange={handleSwitchChange}
                color='primary'
                inputProps={{'aria-label': 'controlled'}}
              />
            </div>
          </div> */}
        </>
      )}
      {/* <AdvanceSetting
        advanceHeading='Credential Scanning'
        advanceSubtitle=''
        name='credential_scanning'
        disabled={!writeable}
        advanceSwitch={
          isGlobal
            ? globalConfigs.credential_scanning
            : scanTypes?.credential_scanning || false
        }
        isGlobal={isGlobal || false}
      />*/}
    </AppCard>
  );
};

export default Advanced;
Advanced.propTypes = {
  writeable: PropTypes.bool,
  isGlobal: PropTypes.bool,
};
