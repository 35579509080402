import {SPECIFIC_ORG_USERS, TEAM_RESPONSE} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  specificOrgUsers: null,
  teamResponse: null,
};

const Team = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SPECIFIC_ORG_USERS:
      return {
        ...state,
        specificOrgUsers: action.payload,
      };
    case TEAM_RESPONSE:
      return {
        ...state,
        teamResponse: action.payload,
      };
    default:
      return state;
  }
};
export default Team;
