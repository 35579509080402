import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {get_oauth_token} from 'redux/actions';
import {useDispatch} from 'react-redux';
import ProcessLoader from 'components/ProcessLoader/ProcessLoader';
import styles from './Authenticate.module.css';
import PlaceHolderDiv from 'components/PlaceHolderDiv/PlaceHolderDiv';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {ADD_SALESFORCE_ORG_RESULT} from 'shared/constants/ActionTypes';

const linkStyle = {
  textDecoration: 'none',
  color: '#209ef7',
  marginTop: '20px',
  fontSize: '16px',
  padding: '9px',
  borderRadius: '8px',
  border: '1px solid #209ef7',
};

const Authenticate = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_oauth_token({code, state}));
    return () => {
      dispatch({type: ADD_SALESFORCE_ORG_RESULT, payload: null});
    };
  }, []);

  const addSalesforceOrgResult = useSelector(
    ({salesforce}) => salesforce.addSalesforceOrgResult,
  );

  return (
    <>
      <div className={styles.authenticateContainer}>
        {addSalesforceOrgResult == null && (
          <>
            <ProcessLoader />
            <h2>Validating your Workspace!</h2>
          </>
        )}
        {addSalesforceOrgResult && (
          <>
            <PlaceHolderDiv
              message={addSalesforceOrgResult?.message}
              status={addSalesforceOrgResult?.status}
            />
            <Link
              to={{
                pathname: '/salesforce',
              }}
              style={linkStyle}
            >
              Home
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default Authenticate;
