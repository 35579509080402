import {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './Phone.module.css';
import {Button} from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useDispatch ,useSelector} from 'react-redux';
import {enable2fa, onUpdateProfile} from 'redux/actions/Profile';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { forwardRef } from 'react';
function PhoneModal({
  open,
  handleClosefun,
  profileData,
  setProfile
}) {
  const countryCodes = useSelector(({profile}) => profile.countryCodes);
  const profileResponse = useSelector(({profile}) => profile.profileResponse);
  const [phoneNumber,setPhoneNumber]=useState("");
  const [countryCode,setCountryCode]=useState("");
  const [checkForRes,setCheckForRes]=useState(false);
  const dispatch = useDispatch();
  const [errorOpen,setErrorOpen]=useState(false);

  useEffect(()=>{
    if(errorOpen){
      setTimeout(()=>{
        setErrorOpen(false);
      },3000);
      }
    },[errorOpen]);

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


  const validateInputs = () => {
    if (phoneNumber === "" || countryCode==="") {
      // Phone number is empty
      return false;
    }
      const numberRegex = /^[0-9]+$/;
  
    if (!numberRegex.test(phoneNumber)) {
      return false;
    }
  
    return true;
  };

  
  useEffect(()=>{
  //  console.log(profileResponse);
  //  console.log(checkForRes);
    if(profileResponse?.message=="Profile Updated Successfully!" && checkForRes){
      // console.log("phone 2fa emabling");
      dispatch(enable2fa("phone"));
      handleClosefun();
      setCheckForRes(false);
    }
   
  },[profileResponse,checkForRes]);

  useEffect(()=>{
    if(checkForRes)
    dispatch(onUpdateProfile(profileData));
  },[profileData]);

  const onSubmit=()=>{
    //console.log({...profileData,phone:phoneNumber,internationalCode:countryCode});
    if(validateInputs()){
      //console.log("ion");
      setCheckForRes(true);
      setProfile({...profileData,phone:phoneNumber,phone_num:phoneNumber,internationalCode:countryCode});
    }
    else{
    setErrorOpen(true);
    }
  
  };

 
  return (
    <>
      <Snackbar
  open={errorOpen}
  autoHideDuration={6000}
  message="All fields are required.Please fill them all."
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
>
<Alert  severity="error" sx={{ width: '100%' }}>
All fields are required. Please fill them all in correct format.</Alert>
</Snackbar>
      <Modal
    open={open}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
    size={"lg"}
    onClose={handleClosefun}
  >
    <div className={styles.modal}>
    <Typography variant="h3" className={styles.firstHeading} gutterBottom>
    Two-Factor Authentication (2FA)
      </Typography>
      <label className={styles.textLabel}>Phone Number:</label>
            <div className={styles.groupPhoneNumber}>
              <Select
                className={styles.internationalCode}
                id='internationalCode'
                variant='filled'
                name='internationalCode'
                onChange={(e)=>setCountryCode(e.target.value)}
                value={countryCode}
                displayEmpty
                renderValue={(selected) => {
                  if (selected?.length === 0) {
                    return <p>Country Code</p>;
                  }

                  return selected;
                }}
              >
                {countryCodes.length > 0 &&
                  countryCodes.map((item, index) => (
                    <MenuItem value={item.code} key={index}>
                      {`${item.name} ${item.code}`}
                    </MenuItem>
                  ))}
              </Select>
              <TextField
                id='phone_num'
                variant='filled'
                name='phone_num'
                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                value={phoneNumber}
                onChange={(e)=>setPhoneNumber(e.target.value)}
                className={styles.phone_num}
                placeholder='Phone Number'
              />
            </div>
      <div className={styles.buttons}>
    <Button onClick={()=>handleClosefun()} size="large" variant="outlined">Cancel</Button>

      <Button onClick={()=>onSubmit()} sx={{ml:3}} size="large" variant="contained">Enable 2FA</Button>
    </div>
    </div>
  </Modal>
  </>

  );
}

export default PhoneModal;

PhoneModal.propTypes = {
  open: PropTypes.bool,
  handleClosefun:PropTypes.func,
  profileData:PropTypes.object,
  setProfile:PropTypes.func
};
