import React from 'react';
import styles from './Process.module.css';

function Process() {
  return (
    <div className={styles.processContainer}>
      <h1>Process</h1>
      <p>
        DigitSec for Salesforce utilizes static, dynamic,
        along with configuration testing in order to rapidly identify
        vulnerabilities in a Salesforce environment. The following figure shows
        the key components of the scanning process.
      </p>

    </div>
  );
}

export default Process;
