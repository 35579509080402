
import styles from './TwoFactorSignIn.module.css';
import {Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import AuthWrapper from '../AuthWrapper';
import AppLogo from '../../../@crema/core/AppLayout/components/AppLogo';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { forwardRef, useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { validate2faOtpRequest,resetError } from 'redux/actions/Profile';
import {useNavigate} from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useAuthMethod} from '@crema/utility/AuthHooks';

import { emptyToken } from 'redux/actions/Profile';

function TwoFactorSignIn() {
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const token = useSelector(({profile}) => profile.token);
  const error = useSelector(({profile}) => profile.error);
  const user2FaData = useSelector(({profile}) => profile.user2FaData);

  const {setJWTAuthData} = useAuthMethod();

  let navigate = useNavigate();
  const [errorOpen,setErrorOpen]=useState(false);

const [code,setCode]=useState("");
const dispatch=useDispatch();
const verifyToken=async()=>{  

  dispatch(resetError());
    await dispatch(validate2faOtpRequest(user2FaData.email,user2FaData.password,code));
};

useEffect(()=>{
if(token){
  setJWTAuthData({
    user: user2FaData,
    isAuthenticated: true,
    isLoading: false,
  });
  dispatch(emptyToken());
  navigate("/salesforce");
  }
},[token]);
//console.log("token",token);
useEffect(()=>{
  if(!localStorage.getItem("logedIn")=="true"){
    // console.log("inside");
     navigate("/signin");
  }
},[]);

useEffect(()=>{
  if(errorOpen){
    setTimeout(()=>{
      setErrorOpen(false);
    },3000);
    }
  },[errorOpen]);

useEffect(()=>{
  //console.log("error open",error);
  if(error.length>0){
   setErrorOpen(true);
    }
    else{
    setErrorOpen(false);
    }
  },[error]);

  return (
    <>
    <Snackbar
  open={errorOpen}
  autoHideDuration={6000}
  message="Invalid OTP Entered"
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
>
<Alert  severity="error" sx={{ width: '100%' }}>
Invalid OTP Entered
  </Alert>
</Snackbar>
    <AuthWrapper>
      <Box sx={{width: '100%'}}>
        <Box sx={{mb: {xs: 6, xl: 8}}}>
          <Box
            sx={{
              mb: 5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <AppLogo />
          </Box>
        </Box>
        <Typography variant='h1'  className={styles.heading} >Two Factor Authentication</Typography>
        <Typography variant='body1'  className={styles.para} >Open the two-step verification app on your mobile device to get the your verification code</Typography>
        <TextField autoFocus sx={{mt:3,mb:3,width:"100%"}} type={'text'} value={code} onChange={(e)=>setCode(e.target.value)} id="outlined-basic" placeholder='Enter Code' variant="outlined" />
        <Button type='submit' className={styles.authBtn} size="large" variant="contained" onClick={()=>verifyToken()}  >Authenticate</Button>
        <Link href="/signin" className={styles.loginLink} >Back to basic login</Link>
        
      </Box>
    </AuthWrapper>
    </>

  );
}

export default TwoFactorSignIn;

