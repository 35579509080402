import React from 'react';
import AppCard from '@crema/core/AppCard';
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';
import {Link} from 'react-router-dom';

const Success = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AppCard style={{height: '300px'}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <DoneAllSharpIcon color='primary' sx={{fontSize: 100}} />
          <h1 style={{margin: '20px'}}>Workspace Added Successfully</h1>
          <Link
            to={{
              pathname: '/salesforce',
            }}
            style={{
              textDecoration: 'none',
              color: '#209ef7',
              marginTop: '20px',
              fontSize: '16px',
              padding: '9px',
              borderRadius: '8px',
              border: '1px solid #209ef7',
            }}
          >
            Home
          </Link>
        </div>
      </AppCard>
    </div>
  );
};

export default Success;
