import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import jwtAxios, {getLocalStorageToken, setAuthToken} from './index';
import {useNavigate} from 'react-router-dom';
import TwoStepVerification from 'pages/auth/TwoStepVerification/TwoStepVerification';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  let navigate = useNavigate();
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const [twoFA, setTwoFa] = useState({display: false, value: '', type: ''});

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = getLocalStorageToken();
      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }

      setAuthToken(token);
      jwtAxios
        .post('api/verify_authentication', {})
        .then(({data}) => {
          if (data instanceof Object != true) {
            setAuthToken();
            throw new Error('Error');
          }
          setJWTAuthData({
            user: data,
            isLoading: false,
            isAuthenticated: true,
          });
        })
        .catch(() => {
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          });
        });
    };

    getAuthUser();
  }, []);

  jwtAxios.interceptors.response.use(
    (res) => {
      if (
        res.data?.auth == false &&
        (res.data.message == 'Failed to authenticate token.' ||
          res.data.message == 'No token provided.')
      ) {

        logout();
      }
      return res;
    },
    (err) => {
      return Promise.reject(err);
    },
  );



  const signInUser = async ({email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('api/login', {email, password});
      if ((data.auth == '2fa' && data.type == 'email') || (data.auth == '2fa' && data.type == 'phone') || (data.auth == '2fa' && data.type=="qrScan")) {
        //console.log("2fa type",data.type);
        if(data.type === 'email'){
        setTwoFa((cur) => {
          return {
            ...cur,
            display: true,
            type: 'email',
            value: {
              email: email,
              password: password,
            },
          };
        });
        dispatch({type: FETCH_SUCCESS});
      }else if(data.type === 'phone'){
        setTwoFa((cur) => {
          return {
            ...cur,
            display: true,
            type: 'phone',
            value: {
              email: email,
              password: password,
            },
          };
        });
        dispatch({type: FETCH_SUCCESS});
      }
      else if (data.type=="qrScan") {
        let dt;
        dt={
          email: email,
          password: password
        };
       /*dt={
        id: data.id,
        customer_id: data.customer_id,
        secret: data.secret
       };*/
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: "Set_User_2FA",payload:dt});
        //localStorage.setItem("logedIn",true);
        navigate('/2fa-signin');
      }
      }
      else {
        if (data.auth == false) {
          throw {
            message: data.message,
          };
        }
        if (data.token == undefined) {
          throw 'Undefined';
        }
        
        setAuthToken(data.token);

        setJWTAuthData({
          user: data.company,
          isAuthenticated: true,
          isLoading: false,
        });
        dispatch({type: FETCH_SUCCESS});
        const returnUrl = localStorage.getItem('returnUrl');
        if (returnUrl && returnUrl !== '/signin') {
          localStorage.removeItem('returnUrl');
          navigate(returnUrl);
        }
      }
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({
        type: FETCH_ERROR,
        payload: error?.message || 'We encountered an error! Please try again.',
      });
    }
  };

  const signUpUser = async ({
    firstname,
    lastname,
    email,
    password,
    role,
    company,
    recaptchtoken,
    terms,
  }) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('api/signup', {
        firstname,
        lastname,
        email,
        password,
        role,
        company,
        recaptchtoken,
        terms,
      });

      if (data.error) {
        dispatch({
          type: FETCH_ERROR,
          payload: data?.error || 'Something went wrong',
        });
      } else {
        setAuthToken(data.token);
        const res = await jwtAxios.get('/user/get');
        setJWTAuthData({
          user: res.data.email,
          isAuthenticated: true,
          isLoading: false,
        });
        dispatch({type: FETCH_SUCCESS});
      }
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.error || 'Something went wrong',
      });
    }
  };

  const logout = async () => {
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  const resetPassword = async ({email, recaptchtoken}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('passwordreset', {
        email,
        recaptchtoken,
      });

      if (data.error) {
        dispatch({
          type: FETCH_ERROR,
          payload: data?.error || 'Something went wrong',
        });
      } else if (data.success) {
        dispatch({type: SHOW_MESSAGE, payload: data.success});
        setTimeout(navigate('signin'), 6000);
      }
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.error || 'Something went wrong',
      });
    }
  };

  const verifyPassword = async ({emailtoken, recaptchtoken, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post(`verifypassword/${emailtoken}`, {
        emailtoken,
        recaptchtoken,
        password,
      });

      if (data.error) {
        dispatch({
          type: FETCH_ERROR,
          payload: data?.error || 'Something went wrong',
        });
      } else if (data.success) {
        dispatch({type: SHOW_MESSAGE, payload: 'Password Reset Successfully!'});
        setTimeout(navigate('signin'), 6000);
      }
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.error || 'Something went wrong',
      });
    }
  };

  const twFAModalClose = () => {
    setTwoFa((cur) => {
      return {
        ...cur,
        display: false,
      };
    });
  };

  const emailVerification = async (event) => {
    event.preventDefault();
    twFAModalClose();
    try {
      const {data} = await jwtAxios.post('verify2fa', {
        code: event.target.name.value,
        email: twoFA.value.email,
        password: twoFA.value.password,
      });
      if (data.auth == false) {
        throw 'OTP Not Correct, Please try again.';
      }
      if (data.token == undefined) {
        throw 'Something went wrong! Please try again';
      }
      setAuthToken(data.token);
      setJWTAuthData({
        user: data.company,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({
        type: FETCH_ERROR,
        payload:
          error?.response?.data?.error || error || 'Something went wrong',
      });
    }
  };

  const googleSSO = async () => {
    window.location.href = `${window.env.REACT_APP_BACKEND_API}/auth/google`;
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          resetPassword,
          verifyPassword,
          googleSSO,
          setJWTAuthData,
          setAuthToken
        }}
      >
        {children}
        {twoFA.display && (
          <TwoStepVerification
            open={twoFA.display}
            handleModalClose={twFAModalClose}
            emailVerification={emailVerification}
            type={twoFA.type}
          />
        )}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
