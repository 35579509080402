/*eslint-disable */
import React, {useEffect, useState} from 'react';
import {IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppNotificationContent from './AppNotificationContent';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AppTooltip from '../AppTooltip';
import {alpha} from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import {updateNotifications} from 'redux/actions';
import {useSelector, useDispatch} from 'react-redux';
import { GET_USER_NOTIFICATIONS } from 'shared/constants/ActionTypes';
// import {SET_SOCKET_VALUE} from 'shared/constants/ActionTypes';

const AppNotifications = ({
  tooltipPosition,
  isMenu,
  sxNotificationContentStyle,
}) => {
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState(false);
  // const socketValue = useSelector(({dashboard}) => dashboard.socketValue);
  const userNotifications = useSelector(({details}) => details.userNotifications);
  const showBadge = (
    //socketValue && Object.keys(socketValue).length > 0) || 
    Array.isArray(userNotifications) && userNotifications.some(notification => !notification.isRead)
  );
  
  const [userNotificationsIds, setUserNotificationsIds] = useState([]);

  // useEffect(() => {
  //   setUserNotificationsIds(userNotifications.map(notification => notification._id));
  // }, [userNotifications]);

  useEffect(() => {
    if (Array.isArray(userNotifications)) {
      setUserNotificationsIds(userNotifications.map(notification => notification._id));
    } else {
      console.warn();
    }
  }, [userNotifications]);
  
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowNotification(true);
  };
  const handleClose = () => {
    // if (socketValue && typeof socketValue === 'object' && '_id' in socketValue) {
    //   const notificationIds = [socketValue._id];      
    //   dispatch(updateNotifications({notificationIds: notificationIds}));
    //   dispatch({ type: SET_SOCKET_VALUE , payload: [] });
    // }
    if (userNotificationsIds && userNotificationsIds.length > 0) {
      dispatch(updateNotifications({ notificationIds: userNotificationsIds }));
    }
  
    dispatch({ type: GET_USER_NOTIFICATIONS, payload: [] });
  
    setShowNotification(false);
    setAnchorEl(null);
  };

  return (
    <>
      {isMenu ? (
        <Box component='span' onClick={() => setShowNotification(true)}>
          Message
        </Box>
      ) : (
        <AppTooltip title='Notifications' placement={tooltipPosition}>
          <IconButton
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            className='icon-btn'
            sx={{
              position: 'relative',
              borderRadius: '50%',
              width: 40,
              height: 40,
              color: (theme) => theme.palette.text.secondary,
              backgroundColor: (theme) => theme.palette.background.default,
              border: 1,
              borderColor: 'transparent',
              '&:hover, &:focus': {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: (theme) =>
                  alpha(theme.palette.background.default, 0.9),
                borderColor: (theme) =>
                  alpha(theme.palette.text.secondary, 0.25),
              },
            }}
            onClick={handleClick}
            size='large'
          >
            <NotificationsNoneIcon />
          </IconButton>
          {showBadge && (
            <Badge
              badgeContent=''
              variant='dot'
              color='error'
              overlap='circular'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                top: -15,
                right: 5,
              }}
            />
          )}
        </AppTooltip>
      )}

      {/* <Drawer
        anchor={drawerPosition}
        open={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <AppNotificationContent
          sxStyle={sxNotificationContentStyle}
          onClose={() => setShowNotification(false)}
        />
      </Drawer> */}

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {showNotification && (
          <AppNotificationContent
            sxStyle={sxNotificationContentStyle}
            onClose={handleClose}
          />
        )}
      </Menu>
    </>
  );
};

export default AppNotifications;

AppNotifications.defaultProps = {
  drawerPosition: 'right',
  tooltipPosition: 'bottom',
};

AppNotifications.propTypes = {
  drawerPosition: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isMenu: PropTypes.bool,
  sxNotificationContentStyle: PropTypes.object,
};
