import React from 'react';
import PropTypes from 'prop-types';
import styles from './RemediationGuidelines.module.css';

function RemediationGuidelines({remediationguidance}) {
  return (
    <div>
      <h3>Remediation Guidelines</h3>

      <ul className={styles.remediationList}>
        {remediationguidance.map((item, index) => {
          return (
            <li className={styles.remediationListItem} key={index}>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

RemediationGuidelines.propTypes = {
  remediationguidance: PropTypes.array,
};

export default RemediationGuidelines;
