import React, {Fragment, useState} from 'react';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import styles from './CCScratchForm.module.css';
import {Button} from '@mui/material';
import {useDispatch} from 'react-redux';
import {addOfflineCC} from 'redux/actions';
import PropTypes from 'prop-types';

function CCScratchForm({closeModal}) {
  const [name, setName] = useState('');
  const dispatch = useDispatch();

  const nameChangeHandler = (event) => {
    setName(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(addOfflineCC({name}));
    setName('');
    closeModal();
  };

  return (
    <Fragment>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <form onSubmit={submitHandler}>
          <div className={styles.inputContainer}>
            <InputLabel className={styles.labelInput}>
              Workspace Name
            </InputLabel>
            <TextField
              id='filled-basic'
              label='Name'
              variant='filled'
              placeholder='Name to Remember your Salesforce'
              name='name'
              value={name}
              onChange={nameChangeHandler}
              sx={{
                width: {xs: '100%', sm: '100%', md: '420px'},
              }}
              required
            ></TextField>
          </div>

          <div
            style={{display: 'flex', justifyContent: 'flex-end', gap: '15px'}}
          >
      
            <Button variant='contained' color='secondary' onClick={closeModal}>
              Cancel
            </Button>
            <Button variant='contained' type='submit'>
              Save
            </Button>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default CCScratchForm;

CCScratchForm.propTypes = {
  closeModal: PropTypes.func,
};
