import React from 'react';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Switch from '@mui/material/Switch';
import styles from './ScanStatistics.module.css';
import PropTypes from 'prop-types';

const label = {inputProps: {'aria-label': 'Scan Rule'}};

const ActiveScanRule = ({title, vulnid, active}) => {
  return (
    <div className={styles.activeScanRule}>
      <div className={styles.scanRuleInfoContainer}>
        <CloudQueueIcon color='primary' />
        <div className={styles.scanRuleInfo}>
          <p>{title}</p>
          <p>{vulnid}</p>
        </div>
      </div>
      <div>
        <Switch {...label} checked={active} />
      </div>
    </div>
  );
};

export default ActiveScanRule;

ActiveScanRule.propTypes = {
  title: PropTypes.string,
  vulnid: PropTypes.string,
  active: PropTypes.bool,
};
