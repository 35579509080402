import React, {useState, useEffect} from 'react';
import styles from './ScanTypes.module.css';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {onPostScanTypes} from 'redux/actions';
import {useParams} from 'react-router-dom';

const label = {inputProps: {'aria-label': 'Switch demo'}};

function ScanTypes({name, checkbool, name_key, writeable}) {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  let {org_id} = useParams();
  const scanTypes = useSelector(({dashboard}) => dashboard.scanTypes);

  useEffect(() => {
    setChecked(checkbool);
  }, [checkbool]);
  

  const switchChangeHandler = (event) => {
    setChecked(event.target.checked);
    dispatch(
      onPostScanTypes(
        org_id,
        Object.assign({}, scanTypes, {[name_key]: event.target.checked}),
      ),
    );
  };

  return (
    <div className={styles.scanTypeContainer}>
      <div className={styles.scanIconName}>
        <div className={styles.settingIcon}>
          <SettingsOutlinedIcon color='primary' />
        </div>
        <div className={styles.scanTypeName}>
          <p>{name}</p>
        </div>
      </div>

      <div>
        <Switch
          {...label}
          onChange={switchChangeHandler}
          checked={checked}
          disabled={!writeable}
        />
      </div>
    </div>
  );
}

export default ScanTypes;

ScanTypes.propTypes = {
  name: PropTypes.string,
  checkbool: PropTypes.bool,
  updateHandler: PropTypes.func,
  name_key: PropTypes.string,
  writeable: PropTypes.bool,
};
