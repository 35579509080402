import React, {useEffect} from 'react';
import {onGetComplianceFindingsData} from 'redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {GET_FINDINGS_DATA} from 'shared/constants/ActionTypes';
import styles from './SummaryReport.module.css';
import HeaderIssues from '../FindingsDetail/HeaderFindings/HeaderFindings';
import ComplianceViolations from '../ComplianceViolations/ComplianceViolations';

const SummaryReport = ({_id, violations, orgId}) => {
  const dispatch = useDispatch();
  const getFindingsData = useSelector(({reports}) => reports.getFindingsData);
  useEffect(() => {
    dispatch(onGetComplianceFindingsData({_id, violations, orgId}));

    return () => {
      dispatch({type: GET_FINDINGS_DATA, payload: null});
    };
  }, []);

  return (
    <div className={styles.issuesContainer}>
      {getFindingsData != null &&
        getFindingsData.map((finding, index) => {
          return (
            <div key={finding._id} className={styles.headerIssues}>
              <div className={styles.pagebreak} />
              <HeaderIssues
                index={index}
                severity={finding.severity}
                issue={finding.issue}
                filename={finding.filename}
                line={finding.line}
                status={finding.status}
                founddate={finding.founddate}
                issueId={finding?.issueId}
                _id={finding._id}
              />
              <ComplianceViolations finding={finding} />
            </div>
          );
        })}
    </div>
  );
};

export default SummaryReport;

SummaryReport.propTypes = {
  _id: PropTypes.string,
  violations: PropTypes.string,
  orgId: PropTypes.string
};
