import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CommerceCloudForm from './CommerceCloudForm/CommerceCloudForm';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import CCScratchForm from './CloudCommerceScratch/CCScratchForm';
import DownloadForOfflineSharpIcon from '@mui/icons-material/DownloadForOfflineSharp';
import {useDispatch} from 'react-redux';
import {ADD_SALESFORCE_ORG_RESULT} from 'shared/constants/ActionTypes';
import {useEffect} from 'react';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CommerceCloudOrgTab({closeModal}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({type: ADD_SALESFORCE_ORG_RESULT, payload: null});
    };
  }, []);

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Workspace Info' {...a11yProps(0)} />
          <Tab label='Scratch (Offline) Workspace' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <ShoppingCartSharpIcon color='primary' />
          <h2> Workspace Info</h2>
        </div>

        <div style={{display: 'flex', justifyContent: 'center', height: '400px', overflowY:'scroll' }}>
          <CommerceCloudForm closeModal={closeModal} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <DownloadForOfflineSharpIcon color='primary' />
          <h2> Add Scratch (Offline) Workspace</h2>
        </div>

        <div style={{display: 'flex', justifyContent: 'center'}}>
          <CCScratchForm closeModal={closeModal} />
        </div>
      </TabPanel>
    </Box>
  );
}

CommerceCloudOrgTab.propTypes = {
  closeModal: PropTypes.func,
};
