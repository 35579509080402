import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Papa from 'papaparse';
import styles from './AddIgnoreFile.module.css';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {onPutCustomRules} from 'redux/actions';
import {onPutCustomRulesBulk} from 'redux/actions';
import {useParams} from 'react-router-dom';
import {useState} from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '350px',
    sm: '500px',
    md: '500px',
    lg: '500px',
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const styleFileModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: 'auto',
};

export default function AddIgnoreFile({writeable}) {
  let {org_id} = useParams();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const userInfo = useSelector(({common}) => common.userInfo);

  const [formValue, setFormValue] = useState({fileName: '', reason: ''});

  const cancelHandler = () => {
    setFormValue({fileName: '', reason: ''});
    setOpen(false);
  };

  const dispatch = useDispatch();
  const saveHandler = () => {
    dispatch(onPutCustomRules({org_id, ...formValue}));
    setFormValue({fileName: '', reason: ''});
    setOpen(false);
  };

  const fileNameChangeHandler = (event) => {
    setFormValue((cur) => {
      return {
        ...cur,
        fileName: event.target.value,
      };
    });
  };

  const reasonChangeHandler = (event) => {
    setFormValue((cur) => {
      return {
        ...cur,
        reason: event.target.value,
      };
    });
  };

  const [createdBy, setCreatedBy] = useState('');
  const date = new Date();
  const createdAt = date.toISOString();

  useEffect(() => {
    if (userInfo != null) {
      setCreatedBy(userInfo.usr.email);
    }
  }, [userInfo, dispatch]);

  const [openFileModal, setOpenFileModal] = useState(false);

  const handleOpenFileModal = () => {
    setOpenFileModal(true);
  };
  const handleCloseFileModal = () => {
    setOpenFileModal(false);
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const ignoreFiles = result.data.map((item) => ({
            filename: item.filename,
            reason: item.reason,
          }));

          // console.log({
          //   isBulkFile: true,
          //   ignoreFiles,
          //   createdBy,
          //   createdAt,
          // });

          dispatch(
            onPutCustomRulesBulk({org_id, ignoreFiles, createdBy, createdAt}),
          );
          handleCloseFileModal();
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };

  return (
    <div>
      <Button variant='contained' onClick={handleOpen} disabled={!writeable}>
        <AddIcon sx={{color: 'white', fontSize: 25}} />
        Add
      </Button>
      &nbsp;
      <Button
        onClick={handleOpenFileModal}
        sx={{marginRight: 1}}
        variant='contained'
        disabled={!writeable}
      >
        <FileUploadIcon sx={{fontSize: 25}} />
        Upload
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className={styles.heading}>
            <InfoRoundedIcon color='primary' sx={{fontSize: 40}} />
            <h2>Filenames to ignore</h2>
          </div>
          <div className={styles.subHeading}>
            <p>
              Please enter full name including extention of the file for
              example: ignoreController.cls
            </p>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>File Name</InputLabel>
              <TextField
                id='FileName'
                label='File Name'
                variant='filled'
                value={formValue.fileName}
                onChange={fileNameChangeHandler}
              ></TextField>
            </div>
            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>Reason</InputLabel>
              <TextField
                id='Reason'
                label='Reason'
                variant='filled'
                value={formValue.reason}
                onChange={reasonChangeHandler}
              ></TextField>
            </div>
          </div>
          <div className={styles.actionButtons}>
            <Button
              onClick={cancelHandler}
              color='secondary'
              variant='contained'
            >
              Cancel
            </Button>
            <Button variant='contained' onClick={saveHandler}>
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openFileModal}
        onClose={handleCloseFileModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleFileModal}>
          <div className={styles.heading}>
            <InfoRoundedIcon color='primary' sx={{fontSize: 40}} />
            <h2>Upload a file to ignore</h2>
          </div>
          <div className={styles.subHeading}>
            <p>Please upload a file in a .csv format to load the fields.</p>
          </div>
          <div className={styles.formContainer}>
            <input
              accept='.csv'
              style={{display: 'block', margin: '20px 0', padding: '15px'}}
              id='raised-button-file'
              multiple
              type='file'
              onChange={handleFileChange}
            />
          </div>
          <div className={styles.actionButtons}>
            <Button
              onClick={handleCloseFileModal}
              color='secondary'
              variant='contained'
            >
              Cancel
            </Button>
            <Button variant='contained' onClick={handleUpload} disabled={!file}>
              Upload
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

AddIgnoreFile.propTypes = {
  writeable: PropTypes.bool,
};
